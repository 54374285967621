import { Checkbox, Form } from 'antd'
import React from 'react'

const ShowEmptyRows = () => {
  return (
    <Form.Item hidden valuePropName="checked" key="showEmptyRows" name={['params', 'showEmptyRows']}>
      <Checkbox />
    </Form.Item>
  )
}

export default ShowEmptyRows
