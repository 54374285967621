import { useMutation, useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CustomReportGroup } from '../../../../redux/context/customReports/types'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { updateCustomReportGroup } from '../../service/customReportGroupService'
import customReportQueryKeys from '../customReportQueryKeys'

export const useCtxUpdateCustomReportGroup = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { notification } = App.useApp()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: Pick<CustomReportGroup, 'id' | 'name'>) => updateCustomReportGroup(data.id, data, companyId),
    onSuccess: () => {
      notification.success({
        message: t('message:EDIT_CUSTOM_REPORT_GROUP_SUCCESS', 'Request sent')
      })
      return queryClient.invalidateQueries({
        queryKey: customReportQueryKeys.groups({ companyId })
      })
    }
  })
}
