import { BudgetingScenario } from '../types/BudgetingScenario'
import { useScenarios } from './useScenarios'

type UseScenarioProps = {
  companyId?: string
  scenarioId?: BudgetingScenario['id']
}

export const useScenario = ({ companyId, scenarioId }: UseScenarioProps) => {
  const { data } = useScenarios({ companyId })

  return data?.find(item => item.id === scenarioId)
}
