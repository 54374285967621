import dayjs, { Dayjs } from 'dayjs'
import { DateRangeValue } from '../../types/date/types'
import { FiscalYear } from '../../types/fiscalYear/FiscalYear'

export const getSuggestedNextPeriod = (fiscalYear?: FiscalYear): DateRangeValue<Dayjs> | undefined => {
  if (!fiscalYear) return undefined
  const startDate = dayjs(fiscalYear.startDate).add(1, 'years')
  const endDate = dayjs(fiscalYear.endDate).add(1, 'years')

  return [startDate, endDate]
}
