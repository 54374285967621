import React from 'react'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { Flex, Spin } from 'antd'

const LoadingContainer = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

type LoadingProps = {
  children?: React.ReactNode
}

const Loading: React.FC<LoadingProps> = ({ children = null }) => {
  const icon = <LoadingOutlined style={{ fontSize: 48 }} spin />
  return (
    <LoadingContainer>
      <Flex vertical>
        <Spin indicator={icon} />
        {children}
      </Flex>
    </LoadingContainer>
  )
}

export default Loading
