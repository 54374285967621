import { ContextFilters } from '../../../types/context/ContextFilters'

const fiscalYearQueryKeys = {
  all: [{ scope: 'fiscalYear' }] as const,
  lists: () => [{ ...fiscalYearQueryKeys.all[0], entity: 'list' }] as const,
  list: (context: Pick<ContextFilters, 'companyId'>) => [{ ...fiscalYearQueryKeys.lists()[0], ...context }] as const,
  details: () => [{ ...fiscalYearQueryKeys.all[0], entity: 'detail' }] as const,
  detail: (context: Pick<ContextFilters, 'companyId'>, id: number) =>
    [{ ...fiscalYearQueryKeys.details()[0], ...context, id }] as const
}

export default fiscalYearQueryKeys
