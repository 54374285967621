import { Store, Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import {
  ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS,
  AddMultiPurposeDashboardFormulasAction,
  SetMultiPurposeDashboardsActiveDashboardAction,
  SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD,
  RESET_MULTI_PURPOSE_DASHBOARD,
  ResetMultiPurposeDashboardAction,
  RESET_MULTI_PURPOSE_DASHBOARD_REPORTS,
  ResetMultiPurposeDashboardReportsAction,
  SetMultiPurposeDashboardAllKeyfigureStatementsAction,
  SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS,
  SetMultiPurposeDashboardFormulasAction,
  SET_MULTI_PURPOSE_DASHBOARD_FORMULAS,
  ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS,
  AddMultiPurposeDashboardDimensionKeyfigureStatementsAction,
  AddMultiPurposeDashboardDimensionsAction,
  ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS,
  SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT,
  SetMultiPurposeDashboardInternalKeyfigureStatementAction,
  SetAllMultiPurposeDashboardKeyfigureStatementAction,
  SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT,
  SetAllMultiPurposeDashboardInternalKeyfigureStatementAction,
  SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT
} from './types'
import { Company } from '../../../../types/company/Company'
import { Report, ReportRow } from '../../../context/reports/types'
import { Formula } from '../../../../types/formula/Formula'
import { Dimension } from '../../../../types/dimension/Dimension'
import { BudgetingScenario } from '../../../../features/scenario/types/BudgetingScenario'

export const resetMultiPurposeDashboard = (): ResetMultiPurposeDashboardAction => ({
  type: RESET_MULTI_PURPOSE_DASHBOARD
})

export const resetMultiPurposeDashboardReports = (): ResetMultiPurposeDashboardReportsAction => ({
  type: RESET_MULTI_PURPOSE_DASHBOARD_REPORTS
})

export const addMultiPurposeDashboardFormulas = (
  companyId: Company['id'],
  formulas: Formula[]
): AddMultiPurposeDashboardFormulasAction => ({
  type: ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS,
  payload: { companyId, formulas }
})

export const addMultiPurposeDashboardDimensions = (
  companyId: Company['id'],
  dimensions: Dimension[]
): AddMultiPurposeDashboardDimensionsAction => ({
  type: ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS,
  payload: { companyId, dimensions }
})

export const setMultiPurposeDashboardFormulas = (formulas: {
  [key: string]: Formula[]
}): SetMultiPurposeDashboardFormulasAction => ({
  type: SET_MULTI_PURPOSE_DASHBOARD_FORMULAS,
  payload: formulas
})

export const setMultiPurposeDashboardActiveDashboard = (
  payload: number | undefined
): SetMultiPurposeDashboardsActiveDashboardAction => ({
  type: SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD,
  payload
})

export const setAllMultiPurposeDashboardKeyfigureStatement = (
  data: { dimensionKey?: string; budgetingScenarioId?: BudgetingScenario['id']; data: Report }[]
): SetAllMultiPurposeDashboardKeyfigureStatementAction => ({
  type: SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT,
  payload: data
})

export const setMultiPurposeDashboardInternalKeyfigureStatement = (
  id: string,
  data: Report
): SetMultiPurposeDashboardInternalKeyfigureStatementAction => ({
  type: SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT,
  payload: { id, data }
})

export const setAllMultiPurposeDashboardInternalKeyfigureStatement = (
  data: { dimensionId: string; data: Report }[]
): SetAllMultiPurposeDashboardInternalKeyfigureStatementAction => ({
  type: SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT,
  payload: data
})

export const setMultiPurposeDashboardAllKeyfigureStatements = (data: {
  [key: string]: ReportRow[]
}): SetMultiPurposeDashboardAllKeyfigureStatementsAction => ({
  type: SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS,
  payload: data
})

export const addMultiPurposeDashboardDimensionKeyfigureStatements = (data: {
  [key: string]: ReportRow[]
}): AddMultiPurposeDashboardDimensionKeyfigureStatementsAction => ({
  type: ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS,
  payload: data
})

export const changeMultiPurposeDashboardActiveDashboard = (
  payload: number | undefined
): ThunkAction<void, Store, null, Action<string>> => {
  return dispatch => {
    dispatch(resetMultiPurposeDashboard())
    dispatch(setMultiPurposeDashboardActiveDashboard(payload))
  }
}
