import React, { useContext } from 'react'
import { Table, Input, Select, Checkbox, Form, InputNumber, Segmented, Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import { BoldOutlined, EyeOutlined, ItalicOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { ColumnProps } from 'antd/lib/table'
import {
  CustomReportVariable,
  RowType,
  ThresholdValueSetting
} from '../../../../../redux/context/customReports/typesVariable'
import DimensionSelect from '../../../../../components/Dimension/DimensionSelect'
import ChartTypeSelect from '../chart/ChartTypeSelect'
import { DashStyle } from '../../../../../redux/context/customReports/typesCategory'
import LineIcon from '../../../../../components/Icons/LineIcons/LineIcon'
import { ChartYAxis } from '../chart/types'
import { SectionType } from '../../../../../redux/context/customReports/typesSection'
import { CategoryContext, CategoryContextType } from '../categorySelection/CategoryContext'
import ColorPicker from '../categorySelection/ColorPicker'
import { isFieldDisabled } from '../../../../../redux/context/customReports/utils'
import { ContractProduct } from '../../../../../types/contract/Contract'
import { useAuthority } from '../../../../../utils/Authorizable/authorize'
import ScenarioCascader from '../../../../../features/scenario/components/cascader/ScenarioCascader'

const { Option } = Select

interface ParamsAndStylesTableProps {
  sectionType: SectionType
}

const ParamsAndStylesTable = ({ sectionType }: ParamsAndStylesTableProps) => {
  const form = Form.useFormInstance()
  const { t } = useTranslation()
  const variables = (Form.useWatch('variables', form) || []) as CustomReportVariable[]
  const { categoryTree } = useContext(CategoryContext) as CategoryContextType
  const isPro = useAuthority({ product: ContractProduct.PRO })

  // Kaikista noStyle,
  const colorColumn = () => {
    return [
      {
        title: t('global:color'),
        dataIndex: 'color',
        width: 40,
        render: (value, record, index) => (
          <Form.Item noStyle name={[index, 'style', 'color']}>
            <ColorPicker
              position="fixed"
              disabled={
                sectionType === 'pie'
                  ? isFieldDisabled(record, sectionType, 'color')
                  : isFieldDisabled(record, sectionType, 'seriesStyleFields')
              }
            />
          </Form.Item>
        )
      }
    ] as ColumnProps<CustomReportVariable>[]
  }

  const tableColumns = () => {
    return [
      {
        title: t('customReportPage:expandable'),
        dataIndex: 'expandable',
        width: 90,
        align: 'center' as const,
        render: (value, record, index) => (
          <Form.Item
            noStyle
            name={[index, 'params', 'expandable']}
            valuePropName="checked"
            getValueProps={epxVal => ({
              checked: epxVal !== false
            })}
          >
            <Checkbox disabled={isFieldDisabled(record, sectionType, 'expandable')} />
          </Form.Item>
        )
      },
      {
        title: t('customReportPage:indentLevel'),
        dataIndex: 'indentLevel',
        width: 70,
        render: (value, record, index) => (
          <Form.Item noStyle name={[index, 'style', 'indentLevel']}>
            <Select style={{ width: '100%' }} disabled={isFieldDisabled(record, sectionType, 'indentLevel')}>
              {Array.from({ length: 9 }, (x, i) => (
                <Option key={i} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )
      },
      {
        title: t('customReportPage:thresholdValueSetting'),
        dataIndex: 'thresholdValueSetting',
        width: 100,
        render: (value, record, index) => (
          <Form.Item noStyle name={[index, 'style', 'thresholdValueSetting']}>
            <Select
              disabled={isFieldDisabled(record, sectionType, 'thresholdValueSetting')}
              popupMatchSelectWidth={false}
              style={{ width: '100%' }}
              options={Object.keys(ThresholdValueSetting).map(val => ({
                label: t(`customReportPage:${val}`),
                value: val
              }))}
            />
          </Form.Item>
        )
      }
    ] as ColumnProps<CustomReportVariable>[]
  }

  const chartColumns = () => {
    return [
      {
        title: t('customReportPage:graphType'),
        dataIndex: 'graphType',
        width: 100,
        render: (value, record, index) => (
          <Form.Item noStyle name={[index, 'style', 'type']}>
            <ChartTypeSelect
              disabled={isFieldDisabled(record, sectionType, 'seriesStyleFields')}
              popupMatchSelectWidth={false}
              style={{ width: '100%' }}
            />
          </Form.Item>
        )
      },
      {
        title: t('customReportPage:dashStyle'),
        dataIndex: 'dashStyle',
        width: 100,
        render: (value, record, index) => (
          <Form.Item noStyle name={[index, 'style', 'dashStyle']}>
            <Select
              style={{ width: '100%' }}
              allowClear
              disabled={isFieldDisabled(record, sectionType, 'seriesStyleFields')}
              options={Object.values(DashStyle).map(dashStyle => ({
                label: (
                  <span>
                    <LineIcon type={dashStyle} />
                  </span>
                ),
                value: dashStyle
              }))}
            />
          </Form.Item>
        )
      },
      {
        title: t('customReportPage:zIndex'),
        dataIndex: 'zIndex',
        width: 50,
        render: (value, record, index) => (
          <Form.Item noStyle name={[index, 'style', 'zIndex']}>
            <InputNumber
              disabled={isFieldDisabled(record, sectionType, 'seriesStyleFields')}
              style={{ width: '100%' }}
            />
          </Form.Item>
        )
      },
      ...colorColumn(),
      {
        title: t('global:yAxis'),
        dataIndex: 'yAxis',
        width: 80,
        render: (value, record, index) => (
          <Form.Item noStyle name={[index, 'style', 'yAxis']}>
            <Segmented
              disabled={isFieldDisabled(record, sectionType, 'seriesStyleFields')}
              size="small"
              options={[
                {
                  label: <LeftOutlined />,
                  value: ChartYAxis.left
                },
                {
                  label: <RightOutlined />,
                  value: ChartYAxis.right
                }
              ]}
            />
          </Form.Item>
        )
      }
    ] as ColumnProps<CustomReportVariable>[]
  }

  const pieColumns = () => {
    return [...colorColumn()]
  }

  const restColumns = (): ColumnProps<CustomReportVariable>[] => {
    switch (sectionType) {
      case 'table':
        return tableColumns()
      case 'graph':
        return chartColumns()
      case 'pie':
        return pieColumns()
      default:
        return []
    }
  }

  const columns: ColumnProps<CustomReportVariable>[] = [
    {
      title: t('global:type'),
      dataIndex: 'rowType',
      key: 'rowType',
      width: 180,
      fixed: 'left' as const,
      render: (_, __, index) => {
        const value = form.getFieldValue(['variables', index, 'rowType'])
        return t(`variableRowType:${value}`)
      }
    },
    {
      title: t('global:name'),
      dataIndex: 'name',
      key: 'name',
      width: 180,
      fixed: 'left' as const,
      render: (value, record, index) => (
        <Form.Item
          name={[index, 'name']}
          noStyle
          rules={[{ required: record.rowType !== RowType.empty, message: 'Required' }]}
        >
          <Input disabled={isFieldDisabled(record, sectionType, 'name')} />
        </Form.Item>
      )
    },
    {
      title: t('global:dataType'),
      width: 150,
      dataIndex: 'dataType',
      render: (value, record, index) => (
        <Form.Item noStyle name={[index, 'params', 'dataType']}>
          <ScenarioCascader
            companyId={categoryTree.companies[0]}
            allowClear
            style={{ width: '100%' }}
            disabled={isFieldDisabled(record, sectionType, 'dataType')}
          />
        </Form.Item>
      )
    },
    {
      title: t('investmentsPage:dimensionId'),
      dataIndex: 'dimensionId',
      width: 100,
      hidden: !isPro,
      render: (value, record, index) => (
        <Form.Item noStyle name={[index, 'params', 'dimension', 'dimensionId']}>
          <DimensionSelect disabled={isFieldDisabled(record, sectionType, 'dimension')} />
        </Form.Item>
      )
    },
    {
      title: t('global:monthOffset'),
      dataIndex: 'offset',
      width: 80,
      render: (value, record, index) => (
        <Form.Item noStyle name={[index, 'params', 'offset']}>
          <InputNumber disabled={isFieldDisabled(record, sectionType, 'offset')} style={{ width: '100%' }} />
        </Form.Item>
      )
    },
    {
      title: t('global:decimal'),
      dataIndex: 'decimals',
      width: 70,
      render: (value, record, index) => (
        <Form.Item noStyle name={[index, 'style', 'decimals']}>
          <Select disabled={isFieldDisabled(record, sectionType, 'decimals')} style={{ width: '100%' }}>
            {Array.from({ length: 4 }, (x, i) => (
              <Option key={i} value={`${i}`}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )
    },
    {
      title: (
        <Popover content={t('global:bold')}>
          <BoldOutlined />
        </Popover>
      ),
      dataIndex: 'fontWeight',
      width: 25,
      align: 'center' as const,
      render: (value, record, index) => (
        <Form.Item noStyle name={[index, 'style', 'fontWeight']} valuePropName="checked">
          <Checkbox disabled={isFieldDisabled(record, sectionType, 'fontWeight')} />
        </Form.Item>
      )
    },
    {
      title: (
        <Popover content={t('global:italic')}>
          <ItalicOutlined />
        </Popover>
      ),
      dataIndex: 'fontStyle',
      width: 25,
      align: 'center' as const,
      onCell: () => ({}),
      render: (value, record, index) => (
        <Form.Item
          noStyle
          name={[index, 'style', 'fontStyle']}
          valuePropName="checked"
          getValueProps={styleValue => ({
            checked: styleValue === 'italic'
          })}
          getValueFromEvent={event => (event.target.checked ? 'italic' : 'normal')}
        >
          <Checkbox disabled={isFieldDisabled(record, sectionType, 'fontStyle')} />
        </Form.Item>
      )
    },
    {
      title: (
        <Popover content={t('customReportPage:showEmptyRows')}>
          <EyeOutlined />
        </Popover>
      ),
      dataIndex: 'showEmptyRows',
      width: 25,
      align: 'center' as const,
      render: (value, record, index) => (
        <Form.Item noStyle name={[index, 'params', 'showEmptyRows']} valuePropName="checked">
          <Checkbox disabled={isFieldDisabled(record, sectionType, 'showEmptyRows')} />
        </Form.Item>
      )
    },
    ...restColumns()
  ]

  return (
    <Form.List name="variables">
      {() => <Table size="small" columns={columns} dataSource={variables} pagination={false} rowKey="id" bordered />}
    </Form.List>
  )
}

export default ParamsAndStylesTable
