import { QueryFunctionContext } from '@tanstack/react-query'
import { DELETE, GET, POST, PUT } from '../../../redux/middleware/types'
import backendRequest from '../../../services/api/backendRequest'
import customReportEndpoints from '../api/customReportEndpoints'
import { CustomReport } from '../../../redux/context/customReports/types'
import customReportQueryKeys from '../queries/customReportQueryKeys'

export const fetchCustomReports = ({
  queryKey: [{ companyId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof customReportQueryKeys)['list']>>) => {
  return backendRequest<CustomReport[]>({
    method: GET,
    url: customReportEndpoints.list(companyId),
    signal
  })
}

export const fetchCustomReport = (reportId: number, companyId: string) => {
  return backendRequest<CustomReport>({
    method: GET,
    url: customReportEndpoints.detail(companyId, reportId)
  })
}

export const createCustomReport = (data: Partial<CustomReport>, companyId: string) => {
  return backendRequest<CustomReport>({
    method: POST,
    url: customReportEndpoints.list(companyId),
    data
  })
}

export const updateCustomReport = (id: number, data: Partial<CustomReport>, companyId: string) => {
  return backendRequest<CustomReport>({
    method: PUT,
    url: customReportEndpoints.detail(companyId, id),
    data
  })
}

export const deleteCustomReport = (companyId: string, id: number) => {
  return backendRequest<CustomReport>({
    method: DELETE,
    url: customReportEndpoints.detail(companyId, id)
  })
}

export const copyCustomReport = async (reportId: number | string, companyId: string) => {
  return backendRequest<CustomReport>({
    method: POST,
    url: customReportEndpoints.copy(companyId, reportId)
  })
}
