import React, { useEffect, useState } from 'react'
import { Modal, Radio, Table } from 'antd'
import { Key } from 'antd/es/table/interface'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { ReportTableRow } from '../../../../../components/Table/types'
import { periodGroupLabel } from '../../../../../redux/context/fiscalYears/utils'
import { ReportDataType } from '../../../../../redux/context/reports/types'
import { formatValueByType } from '../../../../../utils/helpers'
import { Budget } from './types'
import { distributePeriodGroupBudgets, getDistributionWeightsEnabled } from './utils'
import { DTSelector } from '../../../../../redux/context/filters/selectors'
import { statementRowSelector } from '../../../../../redux/entities/statementRows/selectors'
import { incomeStatementSelector } from '../../../../../redux/context/reports/selectors'
import { useAccounts } from '../../../../../features/account/queries/useFetchAccounts'
import { useCtxFiscalYears } from '../../../../../features/fiscalYear/queries/report/useCtxFiscalYears'
import { lastClosedFiscalYearsSelector } from '../../../../../features/fiscalYear/selectors/selectors'

interface BudgetDistributionModalProps {
  rowBudgets?: Budget[]
  isModalVisible: boolean
  reportTableRow?: ReportTableRow
  saveBudgets: (data: Budget[][], row?: ReportTableRow) => void
  handleCancel: () => void
}

export enum DistributionMode {
  Evenly,
  Weights,
  ToAllFiscalYearMonths
}

const BudgetDistributionModal = ({
  rowBudgets,
  isModalVisible,
  reportTableRow,
  saveBudgets,
  handleCancel
}: BudgetDistributionModalProps) => {
  const { t } = useTranslation()
  const [distributionMode, setDistributionMode] = useState<DistributionMode>(DistributionMode.Evenly)
  const [selectedPeriodGroups, setSelectedPeriodGroups] = useState<Key[]>([])
  const incomeStatement = useSelector(incomeStatementSelector)
  const DT = useSelector(DTSelector)
  const statementRows = useSelector(statementRowSelector)
  const { data: allAccounts } = useAccounts()

  const { data: lastClosedFiscalYear } = useCtxFiscalYears(lastClosedFiscalYearsSelector)

  const hasChanged = (budget: Budget) => {
    return Math.round(reportTableRow?.[`budget-${budget?.periodGroup?.id}`]) !== budget.value
  }

  const getReportTableRow = (rowBudget: Budget) => {
    if (rowBudget.accountCode) {
      const accounts = []
      for (const incomeS of incomeStatement) {
        accounts.push(...(incomeS.accounts || []))
      }
      return accounts.find(a => a.code === rowBudget.accountCode)
    }
    if (rowBudget.incomeStatementRowId) {
      return incomeStatement.find(i => i.id === rowBudget.incomeStatementRowId)
    }
    return undefined
  }

  const onSelectChange = (selectedRowKeys: Key[]) => {
    const weightEnabled = getDistributionWeightsEnabled(
      selectedRowKeys as number[],
      rowBudgets?.map(b => ({
        ...b,
        reportTableRow: getReportTableRow(b)
      })) as (Budget & { reportTableRow: ReportTableRow })[],
      lastClosedFiscalYear
    )
    if (!weightEnabled && distributionMode === DistributionMode.Weights) setDistributionMode(DistributionMode.Evenly)
    setSelectedPeriodGroups(selectedRowKeys)
  }

  useEffect(() => {
    onSelectChange(
      rowBudgets
        ?.filter(rb => rb?.periodGroup && hasChanged(rb))
        .map(rb => `${rb.periodGroup?.id}-${rb.code}-${rb.dimensionId}-${rb.dataType}` || -1) || []
    )
  }, [rowBudgets])

  const columns: ColumnProps<Budget>[] = [
    {
      title: t('valuationPage:fiscal-year'),
      dataIndex: 'periodGroup',
      render: record => {
        return `${periodGroupLabel(record, ReportDataType.actuals)}`
      }
    },
    // TODO: vain account tason
    {
      title: t('global:account'),
      dataIndex: 'accountCode'
    },
    {
      title: t('global:accountName'),
      dataIndex: 'accountCode',
      render: accountCode => allAccounts?.find(account => account.code === accountCode)?.name
    },
    {
      title: t('global:accountGroup'),
      dataIndex: 'code',
      render: code => statementRows.find(row => row.code === code)?.title
    },
    {
      title: t('global:dimension'),
      dataIndex: 'dimensionId',
      render: dimensionId => DT.find(dimensionId)?.value?.name
    },
    {
      title: t('global:dataType'),
      dataIndex: 'dataType',
      render: dataType => t(`global:${dataType}`)
    },
    {
      title: t('global:value'),
      dataIndex: 'value',
      render: (val, record) =>
        formatValueByType(val, record.variableType, {
          maximumFractionDigits: 2
        }) || 0
    }
  ]

  const radioStyle = {
    display: 'block',
    height: '38px',
    marginBottom: 8
  }

  const handleOk = () => {
    const budg: Budget[][] = []
    if (rowBudgets) {
      for (const budget of cloneDeep(rowBudgets)) {
        budg.push(
          distributePeriodGroupBudgets(
            [budget] || [],
            selectedPeriodGroups,
            distributionMode,
            lastClosedFiscalYear,
            getReportTableRow(budget)
          )
        )
      }
    }

    saveBudgets(budg, reportTableRow)
    handleCancel()
  }

  return (
    <Modal
      title={t('financialStatementsPage:distribute-budgets')}
      open={isModalVisible}
      okText={t('global:save')}
      onOk={handleOk}
      width={800}
      maskClosable={false}
      okButtonProps={{ disabled: selectedPeriodGroups.length <= 0 }}
      cancelText={t('global:cancel')}
      onCancel={handleCancel}
    >
      <Table
        rowSelection={{
          selectedRowKeys: selectedPeriodGroups,
          onChange: onSelectChange
        }}
        rowKey={record => `${record.periodGroup?.id}-${record.code}-${record.dimensionId}-${record.dataType}` || -1}
        size="small"
        pagination={false}
        columns={columns}
        dataSource={rowBudgets}
      />

      <Radio.Group
        defaultValue={DistributionMode.Evenly}
        value={distributionMode}
        style={{ marginTop: 16 }}
        onChange={e => setDistributionMode(e.target.value)}
      >
        <Radio style={radioStyle} value={DistributionMode.Evenly}>
          {t('financialStatementsPage:distribute-evenly')}
        </Radio>
        <Radio
          disabled={
            !getDistributionWeightsEnabled(
              selectedPeriodGroups as number[],
              rowBudgets?.map(b => ({
                ...b,
                reportTableRow: getReportTableRow(b)
              })) as (Budget & { reportTableRow: ReportTableRow })[],
              lastClosedFiscalYear
            )
          }
          style={radioStyle}
          value={DistributionMode.Weights}
        >
          {t('financialStatementsPage:distribute-by-weigths')}
        </Radio>
        <Radio style={radioStyle} value={DistributionMode.ToAllFiscalYearMonths}>
          {t('financialStatementsPage:distribute-evenly-fiscal-year')}
        </Radio>
      </Radio.Group>
    </Modal>
  )
}

export default BudgetDistributionModal
