import React from 'react'
import { GroupConfig, keyValueConfig } from './masterConfig'

interface FormRendererProps {
  config?: GroupConfig
  switchRowsAndColumns?: boolean
}

const FormRenderer: React.FC<FormRendererProps> = ({ config, switchRowsAndColumns }) => {
  if (!config) return null
  const { basic: basicFields } = config
  return (
    <div>
      {basicFields.map((field: any) => (
        <div key={field}>
          {keyValueConfig[field] ? React.cloneElement(keyValueConfig[field].element, { switchRowsAndColumns }) : null}
        </div>
      ))}
    </div>
  )
}

export default FormRenderer
