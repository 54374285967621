import { useQueries } from '@tanstack/react-query'
import { getCustomReportTree } from '../../../../pages/reporting/custom/components/utils'
import { CustomReportStructure } from '../../types/CustomReportStructure'
import { useCustomReportOptions } from '../report/useCustomReportOptions'
import { useCustomReportGroupOptions } from '../group/useCustomReportGroupOptions'

export const useCustomReportStructure = (companyId: string) => {
  const reportOptions = useCustomReportOptions(companyId)
  const groupOptions = useCustomReportGroupOptions(companyId)

  return useQueries({
    queries: [reportOptions, groupOptions],
    combine: results => {
      const [reports, groups] = results.map(result => result.data || [])
      return {
        data: getCustomReportTree([...reports, ...groups]) as CustomReportStructure[],
        isPending: results.some(result => result.isPending),
        isLoading: results.some(result => result.isLoading),
        error: results.map(result => result.error)[0],
        refetch: () => {
          results.filter(result => result.error)?.forEach(result => result.refetch())
        }
      }
    }
  })
}
