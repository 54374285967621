import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'
import { styled } from 'styled-components'
import { ReactComponent as Logo } from '../../assets/finadeck-logo-web.svg'

const { Text } = Typography

const StyledFooter = styled.div`
  padding: 24px 24px;
  text-align: center;
  line-height: 0.8rem;

  & svg path#finadeck-logo {
    fill: rgba(0, 0, 0, 0.25);
  }
`

const AppFooter = () => {
  return (
    <StyledFooter>
      <Link to="https://finadeck.fi" target="_blank" rel="noopener noreferrer">
        <Logo style={{ height: '32px' }} />
      </Link>
      <Text style={{ display: 'block' }} type="secondary">
        Created by Finadeck
      </Text>
    </StyledFooter>
  )
}

export default AppFooter
