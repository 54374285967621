import React from 'react'
import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import GoogleSheets from '../../../../../components/GoogleSheets/GoogleSheets'

const BudgetGoogleSheets = () => {
  const { t } = useTranslation()
  return (
    <>
      <GoogleSheets type="budget" />
      <Divider />
      {t('budgetingImportPage:info-text')}
    </>
  )
}

export default BudgetGoogleSheets
