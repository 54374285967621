import { queryOptions } from '@tanstack/react-query'
import { fetchCompanyGroupSettings } from '../../service/settingService'
import companyGroupSettingQueryKeys from './companyGroupSettingQueryKeys'
import { GroupSettingData } from '../../types/CompanyGroupSettings'

type UseCompanyGroupSettingsOptionsProps<TData> = {
  companyId: string
  select?: (data: GroupSettingData) => TData
}

export const useCompanyGroupSettingsOptions = <TData = GroupSettingData>({
  companyId,
  select
}: UseCompanyGroupSettingsOptionsProps<TData>) => {
  return queryOptions({
    queryKey: companyGroupSettingQueryKeys.setting(companyId),
    queryFn: fetchCompanyGroupSettings,
    gcTime: 30 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
    enabled: !!companyId,
    select
  })
}
