/* eslint-disable no-case-declarations */
import {
  FinancialStatementsActionTypes,
  FinancialStatementsPage,
  TOGGLE_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT,
  SET_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT,
  RESET_FINANCIAL_STATEMENTS_PAGE,
  TOGGLE_ALL_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT,
  SET_FINANCIAL_STATEMENT_REALLOCATION,
  TOGGLE_FINANCIAL_STATEMENTS_DRIVER_IN_EDIT,
  SET_OPEN_COMMENT
} from './types'

const initialState: FinancialStatementsPage = {
  budgetsInEdit: {},
  driversInEdit: {},
  contextMenu: {},
  reallocations: {},
  openComment: null
}

const financialStatementPageReducer = (state = initialState, action: FinancialStatementsActionTypes) => {
  switch (action.type) {
    case RESET_FINANCIAL_STATEMENTS_PAGE:
      return initialState
    case SET_FINANCIAL_STATEMENT_REALLOCATION:
      return {
        ...state,
        reallocations: {
          ...state.reallocations,
          [action.payload.accountCode]: action.payload.data
        }
      }
    case TOGGLE_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT:
      return {
        ...state,
        budgetsInEdit: {
          ...state.budgetsInEdit,
          ...{ [action.payload]: !state.budgetsInEdit[action.payload] }
        }
      }
    case TOGGLE_FINANCIAL_STATEMENTS_DRIVER_IN_EDIT:
      return {
        ...state,
        driversInEdit: {
          ...state.driversInEdit,
          ...{ [action.payload]: !state.driversInEdit[action.payload] }
        }
      }
    case SET_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT:
      return {
        ...state,
        budgetsInEdit: {
          ...state.budgetsInEdit,
          ...action.payload
        }
      }
    case TOGGLE_ALL_FINANCIAL_STATEMENTS_BUDGETS_IN_EDIT:
      return {
        ...state,
        budgetsInEdit: {}
      }
    case SET_OPEN_COMMENT:
      return {
        ...state,
        openComment: action.payload
      }
    default:
      return state
  }
}

export default financialStatementPageReducer
