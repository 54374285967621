import React, { useEffect, useState } from 'react'
import { Modal, Input, Form, Cascader, Radio, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'
import { CustomReport } from '../../../../redux/context/customReports/types'
import { mapObjectToFieldData } from '../../../../utils/form'
import { currentUserSortedCompaniesSelector } from '../../../../redux/session/currentUser/selectors'
import { useBackend } from '../../../../services/backend'
import { contextRequest } from '../../../../redux/context/actions'
import { getFormulasRequest } from '../../../../redux/context/formulas/actions'
import { AppDispatch } from '../../../../redux/store'
import useCtxCopyCustomReport from '../../../../features/customReport/queries/report/useCtxCopyCustomReport'
import { useCtxCreateCustomReport } from '../../../../features/customReport/queries/report/useCtxCreateCustomReport'
import { useCtxUpdateCustomReport } from '../../../../features/customReport/queries/report/useCtxUpdateCustomReport'
import { useResetFormOnCloseModal } from '../../../../components/Form/hooks'

interface CustomReportFormProps {
  report?: CustomReport | null
  visible: boolean
  onClose: () => void
}

const CustomReportModal: React.FC<CustomReportFormProps> = ({ visible, report, onClose }: CustomReportFormProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const userCompanies = useSelector(currentUserSortedCompaniesSelector)
  const [companies, setCompanies] = useState(userCompanies.map(({ id, name }) => ({ id, title: name, isLeaf: false })))
  const reportRequest = useBackend(`/api/companies/{companyId}/reporting/custom/reports`)
  const [reportForm] = useForm<Pick<CustomReport, 'id' | 'title'>>()
  const [newReportMode, setNewReportMode] = useState<'new' | 'copy'>('new')
  const [copySelection, setCopySelection] = useState<(string | number)[]>([])
  const [confirmLoading, setConfirmLoading] = useState(false)

  const { mutateAsync: copyCustomReport } = useCtxCopyCustomReport()
  const { mutateAsync: createCustomReport } = useCtxCreateCustomReport()
  const { mutateAsync: updateCustomReport } = useCtxUpdateCustomReport()

  useResetFormOnCloseModal({ form: reportForm, open: visible })

  useEffect(() => {
    if (report) {
      const fields = mapObjectToFieldData(report)
      reportForm.setFields(fields)
    }
  }, [report])

  const handleCancel = () => {
    onClose()
    setNewReportMode('new')
  }

  const handleSave = async () => {
    setConfirmLoading(true)
    const [, reportId] = copySelection
    switch (newReportMode) {
      case 'copy':
        await copyCustomReport(reportId)
        // Kopioinnissa saattaa tulla yritykselle lisää kaavoja, joten ne haettava uusiksi
        dispatch(contextRequest(getFormulasRequest))
        onClose()
        break
      case 'new':
        try {
          const values = await reportForm.validateFields()
          if (values.id) {
            await updateCustomReport(values)
          } else {
            await createCustomReport(values)
          }
          onClose()
        } catch (error) {
          console.log('Validate Failed:', error)
        }
        break
      default:
        break
    }
    setConfirmLoading(false)
    setNewReportMode('new')
  }

  const loadData = async (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
    try {
      const response = await reportRequest.get({ urlParams: { companyId: targetOption.id } })
      targetOption.loading = false

      targetOption.children =
        response.length === 0 ? [{ title: t('global:no-results'), id: null, isLeaf: true, disabled: true }] : response
      setCompanies([...companies])
    } catch (error) {
      targetOption.loading = false
      console.log(error)
    }
  }

  const onChange = (value: any) => {
    setCopySelection(value)
  }

  const filter = (inputValue: any, path: any) => {
    if (
      path.find((option: any) => option?.title?.toLowerCase().includes(inputValue?.toLowerCase())) &&
      inputValue.length > 2
    ) {
      const selectedCompany: any = companies?.find(
        c => c.id === path.find((option: any) => option?.title?.toLowerCase().includes(inputValue?.toLowerCase()))?.id
      )
      if (selectedCompany && (!selectedCompany.children || selectedCompany.children.length < 1)) {
        loadData([path.find((option: any) => option?.title?.toLowerCase().includes(inputValue?.toLowerCase()))])
      }
    }
    return path.some((option: any) => option?.title?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1)
  }

  return (
    <Modal
      destroyOnClose
      open={visible}
      title={report?.id ? t('customReportPage:edit-report') : t('customReportPage:new-report')}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      onCancel={handleCancel}
      maskClosable={false}
      onOk={handleSave}
      okButtonProps={{
        disabled: copySelection && copySelection.length < 2 && newReportMode === 'copy'
      }}
      confirmLoading={confirmLoading}
    >
      {!report?.id && (
        <Radio.Group
          onChange={e => {
            e.preventDefault()
            setNewReportMode(e.target.value)
          }}
          value={newReportMode}
          style={{ marginBottom: 16 }}
        >
          <Radio value="new">{t('contractsPage:add-new')}</Radio>
          <Radio value="copy">{t('global:copy', { what: '' })}</Radio>
        </Radio.Group>
      )}
      {newReportMode === 'new' && (
        <Form form={reportForm} layout="vertical" initialValues={{ style: { orientation: 'landscape' } }}>
          <Form.Item name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>

          <Form.Item
            name="title"
            label={t('global:name')}
            rules={[{ required: true, message: t('global:required-field') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['style', 'orientation']}
            label={t('customReportPage:print-orientation')}
            initialValue="landscape"
            rules={[{ required: true, message: t('global:required-field') }]}
          >
            <Select
              defaultValue="landscape"
              style={{ width: 120 }}
              options={[
                { value: 'landscape', label: t('customReportPage:landscape') },
                { value: 'portrait', label: t('customReportPage:portrait') }
              ]}
            />
          </Form.Item>
        </Form>
      )}
      {!report?.id && newReportMode === 'copy' && (
        <Form layout="vertical">
          <Form.Item name="reportId" label={t('global:copy', { what: '' })}>
            <Cascader<any>
              value={copySelection}
              fieldNames={{ label: 'title', value: 'id', children: 'children' }}
              options={companies}
              placeholder={t('global:choose-here')}
              loadData={loadData}
              onChange={onChange}
              showSearch={{ filter }}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

export default CustomReportModal
