/* eslint-disable no-nested-ternary */
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { ExpandableConfig } from 'antd/lib/table/interface'
import _ from 'lodash'
import i18next from 'i18next'
import { Column } from '../../redux/context/periodGroups/hooks'
import {
  Balance,
  Report,
  ReportDataType,
  ReportDataTypeCumulatives,
  ReportRow,
  ReportType
} from '../../redux/context/reports/types'
import { FiscalYear, Period } from '../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../types/periodGroup/PeriodGroup'
import { ReportTableRow, VariableType } from './types'
import { CustomReportVariable, VariableRow } from '../../redux/context/customReports/typesVariable'
import { CustomReportCategoryRow } from '../../redux/context/customReports/typesCategory'

export const hasData = (reportRow: ReportRow) => {
  for (const dataType of [ReportDataType.actuals, ReportDataType.budget]) {
    if (reportRow[dataType] && reportRow[dataType].length > 0) {
      if (!reportRow[dataType].every((row: ReportRow) => row.value === 0)) {
        return true
      }
    }
  }
  return false
}

export const getGroupPeriods = (group: FiscalYear | PeriodGroup) => {
  if ('periods' in group) {
    return group.periods
  }
  return []
}

export const generateDataIndex = (
  dataType?: ReportDataType | ReportDataTypeCumulatives,
  id?: number | string,
  year?: number,
  month?: number,
  cumulative?: 'cumulative' | 'diff' | 'comparativeDiff'
) => {
  let rowDataType = dataType
  let rowYear = year
  if (rowYear && dataType === ReportDataType.comparativePeriod) {
    rowDataType = ReportDataType.actuals
    rowYear -= 1
  }
  let dataIndex = ''
  if (!dataType) {
    dataIndex = `${cumulative}-${rowYear}-${month}`
  } else {
    dataIndex = month ? `${rowDataType}-${rowYear}-${month}` : `${rowDataType}-${id}`
  }

  if (cumulative && dataType) {
    dataIndex = `${dataIndex}-${cumulative}`
  }
  return dataIndex
}

export const generateColumnDataIndex = (column: Column) => {
  const { dataType, periodGroup, year, month, cumulative } = column
  return generateDataIndex(dataType, periodGroup?.id, year, month, cumulative)
}

export const generateCumulativeColumns = (groups: PeriodGroup[] | FiscalYear[], reportRow: ReportRow) => {
  // kumulatiiveile sama (vain tuloslaskelma ja euromääränen kannattavuus)
  const cumulatives: { [key: string]: number } = {}
  for (const dataType of Object.values(ReportDataType)) {
    const reportDataType = dataType === ReportDataType.comparativePeriod ? ReportDataType.actuals : dataType

    for (const group of groups) {
      let cumulativeSum = 0

      for (const period of getGroupPeriods(group)) {
        const balance = (reportRow[reportDataType] || []).find(
          (rowBalance: Balance) => rowBalance.month === period.month && rowBalance.year === period.year
        )

        const rowId = balance ? balance.groupId || balance.fiscalYearId : group.id
        cumulativeSum += balance?.value ?? 0
        const dataIndex = generateDataIndex(
          reportDataType,
          rowId,
          period.year,
          period.month,
          ReportDataTypeCumulatives.cumulative
        )
        cumulatives[dataIndex] = cumulativeSum
      }
    }
  }
  return cumulatives
}

export const generateSpecialCumulativeColumns = (
  groups: PeriodGroup[] | FiscalYear[],
  row: ReportTableRow,
  periods: Period[]
) => {
  // special kumulatiivit (vain tuloslaskelma ja euromääränen kannattavuus)
  const specialCumulatives: { [key: string]: number } = {}
  groups.forEach((group: PeriodGroup | FiscalYear, index: number) => {
    let lastCumulativeActualValue = 0
    let lastYearCumulativeActualValue = 0
    for (const period of periods) {
      const dataIndexActual = generateDataIndex(
        ReportDataType.actuals,
        group.id,
        period.year,
        period.month,
        ReportDataTypeCumulatives.cumulative
      )
      const dataIndexBudget = generateDataIndex(
        ReportDataType.budget,
        group.id,
        period.year,
        period.month,
        ReportDataTypeCumulatives.cumulative
      )

      const cumulativeActualValue = row[dataIndexActual] ? row[dataIndexActual] : lastCumulativeActualValue
      const cumulativeBudgetValue = row[dataIndexBudget] ? row[dataIndexBudget] : 0

      const dataIndex = generateDataIndex(undefined, group.id, period.year, period.month, 'diff')
      if (typeof row[dataIndexActual] === 'number') {
        lastCumulativeActualValue = row[dataIndexActual]
      }

      // tämä cumulatiivi minus tämä budget cumulatiivi
      if (dataIndex) {
        specialCumulatives[dataIndex] = cumulativeActualValue - cumulativeBudgetValue
      }

      // tämän vuoden ja kk cumulatiivi miinus viime vuoden sama kumulatiivi
      if (index > 0) {
        const dataIndexActualPreviousYear = generateDataIndex(
          ReportDataType.actuals,
          undefined,
          period.year - 1,
          period.month,
          ReportDataTypeCumulatives.cumulative
        )

        const previousCumulativeActualValue = row[dataIndexActualPreviousYear]
          ? row[dataIndexActualPreviousYear]
          : lastYearCumulativeActualValue

        const dataIndexCumulativeComparativeDifferencePeriod = generateDataIndex(
          undefined,
          undefined,
          period.year,
          period.month,
          'comparativeDiff'
        )

        if (typeof row[dataIndexActualPreviousYear] === 'number') {
          lastYearCumulativeActualValue = row[dataIndexActualPreviousYear]
        }
        specialCumulatives[dataIndexCumulativeComparativeDifferencePeriod] =
          cumulativeActualValue - previousCumulativeActualValue
      }
    }
  })
  return specialCumulatives
}

export const getBalanceColumns = (reportRow: ReportRow) => {
  const balanceColumns: { [key: string]: number } = {}
  for (const dataType of Object.values(ReportDataType)) {
    if (reportRow[dataType]) {
      for (const balance of reportRow[dataType]) {
        const rowId = balance.groupId || balance.fiscalYearId
        let dataIndex = ''

        if (balance.groupId) {
          dataIndex = generateDataIndex(dataType, rowId)
        } else {
          dataIndex = generateDataIndex(dataType, rowId, balance.year, balance.month)
        }

        balanceColumns[dataIndex] = balance.value
      }
    }
  }
  return balanceColumns
}

export const hasMonthlyData = (report: Report, column: Column) => {
  for (const dataType of [ReportDataType.actuals, ReportDataType.budget]) {
    for (const reportRow of report) {
      if (
        reportRow &&
        reportRow[dataType] &&
        reportRow[dataType].find((b: Balance) => b.month === column.month && b.year === column.year)
      ) {
        return true
      }
    }
  }
  return false
}

export const drillDownExpandable = (
  dispatchAction: (record: ReportTableRow) => void,
  loading: (id: string) => boolean
): ExpandableConfig<ReportTableRow> => ({
  expandIcon: ({ expanded, onExpand, record }) => {
    return !record.children ? undefined : loading(record.id) ? (
      <LoadingOutlined style={{ marginRight: 4, position: 'relative', float: 'left', marginTop: 2.5 }} />
    ) : expanded ? (
      <button
        className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
        aria-label="Collapse row"
        type="button"
        onClick={e => onExpand(record, e)}
      />
    ) : (
      <button
        className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
        aria-label="Collapse row"
        type="button"
        onClick={e => onExpand(record, e)}
      />
    )
  },
  onExpand: (exp, record) => {
    exp && !record.explanation && record.children.length <= 0 && dispatchAction(record)
  },
  rowExpandable: () => true
})

export const getPreviousRow = (data?: any[], id?: number | string) => {
  if (!data || !id) return undefined
  let result
  function iter(a: { id: number | string; children: { id: number | string } }) {
    if (a.id === id) {
      result = a
      return true
    }
    return Array.isArray(a.children) && a.children.some(iter)
  }
  data.some(iter)
  return result
}

export const renderPeriod = (
  rowSelection: ReportTableRow | CustomReportVariable | VariableRow,
  dataSource: ReportTableRow[] | CustomReportVariable[]
) => {
  const previousId = rowSelection.id.split('-')
  previousId.pop()
  const previousRow = getPreviousRow(dataSource, previousId.join('-')) as any
  if (previousRow) {
    if (previousRow.children.every(({ period }: any) => period === 'current')) {
      return false
    }
    return true
  }
  return false
}

export const getTitle = (r: ReportRow) => {
  if (r.report === ReportType.internalKeyFigureStatement) {
    return r.name
  }
  if (r.translation) {
    return i18next.t(r.translation, { code: r.code })
  }
  if (Number.isInteger(r.code)) {
    return r.title || `${r.code} - ${r.name}`
  }
  return i18next.t(`${_.camelCase(r.report)}:${r.id}`, r.name || r.title)
}

const getVariableType = (
  record: ReportTableRow | VariableRow | CustomReportCategoryRow,
  variableType?: VariableType
) => {
  return variableType === VariableType.percentage
    ? VariableType.percentage
    : record.variableType || record.type || variableType
}

export const getDecimals = (
  record: ReportTableRow | VariableRow | CustomReportCategoryRow,
  variableType = VariableType.absolute
) => {
  const cellVariableType = getVariableType(record, variableType)

  let decimals = null
  if (cellVariableType === VariableType.percentage || cellVariableType === VariableType.ratio) {
    decimals = 1
  }

  if (record.style?.decimals) {
    decimals = record.style?.decimals
  }

  return decimals
}

export const getCallDataAttributeZValue = (decimals: number | null) => {
  if (decimals) return `0${decimals ? '.' : ''}`.padEnd(decimals + 2, '0')
  return '0'
}

export const getCellDataAttributes = (
  record: ReportTableRow | VariableRow | CustomReportCategoryRow,
  variableType?: VariableType,
  value?: number
) => {
  let val = value
  const cellVariableType = getVariableType(record, variableType)

  if (value && cellVariableType === VariableType.percentage) {
    val = value * 100
  }

  const decimals = getDecimals(record, variableType)

  return {
    'data-v': typeof val === 'number' ? _.round(val, decimals) : '',
    'data-z': getCallDataAttributeZValue(decimals),
    'data-t': 'n'
  }
}
