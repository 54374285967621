import styled from 'styled-components'
import { Table } from 'antd'

export default styled(Table)`
  .editable-cell {
    position: relative;
    padding-left: 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    margin: -1px;
    border-radius: 2px;
    box-sizing: border-box;
  }

  .no-value {
    padding: 10px 11px;
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
` as typeof Table
