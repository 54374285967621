import { Checkbox, Form } from 'antd'
import { t } from 'i18next'
import React from 'react'

const FontWeight = () => {
  return (
    <Form.Item key="fontWeight" name={['style', 'fontWeight']} valuePropName="checked">
      <Checkbox>{t('formulaPage:bold')}</Checkbox>
    </Form.Item>
  )
}

export default FontWeight
