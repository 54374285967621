import { Form, FormItemProps, Select } from 'antd'
import { t } from 'i18next'
import React from 'react'
import { RowType } from '../../../../../../../redux/context/customReports/typesVariable'

interface RowTypeSelectProps extends FormItemProps {
  rowTypes?: RowType[]
}

const RowTypeSelect = ({ rowTypes = Object.values(RowType), ...formItemProps }: RowTypeSelectProps) => {
  return (
    <Form.Item name="rowType" key="type" label={t('global:type')} shouldUpdate {...formItemProps}>
      <Select
        options={rowTypes.map(srcType => ({
          label: t(`variableRowType:${srcType}`),
          value: srcType
        }))}
      />
    </Form.Item>
  )
}

export default RowTypeSelect
