import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { App } from 'antd'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { BudgetingScenario } from '../../types/BudgetingScenario'
import { updateForecast } from '../../service/forecastService'
import forecastQueryKeys from './forecastQueryKeys'
import { denormalizeScenario } from '../../utils/scenarioUtils'

export const useUpdateForecast = () => {
  const companyId = useSelector(contextCompanyIdSelector)
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { notification } = App.useApp()

  if (!companyId) {
    throw new Error('Company ID is required for fetching budgeting scenarios.')
  }

  return useMutation({
    mutationKey: forecastQueryKeys.list(companyId),
    mutationFn: (data: BudgetingScenario) => {
      const denormalized = denormalizeScenario(data)
      return updateForecast(denormalized, companyId, denormalized.id)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: forecastQueryKeys.list(companyId) })
      notification.success({ message: t('message:UPDATE_BUDGETING_SCENARIO_SUCCESS') })
    }
  })
}
