import { PUT } from '../../../redux/middleware/types'
import backendRequest from '../../../services/api/backendRequest'
import customReportEndpoints from '../api/customReportEndpoints'
import { CustomReportOrderData } from '../../../pages/reporting/custom/components/settings/CustomReportOrder'

export const updateStructure = (data: CustomReportOrderData[], companyId: string) => {
  return backendRequest<CustomReportOrderData[]>({
    method: PUT,
    url: customReportEndpoints.structure(companyId),
    data
  })
}
