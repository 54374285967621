import { queryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { fetchExchangeRates } from '../service/exchangeRateService'
import { CustomExchangeRateSeries } from '../types/exchangeRate'
import exchangeRateQueryKeys from './exchangeRateQueryKeys'

export const useExchangeRatesOptions = <TData = CustomExchangeRateSeries[]>(
  select?: (data: CustomExchangeRateSeries[]) => TData
) => {
  const companyId = useSelector(contextCompanyIdSelector)!

  return queryOptions({
    queryKey: exchangeRateQueryKeys.list(companyId),
    queryFn: fetchExchangeRates,
    select
  })
}
