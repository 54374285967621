import { queryOptions, UseQueryOptions } from '@tanstack/react-query'
import forecastQueryKeys from './forecastQueryKeys'
import { fetchForecasts } from '../../service/forecastService'
import { BudgetingScenario } from '../../types/BudgetingScenario'
import { sortById } from '../../../../utils/helpers'
import { normalizeScenario } from '../../utils/scenarioUtils'

type UseForecastOptionsProps<TData> = {
  companyId: string
  select?: (data: BudgetingScenario[]) => TData
}

export const useForecastOptions = <TData = BudgetingScenario[]>({
  companyId,
  select
}: UseForecastOptionsProps<TData>) => {
  return queryOptions({
    queryKey: forecastQueryKeys.list(companyId),
    queryFn: fetchForecasts,
    gcTime: 15 * 60 * 1000,
    enabled: !!companyId,
    select: data => {
      const sortedData = sortById(data)
      const normalizedData = sortedData.map(normalizeScenario)
      return select ? select(normalizedData) : normalizedData
    }
  }) as UseQueryOptions<TData>
}
