import { ReloadOutlined } from '@ant-design/icons'
import { t } from 'i18next'
import React from 'react'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useSelector } from 'react-redux'
import { Form, Tooltip } from 'antd'
import KeyFigureInput from '../../../../../../../components/inputs/KeyFigureInput'
import { RowType } from '../../../../../../../redux/context/customReports/typesVariable'
import { formulasSelector } from '../../../../../../../redux/context/formulas/selectors'

const KeyfigureId = () => {
  const form = useFormInstance()
  const formulas = useSelector(formulasSelector)
  const keyFigureId = Form.useWatch('keyFigureId', form)

  const updateTranslations = (type: 'keyFigure' | 'formula', id?: number) => {
    if (type === 'keyFigure') {
      form.setFieldsValue({
        nameFi: t(`keyFigureStatement:${id}`, {
          lng: 'fi'
        }),
        nameEn: t(`keyFigureStatement:${id}`, {
          lng: 'en'
        }),
        nameSv: t(`keyFigureStatement:${id}`, {
          lng: 'sv'
        })
      })
    } else {
      const formula = formulas.find(f => f.id === id)
      formula &&
        form.setFieldsValue({
          nameFi: formula.nameFi,
          nameEn: formula.nameEn,
          nameSv: formula.nameSv
        })
    }
  }

  return (
    <Form.Item
      key={RowType.keyFigure}
      name="keyFigureId"
      rules={[{ required: true, message: t('global:required-field') }]}
      label={
        <div>
          {t('global:keyFigure')}
          <Tooltip title={t('customReportPage:updateKeyfigureTranslations')}>
            <ReloadOutlined
              style={{ marginLeft: 5, color: '#0225a1', fontSize: 14 }}
              onClick={() => {
                updateTranslations('keyFigure', keyFigureId)
              }}
            />
          </Tooltip>
        </div>
      }
    >
      <KeyFigureInput />
    </Form.Item>
  )
}

export default KeyfigureId
