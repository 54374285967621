import React, { useContext, useEffect, useRef, useState } from 'react'
import { Tooltip, FloatButton } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import { calculatedReportSelector } from '../../../redux/context/customReports/selectors'
import CustomReportLayoutEditor from './components/layout/CustomReportLayoutEditor'
import CustomReportActions from './components/CustomReportActions'
import PageHeaderExtra from './components/PageHeaderExtra'
import CustomReportGrid from './components/CustomReportGrid'
import { CustomReport } from '../../../redux/context/customReports/types'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { contextRequest } from '../../../redux/context/actions'
import { getFormulasRequest } from '../../../redux/context/formulas/actions'
import { AppDispatch } from '../../../redux/store'
import { CustomReportSectionsType } from '../../../redux/context/customReports/typesSection'
import CustomReportModal from './components/CustomReportModal'
import CategoryProvider from './components/categorySelection/CategoryContext'
import SectionModal from './components/section/CustomReportSectionModal'
import { loadingSelector } from '../../../redux/loading/selectors'
import Loading from '../../../shared/components/Loading'
import { LayoutsContextType, LayoutsContext } from './components/layout/LayoutContext'
import LayoutButtons from './components/layout/LayoutButtons'
import { useSectionLoading } from './useSectionLoading'
import { useBackend } from '../../../services/backend'
import { useCtxCustomReportStructure } from '../../../features/customReport/queries/structure/useCtxCustomReportStructure'

const CustomReportsPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const calculatedReport = useSelector(calculatedReportSelector)! // TODO: calculated pitää olla että voi editaoida layout
  const [reportModalVisible, setreportModalVisible] = useState(false)
  const [sectionModalVisible, setSectionModalVisible] = useState(false)
  const [editSectionData, setEditSectionData] = useState<CustomReportSectionsType | null>(null)
  const getReportDataRequest = useBackend(`/api/companies/{companyId}/reporting/custom/reports/{reportId}`)
  const { isPending: treeIsPending } = useCtxCustomReportStructure()
  const { customReports: customReportsLoading } = useSelector(loadingSelector)
  const sectionListRef = useRef(null)
  const [reportInEdit, setReportInEdit] = useState<CustomReport | null>(null)
  const { layoutEdit } = useContext(LayoutsContext) as LayoutsContextType
  const { isFinished, itemsLoading } = useSectionLoading()

  useEffect(() => {
    if (companyId) {
      dispatch(contextRequest(getFormulasRequest))
    }
  }, [companyId])

  const handleCancel = () => {
    setReportInEdit(null)
    setreportModalVisible(false)
  }

  const openEdit = (report: CustomReport) => {
    setReportInEdit(report)
    setreportModalVisible(true)
  }

  const editModal = (editSection: CustomReportSectionsType | null) => {
    setEditSectionData(editSection)
    setSectionModalVisible(true)
  }

  const layoutView = <CustomReportLayoutEditor report={calculatedReport} />

  const reportView = (
    <div ref={sectionListRef}>
      <CustomReportGrid itemsLoading={itemsLoading} calculatedReport={calculatedReport} />
    </div>
  )

  return (
    <>
      <CustomReportModal onClose={handleCancel} report={reportInEdit} visible={reportModalVisible} />
      {sectionModalVisible && (
        <CategoryProvider visible={sectionModalVisible} categories={(editSectionData as any)?.categories || []}>
          <SectionModal
            visible={sectionModalVisible}
            setVisible={setSectionModalVisible}
            key="add-section"
            reportId={calculatedReport?.id}
            section={editSectionData || null}
          />
        </CategoryProvider>
      )}
      <PageHeaderWrapper
        fixedHeader
        affixProps={{ offsetTop: 56 }}
        title={t('customReportPage:custom-reports')}
        subTitle={
          <Tooltip placement="right" title="info">
            <DocumentationLink />
          </Tooltip>
        }
        ghost={false}
        extra={
          layoutEdit
            ? [<LayoutButtons />]
            : [
                <PageHeaderExtra itemsLoading={itemsLoading} getReportDataRequest={getReportDataRequest} />,
                <CustomReportActions
                  isFinished={isFinished}
                  sectionListRef={sectionListRef}
                  editModal={editModal}
                  openEdit={openEdit}
                  setreportModalVisible={setreportModalVisible}
                />
              ]
        }
      >
        {/* TODO: loading hässäkkä */}
        {getReportDataRequest.loading || customReportsLoading || treeIsPending ? (
          <Loading />
        ) : (
          <>
            {layoutEdit && calculatedReport ? layoutView : reportView}
            <FloatButton.BackTop visibilityHeight={1000} />
          </>
        )}
      </PageHeaderWrapper>
    </>
  )
}

export default CustomReportsPage
