import { Form, Input } from 'antd'
import React from 'react'

const FontStyle = () => {
  return (
    <Form.Item hidden key="fontStyle" name={['style', 'fontStyle']}>
      <Input />
    </Form.Item>
  )
}

export default FontStyle
