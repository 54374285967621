import { createSelector } from 'reselect'
import { VariableType } from '../../../components/Table/types'
import { contextSelector } from '../selectors'
import { appLanguageSelector } from '../../app/selectors'
import { translateRecord } from '../../../utils/helpers'
import { Formula } from '../../../types/formula/Formula'

export const formulasBaseSelector = createSelector(contextSelector, context => {
  return context.formulas
})

// Not translated
export const formulaByIdSelector = (id?: number) =>
  createSelector(formulasBaseSelector, formulas => {
    return formulas.find(f => f.id === id)
  })

export const formulasSelector = createSelector([contextSelector, appLanguageSelector], (context, language) => {
  return context.formulas
    .slice()
    .sort((a, b) => a.order - b.order)
    .map(formula => translateRecord(formula, language)) as Formula[]
})

export const getFormulasByTypeSelector = (type: VariableType) => {
  return createSelector(formulasSelector, formulas => {
    return formulas.filter(d => d.type === type)
  })
}

export const refArgumentOptionsSelector = createSelector(formulasSelector, formulas => {
  return formulas
    .filter(formula => formula.code)
    .filter(f => f.isVariable === false)
    .map(formula => ({
      name: `${formula.code} - ${formula.name}`,
      value: `'${formula.code}'`
    }))
})

export const varArgumentOptionsSelector = createSelector(formulasSelector, formulas => {
  return formulas
    .filter(formula => formula.code)
    .filter(f => f.isVariable === true)
    .map(formula => ({
      name: `${formula.code} - ${formula.name}`,
      value: `'${formula.code}'`
    }))
})

export const formulasWithoutDriversSelector = createSelector(formulasSelector, formulas => {
  return formulas.filter(f => f.isVariable === false)
})
