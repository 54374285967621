import React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import { filtersSelector } from '../../redux/context/filters/selectors'
import DimensionText from './DimensionText'
import ScenarioName from '../../features/scenario/components/ScenarioName'

const { Text } = Typography

const HeaderInfo = () => {
  const { budgetingScenario } = useSelector(filtersSelector)
  const { t } = useTranslation()

  return (
    <Text ellipsis>
      <DimensionText />
      <span style={{ marginLeft: 8, fontSize: 17, fontWeight: 'bold' }}>
        <ScenarioName scenarioId={budgetingScenario?.id} />
        {budgetingScenario?.forecastCursor && (
          <span style={{ fontSize: 12 }}>
            {` (${t('global:actuals_until', {
              date: dayjs(budgetingScenario?.forecastCursor).format('MM/YYYY')
            })})`}
          </span>
        )}
        {budgetingScenario?.isLocked && <span style={{ fontSize: 12 }}>{` (${t('global:isLocked')})`}</span>}
      </span>
    </Text>
  )
}

export default HeaderInfo
