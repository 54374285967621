const customReportEndpoints = {
  list: (companyId: string) => `/companies/${companyId}/reporting/custom/reports` as const,
  detail: (companyId: string, reportId: number) =>
    `/companies/${companyId}/reporting/custom/reports/${reportId}` as const,
  listGroup: (companyId: string) => `/companies/${companyId}/reporting/custom/reports/groups` as const,
  detailGroup: (companyId: string, id: number) =>
    `/companies/${companyId}/reporting/custom/reports/groups/${id}` as const,
  structure: (companyId: string) => `/companies/${companyId}/reporting/custom/reports/groups/structure` as const,
  copy: (companyId: string, reportId: number | string) =>
    `/companies/${companyId}/reporting/custom/reports/copy/${reportId}` as const
}

export default customReportEndpoints
