import React from 'react'
import { styled } from 'styled-components'

type CustomHeaderCellProp = React.HTMLAttributes<HTMLTableCellElement>

const CustomDiv = styled.div`
  display: flex;
  flex-direction: row;
  &::before {
    flex-grow: 2;
    display: block;
    content: '';
  }

  > * {
    text-align: left;
  }
`

const CustomHeaderCell: React.FC<CustomHeaderCellProp> = props => {
  const { style: propsStyle, children } = props

  const style: React.CSSProperties = {
    ...propsStyle,
    fontSize: '11px',
    lineHeight: '.8rem',
    verticalAlign: 'top'
  }

  return (
    <th {...props} style={style}>
      {propsStyle?.textAlign === 'right' ? <CustomDiv>{children}</CustomDiv> : children}
    </th>
  )
}

export default CustomHeaderCell
