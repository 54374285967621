import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'
import OneDrivePage from '../../../../../components/Onedrive/OneDrive'

const BudgetOneDrive = () => {
  const { t } = useTranslation()
  return (
    <>
      <OneDrivePage type="budget" dokumentationPath="/budgeting?id=onedrive-budjetti" />
      <Divider />
      {t('budgetingImportPage:info-text')}
    </>
  )
}

export default BudgetOneDrive
