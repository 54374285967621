import { queryOptions } from '@tanstack/react-query'
import customReportQueryKeys from '../customReportQueryKeys'
import { fetchCustomReports } from '../../service/customReportService'

export const useCustomReportOptions = (companyId: string) => {
  return queryOptions({
    queryKey: customReportQueryKeys.list({ companyId }),
    queryFn: fetchCustomReports,
    enabled: !!companyId
  })
}
