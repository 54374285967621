import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'
import { ReportDataType } from '../reports/types'
import {
  SetDataTypeFilterAction,
  SetFiscalYearFilterAction,
  SET_DATATYPE_FILTER,
  SET_FISCAL_YEAR_FILTER,
  ToggleDisplayEmptyRowsAction,
  TOGGLE_DISPLAY_EMPTY_ROWS,
  ToggleDataTypeFilterAction,
  TOGGLE_DATATYPE_FILTER,
  SetDimensionsFilterAction,
  SetBudgetingScenarioFilterAction,
  SET_BUDGETING_SCENARIO_FILTER,
  SetContextFilterAction,
  SET_CONTEXT_FILTER,
  SET_CUMULATIVE_FILTER,
  SetCumulativeFilterAction,
  ToggleShowIncomeStatementPercentageChangeAction,
  TOGGLE_SHOW_INCOME_STATEMENT_PERCENTAGE_CHANGE,
  SetPeriodGroupsAction,
  SET_PERIOD_GROUPS,
  AddPeriodGroupAction,
  ADD_PERIOD_GROUP,
  RemovePeriodGroupAction,
  REMOVE_PERIOD_GROUP,
  SET_PERIOD_GROUP_FILTER,
  SetPeriodGroupFilterAction,
  ToggleCumulativeFilterAction,
  TOGGLE_CUMULATIVE_FILTER,
  RESET_CONTEXT_FILTER,
  ResetContextFilterAction,
  SET_DIMENSIONS_FILTER
} from './types'
import { Dimension } from '../../../types/dimension/Dimension'
import { BudgetingScenario } from '../../../features/scenario/types/BudgetingScenario'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { Column } from '../periodGroups/hooks'

export const setFiscalYearFilter = (payload: FiscalYear | null): SetFiscalYearFilterAction => ({
  type: SET_FISCAL_YEAR_FILTER,
  payload
})

export const setCumulativeFilter = (payload: any): SetCumulativeFilterAction => ({
  type: SET_CUMULATIVE_FILTER,
  payload
})

export const toggleDisplayEmptyRows = (): ToggleDisplayEmptyRowsAction => ({
  type: TOGGLE_DISPLAY_EMPTY_ROWS
})

export const setDataTypeFilter = (payload: ReportDataType[]): SetDataTypeFilterAction => ({
  type: SET_DATATYPE_FILTER,
  payload
})

export const toggleDataTypeFilter = (payload: ReportDataType): ToggleDataTypeFilterAction => ({
  type: TOGGLE_DATATYPE_FILTER,
  payload
})

export const ToggleShowIncomeStatementPercentageChange = (): ToggleShowIncomeStatementPercentageChangeAction => ({
  type: TOGGLE_SHOW_INCOME_STATEMENT_PERCENTAGE_CHANGE
})

export const setContextFilter = (payload: {
  dimension?: Dimension
  dimensions?: string[]
  budgetingScenario?: BudgetingScenario
  periodGroups?: PeriodGroup[]
}): SetContextFilterAction => ({
  type: SET_CONTEXT_FILTER,
  payload
})

export const setDimensionsFilter = (payload?: string[]): SetDimensionsFilterAction => ({
  type: SET_DIMENSIONS_FILTER,
  payload
})

export const setBudgetingScenarioFilter = (payload?: BudgetingScenario): SetBudgetingScenarioFilterAction => ({
  type: SET_BUDGETING_SCENARIO_FILTER,
  payload
})

export const setPeriodGroups = (payload?: PeriodGroup[]): SetPeriodGroupsAction => ({
  type: SET_PERIOD_GROUPS,
  payload
})

export const addPeriodGroup = (payload: PeriodGroup): AddPeriodGroupAction => ({
  type: ADD_PERIOD_GROUP,
  payload
})

export const removePeriodGroup = (payload: PeriodGroup): RemovePeriodGroupAction => ({
  type: REMOVE_PERIOD_GROUP,
  payload
})

export const setPeriodGroupFilter = (payload?: FiscalYear | PeriodGroup): SetPeriodGroupFilterAction => ({
  type: SET_PERIOD_GROUP_FILTER,
  payload
})

export const toggleCumulativeFilter = (payload: Column): ToggleCumulativeFilterAction => ({
  type: TOGGLE_CUMULATIVE_FILTER,
  payload
})

export const resetContextFilter = (): ResetContextFilterAction => ({
  type: RESET_CONTEXT_FILTER
})
