import React from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Input, Form, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'antd/es/form/Form'
import { styled } from 'styled-components'
import { useSelector } from 'react-redux'
import { primaryColor } from '../../../pages/user/utils'
import { useLogin } from '../queries/useLogin'
import { currentUserHomepageSelector } from '../../../redux/session/currentUser/selectors'
import { Credentials } from '../types/Login'

const StyledLogin = styled.div`
  .login-form {
    max-width: 400px;
    padding: 0 16px;
    margin: 0 auto;
  }

  .login-input,
  .login-form button {
    height: 40px;
  }
`

const Login: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form] = useForm<Credentials>()
  const location = useLocation()
  const homepage = useSelector(currentUserHomepageSelector)
  const { mutateAsync: login, isPending: isLoggingIn, error } = useLogin()

  const errorMessage = t(`description:${error?.response?.data?.message}`, '') || t('errorMessage:DEFAULT')

  const handleLogin = async (credentials: Credentials) => {
    try {
      await login(credentials)
      navigate(location?.state?.from || homepage)
    } catch (e) {
      // Error is already handled by the useLogin hook
      console.debug('Login failed, not navigating')
    }
  }

  return (
    <StyledLogin>
      <Form form={form} onFinish={handleLogin} className="login-form">
        <Form.Item name="email">
          <Input
            className="login-input email"
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="email"
            placeholder={t('global:email')}
            type="email"
          />
        </Form.Item>
        <Form.Item name="password">
          <Input
            className="login-input pass"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="password"
            type="password"
            placeholder={t('global:password')}
          />
        </Form.Item>
        {error && (
          <div style={{ marginBottom: 16 }}>
            <Typography.Text type="danger">{errorMessage}</Typography.Text>
          </div>
        )}
        <Form.Item>
          <Button
            block
            loading={isLoggingIn}
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ backgroundColor: primaryColor(window.location.hostname) }}
          >
            {t('global:login')}
          </Button>
        </Form.Item>
        <Link to="/user/forgot-password">{t('usersPage:forgot-password')}</Link>
      </Form>
    </StyledLogin>
  )
}

export default Login
