import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { ExchangeRateSeriesRequestBody } from '../types/exchangeRate'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { createExchangeRate } from '../service/exchangeRateService'
import { useExchangeRatesOptions } from './useExchangeRateOptios'

export default function useCreateExchangeRate() {
  const groupId = useSelector(contextCompanyIdSelector)!
  const queryClient = useQueryClient()
  const options = useExchangeRatesOptions()

  return useMutation({
    mutationKey: ['exchangeRate'],
    mutationFn: (data: ExchangeRateSeriesRequestBody) => createExchangeRate(groupId, data),
    retry: 2,
    onSuccess: updatedItem => {
      queryClient.setQueryData(options.queryKey, oldItems => {
        return oldItems?.map(item => (item.id === updatedItem.id ? updatedItem : item))
      })
    }
  })
}
