import React from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { arrayDownload, downloadMenuItems } from '../../../utils/tableExport'
import { CustomExchangeRateSeriesWithCompany } from '../types/exchangeRate'
import { parseTitleRow, parseReportRows } from '../utils'

interface ExchangeRateDownloadButtonProps {
  companyData?: CustomExchangeRateSeriesWithCompany
}

const ExchangeRateDownloadButton: React.FC<ExchangeRateDownloadButtonProps> = ({ companyData }) => {
  const { company, baseCurrency, currency } = companyData || {}

  const handleMenuClick = ({ key }: any) => {
    const titleRow = parseTitleRow(companyData)
    const valueRows = parseReportRows(companyData)
    const aoa = [titleRow, ...valueRows]

    const fileName = `${company?.name}_${currency}_${baseCurrency}`

    switch (key) {
      case 'csv':
      case 'xlsx':
        arrayDownload(aoa, fileName, key)
        break
      default:
        break
    }
  }

  const menuProps = {
    items: downloadMenuItems,
    onClick: handleMenuClick
  }

  return (
    <Dropdown menu={menuProps}>
      <Button icon={<DownloadOutlined />} />
    </Dropdown>
  )
}

export default ExchangeRateDownloadButton
