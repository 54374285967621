export const SET_TOKEN = 'SET_TOKEN'
export const LOGOUT = 'LOGOUT'

export interface SetTokenAction {
  type: typeof SET_TOKEN
  payload: string
}

export interface LogoutAction {
  type: typeof LOGOUT
}

export type AuthenticationActionTypes = SetTokenAction | LogoutAction
