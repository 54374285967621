import { BudgetingConfig } from '../../../redux/config/budgeting/types'
import { CashFlowStatementConfig } from '../../../redux/config/cashFlowStatement/types'
import { FinancialStatementConfig } from '../../../redux/config/financialStatement/types'
import { LiabilityMapping } from '../../../redux/config/liabilityMapping/types'
import { Config } from '../../../redux/config/types'
import backendRequest from '../../../services/api/backendRequest'
import configEndpoints from '../api/configEndpoints'

export const fetchConfigs = async () => {
  return backendRequest<Config>({
    method: 'GET',
    url: configEndpoints.configs
  })
}

export const getBudgetingConfig = async () => {
  return backendRequest<BudgetingConfig>({
    method: 'GET',
    url: configEndpoints.budgeting
  })
}

export const getCashFlowStatementConfig = async () => {
  return backendRequest<CashFlowStatementConfig>({
    method: 'GET',
    url: configEndpoints.cashFlowStatement
  })
}

export const getFinancialStatementConfig = async () => {
  return backendRequest<FinancialStatementConfig>({
    method: 'GET',
    url: configEndpoints.financialStatement
  })
}

export const getLiabilityMappingConfig = async () => {
  return backendRequest<LiabilityMapping>({
    method: 'GET',
    url: configEndpoints.liabilityMapping
  })
}
