import React from 'react'
import ScenariosPage from '../../../pages/settings/company/budgeting/budgetingScenarios/ScenariosPage'
import { useBudgetingScenarios } from '../queries/budgetingScenario/useBudgetingScenarios'
import { useCreateBudgetingScenario } from '../queries/budgetingScenario/useCreateBudgetingScenario'
import { useUpdateBudgetingScenario } from '../queries/budgetingScenario/useUpdateBudgetingScenario'
import { useDeleteBudgetingScenario } from '../queries/budgetingScenario/useDeleteBudgetingScenario'
import { useLockBudgetingScenario } from '../queries/budgetingScenario/useLockBudgetingScenario'
import { useUnLockBudgetingScenario } from '../queries/budgetingScenario/useUnLockBudgetingScenario'

const BudgetingScenarios: React.FC = () => {
  const query = useBudgetingScenarios()
  const create = useCreateBudgetingScenario()
  const update = useUpdateBudgetingScenario()
  const remove = useDeleteBudgetingScenario()
  const lock = useLockBudgetingScenario()
  const unLock = useUnLockBudgetingScenario()

  return (
    <ScenariosPage
      page="budgetingScenarios"
      query={query}
      createScenario={create}
      updateScenario={update}
      deleteScenario={remove}
      lockScenario={lock}
      unLockScenario={unLock}
    />
  )
}

export default BudgetingScenarios
