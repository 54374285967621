import {
  CheckCircleTwoTone,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  ReloadOutlined,
  WarningTwoTone
} from '@ant-design/icons'
import { Button, Divider, List, Popconfirm, Popover, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { yellow, green } from '@ant-design/colors'
import { GoogleSheet } from '../../pages/settings/company/integrations/types'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { dimensionListSelector } from '../../redux/context/dimensions/selectors'
import { ReportDataType } from '../../redux/context/reports/types'
import { notificationAction } from '../../redux/middleware/actions'
import { currentUserSelector, currentUserIsAdminSelector } from '../../redux/session/currentUser/selectors'
import { useBackend } from '../../services/backend'
import LoadingWrapper from '../Misc/LoadingWrapper'
import GoogleSheetsModal from './GoogleSheetsModal'
import DocumentationLink from '../Misc/DocumentationLink'
import { AppDispatch } from '../../redux/store'
import ScenarioName from '../../features/scenario/components/ScenarioName'

interface GoogleSheetsProps {
  type: 'budget' | 'external' | 'actuals'
}

enum columnNames {
  title = 'title',
  sheet = 'sheet',
  code = 'code',
  user = 'user',
  dataType = 'dataType',
  dimensionId = 'dimensionId',
  budgetingScenarioId = 'budgetingScenarioId',
  status = 'status',
  action = 'action'
}

const GoogleSheets: React.FC<GoogleSheetsProps> = ({ type }: GoogleSheetsProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const currentUser = useSelector(currentUserSelector)
  const dimensions = useSelector(dimensionListSelector)
  const isAdmin = useSelector(currentUserIsAdminSelector)

  const [sheets, setSheets] = useState<GoogleSheet[]>([])
  const getSheetsRequest = useBackend(`/google/sheets/{companyId}/${type}`, process.env.REACT_APP_INTEGRATION_URL)
  const deleteSheetRequest = useBackend(`/google/sheets/{companyId}/{sheetId}`, process.env.REACT_APP_INTEGRATION_URL)
  const refreshSheetRequest = useBackend(
    `/google/sheets/{companyId}/{sheetId}/refresh`,
    process.env.REACT_APP_INTEGRATION_URL
  )
  const [sheet, setSheet] = useState<GoogleSheet>()
  const [modalVisible, setModalVisible] = useState(false)
  const [loadingId, setLoadingId] = useState(0)

  const isOwner = (user: GoogleSheet['user']) => {
    return isAdmin || user.id === currentUser.id
  }

  const toggleModalVisible = () => {
    setSheet(undefined)
    setModalVisible(!modalVisible)
  }

  const toggleSheet = (sheetToUpdate: GoogleSheet) => {
    setSheet(sheetToUpdate)
    setModalVisible(true)
  }

  const refreshSheet = (sheetId: number) => {
    setLoadingId(sheetId)
    refreshSheetRequest
      .put({
        urlParams: { companyId, sheetId }
      })
      .then(() => {
        dispatch(
          notificationAction({
            type: 'success',
            message: 'UPDATE_INTEGRATION_SUCCESS'
          })
        )
        setLoadingId(0)
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'UPDATE_INTEGRATION_ERROR',
            description: 'VALUE_NOT_VALID'
          })
        )
        setLoadingId(0)
      })
  }

  const getSheetsHandler = () => {
    getSheetsRequest
      .get({
        urlParams: { companyId }
      })
      .then(setSheets)
      .catch((err: any) => {
        console.log(err)
      })
  }

  useEffect(getSheetsHandler, [companyId])

  const columns: ColumnProps<GoogleSheet>[] = []

  for (const [name] of Object.entries(columnNames)) {
    if (name === 'title') {
      columns.push({
        title: t('integrationsPage:title'),
        key: 'title',
        render: ({ url, title, user }: GoogleSheet) => {
          return isOwner(user) ? (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          ) : (
            title
          )
        }
      })
    }
    if (name === 'sheet') {
      columns.push({
        title: t('integrationsPage:sheet'),
        key: 'sheet',
        dataIndex: 'sheet'
      })
    }
    if (name === 'code' && type === 'external') {
      columns.push({
        title: t('integrationsPage:code'),
        key: 'code',
        dataIndex: 'code'
      })
    }
    if (name === 'user') {
      columns.push({
        title: t('integrationsPage:owner'),
        key: 'user',
        dataIndex: 'user.displayName',
        ellipsis: true,
        render: (displayName: string) => {
          return <Popover content={displayName}>{displayName}</Popover>
        }
      })
    }
    if (name === 'dataType' && type === 'external') {
      columns.push({
        title: t('global:type'),
        key: 'dataType',
        dataIndex: 'dataType',
        render: (dataType: ReportDataType) => t(`global:${dataType}`)
      })
    }
    if (name === 'dimensionId' && type === 'external') {
      columns.push({
        title: t('global:dimension'),
        key: 'dimensionId',
        dataIndex: 'dimensionId',
        ellipsis: true,
        render: (dimensionId: string) => {
          const dimension = dimensions.find(d => d.dimensionId === dimensionId)
          return dimension && <Popover content={dimension.name}>{dimension.name}</Popover>
        }
      })
    }
    if (name === 'budgetingScenarioId' && (type === 'external' || type === 'budget')) {
      columns.push({
        title: t('global:scenario'),
        key: 'budgetingScenarioId',
        dataIndex: 'budgetingScenarioId',
        render: (id: number) => <ScenarioName scenarioId={id} />
      })
    }
    if (name === 'status') {
      columns.push({
        title: t('status:status'),
        key: 'status',
        render: ({ errors }: GoogleSheet) => {
          return errors ? (
            <Popover
              content={
                <List
                  itemLayout="horizontal"
                  dataSource={errors}
                  renderItem={error => (
                    <List.Item>
                      <List.Item.Meta description={t(`error:${error}`)} />
                    </List.Item>
                  )}
                />
              }
            >
              <WarningTwoTone twoToneColor={yellow.primary} />
            </Popover>
          ) : (
            <CheckCircleTwoTone twoToneColor={green.primary} />
          )
        }
      })
    }
    if (name === 'action') {
      columns.push({
        title: t('global:actions'),
        key: 'action',
        align: 'right',
        width: 100,
        render: (s: GoogleSheet) =>
          // eslint-disable-next-line react/destructuring-assignment
          isOwner(s.user) && (
            <span>
              {refreshSheetRequest.loading && loadingId === s.id ? (
                <LoadingOutlined style={{ fontSize: 14 }} spin />
              ) : (
                <ReloadOutlined onClick={() => refreshSheet(s.id)} />
              )}
              <Divider type="vertical" />
              <EditOutlined onClick={() => toggleSheet(s)} />
              <Divider type="vertical" />
              <Popconfirm
                placement="bottomRight"
                title={t('global:delete-confirm')}
                onConfirm={() => {
                  deleteSheetRequest
                    .delete({ urlParams: { companyId, sheetId: s.id } })
                    .then((response: GoogleSheet) => {
                      dispatch(
                        notificationAction({
                          type: 'success',
                          message: 'DELETE_INTEGRATION_SUCCESS'
                        })
                      )
                      setSheets(sheets.filter(deleted => deleted.id !== response.id))
                    })
                    .catch(() => {
                      dispatch(
                        notificationAction({
                          type: 'error',
                          message: 'DELETE_INTEGRATION_ERROR'
                        })
                      )
                    })
                }}
                okText={t('global:yes')}
                cancelText={t('global:no')}
              >
                <DeleteOutlined style={{ color: 'red' }} />
              </Popconfirm>
            </span>
          )
      })
    }
  }

  return (
    <>
      <div style={{ marginBottom: 8 }}>
        <DocumentationLink route={{ path: '/settings/company?id=google-sheets' }} />
      </div>
      <Button onClick={toggleModalVisible} style={{ marginBottom: 8 }}>
        <PlusOutlined />
        {t('global:add-new')}
      </Button>
      <GoogleSheetsModal
        sheets={sheets}
        setSheets={setSheets}
        sheet={sheet}
        modalVisible={modalVisible}
        toggleModalVisible={toggleModalVisible}
        type={type}
      />
      <LoadingWrapper loading={getSheetsRequest.loading}>
        <Table size="small" rowKey="id" dataSource={sheets} columns={columns} />
      </LoadingWrapper>
    </>
  )
}

export default GoogleSheets
