import { createSelector } from 'reselect'
import { filtersSelector } from '../filters/selectors'

export const columnPeriodsSelector = createSelector(filtersSelector, filters => {
  const { periodGroup } = filters
  if (!periodGroup) return []

  if (periodGroup && 'periods' in periodGroup) {
    return periodGroup.periods
  }
  return []
})
