import dayjs from 'dayjs'
import { BackendRequest } from '../../../services/backend'
import { CustomReport } from '../../../redux/context/customReports/types'
import { Company } from '../../../types/company/Company'
import { QuickFilterForm } from './components/QuickFilters'
import { setCalculatedReport } from '../../../redux/context/customReports/actions'
import { CustomReportSectionsType } from '../../../redux/context/customReports/typesSection'
import { Language } from '../../../types/user/User'
import { notificationAction } from '../../../redux/middleware/actions'

export const fetchCustomReportSection = async (
  request: BackendRequest,
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: CustomReportSectionsType['id'],
  date?: string,
  quickFilter?: QuickFilterForm,
  language?: Language
): Promise<CustomReportSectionsType> => {
  return request
    .get({
      urlParams: { companyId, reportId, sectionId },
      body: {
        params: {
          lng: language,
          date: date && dayjs(date).format('YYYY-MM-DD'),
          quickFilter,
          dimensions: undefined
        }
      }
    })
    .catch(() => {
      return []
    })
}

export const fetchReportData = async (
  dispatch: Function,
  getReportDataRequest: BackendRequest,
  companyId: string,
  report?: CustomReport
) => {
  if (report) {
    await getReportDataRequest
      .get({ urlParams: { companyId, reportId: report.id } })
      .then((res: CustomReport) => dispatch(setCalculatedReport(res)))
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'Error fetching data'
          })
        )
        dispatch(setCalculatedReport(null))
      })
  }
}
