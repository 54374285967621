import { DeleteOutlined, EditOutlined, LockOutlined, PlusOutlined, UnlockOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Table, Form, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnProps } from 'antd/lib/table'
import dayjs from 'dayjs'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import {
  BudgetingScenario,
  ForecastType,
  CreateScenarioMutation,
  ScenarioQuery,
  UpdateScenarioMutation,
  BudgetingScenarioTransfer,
  DeleteScenarioMutation,
  LockScenarioMutation
} from '../../../../../features/scenario/types/BudgetingScenario'
import BudgetingScenarioForm from './components/ScenariosForm'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { setBudgetingScenarioFilter } from '../../../../../redux/context/filters/actions'
import { useBackend } from '../../../../../services/backend'
import { GroupSettingDataSource } from '../../../../../features/companyGroup/types/CompanyGroupSettings'
import { subsidiariesSelector } from '../../../../../redux/context/subsidiaries/selectors'
import { getSubsidiariesRequest } from '../../../../../redux/context/subsidiaries/actions'
import { Company } from '../../../../../types/company/Company'
import { formatFormValues } from './utils'
import { AppDispatch } from '../../../../../redux/store'
import { Loading } from '../../../../../components/Misc/Loading'
import RequestError from '../../../../../shared/components/RequestError'
import { useUpdateDefaultBudgetingScenario } from '../../../../../features/scenario/queries/budgetingScenario/useUpdateDefaultBudgetingScenario'
import { useCtxCompanyGroupBudgetSource } from '../../../../../features/companyGroup/queries/companyGroupSetting/useCtxCompanyGroupSettings'

interface ScenariosPageProps {
  page: 'budgetingScenarios' | 'forecasts'
  query: ScenarioQuery
  createScenario: CreateScenarioMutation
  updateScenario: UpdateScenarioMutation
  deleteScenario: DeleteScenarioMutation
  lockScenario: LockScenarioMutation
  unLockScenario: LockScenarioMutation
}

const ScenariosPage: React.FC<ScenariosPageProps> = ({
  page,
  query: { data: scenarios, isLoading, isFetching, error, refetch },
  createScenario,
  updateScenario,
  deleteScenario,
  lockScenario,
  unLockScenario
}: ScenariosPageProps) => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const [form] = Form.useForm()
  const companyId = useSelector(contextCompanyIdSelector)
  const { budgetingScenario: budgetingScenarioFilter } = useSelector(filtersSelector)
  const subsidiariesData = useSelector(subsidiariesSelector)

  const [modalVisible, setModalVisibility] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState<Company>()
  const groupRequest = useBackend(`api/companies/${companyId}/group`)

  const updateDefaultScenario = useUpdateDefaultBudgetingScenario()
  const { data: groupBudgetSourceSetting } = useCtxCompanyGroupBudgetSource()

  const getSubsidiariesAndGroupCompany = async () => {
    const group = await groupRequest.get()
    setSelectedGroup(group.group)
    companyId && dispatch(getSubsidiariesRequest(companyId))
  }

  useEffect(() => {
    groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries && getSubsidiariesAndGroupCompany()
  }, [companyId, groupBudgetSourceSetting])

  const handleCancel = () => {
    if (form) {
      form.resetFields()
    }
    setModalVisibility(false)
  }

  const initSubsidiaries = (budgetingScenario: BudgetingScenario) => {
    const parsedSubsidiaries = {}
    budgetingScenario?.subsidiaries?.forEach(s => {
      const id = s.companyId
      if (id) {
        Object.assign(parsedSubsidiaries, { [id]: s.id })
      }
    })
    return parsedSubsidiaries
  }

  const setInitValues = (budgetingScenario: BudgetingScenario) => {
    form.setFieldsValue({
      ...budgetingScenario,
      forecastCursor: budgetingScenario?.forecastCursor && dayjs(budgetingScenario.forecastCursor, 'YYYY-MM-DD'),
      selectedForecastType: budgetingScenario?.forecastType === 'MANUAL' ? 'manual' : 'rolling',
      subsidiaries: initSubsidiaries(budgetingScenario)
    })
  }

  const handleEdit = (budgetingScenario: BudgetingScenario) => {
    setModalVisibility(true)
    setInitValues(budgetingScenario)
  }

  const handleSave = async (transfersData?: BudgetingScenarioTransfer[]) => {
    const formValues = await form.validateFields()
    let values = formatFormValues(formValues, transfersData)

    if (page === 'forecasts') {
      values = {
        forecastType: ForecastType.MANUAL,
        ...values
      }
    }

    if (values.id === undefined) {
      /** Create new scenario */
      await createScenario.mutateAsync(values)
    } else if (values.id === null) {
      /** Update default scenario */
      await updateDefaultScenario.mutateAsync(values)
    } else {
      /** Update normal scenario */
      await updateScenario.mutateAsync(values)
    }

    handleCancel()
  }

  const handleDelete = (record: BudgetingScenario) => {
    if (record.id === budgetingScenarioFilter?.id) {
      dispatch(setBudgetingScenarioFilter(undefined))
    }
    return deleteScenario.mutateAsync(record.id)
  }

  const handleLock = async (record: BudgetingScenario) => {
    let response = null
    if (record.isLocked) {
      response = await unLockScenario.mutateAsync(record.id)
    }
    if (!record.isLocked) {
      response = await lockScenario.mutateAsync(record.id)
    }
    if (response && budgetingScenarioFilter?.id === response?.id) {
      dispatch(setBudgetingScenarioFilter(response))
    }
  }

  const generateCompanyColumns = () => {
    if (groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries) {
      const group = {
        title: selectedGroup?.name,
        ellipsis: true,
        width: 150,
        render: (record: BudgetingScenario) => (
          <>
            {record.subsidiaries?.find((subsidiary: any) => subsidiary.companyId === selectedGroup?.id)?.name ||
              t('global:default')}
          </>
        )
      }
      /** PYSTYY */
      const groupSubsidiaries = subsidiariesData.map(s => {
        return {
          title: s.name,
          ellipsis: true,
          width: 150,
          render: (record: BudgetingScenario) => (
            <>
              {record.subsidiaries?.find((subsidiary: any) => subsidiary.companyId === s.id)?.name ||
                t('global:default')}
            </>
          )
        }
      })
      return [group, ...groupSubsidiaries]
    }
    return []
  }

  const columns: ColumnProps<BudgetingScenario>[] = [
    {
      title: t('global:name'),
      dataIndex: 'name',
      ellipsis: true,
      fixed: 'left',
      width: groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries ? 200 : undefined
    },
    {
      title: t('global:description'),
      dataIndex: 'description',
      ellipsis: true,
      fixed: 'left',
      width: groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries ? 200 : undefined
    },
    ...generateCompanyColumns(),
    {
      title: t('global:actions'),
      key: 'action',
      width: 110,
      align: 'right',
      fixed: groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries && 'right',
      render: (record: BudgetingScenario) => (
        <Space>
          <Button size="small" type="text" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          <Popconfirm
            placement="bottomRight"
            title={record.isLocked ? t('global:unlock') : t('global:lock')}
            onConfirm={() => handleLock(record)}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            <Button
              disabled={record.id === null}
              type="text"
              size="small"
              danger={record.isLocked}
              icon={record.isLocked ? <LockOutlined /> : <UnlockOutlined />}
            />
          </Popconfirm>
          <Popconfirm
            placement="bottomRight"
            title={t('global:delete-confirm')}
            onConfirm={() => handleDelete(record)}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            <Button disabled={record.id === null} type="text" size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      )
    }
  ]

  if (page === 'forecasts' && !selectedGroup) {
    columns.splice(-1, 0, {
      title: t('global:actuals_until', { date: '' }),
      dataIndex: 'forecastCursor',
      ellipsis: true,
      width: 200,
      render: value => dayjs(value).format('L')
    })
  }

  if (isLoading) return <Loading />

  if (error) return <RequestError error={error} refetch={refetch} />

  return (
    <div>
      <Button
        style={{ marginBottom: '16px' }}
        onClick={() => {
          setModalVisibility(true)
        }}
      >
        <PlusOutlined />
        {t('global:add-new')}
      </Button>
      <BudgetingScenarioForm
        page={page}
        form={form}
        handleOk={handleSave}
        handleCancel={handleCancel}
        modalVisible={modalVisible}
        selectedGroup={selectedGroup}
        loading={createScenario.isPending || updateScenario.isPending || updateDefaultScenario.isPending}
      />
      <Table
        scroll={{ x: 500 }}
        loading={isFetching}
        rowKey={record => `${record.id}`}
        columns={columns}
        dataSource={scenarios}
      />
    </div>
  )
}

export default ScenariosPage
