import { Form, Input } from 'antd'
import React from 'react'

const IntendLevel = () => {
  return (
    <Form.Item hidden key="indentLevel" name={['style', 'indentLevel']}>
      <Input />
    </Form.Item>
  )
}

export default IntendLevel
