import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { useCtxReportableFiscalYears } from './useCtxReportableFiscalYears'
import { filtersSelector, periodGroupSelector } from '../../../redux/context/filters/selectors'
import { sortedPeriodGroupSelector } from '../selectors/selectors'

export const useCtxReportablePeriodGroups = () => {
  const periodGroups = useSelector(periodGroupSelector)
  const filters = useSelector(filtersSelector)
  const { data: reportable } = useCtxReportableFiscalYears()
  const reportablePeriodGroups = useMemo(
    () => sortedPeriodGroupSelector(periodGroups, reportable, filters),
    [periodGroups, reportable, filters]
  )
  return reportablePeriodGroups
}
