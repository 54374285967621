import React from 'react'
import { TreeSelectProps } from 'antd'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import CustomReportTreeSelect from './CustomReportTreeSelect'

const CtxCustomReportTreeSelect = (props: TreeSelectProps) => {
  const companyId = useSelector(contextCompanyIdSelector)!

  return <CustomReportTreeSelect companyId={companyId} {...props} />
}

export default CtxCustomReportTreeSelect
