import React, { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, Switch, Table, TableColumnsType } from 'antd'
import { useTranslation } from 'react-i18next'
import { reverse } from 'lodash'
import { FiscalYear } from '../../../../types/fiscalYear/FiscalYear'
import FiscalYearsForm from '../../../../features/fiscalYear/components/FiscalYearsForm'
import { useAuthorizedData } from '../../../../utils/Authorizable/authorize'
import { ContractProduct } from '../../../../types/contract/Contract'
import { useCtxFiscalYears } from '../../../../features/fiscalYear/queries/report/useCtxFiscalYears'
import { useCtxUpdateFiscalYear } from '../../../../features/fiscalYear/queries/report/useCtxUpdateFiscalYear'
import { useCtxDeleteFiscalYear } from '../../../../features/fiscalYear/queries/report/useCtxDeleteFiscalYear'

export const budgetableSettingAuthority = { product: [ContractProduct.BASIC, ContractProduct.PRO] }

const FiscalYearsView: React.FC = () => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisibility] = useState(false)
  const [editFiscalYear, setEditFiscalYear] = useState<FiscalYear>()

  const { data: fiscalYears, isLoading } = useCtxFiscalYears(reverse)
  const { mutate: updateFiscalYear } = useCtxUpdateFiscalYear()
  const { mutateAsync: deleteFiscalYear } = useCtxDeleteFiscalYear()

  const handleModalOpen = () => {
    setModalVisibility(true)
  }

  const handleUpdate = (values: FiscalYear) => {
    updateFiscalYear(values)
  }

  const handleCancel = () => {
    setModalVisibility(false)
    setEditFiscalYear(undefined)
  }

  const handleEdit = (fiscalYear: FiscalYear) => {
    setEditFiscalYear(fiscalYear)
    handleModalOpen()
  }

  const columns: TableColumnsType<FiscalYear> = useMemo(
    () => [
      {
        title: t('fiscalYearsPage:year'),
        dataIndex: 'year',
        ellipsis: true
      },
      {
        title: t('fiscalYearsPage:start-date'),
        dataIndex: 'startDate',
        ellipsis: true,
        render: (record: any) => dayjs(record).format('DD.MM.YYYY')
      },
      {
        title: t('fiscalYearsPage:end-date'),
        dataIndex: 'endDate',
        ellipsis: true,
        render: (text: any) => dayjs(text).format('DD.MM.YYYY')
      },
      {
        title: t('fiscalYearsPage:length'),
        dataIndex: 'length',
        align: 'center' as const,
        ellipsis: true
      },
      {
        title: t('fiscalYearsPage:state'),
        dataIndex: 'isOpen',
        width: 150,
        render: (isOpen: boolean, record: FiscalYear) => (
          <>
            <Switch onChange={() => handleUpdate({ ...record, isOpen: !record.isOpen })} checked={isOpen} />
            <span style={{ marginLeft: 8 }}>
              {isOpen ? t('fiscalYearsPage:isopen') : t('fiscalYearsPage:isclosed')}
            </span>
          </>
        )
      },
      {
        title: t('global:budgetable'),
        dataIndex: 'budgetable',
        width: 150,
        render: (budgetable: boolean, record: FiscalYear) => (
          <Switch onChange={() => handleUpdate({ ...record, budgetable: !record.budgetable })} checked={budgetable} />
        ),
        authority: budgetableSettingAuthority
      },
      {
        title: t('global:actions'),
        key: 'action',
        width: 100,
        align: 'right' as const,
        render: (record: FiscalYear) => (
          <Space>
            <Button type="text" size="small" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
            <Popconfirm
              placement="bottomRight"
              title={t('global:delete-confirm')}
              onConfirm={() => deleteFiscalYear(record.id)}
              okText={t('global:yes')}
              cancelText={t('global:no')}
            >
              <Button type="text" size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        )
      }
    ],
    [t, handleUpdate, handleEdit, deleteFiscalYear]
  )

  const authorizedColumns = useAuthorizedData(columns)

  return (
    <>
      <Button onClick={handleModalOpen} style={{ marginBottom: 8 }}>
        {`+ ${t('global:add-new')}`}
      </Button>
      {modalVisible && (
        <FiscalYearsForm handleCancel={handleCancel} modalVisible={modalVisible} fiscalYear={editFiscalYear} />
      )}
      <Table
        scroll={{ x: 'max-content' }}
        loading={isLoading}
        columns={authorizedColumns}
        dataSource={fiscalYears}
        rowKey={record => record.id.toString()}
      />
    </>
  )
}

export default FiscalYearsView
