import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'

import { App } from 'antd'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { copyCustomReport } from '../../service/customReportService'
import { setCalculatedReport } from '../../../../redux/context/customReports/actions'
import customReportQueryKeys from '../customReportQueryKeys'

const useCtxCopyCustomReport = () => {
  const { t } = useTranslation()
  const { notification } = App.useApp()

  const companyId = useSelector(contextCompanyIdSelector)!
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (reportId: number | string) => copyCustomReport(reportId, companyId),
    onSuccess: response => {
      dispatch(setCalculatedReport(response))
      return queryClient.invalidateQueries({
        queryKey: customReportQueryKeys.list({ companyId })
      })
    },
    onError: () => {
      notification.error({ message: t('messages:actionError') })
      dispatch(setCalculatedReport(null))
    }
  })
}

export default useCtxCopyCustomReport
