import { BgColorsOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState } from 'react'
import { ColorChangeHandler, SketchPicker } from 'react-color'
import { parseRgbString } from '../../../../../utils/helpers'

interface ColorPickerProps {
  value?: string
  verticalPosition?: 'top' | 'bottom'
  position?: 'fixed' | 'absolute'
  disabled?: boolean
  onChange?: (color?: string) => void
}

const ColorPicker: React.FC<ColorPickerProps> = ({ value, verticalPosition, position, onChange, disabled }) => {
  const [visible, setVisible] = useState(false)

  const handleChange: ColorChangeHandler = selectedColor => {
    const { r, g, b, a } = selectedColor.rgb
    const rgbString = `rgb(${[r, g, b, a].join(',')})`
    onChange?.(rgbString)
  }

  const handleReset = () => {
    onChange?.(undefined)
  }

  const popover = {
    position: 'absolute',
    zIndex: '9000',
    top: verticalPosition === 'bottom' ? 30 : -340
  } satisfies React.CSSProperties

  const cover = {
    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.15)',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    zIndex: '-1'
  } satisfies React.CSSProperties

  const toggle = () => {
    setVisible(!visible)
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = () => {
    toggle()
  }

  return (
    <>
      <Button
        disabled={disabled}
        style={{ backgroundColor: value }}
        icon={<BgColorsOutlined />}
        aria-hidden="true"
        onClick={toggle}
      />
      {visible ? (
        <div style={popover}>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div role="button" style={cover} onClick={toggle} onKeyDown={handleKeyDown} tabIndex={0} />
          <div style={{ position }}>
            <SketchPicker color={parseRgbString(value)} onChange={handleChange} />
            <Button block onClick={handleReset}>
              Clear
            </Button>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ColorPicker
