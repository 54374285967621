import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { App } from 'antd'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import useDeleteQueryItem from '../../../../services/api/hooks/useDeleteQueryItem'
import budgetingScenarioQueryKeys from './budgetingScenarioQueryKeys'
import { useBudgetingScenarioOptions } from './useBudgetingScenarioOptions'
import { deleteBudgetingScenario } from '../../service/budgetingScenarioService'
import { BudgetingScenario } from '../../types/BudgetingScenario'

export const useDeleteBudgetingScenario = () => {
  const companyId = useSelector(contextCompanyIdSelector)
  const { notification } = App.useApp()

  if (!companyId) {
    throw new Error('Company ID is required for deleting budgeting scenarios.')
  }

  const options = useBudgetingScenarioOptions({ companyId })
  const deleteQueryItem = useDeleteQueryItem()

  return useMutation({
    mutationKey: budgetingScenarioQueryKeys.list(companyId),
    mutationFn: (scenarioId: BudgetingScenario['id']) => {
      if (scenarioId === null) {
        throw new Error('Cannot delete the default budgeting scenario.')
      }
      return deleteBudgetingScenario(companyId, scenarioId)
    },
    onSuccess(item) {
      deleteQueryItem(item, options)
    },
    onError(error) {
      notification.error({ message: error.message })
    }
  })
}
