import { QueryFunctionContext } from '@tanstack/react-query'
import { DELETE, GET, POST, PUT } from '../../../redux/middleware/types'
import backendRequest from '../../../services/api/backendRequest'
import forecastEndpoints from '../api/forecastEndpoints'
import { BudgetingScenario } from '../types/BudgetingScenario'
import forecastQueryKeys from '../queries/forecast/forecastQueryKeys'

export const fetchForecasts = async ({
  queryKey: [{ companyId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof forecastQueryKeys)['list']>>) => {
  return backendRequest<BudgetingScenario[]>({
    method: GET,
    url: forecastEndpoints.list(companyId),
    signal
  })
}

export const createForecast = async (data: BudgetingScenario, companyId: string) => {
  return backendRequest<BudgetingScenario>({
    method: POST,
    url: forecastEndpoints.list(companyId),
    data
  })
}

export const updateForecast = async (
  data: BudgetingScenario,
  companyId: string,
  scenarioId: BudgetingScenario['id']
) => {
  return backendRequest<BudgetingScenario>({
    method: PUT,
    url: forecastEndpoints.detail(companyId, scenarioId),
    data
  })
}

export const deleteForecast = async (companyId: string, scenarioId: BudgetingScenario['id']) => {
  return backendRequest<BudgetingScenario>({
    method: DELETE,
    url: forecastEndpoints.detail(companyId, scenarioId)
  })
}

export const lockForecast = async (companyId: string, scenarioId: BudgetingScenario['id']) => {
  return backendRequest<BudgetingScenario>({
    method: PUT,
    url: forecastEndpoints.lock(companyId, scenarioId)
  })
}

export const unLockForecast = async (companyId: string, scenarioId: BudgetingScenario['id']) => {
  return backendRequest<BudgetingScenario>({
    method: PUT,
    url: forecastEndpoints.unLock(companyId, scenarioId)
  })
}
