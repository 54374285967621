import React, { Key, useEffect, useState } from 'react'
import { Button, Divider, Input, Popconfirm, Tooltip, TreeProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import DirectoryTree from 'antd/es/tree/DirectoryTree'
import { generateStructureData, generateTreeDataNodes } from './utils'
import { useCreateCustomReportGroup } from '../../../../../features/customReport/queries/group/useCreateCustomReportGroup'
import { recursiveFind } from '../../../../../utils/helpers'
import { useCtxDeleteCustomReportGroup } from '../../../../../features/customReport/queries/group/useCtxDeleteCustomReportGroup'
import { useCtxUpdateCustomReportGroup } from '../../../../../features/customReport/queries/group/useCtxUpdateCustomReportGroup'
import { useUpdateStructure } from '../../../../../features/customReport/queries/structure/useUpdateStructure'
import { useCtxCustomReportStructure } from '../../../../../features/customReport/queries/structure/useCtxCustomReportStructure'

export interface CustomReportData {
  id: number
  groupId?: number
  order?: number
  key?: string
  title: string
  name?: string
  companyId?: string
  children?: CustomReportData[]
  isLeaf?: boolean
  group?: boolean
}

export interface CustomReportOrderData {
  id: number
  order: number
  type: 'CustomReport' | 'CustomReportGroup'
  groupId: number | null
}

const CustomReportOrder: React.FC = () => {
  const { t } = useTranslation()
  const { data: customReportTree } = useCtxCustomReportStructure()
  const { mutate: createCustomReportGroup } = useCreateCustomReportGroup()
  const { mutate: updateCustomReportGroup } = useCtxUpdateCustomReportGroup()
  const { mutate: deleteCustomReportGroup } = useCtxDeleteCustomReportGroup()
  const { mutateAsync: updateStucture } = useUpdateStructure()

  const [treeData, setTreeData] = useState<CustomReportData[]>([])
  const [groupName, setGroupName] = useState<string>('')

  const addGroup = async (path?: Key) => {
    const item = recursiveFind(treeData, path as string, 'key')
    createCustomReportGroup({ name: groupName, groupId: item?.id })
    setGroupName('')
  }

  useEffect(() => {
    setTreeData(generateTreeDataNodes(customReportTree as CustomReportData[]))
    return () => {
      setTreeData([])
    }
  }, [customReportTree])

  const onDrop: TreeProps<CustomReportData>['onDrop'] = async info => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (
      data: CustomReportData[],
      key: React.Key,
      callback: (node: CustomReportData, i: number, data: CustomReportData[]) => void
    ) => {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].key === key) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback)
        }
      }
      return true
    }
    const data = [...treeData]

    let dragObj: CustomReportData
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || []
        item.children?.unshift(dragObj)
      })
    } else if (
      ((info.node as any).props.children || []).length > 0 &&
      (info.node as any).props.expanded &&
      dropPosition === 1
    ) {
      loop(data, dropKey, item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || []
        item.children?.unshift(dragObj)
      })
    } else {
      let ar: CustomReportData[] = []
      let i: number
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr
        i = index
      })
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!)
      } else {
        ar.splice(i! + 1, 0, dragObj!)
      }
    }
    const changedRow = info.dragNode

    const structuredData = generateStructureData(data, [changedRow.id])
    await updateStucture(structuredData)
    setTreeData(data)
  }

  const addNewGroup = (path?: Key) => {
    return (
      <Tooltip title={t('customReportPage:addReportGroup')}>
        <Popconfirm
          title={t('customReportPage:reportGroupName')}
          description={<Input style={{ width: 300 }} value={groupName} onChange={e => setGroupName(e.target.value)} />}
          onConfirm={() => addGroup(path)}
          onCancel={() => setGroupName('')}
          okText={t('global:save')}
          cancelText={t('global:cancel')}
        >
          {path ? (
            <PlusCircleOutlined style={{ fontSize: 17 }} />
          ) : (
            <Button style={{ marginLeft: 8 }} type="primary">
              {t('customReportPage:addReportGroupToRoot')}
            </Button>
          )}
        </Popconfirm>
      </Tooltip>
    )
  }

  const editGroup = (id: number, title: string) => {
    let editGroupName = title
    return (
      <>
        <Divider type="vertical" />
        <Tooltip title={t('customReportPage:editReportGroupName')}>
          <Popconfirm
            title={t('customReportPage:reportGroupName')}
            description={
              <Input
                style={{ width: 300 }}
                defaultValue={editGroupName}
                onChange={e => {
                  editGroupName = e.target.value
                }}
              />
            }
            onConfirm={() => {
              updateCustomReportGroup({ id, name: editGroupName })
            }}
            okText={t('global:edit')}
            cancelText={t('global:cancel')}
            onCancel={() => {
              editGroupName = title
            }}
          >
            <EditOutlined style={{ fontSize: 17, color: 'black' }} />
          </Popconfirm>
        </Tooltip>
      </>
    )
  }

  const deleteGroup = (id: number, children?: CustomReportData['children']) => {
    return (
      <>
        <Divider type="vertical" />
        <Tooltip title={t('customReportPage:deleteReportGroup')}>
          <Popconfirm
            title={t('global:delete-confirm')}
            onConfirm={() => {
              deleteCustomReportGroup(id)
            }}
            okText={t('global:yes')}
            cancelText={t('global:no')}
            disabled={children && children?.length > 0}
          >
            <DeleteOutlined
              style={{
                fontSize: 17,
                color: children && children?.length > 0 ? 'grey' : 'red'
              }}
            />
          </Popconfirm>
        </Tooltip>
      </>
    )
  }

  return (
    <>
      <DirectoryTree
        className="draggable-tree"
        draggable
        blockNode
        onDrop={onDrop}
        selectable={false}
        expandAction={false}
        showLine
        allowDrop={({ dropNode, dropPosition }) => {
          if (dropNode.isLeaf && dropPosition === 0) {
            return false
          }
          return true
        }}
        treeData={treeData}
        titleRender={cat => (
          <span style={{ color: !cat.isLeaf ? '#0225a1' : undefined, height: 90, fontSize: 15 }}>
            {`${cat.title}  `}
            <span style={{ float: 'right', marginRight: 50 }}>
              {!cat.isLeaf && [
                // actions
                addNewGroup(cat.key),
                editGroup(cat.id, cat.title),
                deleteGroup(cat.id, cat.children)
              ]}
            </span>
          </span>
        )}
      />
      <div style={{ marginTop: 20, float: 'right', marginRight: 50 }}>{addNewGroup()}</div>
    </>
  )
}

export default CustomReportOrder
