import React from 'react'
import { FolderOutlined, FileOutlined } from '@ant-design/icons'
import { CustomReportStructure } from './types/CustomReportStructure'

interface TreeNode {
  value: string | number // Represents the unique identifier of the node.
  title: string // The display title for the node.
  children?: TreeNode[] // Array of child nodes, optional.
  disabled?: boolean // Whether the node is disabled.
  disableCheckbox?: boolean // Whether the checkbox for the node is disabled.
  selectable?: boolean // Whether the node is selectable.
  checkable?: boolean // Whether the node is checkable.
}

export const generateTreeData = (
  customReportNodes: CustomReportStructure[],
  maxDepth = 100,
  currentDepth = 0
): TreeNode[] => {
  // Prevent infinite recursion
  if (currentDepth > maxDepth) {
    console.warn('Maximum depth exceeded in generateTreeData, possible circular reference')
    return []
  }

  return customReportNodes?.map(customReportNode => {
    const { group, id, title, children } = customReportNode
    return {
      title,
      value: id,
      icon: group ? <FolderOutlined /> : <FileOutlined />,
      selectable: !group,
      style: { color: !group ? 'rgba(0, 0, 0, 0.98)' : 'rgba(0, 0, 0, 0.78)' },
      children: children && children.length > 0 ? generateTreeData(children, maxDepth, currentDepth + 1) : undefined
    }
  })
}
