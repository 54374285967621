import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Decimals = () => {
  const { t } = useTranslation()

  return (
    <Form.Item key="decimals" name={['style', 'decimals']} label={t('global:decimal', 'Desimaalit')}>
      <Select
        options={[0, 1, 2, 3].map(val => ({
          label: val,
          value: val,
          key: val
        }))}
      />
    </Form.Item>
  )
}

export default Decimals
