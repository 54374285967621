import { QueryFunctionContext } from '@tanstack/react-query'
import { GET } from '../../../redux/middleware/types'
import backendRequest from '../../../services/api/backendRequest'
import { Company } from '../../../types/company/Company'
import companyEndpoints from '../api/companyEndpoints'
import companyQueryKeys from '../queries/companyQueryKeys'

export const fetchcompanies = ({
  queryKey: [{ vatId, industryId, revenueFrom, revenueTo }],
  signal
}: QueryFunctionContext<ReturnType<(typeof companyQueryKeys)['list']>>) => {
  return backendRequest<Company[]>({
    method: GET,
    url: companyEndpoints.list,
    params: { vatId, industryId, revenueFrom, revenueTo },
    signal
  })
}

export const fetchcompany = async (vatId: string) => {
  const response = await backendRequest<Company[]>({
    method: GET,
    url: companyEndpoints.list,
    params: { vatId }
  })
  return response?.[0]
}
