import React, { useState } from 'react'
import { List, Dropdown, Button, Tooltip, Popconfirm, MenuProps } from 'antd'
import { useSelector } from 'react-redux'
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import _ from 'lodash'
import { currentUserSelector } from '../../../../../redux/session/currentUser/selectors'
import BudgetCommentForm from './BudgetCommentForm'
import { companyUsersSelector } from '../../../../../redux/context/companyUsers/selectors'
import './comments.css'
import { BudgetingScenario } from '../../../../../features/scenario/types/BudgetingScenario'

export interface BudgetComment {
  id: number
  companyId: string
  userId: number
  fiscalYearId?: number
  month?: number
  year?: number
  incomeStatementRowId?: number
  accountCode?: number
  text: string
  dimensionId?: string
  budgetingScenarioId?: BudgetingScenario['id']
  createdAt: string
  updatedAt: string
}
interface BudgetCommetItemProps {
  comment: BudgetComment
  handleSave: any
  handleDelete: any
  disableActions?: boolean
}

const BudgetCommentItem: React.FC<BudgetCommetItemProps> = ({ comment, handleSave, handleDelete, disableActions }) => {
  const user = useSelector(currentUserSelector)
  const { t } = useTranslation()
  const [inEdit, setInEdit] = useState(false)
  const companyUsers = useSelector(companyUsersSelector) || []
  const currentUser = useSelector(currentUserSelector)

  const items: MenuProps['items'] = [
    {
      icon: <EditOutlined />,
      label: t('global:edit'),
      key: 'edit',
      onClick: () => setInEdit(true)
    },
    {
      icon: (
        <Popconfirm title={t('global:delete-confirm')} onConfirm={() => handleDelete(comment)}>
          <DeleteOutlined />
        </Popconfirm>
      ),
      label: (
        <Popconfirm title={t('global:delete-confirm')} onConfirm={() => handleDelete(comment)}>
          {t('global:delete')}
        </Popconfirm>
      ),
      key: 'delete'
    }
  ]

  const actionDropDown = () => (
    <Dropdown trigger={['click']} menu={{ items }} placement="bottomRight">
      <Button type="text" icon={<MoreOutlined />} />
    </Dropdown>
  )

  const dateTime = (timeStamp: string) => {
    const time = dayjs(timeStamp)
    return (
      <Tooltip title={time?.format('llll')}>
        <span>{dayjs(time).fromNow()}</span>
      </Tooltip>
    )
  }

  const handleUpdate = ({ text }: BudgetComment) => {
    handleSave({ ...comment, text })
    setInEdit(false)
  }

  const actions = user.id === comment?.userId && !disableActions ? actionDropDown() : null

  return (
    <List.Item actions={[actions]} key={comment.id} style={{ padding: 0, alignItems: 'flex-start' }}>
      <div key={comment.id} className="comment">
        <div className="comment-author">
          <strong>{_.keyBy([currentUser, ...companyUsers], 'id')[comment.userId]?.displayName}</strong>
          <span> • {dateTime(comment?.updatedAt || comment.createdAt)}</span>
        </div>
        <p className="comment-text">
          {!inEdit ? (
            <div style={{ wordBreak: 'break-all' }}>{comment.text}</div>
          ) : (
            <BudgetCommentForm comment={comment} handleSubmit={handleUpdate} handleCancel={() => setInEdit(false)} />
          )}
        </p>
      </div>
    </List.Item>
  )
}

export default BudgetCommentItem
