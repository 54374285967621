import { Form, FormItemProps, InputNumber } from 'antd'
import { t } from 'i18next'
import React from 'react'

const Offset = (props: FormItemProps) => {
  return (
    <Form.Item key="offset" name={['params', 'offset']} label={t('global:monthOffset', '± kuukautta')} {...props}>
      <InputNumber />
    </Form.Item>
  )
}

export default Offset
