import { CustomExchangeRateSeries } from '../../../features/exchangeRate/types/exchangeRate'

export const SET_EXCHANGE_RATES = 'SET_EXCHANGE_RATES'
export const UPDATE_EXCHANGE_RATES = 'UPDATE_EXCHANGE_RATES'

export interface SetExchangeRatesAction {
  type: typeof SET_EXCHANGE_RATES
  payload: CustomExchangeRateSeries[]
}

export interface UpdateExchangeRateAction {
  type: typeof UPDATE_EXCHANGE_RATES
  payload: CustomExchangeRateSeries
}

export type ExchangeRatesActionTypes = SetExchangeRatesAction | UpdateExchangeRateAction
