import { QueryFunctionContext } from '@tanstack/react-query'
import { GET, POST, PUT } from '../../../redux/middleware/types'
import { exchangeRateEndpoints } from '../api/exchangeRateEndpoints'
import request from '../../../services/api/backendRequest'
import exchangeRateQueryKeys from '../queries/exchangeRateQueryKeys'
import { CustomExchangeRateSeries, ExchangeRateSeriesRequestBody } from '../types/exchangeRate'

export const fetchExchangeRates = ({
  queryKey: [{ groupId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof exchangeRateQueryKeys)['list']>>) => {
  return request<CustomExchangeRateSeries[]>({
    method: GET,
    url: exchangeRateEndpoints.list(groupId),
    signal
  })
}

export const createExchangeRate = (groupId: string, data: ExchangeRateSeriesRequestBody) => {
  return request<CustomExchangeRateSeries>({
    method: POST,
    url: exchangeRateEndpoints.list(groupId),
    data
  })
}

export const updateExchangeRate = (groupId: string, serieId: number, data: Partial<CustomExchangeRateSeries>) => {
  return request<CustomExchangeRateSeries>({
    method: PUT,
    url: exchangeRateEndpoints.detail(groupId, serieId),
    data
  })
}
