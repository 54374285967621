import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import Bugsnag from '@bugsnag/js'
import { setToken } from '../../../redux/session/authentication/actions'
import { AppDispatch } from '../../../redux/store'
import { Credentials } from '../types/Login'
import { setCurrentUser } from '../../../redux/session/currentUser/actions'
import { setSessionContract } from '../../../redux/session/contract/actions'
import { loginUser } from '../service/loginService'

export function useLogin() {
  const dispatch = useDispatch() as AppDispatch

  return useMutation({
    mutationFn: (data: Credentials) => {
      return loginUser(data)
    },
    onSuccess: async ({ token, user, user: { contract } }) => {
      dispatch(setToken(token))
      dispatch(setCurrentUser(user))
      dispatch(setSessionContract(contract))
      if (user?.email) {
        Bugsnag.setUser(user.email)
      }
    },
    onError: error => {
      console.error(error)
    }
  })
}
