import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Modal, Popconfirm, Select, Space } from 'antd'
import React, { ReactText, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { currentUserSortedCompaniesSelector } from '../../../../../redux/session/currentUser/selectors'
import { CategoryContext, CategoryContextType } from './CategoryContext'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import RowSettingsModal from '../RowSettingsModal'

interface CompanyCategoryProps {
  path: string
  category: CustomReportCategory
}

export const CompanyCategory: React.FC<CompanyCategoryProps> = ({ path, category }: CompanyCategoryProps) => {
  const { addDimension, addPeriodGroup, addFunction, editObject, deleteObject, dimensionMap, dimensionLoading } =
    useContext(CategoryContext) as CategoryContextType
  const { t } = useTranslation()
  const companies = useSelector(currentUserSortedCompaniesSelector)
  const company = companies?.find(c => c.id === category.value)
  const dimensionTree = dimensionMap && category.value && dimensionMap[category.value as ReactText]

  function confirm(value: string) {
    Modal.confirm({
      title: t('global:note'),
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: t('customReportPage:dimension-change'),
      okText: 'Ok',
      cancelText: t('global:cancel'),
      onOk() {
        editObject(path, { ...category, value }, true)
      }
    })
  }

  function hasDimensions(cat: CustomReportCategory) {
    let containsDimensions = false

    if (!cat.children || cat.children.length < 1) return containsDimensions

    function recurse(data: CustomReportCategory[]) {
      for (const c of data) {
        if (c) {
          if (c.type === 'dimension') {
            containsDimensions = true
            break
          }
        }
        if (c.children) {
          recurse(c.children)
          if (containsDimensions) break
        }
      }
    }

    recurse(cat.children)
    return containsDimensions
  }

  const onChangeTitle = (c: Partial<CustomReportCategory>) => {
    if (c) {
      editObject(path, { ...category, ...c })
    }
  }

  return (
    <>
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder={t('contractsPage:company')}
        optionFilterProp="children"
        value={company ? company.id : undefined}
        onChange={value => {
          if (hasDimensions(category)) {
            confirm(value)
          } else {
            editObject(path, { ...category, value }, true)
          }
        }}
      >
        {(companies || []).map(c => (
          <Select.Option value={c.id} key={c.id} className={c.name}>
            {c.name}
          </Select.Option>
        ))}
      </Select>
      <Divider type="vertical" />
      <Space>
        <Button
          disabled={!!(dimensionTree && dimensionTree.length < 1) || !category.value || dimensionLoading}
          onClick={() => addDimension(path)}
          icon={<PlusOutlined />}
        >
          {t(`customReportCategoryType:dimension`)}
        </Button>
        <Button onClick={() => addFunction(path)} icon={<PlusOutlined />}>
          {t(`customReportCategoryType:function`)}
        </Button>
        <Button onClick={() => addPeriodGroup(path)} icon={<PlusOutlined />}>
          {t(`customReportCategoryType:periodGroup`)}
        </Button>
      </Space>
      <Divider type="vertical" />
      <Popconfirm
        title={t('global:delete-confirm')}
        onConfirm={() => deleteObject(path)}
        okText={t('global:yes')}
        cancelText={t('global:no')}
      >
        <Button icon={<DeleteOutlined />} />
      </Popconfirm>
      <Divider type="vertical" />
      <RowSettingsModal onChange={onChangeTitle} path={path} category={category} />
    </>
  )
}
