import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { CustomErrorResponseData } from '../../../services/api/globalErrorRegistration'
import { Company } from '../../../types/company/Company'
import { ReportDataType } from '../../../redux/context/reports/types'

export enum ForecastType {
  MANUAL = 'MANUAL',
  PERIOD_LOCK = 'PERIOD_LOCK', // Toteuma viimeisimpään suljettuun ALV-kauteen saakka
  CURRENT = 'CURRENT', // Toteuma aina käynnissä olevaa kuukautta edeltävään kuukauteen saakka
  PREVIOUS = 'PREVIOUS' // Toteuma aina käynnissä olevaa kuukautta edeltänyttä kuukautta edeltävään kuukauteen saakka
}

export interface BudgetingScenario {
  id: number | null // null is default scenario
  companyId?: Company['id']
  name: string | null
  forecastCursor?: string
  isLocked?: boolean
  description: string | null
  templatebudgetingScenarioId?: BudgetingScenario['id']
  forecastType?: ForecastType
  subsidiaries?: BudgetingScenario[]
  /** Näillä kopioidaan */
  transfers?: Pick<BudgetingScenarioTransfer, 'sourceId' | 'entity'>[]
  /** Loki kopioiduista */
  transferLog?: BudgetingScenarioTransfer[]
}

export interface BudgetingScenarioTransfer {
  id: number
  companyId: string
  budgetingScenarioId: BudgetingScenario['id']
  timestamp: string
  /** Skenaario, jolta kopiointi on tehty */
  sourceId?: BudgetingScenario['id']
  /** Mitä dataa on kopioitu */
  entity: BudgetingScenarioEntity
}

export enum BudgetingScenarioEntity {
  /** Budjetointiasetukset */
  settings = 'settings',
  /** Tulos (tilikohtaiset budjetointitavat etc.) */
  budget = 'budget',
  /** Olemassa olevat lainat */
  existingLoans = 'existingLoans',
  /** Olemassa oleva käyttöomaisuus (Professional) */
  existingInvestments = 'existingInvestments',
  /** Poistosuunnitelmat (Basic) */
  deprecationPlans = 'deprecationPlans',
  /** Investoinnit ja suunnitellut rahoituksen lähteet */
  newInvestments = 'newInvestments',
  /** Lisäykset / vähennykset */
  amendments = 'amendments'
}

export type CreateScenarioMutation = UseMutationResult<
  BudgetingScenario,
  AxiosError<CustomErrorResponseData, any>,
  BudgetingScenario,
  unknown
>

export type UpdateScenarioMutation = UseMutationResult<
  BudgetingScenario,
  AxiosError<CustomErrorResponseData, any>,
  BudgetingScenario,
  unknown
>

export type DeleteScenarioMutation = UseMutationResult<
  BudgetingScenario,
  AxiosError<CustomErrorResponseData, any>,
  BudgetingScenario['id'],
  unknown
>

export type LockScenarioMutation = UseMutationResult<
  BudgetingScenario,
  AxiosError<CustomErrorResponseData, any>,
  BudgetingScenario['id'],
  unknown
>

export type ScenarioQuery = UseQueryResult<BudgetingScenario[], AxiosError<CustomErrorResponseData, any>>

export type UseScenariosProps<TData> = {
  companyId?: string
  select?: (data: BudgetingScenario[]) => TData
  dataType?: ReportDataType.forecast | ReportDataType.budget | 'all'
}
