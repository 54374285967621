import { ReportDataType } from '../../../redux/context/reports/types'

export enum GroupSettingDataSource {
  group = 'group',
  subsidiaries = 'subsidiaries'
}

export interface GroupSettingData {
  /** Konsernin luvut (toteuma) */
  [ReportDataType.actuals]: {
    source: GroupSettingDataSource
  }
  /** Konsernin luvut (budjetti) */
  [ReportDataType.budget]: {
    source: GroupSettingDataSource
  }
  /** Osakkuusyritysten laskennan tili tuloslaskelmassa */
  plAssociateProfitAccountCode?: number
  /** Vähemmistöosuuksien laskennan tili tuloslaskelmassa */
  plMinoritySharesAccountCode?: number
  /** Vähemmistöosuuksien laskennan tili taseessa */
  bsMinoritySharesAccountCode?: number
  /** Osakkuusyritysten laskennan tili taseessa */
  bsAssociateSharesAccountCode?: number
}
