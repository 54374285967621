import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { ExchangeRateSeriesRequestBody } from '../types/exchangeRate'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { updateExchangeRate } from '../service/exchangeRateService'
import { useExchangeRatesOptions } from './useExchangeRateOptios'

export default function useUpdateExchangeRate() {
  const groupId = useSelector(contextCompanyIdSelector)!
  const queryClient = useQueryClient()
  const options = useExchangeRatesOptions()

  return useMutation({
    mutationKey: ['exchangeRate'],
    mutationFn: ({ data, id }: { data: ExchangeRateSeriesRequestBody; id: number }) =>
      updateExchangeRate(groupId, id, data),
    retry: 2,
    onSuccess: updatedItem => {
      queryClient.setQueryData(options.queryKey, oldItems => {
        return oldItems?.map(item => (item.id === updatedItem.id ? updatedItem : item))
      })
    }
  })
}
