import { queryOptions } from '@tanstack/react-query'
import customReportQueryKeys from '../customReportQueryKeys'
import { fetchCustomReportGroups } from '../../service/customReportGroupService'

export const useCustomReportGroupOptions = (companyId: string) => {
  return queryOptions({
    queryKey: customReportQueryKeys.groups({ companyId }),
    queryFn: fetchCustomReportGroups,
    enabled: !!companyId,
    select: data => data.map(group => ({ ...group, group: true }))
  })
}
