import dayjs from 'dayjs'
import {
  BudgetingScenario,
  BudgetingScenarioEntity,
  BudgetingScenarioTransfer
} from '../../../../../features/scenario/types/BudgetingScenario'

export const notLocked = (budgetingScenario?: BudgetingScenario) => {
  if (budgetingScenario && budgetingScenario.isLocked === true) {
    return false
  }
  return true
}

export const formatFormValues = (
  scenario: BudgetingScenario,
  transfersData?: BudgetingScenarioTransfer[]
): BudgetingScenario => {
  const subsidiariesArr: BudgetingScenario[] = []
  Object.values(scenario?.subsidiaries || {}).forEach(s => {
    if (s) {
      subsidiariesArr.push({
        id: s
      } as unknown as BudgetingScenario)
    }
  })
  return {
    ...scenario,
    forecastCursor: scenario.forecastCursor && dayjs(scenario.forecastCursor).date(1).format('YYYY-MM-DD'),
    subsidiaries: subsidiariesArr,
    transfers: transfersData?.filter(obj => obj.entity !== undefined || obj.sourceId !== undefined)
  }
}

/**
 * Maps entities to their latest transfers from a transfer log.
 * @param entities - Array of BudgetingScenarioEntity objects
 * @param transferLog - Optional array of transfer log items
 * @returns Array of objects with entity and latest sourceId
 */
export const getEntityTransfers = (
  entities: BudgetingScenarioEntity[],
  transferLog: BudgetingScenario['transferLog'] = []
): BudgetingScenario['transfers'] => {
  return entities.map(entity => {
    const transfersForEntity = transferLog?.filter(item => item.entity === entity) || []

    const latestTransfer = transfersForEntity?.reduce((latest, current) => {
      const currentTimeStamp = dayjs(current.timestamp).isValid() ? +dayjs(current.timestamp) : 0
      const latestTimeStamp = dayjs(latest.timestamp).isValid() ? +dayjs(latest.timestamp) : 0

      if (!dayjs(current.timestamp).isValid()) {
        return latest
      }

      return currentTimeStamp >= latestTimeStamp ? current : latest
    }, transfersForEntity[0]) // Use first item as initial value

    return {
      entity,
      sourceId: latestTransfer?.sourceId
    }
  })
}
