import { Result, Button } from 'antd'
import { AxiosError } from 'axios'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface RequestErrorProps {
  error: AxiosError | string
  refetch?: () => void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RequestError = ({ error, refetch }: RequestErrorProps) => {
  const { t } = useTranslation()
  return (
    <Result
      status="error"
      title={t('query:error:message:default')}
      subTitle={t('query:error:description:default')}
      extra={[
        <Button type="default" key="retry" onClick={() => refetch?.()}>
          {t('global:retry')}
        </Button>
      ]}
    />
  )
}

export default RequestError
