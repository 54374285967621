import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReportDataType } from '../../../../redux/context/reports/types'

interface ScenarioPlaceholderProps {
  dataType?: ReportDataType.budget | ReportDataType.forecast
}

const ScenarioPlaceholder: React.FC<ScenarioPlaceholderProps> = ({ dataType }) => {
  const { t } = useTranslation()
  const getPlaceholder = () => {
    switch (dataType) {
      case ReportDataType.budget:
        return t('scenarioSelect:budgetingScenarioPlaceholder')
      case ReportDataType.forecast:
        return t('scenarioSelect:forecastPlaceholder')
      default:
        return t('scenarioSelect:scenarioPlaceholder')
    }
  }
  return <span>{getPlaceholder()}</span>
}

export default ScenarioPlaceholder
