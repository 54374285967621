import { InvestmentFundingSource } from '../../types/investment/InvestmentFundingSource'
import { KeyFigure } from '../../types/keyFigure/KeyFigure'
import { StatementRow } from '../../types/statementRow/StatementRow'
import { Industry } from '../../types/industry/Industry'
import { Permission } from '../../types/user/Permission'
import { ContractProduct } from '../../types/contract/Contract'
import { LoanRepaymentMethod } from '../../features/entities/types/LoanRepaymentMethod'

export const SET_ENTITIES = 'SET_ENTITIES'

export interface Entities {
  contractProducts: Record<string, ContractProduct>
  investmentFundingSources: InvestmentFundingSource[]
  keyFigures: KeyFigure[]
  loanRepaymentMethods: LoanRepaymentMethod[]
  statementRows: StatementRow[]
  industries: Industry[]
  permissions: Permission[]
}

export interface SetEntitiesAction {
  type: typeof SET_ENTITIES
  payload: Entities
}
