import { useMutation, useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { deleteFiscalYear } from '../../service/fiscalYearService'
import fiscalYearQueryKeys from '../fiscalYearQueryKeys'

export const useCtxDeleteFiscalYear = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { notification } = App.useApp()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['deleteFiscalYear'],
    mutationFn: (id: number) => {
      if (!companyId) {
        throw new Error('Company ID is required')
      }
      if (!id) {
        throw new Error('Fiscal Year ID is required')
      }
      return deleteFiscalYear(companyId, id)
    },
    onSuccess: () => {
      notification.success({
        message: t('messages:deleteSuccess', 'Request sent')
      })
      return queryClient.invalidateQueries({
        queryKey: fiscalYearQueryKeys.list({ companyId })
      })
    },
    onError: error => {
      notification.error({
        message: t('messages:deleteError', 'Failed to delete fiscal year'),
        description: error.message
      })
    }
  })
}
