import React, { useEffect, useMemo, useState } from 'react'
import { Form, DatePicker, Space, Card, Button } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { useNavigate, useLocation } from 'react-router-dom'
import { PageHeader } from '@ant-design/pro-components'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useForm } from 'antd/es/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import ExchangeRateChart from './ExchangeRateChart'
import { enumerateDaysBetweenDates } from '../../../utils/periodGroups/utils'
import ExchangeRateSheet from './ExchangeRateSheet'
import {
  CustomExchangeRateSeries,
  CustomExchangeRateSeriesType,
  CustomExchangeRateSeriesWithCompany,
  SheetRow,
  StatementType
} from '../types/exchangeRate'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import { parseRates } from '../utils'
import ExchangeAverageRates from './ExchangeAverageRates'
import ExchangeRateDownloadButton from './ExchangeRateDownloadButton'
import { DateRangeValue } from '../../../types/date/types'
import useCreateExchangeRate from '../queries/useCreateExchangeRate'
import useUpdateExchangeRate from '../queries/useUpdateExchangeRate'
import { useExchangeRates } from '..'

const { RangePicker } = DatePicker

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ExchangeRateEdit = () => {
  const { t } = useTranslation()

  const { state: { company } = {} } = useLocation() as {
    state: CustomExchangeRateSeriesWithCompany
  }

  const navigate = useNavigate()
  const [form] = useForm()
  const [sheetData, setSheetData] = useState<SheetRow[]>([])
  const [dateRange, setDateRange] = useState<DateRangeValue<Dayjs>>([dayjs().subtract(1, 'year'), dayjs()])
  const dates = useMemo(() => enumerateDaysBetweenDates(dateRange?.[0] || null, dateRange?.[1] || null), [dateRange])

  const { data: exchangeRates, isLoading: exchangeRatesLoading } = useExchangeRates()
  const { mutate: updateExchangeRate, isPending: exchangeRateUpdatePending } = useUpdateExchangeRate()
  const { mutate: createExchangeRate, isPending: exchangeRateCreatePending } = useCreateExchangeRate()

  const companyData = useMemo(() => exchangeRates?.find(r => r.companyId === company?.id), [exchangeRates])

  useEffect(() => {
    const grouped = _.groupBy(companyData?.rates, 'statementType')
    const newData = Object.values(StatementType).map(name => {
      const reportData = grouped[name] || []
      const dataObject = reportData.reduce((acc, record) => {
        const { month = 0, year } = record
        const dateString = dayjs({ month: month - 1, year }).format('MM/YYYY')

        return {
          ...acc,
          [dateString]: record.value.toString()
        }
      }, {})
      return {
        report: name,
        ...dataObject
      }
    })
    setSheetData(newData)
  }, [company])

  const handleSave = async (data: CustomExchangeRateSeries) => {
    if (data.id) {
      updateExchangeRate({ data, id: data.id })
    } else {
      createExchangeRate(data)
    }
  }

  const onFinish = (values: any) => {
    const newData = parseRates(sheetData, companyData, values)
    if (newData === null) return
    handleSave(newData)
  }

  if (!company) return null

  return (
    <LoadingWrapper loading={exchangeRatesLoading || exchangeRateUpdatePending || exchangeRateCreatePending}>
      <StyledContainer>
        <PageHeader
          title={`${company.name}`}
          subTitle={`${companyData?.currency}/${companyData?.baseCurrency}`}
          onBack={() => navigate(-1)}
          extra={
            <Form form={form} initialValues={companyData} onFinish={onFinish} layout="inline">
              <FormItem label={t('global:time-period')}>
                <RangePicker value={dateRange} picker="month" onChange={range => setDateRange(range)} />
              </FormItem>
              <Space.Compact>
                <ExchangeRateDownloadButton companyData={companyData} />
                <Button
                  disabled={companyData?.type === CustomExchangeRateSeriesType.automatic}
                  htmlType="submit"
                  type="primary"
                >
                  {t('global:save')}
                </Button>
              </Space.Compact>
            </Form>
          }
        />
        <ExchangeRateSheet
          companyData={companyData}
          editable={companyData?.type === CustomExchangeRateSeriesType.manual}
          data={sheetData}
          columns={dates}
          onChange={setSheetData}
        />
        <Card style={{ backgroundColor: 'rgba(175, 175, 175, 0.04)' }}>
          <ExchangeRateChart data={sheetData} periods={dates} />
        </Card>
        <ExchangeAverageRates data={sheetData} dates={dates} />
      </StyledContainer>
    </LoadingWrapper>
  )
}

export default ExchangeRateEdit
