import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Space } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PlusOutlined, SettingOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import { useBackend } from '../../../services/backend'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import MultiPurposeDashboardFormulaCharts from './components/MultiPurposeDashboardFormulaCharts'
import MultiPurposeParamsForm from '../../../features/dashboards/components/MultiDashboardForm'
import MultiPurposeDashboardProvider from './MultiPurposeDashboardProvider'
import MultiPurposeDashboardDefaultSections from './MultiPurposeDashboardDefaultSections'
import { contextCompanySelector } from '../../../redux/context/company/selectors'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { getDashboardData } from './utils'
import { currentUserSortedCompaniesSelector } from '../../../redux/session/currentUser/selectors'
import { dimensionTreeSelector } from '../../../redux/context/dimensions/selectors'
import { multiPurposeDashboardPageSelector } from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import {
  changeMultiPurposeDashboardActiveDashboard,
  setAllMultiPurposeDashboardKeyfigureStatement
} from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import { Report, ReportDataType, ReportRow } from '../../../redux/context/reports/types'
import { Dimension, DimensionQueryOperator } from '../../../types/dimension/Dimension'
import { BudgetingScenario } from '../../../features/scenario/types/BudgetingScenario'
import MultiPurposeDashboardTable from './components/MultiPurposeDashboardTable'
import MultiPurposeDashboardKeyFigureCharts from './components/MultiPurposeDashboardKeyFigureCharts'
import MultiDashboardModal from '../../../features/dashboards/components/MultiDashboardModal'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { getCompanysKeyFigureIds } from '../../../utils/keyFigures'
import MultiToolbar from '../multiCompanyDashboard/MultiCompanyToolbar'
import NoDashboards from './components/NoDashboards'
import { AppDispatch } from '../../../redux/store'
import ReportSelect from '../../../components/Misc/ReportSelect'
import { MultiDashboard } from '../../../features/dashboards'
import { DashboardPageType } from '../types'

interface MultiPurposeDashboardProps {
  selectedKeyfigureIds: number[]
  selectedInternalKeyfigureIds: number[]
  selectedRows?: string[] // tää voi olla scenarios tai dimensions riippuu sivusta
  allRows: Dimension[] | BudgetingScenario[] // Näistä katsotaan pelkästään nimi?
  settingsUrl: string
  pageTitle: string
  dashboards?: MultiDashboard[]
}

const MultiPurposeDashboard: React.FC<MultiPurposeDashboardProps> = ({
  selectedKeyfigureIds,
  selectedInternalKeyfigureIds,
  selectedRows,
  allRows,
  settingsUrl,
  pageTitle,
  dashboards
}) => {
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const { activeDashboard, keyfigureStatements } = useSelector(multiPurposeDashboardPageSelector)
  const currentPage = location.pathname.split('/').pop() as DashboardPageType

  const [modalVisible, setModalVisible] = useState(false)
  const statementsRequest = useBackend(`/api/companies/{contextCompany}/reporting/custom`)
  const { budgetingScenario, periodGroups } = useSelector(filtersSelector)
  const contextCompany = useSelector(contextCompanySelector)
  const prevPrediodGroups = useRef<PeriodGroup[]>()
  const myCompanies = useSelector(currentUserSortedCompaniesSelector)
  const dimensionTree = useSelector(dimensionTreeSelector)
  const multiPurposeDashboard = useSelector(multiPurposeDashboardPageSelector)
  const [showCharts, setShowCharts] = useState(false)
  const [loading, setLoading] = useState(false)
  const company = useSelector(contextCompanySelector)

  const activeDashboardData = useMemo(
    () => dashboards?.find(d => d.id === activeDashboard) || ({} as MultiDashboard),
    [activeDashboard, dashboards]
  )

  useEffect(() => {
    return () => {
      dispatch(changeMultiPurposeDashboardActiveDashboard(undefined))
    }
  }, [])

  const onChange = (id: number) => {
    dispatch(changeMultiPurposeDashboardActiveDashboard(id))
  }

  const contentEl = useRef(null)

  const getKeyFigureStatementData = async (dimensionIds?: string[], budgetingScenarioId?: BudgetingScenario['id']) => {
    if (contextCompany && (dimensionIds || budgetingScenarioId)) {
      const data: ReportRow[] = statementsRequest.get({
        urlParams: { contextCompany: contextCompany.id },
        body: {
          params: {
            keyFigureIds: getCompanysKeyFigureIds(company, selectedKeyfigureIds),
            formulaIds: selectedInternalKeyfigureIds,
            budgetingScenarioId: currentPage !== DashboardPageType.companies && budgetingScenarioId,
            dimensions: JSON.stringify(
              dimensionIds?.map(id => ({
                k: id,
                o: DimensionQueryOperator.has,
                v: id
              }))
            ),
            periodGroups,
            dataTypes: [ReportDataType.actuals, ReportDataType.budget]
          }
        }
      })
      return data
    }
    return null
  }

  // TODO: Selvitä getKeyFigureStatementData interfacet
  const getData = async () => {
    if (selectedRows && currentPage === DashboardPageType.default) {
      prevPrediodGroups.current = periodGroups
      setLoading(true)

      // dimensiot saa yhdellä requestilla
      const promises = await getKeyFigureStatementData(selectedRows, budgetingScenario?.id)
        .then((res: any) => {
          return res
        })
        .catch(console.log)

      const keyfigureStatementDatas = await Promise.all(promises)

      if (keyfigureStatementDatas.length > 0) {
        dispatch(
          setAllMultiPurposeDashboardKeyfigureStatement(
            keyfigureStatementDatas as {
              data: Report
              dimensionKey?: string
              budgetingScenarioId?: BudgetingScenario['id']
            }[]
          )
        )
      }

      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [periodGroups, selectedKeyfigureIds, selectedInternalKeyfigureIds, selectedRows])

  const content = () => {
    switch (currentPage) {
      case DashboardPageType.compact:
        return (
          <>
            <MultiPurposeDashboardTable
              tableData={getDashboardData(
                {
                  ...activeDashboardData,
                  companies: [company?.id]
                } as MultiDashboard,
                multiPurposeDashboard.keyfigureStatements,
                multiPurposeDashboard.dimensionKeyfigureStatements,
                multiPurposeDashboard.dimensions,
                myCompanies,
                periodGroups,
                dimensionTree,
                DashboardPageType.compact
              )}
            />
            {showCharts && (
              <>
                <MultiPurposeDashboardKeyFigureCharts />
                <MultiPurposeDashboardFormulaCharts />
              </>
            )}
          </>
        )
      case DashboardPageType.default:
        return (
          <MultiPurposeDashboardDefaultSections
            showCharts={showCharts}
            keyfigureStatements={keyfigureStatements}
            selectedKeyfigureIds={selectedKeyfigureIds}
            selectedInternalKeyfigureIds={selectedInternalKeyfigureIds}
            allRows={allRows}
            selectedRows={selectedRows}
            dashboardType="dimensions"
          />
        )

      default:
        return undefined
    }
  }

  return (
    <MultiPurposeDashboardProvider dashboard={activeDashboardData} multiPurposeDashboardPage={currentPage}>
      <PageHeaderWrapper
        title={pageTitle}
        subTitle={
          <DocumentationLink
            route={{
              path:
                currentPage === DashboardPageType.compact || currentPage === DashboardPageType.default
                  ? '/dashboard/dimensions'
                  : undefined
            }}
          />
        }
        ghost={false}
        extra={[
          <ReportSelect activeDashboard={activeDashboard} dashboards={dashboards} onChange={onChange} />,
          <Space>
            <Link to={settingsUrl}>
              <Button icon={<SettingOutlined />} onClick={() => setModalVisible(true)}>
                {t('global:settings')}
              </Button>
            </Link>
            <Button icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
              {t('dashboardPage:new-dashboard')}
            </Button>
          </Space>
        ]}
        content={activeDashboard && <MultiPurposeParamsForm setLoading={setLoading} />}
      >
        <LoadingWrapper loading={loading}>
          <MultiDashboardModal multiPurposePage={currentPage} visible={modalVisible} setVisible={setModalVisible} />
          {dashboards?.length ? (
            <Card>
              <MultiToolbar
                toggleCharts={val => setShowCharts(val)}
                element={contentEl}
                exportName={activeDashboardData?.name}
              />
              <div ref={contentEl}>{content()}</div>
            </Card>
          ) : (
            <NoDashboards onClick={() => setModalVisible(true)} />
          )}
        </LoadingWrapper>
      </PageHeaderWrapper>
    </MultiPurposeDashboardProvider>
  )
}

export default MultiPurposeDashboard
