import { useState, useEffect, Dispatch, useMemo, SetStateAction } from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { ColumnsType } from 'antd/es/table'
import { ResizeCallbackData } from 'react-resizable'
import { useWindowWidth } from '@react-hook/window-size'
import { useTranslation } from 'react-i18next'
import { ReportDataType, Report, ReportRow, ReportRowType, ReportType } from '../../redux/context/reports/types'
import { ReportTableRow } from './types'
import {
  generateCumulativeColumns,
  generateDataIndex,
  generateSpecialCumulativeColumns,
  getBalanceColumns,
  getGroupPeriods,
  getTitle
} from './utils'
import { columnPeriodsSelector } from '../../redux/context/periodGroups/selectors'
import { PeriodGroup } from '../../types/periodGroup/PeriodGroup'
import { filtersSelector } from '../../redux/context/filters/selectors'
import { getRowData } from '../../utils/csvExport'
import { Column } from '../../redux/context/periodGroups/hooks'
import { formatValueToNumberByType } from '../../utils/helpers'
import { revenueRowSelector } from '../../redux/config/financialStatement/selectors'
import { useCtxFiscalYears } from '../../features/fiscalYear/queries/report/useCtxFiscalYears'
import { lastClosedFiscalYearsSelector } from '../../features/fiscalYear/selectors/selectors'
import { useCtxCurrentFiscalYear } from '../../features/fiscalYear/queries/report/useCtxCurrentFiscalYear'
import { useCtxReportablePeriodGroups } from '../../features/fiscalYear/hooks/useCtxReportablePeriodGroups'

export enum ActualRowType {
  statementRow = 'statementRow',
  account = 'account',
  dimension = 'dimension'
}

/** Muuttaa raportin taululle sopivaan muotoon */
export const useReportTableData = (source: Report, columns?: Column[]) => {
  const {
    i18n: { language }
  } = useTranslation()
  const groups = useCtxReportablePeriodGroups()
  const periods = useSelector(columnPeriodsSelector)
  const { displayEmptyRows } = useSelector(filtersSelector)
  const { dataTypes, periodGroup, periodGroups } = useSelector(filtersSelector)
  const revenueRow = useSelector(revenueRowSelector) as ReportRow
  const [tableData, setTableData] = useState<ReportTableRow[]>([])
  const [revenueTableRow, setRevenueTableRow] = useState<any>()

  const { data: currentFiscalYear } = useCtxCurrentFiscalYear()
  const { data: lastClosedFiscalYear } = useCtxFiscalYears(lastClosedFiscalYearsSelector)

  const transform = (report: Report) => {
    const reportRowToReportTableRow = (row: ReportRow): ReportTableRow => {
      const { actuals, forecast, budget, accountCode, name, code, type, ...rest } = row
      return {
        ...rest,
        actuals,
        forecast,
        budget,
        name,
        accountCode: accountCode || (typeof code === 'number' ? code : undefined),
        title: getTitle(row),
        code,
        variableType: type
      }
    }

    // luodaan "soludata" tyyppiä: type-id-vuosi-kk
    const transformRow = (reportRow: ReportRow) => {
      const row = reportRowToReportTableRow(reportRow)

      Object.assign(row, getBalanceColumns(reportRow))
      Object.assign(row, { revenueRow: revenueTableRow })

      // tulokseen ja euromääräseen kannattavuuteen kumulatiivit vain
      // aggregationtype sum niin tehdään cumulatiivit
      if (reportRow.aggregationType === 'sum') {
        Object.assign(row, generateCumulativeColumns(groups, reportRow))
        Object.assign(row, generateSpecialCumulativeColumns(groups, row, periods))
      }

      if (reportRow.children) {
        row.children = transform(reportRow.children)
      }

      return row
    }

    const transformChildrenRow = (reportRow: ReportRow) => {
      const row: ReportTableRow = reportRowToReportTableRow(reportRow)
      const rowData = reportRow[reportRow.rowType]
      if (rowData) {
        const { year } = rowData
        const { month } = rowData
        const inGroups = (groups as PeriodGroup[])?.filter(g => {
          return (
            dayjs(g.startDate) <= dayjs(`${year}-${month}-15`).endOf('month') &&
            dayjs(g.endDate) >= dayjs(`${year}-${month}-15`).startOf('month')
          )
        })

        row[`${ReportDataType.budget}-${year}-${month}`] = rowData.value
        row[`${ReportDataType.forecast}-${year}-${month}`] = rowData.value
        for (const group of inGroups) {
          row[`${ReportDataType.budget}-${group.id}`] = rowData.value
          row[`${ReportDataType.forecast}-${group.id}`] = rowData.value
        }
      }

      return row
    }

    const transformBudgetMenuRow = (reportRow: ReportRow) => {
      const row: ReportTableRow = reportRowToReportTableRow(reportRow)
      // const [dataType] = dataTypes.filter(dt => dt === ReportDataType.budget || dt === ReportDataType.forecast)
      row.rowType = ReportRowType.budgetMenu
      for (const dataType of dataTypes) {
        for (const group of groups) {
          const groupBalance =
            (dataType === ReportDataType.actuals ? reportRow.actuals || [] : reportRow.budgets || []).find(
              b => b.groupId === group.id
            )?.value || 0
          for (const period of getGroupPeriods(group)) {
            const dataIndex = generateDataIndex(dataType, undefined, period.year, period.month)
            const dataValue = (
              dataType === ReportDataType.actuals ? reportRow.actuals || [] : reportRow.budgets || []
            ).find(b => b.year === period.year && b.month === period.month)
            row[dataIndex] = dataValue ? dataValue.value : 0
          }
          const dataIndex = generateDataIndex(dataType, group.id)
          row[dataIndex] = groupBalance
        }
      }
      return row
    }

    const handleRowTransform = (row: ReportRow) => {
      let f: (reportRow: ReportRow) => ReportTableRow
      switch (row.rowType) {
        case ReportRowType.budgetMenu:
          f = transformBudgetMenuRow
          break
        case ReportRowType.investment:
        case ReportRowType.investmentFunding:
        case ReportRowType.amendment:
        case ReportRowType.amendmentCounterPart:
          f = transformChildrenRow
          break
        default:
          f = transformRow
          break
      }
      return f(row)
    }

    if (!columns) return []

    return report
      ?.map(reportRow => handleRowTransform(reportRow))
      ?.filter(reportRow => {
        // jos rivillä erroreita niin näytetään rivit
        if (
          reportRow.actuals?.find(a => a.errors && a.errors.length > 0) ||
          reportRow.budget?.find(b => b.errors && b.errors.length > 0)
        ) {
          return true
        }

        // jos dimensiolla ei ole childreneitä niin ei näytetä namiskoja, jolla saa aladimensiot näkyviin
        if (reportRow.isLeafDimension) {
          return false
        }
        if (displayEmptyRows) return true
        if (reportRow.key?.includes('menu')) return true

        if (reportRow.accounts) {
          for (const c of reportRow.accounts) {
            if (c.key?.includes('menu')) {
              if (c.children?.length > 0) {
                return true
              }
            }
          }
        }

        const rowValues = getRowData(reportRow, columns)
        if (rowValues.some(val => val !== 0)) {
          return true
        }

        // tulokseen ja taseeseen rivejä näkyviin jos menee sellasille kausille, jossa ei ole actuaaleja
        // etsitään lukuja menossa olevasta tilikaudesta ja sitä edellisestä, ja jos löytyy niin rivi näytetään
        if (
          (source?.[0].report === ReportType.incomeStatement || source?.[0].report === ReportType.balanceSheet) &&
          !displayEmptyRows &&
          periodGroup &&
          !periodGroups
        ) {
          const rowValuesArr = getRowData(reportRow, columns)
          if (rowValuesArr.some(val => val === 0)) {
            const currentFiscalYearDataindex = generateDataIndex(ReportDataType.actuals, currentFiscalYear?.id)
            const currentFiscalYearValue =
              formatValueToNumberByType(reportRow[currentFiscalYearDataindex], reportRow.variableType) || 0
            if (currentFiscalYearValue === 0) {
              const lastClosedFiscalYearDataindex = generateDataIndex(ReportDataType.actuals, lastClosedFiscalYear?.id)
              const lastClosedFiscalYearValue =
                formatValueToNumberByType(reportRow[lastClosedFiscalYearDataindex], reportRow.variableType) || 0
              if (lastClosedFiscalYearValue !== 0) {
                return true
              }
              return false
            }
            return true
          }
          return true
        }

        return false
      })
  }

  useEffect(() => {
    setTableData(transform(source))

    if (revenueRow) {
      const revenue = {
        ...getBalanceColumns(revenueRow),
        ...generateCumulativeColumns(groups, revenueRow),
        ...generateSpecialCumulativeColumns(groups, revenueRow, periods)
      } as ReportTableRow

      Object.assign(revenue, generateSpecialCumulativeColumns(groups, revenue, periods))
      setRevenueTableRow(revenue)
    }
  }, [source, columns, revenueRow, language])
  return tableData
}

export const useFixedNameRow = (fixedBreakPoint = 800) => {
  const width = useWindowWidth({ wait: 500, initialWidth: 0 })
  const [fixedNameRow, setFixedNameRow] = useState<'left' | undefined>()

  const setFixedNameRowHandler = () => {
    if (width) {
      const f = width >= fixedBreakPoint ? 'left' : undefined
      setFixedNameRow(f)
    }
  }

  useEffect(setFixedNameRowHandler, [width])
  return fixedNameRow
}

export const useResizeableColumns = <T extends {}>(
  columnData: ColumnsType<T>
): [columns: ColumnsType<T>, setColumns: Dispatch<SetStateAction<any>>] => {
  const [columns, setColumns] = useState<ColumnsType<T>>(columnData)

  const handleResize: Function =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...columns]
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      }
      setColumns(newColumns)
    }

  const mergeColumns: ColumnsType<T> = useMemo(
    () =>
      columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column: ColumnsType<T>[number]) => ({
          width: column.width,
          onResize: handleResize(index) as React.ReactEventHandler<T>
        })
      })),
    [columns]
  )

  return [mergeColumns, setColumns]
}
