/* eslint-disable no-debugger */
/* eslint-disable import/no-cycle */
import dayjs from 'dayjs'
import { CustomReportSortOrder } from '../../../pages/reporting/custom/components/SortButton'
import {
  ADD_CUSTOM_REPORT_SECTION,
  ADD_SECTION_VARIABLE_EXPLAINER,
  ADD_SECTION_VARIABLE,
  CustomReportsActionTypes,
  CustomReportStore,
  DELETE_CUSTOM_REPORT_SECTION,
  EDIT_CUSTOM_REPORT_SECTION_STYLE,
  EDIT_CUSTOM_REPORT_SECTION,
  ORDER_COLUMN_ASCENDING,
  ORDER_COLUMN_DEFAULT,
  ORDER_COLUMN_DESCENDING,
  RESET_CUSTOM_REPORT_ORDER,
  SET_CALCULATED_REPORT,
  SET_DATE,
  SET_SECTION_DATA,
  UPDATE_CUSTOM_REPORT,
  SET_QUICK_FILTERS,
  EMPTY_QUICK_FILTERS,
  UPDATE_SECTIONS
} from './types'
import { CustomReportCategory } from './typesCategory'
import { CustomReportTableSection, CustomReportChartSection } from './typesSection'
import { CustomReportVariable, VariableRow } from './typesVariable'
import { SET_CONTEXT_COMPANY } from '../company/types'

const initialState: CustomReportStore = {
  date: dayjs().endOf('month').toString(),
  calculated: null,
  sort: {},
  quickFilters: undefined
}

const recursiveDefaultUpdate = (variable: CustomReportVariable, row: VariableRow) => {
  const recurse = (v: CustomReportVariable): CustomReportVariable => {
    if (v.id === row.id) {
      return {
        ...v,
        children: v?.children?.sort((a, b) => {
          return a.id?.toString().localeCompare(b.id?.toString() ?? '') ?? 0
        })
      }
    }
    return {
      ...v,
      children: v?.children?.map(c => {
        if (row.id?.toString().startsWith(c.id?.toString() ?? '')) {
          return recurse(c)
        }
        return c
      })
    }
  }

  return recurse(variable)
}

const recursiveAscendingUpdate = (variable: CustomReportVariable, row: VariableRow, column: CustomReportCategory) => {
  const recurse = (v: CustomReportVariable): CustomReportVariable => {
    if (v.id === row.id) {
      return {
        ...v,
        children: v?.children?.sort((a, b) => {
          const aValue = a?.balances?.find(({ groupId }) => groupId === column.id)?.value || -Infinity
          const bValue = b?.balances?.find(({ groupId }) => groupId === column.id)?.value || -Infinity
          return aValue - bValue
        })
      }
    }
    return {
      ...v,
      children: v?.children?.map(c => {
        if (row.id?.toString().startsWith(c.id?.toString() ?? '')) {
          return recurse(c)
        }
        return c
      })
    }
  }

  return recurse(variable)
}
const recursiveDescendingUpdate = (variable: CustomReportVariable, row: VariableRow, column: CustomReportCategory) => {
  if (!row.id) return variable
  const recurse = (v: CustomReportVariable): CustomReportVariable => {
    if (v.id === row.id) {
      return {
        ...v,
        children: v?.children?.sort((a, b) => {
          const aValue = a?.balances?.find(({ groupId }) => groupId === column.id)?.value || -Infinity
          const bValue = b?.balances?.find(({ groupId }) => groupId === column.id)?.value || -Infinity
          return bValue - aValue
        })
      }
    }
    return {
      ...v,
      children: v?.children?.map(c => {
        if (row?.id?.toString().startsWith(c?.id?.toString() ?? '')) {
          return recurse(c)
        }
        return c
      })
    }
  }

  return recurse(variable)
}

const customReportsReducer = (state = initialState, action: CustomReportsActionTypes) => {
  switch (action.type) {
    case SET_CONTEXT_COMPANY:
      // initialize state if company is changed
      return {
        ...initialState,
        date: state.date
      }
    case UPDATE_CUSTOM_REPORT:
      return {
        ...state,
        calculated: { ...state.calculated, ...action.payload }
      }
    case DELETE_CUSTOM_REPORT_SECTION:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections.filter(s => s.id !== action.payload.sectionId)
        }
      }
    case ADD_CUSTOM_REPORT_SECTION:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: [...(state.calculated?.sections || []), action.payload]
        }
      }
    case EDIT_CUSTOM_REPORT_SECTION:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections.map(section => {
            if (section.id === action.payload.id) return { ...section, ...action.payload }
            return section
          })
        }
      }
    case EDIT_CUSTOM_REPORT_SECTION_STYLE:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections.map(item =>
            item.id !== action.payload.id
              ? item
              : {
                  ...item,
                  style: {
                    ...(item as CustomReportTableSection | CustomReportChartSection).style,
                    type: action.payload?.style?.type || 'column',
                    switchRowsAndColumns: action.payload?.style?.switchRowsAndColumns || undefined,
                    showDataLabels: action.payload?.style?.showDataLabels || undefined
                  },
                  variables: (item as CustomReportTableSection | CustomReportChartSection).variables
                    ?.map(variable => {
                      const data = action.payload?.variables?.find(f => f.id === variable.id) || {}
                      return { ...variable, ...data }
                    })
                    .sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1)),
                  categories: action.payload?.categories,
                  title: action.payload?.title,
                  type: action.payload?.type
                }
          )
        }
      }
    case SET_CALCULATED_REPORT:
      return {
        ...state,
        calculated: action.payload
      }
    case SET_SECTION_DATA:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id === action.payload.id) return action.payload
            return section
          })
        }
      }
    case UPDATE_SECTIONS:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections.map(section => {
            const found = action.payload.find(s => s.id === section.id)
            return found || section
          })
        }
      }

    case ADD_SECTION_VARIABLE:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id === action.payload.variable[0].sectionId) {
              return {
                ...section,
                variables: [
                  ...((section as CustomReportTableSection | CustomReportChartSection)?.variables || []),
                  ...action.payload.variable
                ]
              }
            }
            return section
          })
        }
      }
    case ADD_SECTION_VARIABLE_EXPLAINER:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id === action.payload.variable.sectionId) {
              return {
                ...section,
                variables: (section as CustomReportTableSection | CustomReportChartSection).variables.map(variable => {
                  if (variable.id === action.payload.variable.id)
                    return { ...variable, children: action.payload.explainer }
                  return variable
                })
              }
            }
            return section
          })
        }
      }

    case ORDER_COLUMN_ASCENDING:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id.toString() === action.payload.sectionId.toString()) {
              return {
                ...section,
                variables: (section as CustomReportTableSection | CustomReportChartSection).variables.map(variable => {
                  return recursiveAscendingUpdate(variable, action.payload.row, action.payload.column)
                })
              }
            }
            return section
          })
        },
        sort: {
          ...state.sort,
          [action.payload.sectionId.toString()]: {
            ...state.sort?.[action.payload.sectionId.toString()],
            [action.payload.row.id!]: {
              columnId: action.payload.column.id,
              sortOrder: CustomReportSortOrder.ascending
            }
          }
        }
      }
    case ORDER_COLUMN_DESCENDING:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id.toString() === action.payload.sectionId.toString()) {
              return {
                ...section,
                variables: (section as CustomReportTableSection | CustomReportChartSection).variables.map(variable => {
                  return recursiveDescendingUpdate(variable, action.payload.row, action.payload.column)
                })
              }
            }
            return section
          })
        },
        sort: {
          ...state.sort,
          [action.payload.sectionId.toString()]: {
            ...state.sort?.[action.payload.sectionId.toString()],
            [action.payload.row.id!]: {
              columnId: action.payload.column.id,
              sortOrder: CustomReportSortOrder.descending
            }
          }
        }
      }
    case ORDER_COLUMN_DEFAULT:
      return {
        ...state,
        calculated: {
          ...state.calculated,
          sections: state.calculated?.sections?.map(section => {
            if (section.id.toString() === action.payload.sectionId.toString()) {
              return {
                ...section,
                variables: (section as CustomReportTableSection | CustomReportChartSection).variables.map(variable => {
                  return recursiveDefaultUpdate(variable, action.payload.row)
                })
              }
            }
            return section
          })
        },
        sort: {
          ...state.sort,
          [action.payload.sectionId.toString()]: {
            ...state.sort?.[action.payload.sectionId.toString()],
            [action.payload.row.id!]: {
              columnId: action.payload.column.id,
              sortOrder: CustomReportSortOrder.default
            }
          }
        }
      }
    case RESET_CUSTOM_REPORT_ORDER:
      return {
        ...state,
        sort: {}
      }
    case SET_DATE:
      return {
        ...state,
        date: action.payload
      }
    case SET_QUICK_FILTERS:
      return {
        ...state,
        quickFilters: action.payload
      }
    case EMPTY_QUICK_FILTERS:
      return {
        ...state,
        quickFilters: undefined
      }
    default:
      return state
  }
}

export default customReportsReducer
