import { Form, Select } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { capitalize } from 'lodash'
import React from 'react'

const Month = () => {
  return (
    <Form.Item key="month" name={['params', 'month']} label={t('global:month')}>
      <Select
        allowClear
        options={dayjs.months().map((month, index) => ({
          label: capitalize(month),
          value: index + 1
        }))}
      />
    </Form.Item>
  )
}

export default Month
