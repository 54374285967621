import { useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useDeleteQueryItem = () => {
  const queryClient = useQueryClient()
  const { notification } = App.useApp()
  const { t } = useTranslation()

  const deleteQueryItem = useMemo(
    () => (deleteItem: { id: any }, options: any) => {
      queryClient.setQueryData(options.queryKey, (oldItems: { id: number }[] = []) => {
        return oldItems.filter(item => item.id !== deleteItem.id)
      })
      notification.success({ message: t('messages:deleteSuccess', 'Success') })
    },
    []
  )

  return deleteQueryItem
}

export default useDeleteQueryItem
