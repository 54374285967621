import { LOGOUT, LogoutAction, SetTokenAction, SET_TOKEN } from './types'

export const logoutAction = (): LogoutAction => ({
  type: LOGOUT
})

export const setToken = (payload: string): SetTokenAction => ({
  type: SET_TOKEN,
  payload
})
