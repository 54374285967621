import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useFiscalYearOptions } from '../queries/useFiscalYearOptions'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { reportableFiscalYearsSelector } from '../selectors/selectors'
import { contextContractSelector } from '../../../redux/context/contract/selectors'

export const useCtxReportableFiscalYears = () => {
  const contract = useSelector(contextContractSelector)
  const companyId = useSelector(contextCompanyIdSelector)!
  const options = useFiscalYearOptions(companyId, reportableFiscalYearsSelector(contract))
  return useQuery(options)
}
