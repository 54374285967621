import { useMutation } from '@tanstack/react-query'
import { App } from 'antd'
import { useTranslation } from 'react-i18next'
import { useContextParameters } from '../../../services/api/hooks/useContextParameters'
import { updateCompanyDashboardsOrder } from '../service/dashboardService'
import { CompanyDashboard } from '../../../types/dashboard/company/types'

export default function useUpdateCompanyDashboardsOrder() {
  const { companyId } = useContextParameters()
  const { notification } = App.useApp()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (order: Partial<CompanyDashboard>[]) => updateCompanyDashboardsOrder({ data: order, companyId }),
    mutationKey: ['dimensions'],
    retry: 2,
    onSuccess: () => {
      notification.success({ message: t('message:UPDATE_CUSTOM_REPORT_ORDER_SUCCESS') })
    }
  })
}
