import { QueryFunctionContext } from '@tanstack/react-query'
import { DELETE, GET, POST, PUT } from '../../../redux/middleware/types'
import backendRequest from '../../../services/api/backendRequest'
import customReportEndpoints from '../api/customReportEndpoints'
import { CustomReportGroup } from '../../../redux/context/customReports/types'
import customReportQueryKeys from '../queries/customReportQueryKeys'

export const fetchCustomReportGroups = ({
  queryKey: [{ companyId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof customReportQueryKeys)['groups']>>) => {
  return backendRequest<CustomReportGroup[]>({
    method: GET,
    url: customReportEndpoints.listGroup(companyId),
    signal
  })
}

export const createCustomReportGroup = (data: Partial<CustomReportGroup>, companyId: string) => {
  return backendRequest<CustomReportGroup>({
    method: POST,
    url: customReportEndpoints.listGroup(companyId),
    data
  })
}

export const updateCustomReportGroup = (id: number, data: Partial<CustomReportGroup>, companyId: string) => {
  return backendRequest<CustomReportGroup>({
    method: PUT,
    url: customReportEndpoints.detailGroup(companyId, id),
    data
  })
}

export const deleteCustomReportGroup = (companyId: string, id: number) => {
  return backendRequest<CustomReportGroup>({
    method: DELETE,
    url: customReportEndpoints.detailGroup(companyId, id)
  })
}
