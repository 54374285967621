import { t } from 'i18next'
import React, { useContext } from 'react'
import { Form, FormItemProps } from 'antd'
import DimensionSelect from '../../../../../../../../components/Dimension/DimensionSelect'
import { CategoryContext, CategoryContextType } from '../../../../categorySelection/CategoryContext'

const Dimension = (props: FormItemProps) => {
  const { categoryTree } = useContext(CategoryContext) as CategoryContextType

  return (
    <Form.Item key="dimension" name={['params', 'dimension', 'dimensionId']} label={t('global:dimension')} {...props}>
      <DimensionSelect companyId={categoryTree.companies[0]} />
    </Form.Item>
  )
}

export default Dimension
