import { SeriesPieOptions } from 'highcharts'
import i18next from 'i18next'
import { VariableType } from '../../../../../components/Table/types'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import { CustomReportPieSection } from '../../../../../redux/context/customReports/typesSection'
import {
  CustomReportVariable,
  CustomReportVariableExplainer,
  VariableRow
} from '../../../../../redux/context/customReports/typesVariable'
import { formatValueToNumberByType, formatValueByType } from '../../../../../utils/helpers'
import { getTitleByLanguage } from '../customReportTable/utils'
import { getRight, getCommonSizeValue } from '../utils'
import { DrillData } from './chartUtils'

export const variablesToSeries = (
  variables: CustomReportVariable[],
  referenceValue: number,
  category: CustomReportCategory,
  forceDrillDown?: boolean,
  onlyPercentage?: boolean
): Highcharts.PointOptionsObject[] => {
  return variables.map(variable => {
    const { balances } = variable
    const { value } = balances?.find(b => b.groupId === category.id) || {}
    const { decimals = 0, color } = variable.style || {}
    const variableValue = value ? value * referenceValue : 0
    const formattedValue = formatValueByType(variableValue, variable.type, {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals
    })

    return {
      name: getTitleByLanguage(variable as VariableRow) || '',
      drilldown: forceDrillDown || 'children' in variable ? variable.id?.toString() : undefined,
      y: variableValue ? Math.abs(variableValue) : null,
      negative: variableValue ? variableValue < 0 : false,
      color: forceDrillDown ? color : undefined,
      style: {
        fontWeight: variable.style?.fontWeight ? 'bold' : undefined,
        fontStyle: variable.style?.fontStyle
      },
      ...(!onlyPercentage && {
        dataLabels: {
          format: `{point.name}: ${formattedValue}`
        }
      })
    }
  })
}

export const getDataSerie = (variable: CustomReportVariable, categories: CustomReportCategory[]): (number | null)[] => {
  return categories.map(c => {
    const { balances } = variable
    const balance = balances?.find(b => b.groupId === c.id)
    const variableType = ['growth', 'commonSize'].includes(c.value as string) ? VariableType.percentage : variable.type

    return formatValueToNumberByType(balance?.value, variableType, variable?.style?.decimals)
  })
}

export const getLeafCategories = (categories: CustomReportCategory[]) => {
  const leafCategories: CustomReportCategory[] = []

  function recurse(data: CustomReportCategory[]) {
    if (!data) return
    data.forEach(category => {
      if (!category.children || category.type === 'function') leafCategories.push({ ...category })

      if (category.children && category.type !== 'function') {
        recurse(category.children)
      }
    })
  }

  recurse(categories)

  return leafCategories
}

export const getDrillData = (
  variableId: string,
  explainer: CustomReportVariableExplainer,
  categories: CustomReportCategory[]
): DrillData => {
  const leafs = getLeafCategories(categories)
  const category = leafs[0]
  const explanations: DrillData = {}
  const right = getRight(categories)
  const referenceValue = getCommonSizeValue(right?.id, explainer.commonSizeVariables)

  const traverse = (varId: string, variables: CustomReportVariable[], parentValue: number) => {
    if (!variables) return

    // Jos rivien määrä 1kpl silloin skipataan sarjan teko ja passataan edellinen id eteenpäin
    if (variables?.length === 1 && 'children' in variables[0]) {
      variables.forEach((row: CustomReportVariable) => {
        row.children && row.id && traverse(varId, row.children, referenceValue)
      })
    } else {
      const series = variablesToSeries(variables, parentValue, category)
      explanations[varId.toString()] = series
      variables.forEach((row: CustomReportVariable) => {
        row.children && row.id && traverse(row.id.toString(), row.children, referenceValue)
      })
    }
  }

  traverse(variableId, explainer.variables, referenceValue)

  return explanations
}

export const getPieSeries = ({
  title,
  categories,
  variables,
  commonSizeVariables
}: CustomReportPieSection): SeriesPieOptions => {
  const leafs = getLeafCategories(categories)
  const category = leafs[0]
  const right = getRight(categories)
  let onlyPercentage = false
  if (
    commonSizeVariables?.every((row: CustomReportVariable) => row.type === VariableType.percentage) &&
    variables?.every((row: CustomReportVariable) => row.type === VariableType.percentage)
  ) {
    onlyPercentage = true
  }
  const referenceValue = getCommonSizeValue(right?.id, commonSizeVariables)
  const data = variablesToSeries(variables, referenceValue, category, true, onlyPercentage)
  let othersValue = referenceValue - data.reduce((acc, cur) => acc + (cur?.y || 0), 0)
  if (!onlyPercentage) {
    othersValue = formatValueToNumberByType(othersValue) || 0
  }
  const others = {
    name: i18next.t('dashboardPage:others') ?? 'Others',
    y: Math.abs(othersValue),
    negative: othersValue ? othersValue < 0 : false
  }
  const pieSerie: SeriesPieOptions = {
    name: title,
    type: 'pie',
    data: commonSizeVariables && commonSizeVariables?.length > 0 ? [others, ...data] : [...data]
  }
  return pieSerie
}
