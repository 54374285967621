import backendRequest from '../../../services/api/backendRequest'
import { ContractProduct } from '../../../types/contract/Contract'
import { Industry } from '../../../types/industry/Industry'
import { InvestmentFundingSource } from '../../../types/investment/InvestmentFundingSource'
import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { StatementRow } from '../../../types/statementRow/StatementRow'
import { Permission } from '../../../types/user/Permission'
import entitiesEndpoints from '../api/entitiesEndpoints'
import { Entities } from '../../../redux/entities/types'
import { LoanRepaymentMethod } from '../types/LoanRepaymentMethod'

export const fetchEntities = async () => {
  return backendRequest<Entities>({
    method: 'GET',
    url: entitiesEndpoints.entities
  })
}

export const fetchContractProducts = async () => {
  return backendRequest<Record<string, ContractProduct>>({
    method: 'GET',
    url: entitiesEndpoints.contractProducts
  })
}

export const fetchIndustries = async () => {
  return backendRequest<Industry[]>({
    method: 'GET',
    url: entitiesEndpoints.industries
  })
}

export const fetchInvestmentFundingSources = async () => {
  return backendRequest<InvestmentFundingSource[]>({
    method: 'GET',
    url: entitiesEndpoints.investmentFundingSources
  })
}

export const fetchKeyFigures = async () => {
  return backendRequest<KeyFigure[]>({
    method: 'GET',
    url: entitiesEndpoints.keyFigures
  })
}

export const fetchLoanRepaymentMethods = async () => {
  return backendRequest<LoanRepaymentMethod[]>({
    method: 'GET',
    url: entitiesEndpoints.loanRepaymentMethods
  })
}

export const fetchPermissions = async () => {
  return backendRequest<Permission[]>({
    method: 'GET',
    url: entitiesEndpoints.permissions
  })
}

export const fetchStatementRows = async () => {
  return backendRequest<StatementRow[]>({
    method: 'GET',
    url: entitiesEndpoints.statementRows
  })
}
