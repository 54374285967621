import axios from 'axios'
import axiosRetry from 'axios-retry'
import { store } from '../../redux/store'

export const integrationClient = axios.create({
  baseURL: `${process.env.REACT_APP_INTEGRATION_URL}`
})

// Enable retry for axios
axiosRetry(integrationClient, {
  retries: 0, // Number of retries
  retryDelay: retryCount => {
    return retryCount * 1000 // Time between retries (1000ms per retry)
  },
  retryCondition: error => {
    // Retry on network errors or 5xx status codes
    return axiosRetry.isNetworkError(error) || axiosRetry.isRetryableError(error)
  }
})

// Add a request interceptor
integrationClient.interceptors.request.use(
  config => {
    const token = (store.getState() as any)?.session?.authentication?.token
    // Do something before request is sent
    return {
      ...config,
      headers: {
        ...config.headers,
        'X-Token': token
      }
    }
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)
