import { DeleteOutlined, EditOutlined, FileOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Popover, Space, Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface'
import { useSelector } from 'react-redux'
import { ColumnFilterItem } from 'antd/lib/table/interface'
import { Voucher, VoucherQueryResult, VoucherQueryParams, VoucherQuerySort } from '../../pages/reporting/vouchers/types'
import { contextCompanySelector } from '../../redux/context/company/selectors'
import { useBackend } from '../../services/backend'
import { Customer } from '../../types/customer/customer'
import VoucherCustomerFilterDropDown from './VoucherCustomerFilterDropDown'
import { useColumnSearchProps } from '../Table/useColumSearchProps'
import ResizableTitle from '../Table/ReziableTitle'
import { useResizeableColumns } from '../Table/hooks'

import { TaskApplication } from '../../pages/superuser/types'
import { useAuthority } from '../../utils/Authorizable/authorize'
import AttacmentModal from '../../pages/reporting/vouchers/components/AttacmentModal'
import { formatValueByType } from '../../utils/helpers'
import TransactionTable from '../../pages/reporting/vouchers/TransactionTable'

type VouherTableProps = {
  filtersEnabled?: boolean
  dataSource?: Partial<VoucherQueryResult>
  loading?: boolean
  params?: Partial<VoucherQueryParams>
  showActions?: boolean
  deleteVoucher?: (value: Voucher) => void
  editVoucher?: (value: Voucher) => void
  setParams?: (params: Partial<VoucherQueryParams>) => void
}

const VoucherTable = ({
  dataSource,
  params,
  loading,
  filtersEnabled = false,
  showActions = false,
  deleteVoucher,
  editVoucher,
  setParams
}: VouherTableProps) => {
  const { t } = useTranslation()
  const expandedRowRender = (voucher: Voucher) => <TransactionTable voucher={voucher} />
  const company = useSelector(contextCompanySelector)

  const [voucherTypes, setVoucherTypes] = useState<ColumnFilterItem[]>([])
  const [customer, setCustomer] = useState<ColumnFilterItem[]>([])
  const voucherTypeRequest = useBackend(`/reporting/vouchers/types`, process.env.REACT_APP_INTEGRATION_URL)
  const customerRequest = useBackend(`/reporting/customers/accounting`, process.env.REACT_APP_INTEGRATION_URL)
  const invoiceNumberSearch = useColumnSearchProps(['invoice', 'number'])
  const descriptionSearch = useColumnSearchProps('description')
  const quidSearch = useColumnSearchProps('guid')
  const voucherActionsEnabled = useAuthority({
    accountingSoftware: TaskApplication.FINADECK
  })
  const [voucher, setVoucher] = useState<Voucher>()
  const [resizeableColumns, setColumns] = useResizeableColumns<Voucher>([])
  const columns: ColumnsType<Voucher> = [
    {
      title: t('vouchersPage:voucherGuid'),
      dataIndex: 'guid',
      key: 'guid',
      width: 150,
      sorter: true,
      ...(filtersEnabled ? quidSearch : undefined)
    },
    {
      title: t('vouchersPage:voucherType'),
      dataIndex: 'type',
      key: 'voucherType',
      ellipsis: true,
      sorter: true,
      width: 150,
      filters: filtersEnabled ? voucherTypes : undefined,
      render: (type: string) => {
        return <Popover content={<div>{type}</div>}>{type}</Popover>
      }
    },
    {
      title: t('vouchersPage:invoicePartnerName'),
      dataIndex: ['invoice', 'partner', 'name'],
      key: 'invoicePartnerId',
      width: 150,
      sorter: true,
      ...(filtersEnabled
        ? {
            filters: customer,
            filterDropdown: filters => <VoucherCustomerFilterDropDown {...filters} />
          }
        : undefined)
    },
    {
      title: 'Debet',
      key: 'debit',
      dataIndex: 'debit',
      align: 'right' as const,
      width: 130,
      render: (val: number) => {
        return formatValueByType(val, undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      }
    },
    {
      title: 'Kredit',
      key: 'credit',
      dataIndex: 'credit',
      align: 'right' as const,
      width: 130,
      render: (val: number) => {
        return formatValueByType(val, undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      }
    },
    {
      title: t('vouchersPage:voucherStatus'),
      dataIndex: 'status',
      sorter: true,
      width: 110
    },
    {
      title: t('vouchersPage:voucherDate'),
      dataIndex: 'date',
      sorter: true,
      width: 150,
      render: (date: string) => {
        return date && dayjs(date).format('ll')
      }
    },
    {
      title: t('vouchersPage:invoiceNumber'),
      dataIndex: ['invoice', 'number'],
      key: 'invoiceNumber',
      sorter: true,
      width: 150,
      ...(filtersEnabled ? invoiceNumberSearch : undefined)
    },
    {
      title: t('vouchersPage:invoicePaymentDate'),
      dataIndex: ['invoice', 'paymentDate'],
      sorter: true,
      width: 150,
      render: (paymentDate: string) => {
        return paymentDate && dayjs(paymentDate).format('ll')
      }
    },
    {
      title: t('vouchersPage:voucherDescription'),
      dataIndex: 'description',
      key: 'voucherDesc',
      ellipsis: true,
      sorter: true,
      ...(filtersEnabled ? descriptionSearch : undefined)
    }
  ]

  const actionsColumns: ColumnsType<Voucher> = [
    {
      title: t('global:actions'),
      key: 'actions',
      fixed: 'right',
      width: 80,
      render: (val: Voucher) => (
        <Space>
          <Button size="small" type="link" icon={<EditOutlined />} onClick={() => editVoucher?.(val)} />
          <Button
            style={{
              visibility: (val?.attachments?.length || 0) > 0 ? 'visible' : 'hidden'
            }}
            size="small"
            type="link"
            icon={<FileOutlined />}
            onClick={() => {
              setVoucher(val)
            }}
          />
          <Popconfirm placement="topRight" title={t('global:delete-confirm')} onConfirm={() => deleteVoucher?.(val)}>
            <Button danger size="small" type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      )
    }
  ]

  const onContextCompanyChange = () => {
    if (company) {
      voucherTypeRequest
        .get({ body: { params: { companyId: company.id } } })
        .then((data: any) => setVoucherTypes(data.map((v: any) => ({ text: v, value: v }))))
        .catch(() => {
          setVoucherTypes([])
        })
      customerRequest
        .get({ body: { params: { companyId: company.id } } })
        .then((data: Customer[]) => {
          setCustomer(
            data.sort((a, b) => a.name.localeCompare(b.name)).map((c: any) => ({ text: c.name, value: c.id }))
          )
        })
        .catch(() => {
          setCustomer([])
        })
    }
  }

  useEffect(onContextCompanyChange, [company])
  useEffect(() => setColumns(columns), [customer, voucherTypes])

  const transformSort = (sorter: SorterResult<Voucher>): VoucherQuerySort | undefined => {
    if (!sorter.order || !sorter.field) return undefined
    const orderMap = {
      ascend: 'ASC' as const,
      descend: 'DESC' as const
    }

    return {
      field: sorter.field.toString().replaceAll(',', '.'),
      direction: orderMap[sorter.order]
    }
  }

  const transformFilters = (
    filters: Record<string, FilterValue | null>
  ): Record<string, FilterValue | null> | undefined => {
    return {
      ...filters,
      invoiceNumber: filters?.invoiceNumber?.[0] as string as unknown as FilterValue
    }
  }

  const transformParams = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Voucher>
  ): Partial<VoucherQueryParams> => {
    const sort = transformSort(sorter)
    return {
      ...params,
      ...transformFilters(filters),
      sort: sort ? [sort] : undefined,
      page: pagination.current,
      pageSize: pagination.pageSize
    }
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Voucher> | SorterResult<Voucher>[]
  ) => {
    const requestParams = transformParams(pagination, filters, sorter as SorterResult<Voucher>)
    setParams && setParams(requestParams)
  }

  const handleAttachmentClose = () => {
    setVoucher(undefined)
  }

  return (
    <>
      <AttacmentModal voucher={voucher} onClose={handleAttachmentClose} />
      <Table
        rowKey="id"
        size="small"
        components={{
          header: {
            cell: ResizableTitle
          }
        }}
        bordered
        locale={t('global:no-data-available')}
        loading={{
          spinning: loading,
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
        }}
        dataSource={dataSource?.vouchers}
        columns={resizeableColumns.concat(showActions && voucherActionsEnabled ? actionsColumns : [])}
        onChange={handleTableChange}
        scroll={{
          x: 1500
        }}
        expandable={{
          expandedRowRender: (v: Voucher) => expandedRowRender(v)
        }}
        pagination={{
          locale: { items_per_page: `/ ${t('global:page')}` },
          showSizeChanger: true,
          defaultCurrent: 1,
          current: dataSource?.page,
          total: dataSource?.results,
          pageSize: params?.pageSize,
          showTotal: (total: number) => t('global:item-count', { count: total })
        }}
      />
    </>
  )
}

export default VoucherTable
