import { TreeSelect, TreeSelectProps } from 'antd'
import React, { useMemo } from 'react'
import { useCustomReportStructure } from '../../queries/structure/useCustomReportStructure'
import { generateTreeData } from '../../utils'

interface CustomReportTreeSelectProps extends TreeSelectProps {
  companyId: string
}

const CustomReportTreeSelect = ({ companyId, ...props }: CustomReportTreeSelectProps) => {
  const { data: customReportTree, isLoading, isPending } = useCustomReportStructure(companyId)
  const treeData = useMemo(() => (customReportTree ? generateTreeData(customReportTree) : []), [customReportTree])

  return (
    <TreeSelect
      treeIcon
      treeLine
      treeDefaultExpandAll
      showSearch
      disabled={isPending}
      treeData={treeData}
      loading={isLoading}
      filterTreeNode={(inputval, node) => {
        if (!node || !inputval) return false
        return String(node.title || '')
          .toLowerCase()
          .includes(inputval.toLowerCase())
      }}
      style={{ width: 300 }}
      {...props}
    />
  )
}

export default CustomReportTreeSelect
