import { createSelector } from 'reselect'
import { loadingSelector } from '../../loading/selectors'
import { contextSelector } from '../selectors'
import { ContractProduct } from '../../../types/contract/Contract'
import { BudgetingScenarioEntity } from '../../../features/scenario/types/BudgetingScenario'

type EntityMap = {
  [key in BudgetingScenarioEntity]: ContractProduct[]
}

const entitiesMap: EntityMap = {
  settings: [ContractProduct.BASIC, ContractProduct.PRO],
  budget: [ContractProduct.BASIC, ContractProduct.PRO],
  existingLoans: [ContractProduct.BASIC, ContractProduct.PRO],
  existingInvestments: [ContractProduct.PRO],
  deprecationPlans: [ContractProduct.BASIC],
  newInvestments: [ContractProduct.BASIC, ContractProduct.PRO],
  amendments: [ContractProduct.BASIC, ContractProduct.PRO]
}

export const contextContractSelector = createSelector([contextSelector], context => context?.contract)
export const contextContractIdSelector = createSelector(contextContractSelector, contract => contract && contract.id)
export const contextContractProductSelector = createSelector(
  contextContractSelector,
  contract => contract && contract.product
)
export const contextContractUsersLoadingSelector = createSelector(
  loadingSelector,
  loading => loading.contextContractUsers
)
export const contextContractUserSelector = createSelector(
  contextContractSelector,
  contract => (contract && contract.users) || []
)

export const availableEntitiesSelector = createSelector([contextContractProductSelector], product => {
  return product
    ? (Object.entries(entitiesMap)
        .filter(([, products]) => products.includes(product)) // filter correct entities
        .map(([entity]) => entity) as BudgetingScenarioEntity[])
    : ([] as BudgetingScenarioEntity[])
})
