import { queryOptions } from '@tanstack/react-query'
import dayjs from 'dayjs'
import fiscalYearQueryKeys from './fiscalYearQueryKeys'
import { fetchFiscalYears } from '../service/fiscalYearService'
import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'

export const useFiscalYearOptions = <TData = FiscalYear[]>(
  companyId: string,
  select?: (data: FiscalYear[]) => TData
) => {
  return queryOptions({
    queryKey: fiscalYearQueryKeys.list({ companyId }),
    queryFn: fetchFiscalYears,
    enabled: !!companyId,
    staleTime: 1000 * 60 * 60 * 4,
    select: data => {
      const sortedData = data.slice().sort((a, b) => +dayjs(a.startDate) - +dayjs(b.startDate))
      return select ? select(sortedData) : (sortedData as TData)
    }
  })
}
