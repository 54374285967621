import i18next from 'i18next'
import dayjs from 'dayjs'
import { CustomReportCategory } from './typesCategory'
import { TimePeriodOptions } from './types'
import { ReportDataType } from '../reports/types'

export const keyFigureTrans = (c: CustomReportCategory) => {
  const keyFigureArr = Array.isArray(c.value) ? c.value : [c.value]
  let transLated: string = i18next.t(`keyFigureStatement:${keyFigureArr[0]}`)
  if (keyFigureArr.length > 1) {
    transLated += ` + ${keyFigureArr.length - 1}`
  }
  return transLated
}

export const formulaTrans = (c: CustomReportCategory) => {
  const formulaArr = Array.isArray(c.value) ? c.value : [c.value]
  let formulas: string = c.formulas?.[0].name || ''
  if (formulaArr.length > 1) {
    formulas += ` + ${formulaArr.length - 1}`
  }
  return formulas
}

export const defaultTitle = (
  type: CustomReportCategory['type'],
  value: string | string[] | number | number[] | undefined
) => {
  switch (type) {
    case 'company':
      return `{company}
`
    case 'dimension':
      return `{dimensionName}
`
    case 'keyFigureId':
      return `{keyFigure}
`
    case 'formulaId':
      return `{formula}
`
    case 'reference':
      return '{reference}'
    case 'periodGroup':
      switch (value) {
        case TimePeriodOptions.CustomPeriod:
        case TimePeriodOptions.Past12months:
        case TimePeriodOptions.FiscalyearToDate:
        case TimePeriodOptions.PreviousFiscalyear:
        case TimePeriodOptions.PreviousFiscalyearToDate:
        case TimePeriodOptions.Currentfiscalyear:
        case TimePeriodOptions.PreviousYear:
        case TimePeriodOptions.Previousyeartodate:
        case TimePeriodOptions.YearToDate:
        case TimePeriodOptions.FromToDate:
        case TimePeriodOptions.Quarter:
        case TimePeriodOptions.CumulativeFiscalyear:
          return `{mm/yy} - {mm/yy}
{dataType}
`
        case TimePeriodOptions.CustomMonth:
        case TimePeriodOptions.FiscalyearFirstMonth:
        case TimePeriodOptions.CurrentMonth:
          return `{mm/yy}
{dataType}
`
        case TimePeriodOptions.CurrentYear:
        case TimePeriodOptions.CustomYear:
          return `{yyyy}
{dataType}
`
        case TimePeriodOptions.CurrentDay:
          return `{dd/mm/yyyy}
{dataType}
`
        case TimePeriodOptions.CurrentWeek:
          return `{w/yyyy}
{dataType}
`
        default:
          return ''
      }
    case 'function':
      return `{function}
`

    default:
      return ''
  }
}

const rulesOther: {
  [key: string]: (e: CustomReportCategory, categories?: CustomReportCategory[], noStyles?: boolean) => string
} = {
  '{dimensionName}': (e: CustomReportCategory) => e.dimension?.name || '',
  '{dataType}': (e: CustomReportCategory, categories?: CustomReportCategory[], noStyles = false) =>
    noStyles
      ? i18next.t(`global:${e.dataType || ReportDataType.actuals}`)
      : `<span style="font-size: 90%; opacity: 0.7;">${i18next.t(
          `global:${e.dataType || ReportDataType.actuals}`
        )}</span>`,
  '{scenarioName}': (e: CustomReportCategory) => e.budgetingScenario?.name || '',
  '{company}': (e: CustomReportCategory) => e.company?.name || '',
  '{formula}': (e: CustomReportCategory) => formulaTrans(e),
  '{keyFigure}': (e: CustomReportCategory) => keyFigureTrans(e),
  '{reference}': (e: CustomReportCategory, categories?: CustomReportCategory[], noStyles = false) =>
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getReferenceTitle(e, categories, noStyles),
  '{function}': (e: CustomReportCategory, categories?: CustomReportCategory[], noStyles = false) =>
    noStyles
      ? i18next.t(`customReportPage:${e.value}`)
      : `<span style="font-size: 90%; opacity: 0.7;">${i18next.t(`customReportPage:${e.value}`)}</span>`
}

const rulesDate: { [key: string]: (e: Date) => string } = {
  '{yyyy}': (e: Date) => dayjs(e).format('YYYY'),
  '{mm/yyyy}': (e: Date) => dayjs(e).format('MM/​YYYY'),
  '{mm/yy}': (e: Date) => dayjs(e).format('MM/​YY'),
  '{dd/mm/yyyy}': (e: Date) => dayjs(e).format('DD/​MM/​YYYY'),
  '{dd}': (e: Date) => dayjs(e).format('dd'),
  '{dddd}': (e: Date) => dayjs(e).format('dddd'),
  '{w}': (e: Date) => dayjs(e).format('w'),
  '{w/yyyy}': (e: Date) => `W${dayjs(e).format('w')}/${dayjs(e).format('YYYY')}`
}

const arrayDate = ['startDate', 'endDate']

const transformOtherPlaceholder = (
  placeholderString: string,
  c: CustomReportCategory,
  categories?: CustomReportCategory[],
  noStyles = false
) => {
  return rulesOther[placeholderString](c, categories, noStyles)
}

const extractPlaceholders = (title: string, rules: { [key: string]: (e: any) => string }) => {
  const keys = Object.keys(rules).map(key => key.replace(/[{}]/g, '\\$&'))
  const regex = new RegExp(keys.join('|'), 'g')
  return title.match(regex) || []
}

export const transform = (
  t: string,
  c: CustomReportCategory,
  categories?: CustomReportCategory[],
  noStyles = false
) => {
  let title = t
  for (let i = 0; i < arrayDate.length; i += 1) {
    const stringArrDates = extractPlaceholders(title, rulesDate)
    if (stringArrDates[0] && rulesDate[stringArrDates[0]]) {
      title = title.replace(
        stringArrDates[0],
        rulesDate[stringArrDates[0]](c[arrayDate[i] as keyof CustomReportCategory['startDate' | 'endDate']])
      )
    }
  }

  for (const otherPlaceholder of extractPlaceholders(title, rulesOther)) {
    title = title.replace(otherPlaceholder, transformOtherPlaceholder(otherPlaceholder, c, categories, noStyles))
  }

  return title
}

export const transformTitle = (c: CustomReportCategory, categories?: CustomReportCategory[], noStyles = false) => {
  if (!c) return ''
  let transformedTitle = transform(c.title ?? defaultTitle(c.type, c.value), c, categories, noStyles)

  const processChildren = (category: CustomReportCategory): string => {
    let result = ''
    if (category.children) {
      for (const child of category.children) {
        result += transform(` ${child?.title ?? defaultTitle(child.type, child.value)}`, child, categories, noStyles)
        if (child?.children) {
          result += processChildren(child)
        }
      }
    }
    return result
  }
  if ((c.type !== 'company' && c.type !== 'dimension') || c.value === 'sum') {
    transformedTitle += processChildren(c)
  }
  return transformedTitle
}

function filterTreeByPath(categories: CustomReportCategory[] = [], path?: string) {
  const pathSegments = path?.split('-') // Split path into array: "0-1-0" -> ["0", "1", "0"]

  if (!categories || !path) return []
  function filterNodes(nodes: CustomReportCategory[], currentPathIndex: number): CustomReportCategory[] {
    return nodes.reduce((acc, node) => {
      // Check if this node's ID matches the current segment of the path
      const id = pathSegments?.slice(0, currentPathIndex + 1).join('-')
      const matchesCurrentSegment = node.id === id
      if (matchesCurrentSegment) {
        // If this node is part of the path, include it and filter its children
        const filteredChildren = node.children ? filterNodes(node.children, currentPathIndex + 1) : []
        acc.push(node, ...filteredChildren)
      }
      return acc
    }, [] as CustomReportCategory[])
  }

  return filterNodes(categories, 0)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getReferenceTitle = (c: CustomReportCategory, categories?: CustomReportCategory[], noStyles = false) => {
  const referenceCategories = filterTreeByPath(categories, c?.value as string)

  let title = ''
  for (const cat of referenceCategories) {
    title += transform(cat.title ?? defaultTitle(cat.type, cat.value), cat, categories, true)
  }
  return title
}
