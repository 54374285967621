import { useMutation, useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { deleteCustomReportGroup } from '../../service/customReportGroupService'
import customReportQueryKeys from '../customReportQueryKeys'

export const useCtxDeleteCustomReportGroup = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { notification } = App.useApp()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id: number) => deleteCustomReportGroup(companyId, id),
    onSuccess: () => {
      notification.success({
        message: t('message:DELETE_CUSTOM_REPORT_GROUP_SUCCESS', 'Request sent')
      })
      return queryClient.invalidateQueries({
        queryKey: customReportQueryKeys.groups({ companyId })
      })
    }
  })
}
