import React, { forwardRef } from 'react'
import { GetRef } from 'antd'
import { lockedScenariosDisabledOptionsSelector } from '../../selectors/selectors'
import { disabledLockRender } from './disabledLockRender'
import ScenarioSelect from './ScenarioSelect'

type SelectRefType = GetRef<typeof ScenarioSelect> // BaseSelectRef

const ScenarioLockSelect = forwardRef<SelectRefType>((props, ref) => {
  return (
    <ScenarioSelect
      ref={ref}
      optionRender={disabledLockRender}
      optionSelector={lockedScenariosDisabledOptionsSelector}
      {...props}
    />
  )
})

export default ScenarioLockSelect
