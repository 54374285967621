import { Entities, SET_ENTITIES } from './types'

const initialState: Entities = {
  contractProducts: {},
  investmentFundingSources: [],
  keyFigures: [],
  loanRepaymentMethods: [],
  statementRows: [],
  industries: [],
  permissions: []
}

const entitiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ENTITIES:
      return action.payload
    default:
      return state
  }
}

export default entitiesReducer
