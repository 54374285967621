import { useMutation, useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import fiscalYearQueryKeys from '../fiscalYearQueryKeys'
import { updateFiscalYear } from '../../service/fiscalYearService'
import { FiscalYear } from '../../../../types/fiscalYear/FiscalYear'

export const useCtxUpdateFiscalYear = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { notification } = App.useApp()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['updateFiscalYear'],
    mutationFn: (data: Partial<FiscalYear>) => {
      if (data.id === undefined) {
        throw new Error('Fiscal Year ID is required for update')
      }
      if (!companyId) {
        throw new Error('Company ID is required for update')
      }
      return updateFiscalYear(data.id, data, companyId)
    },
    onSuccess: () => {
      notification.success({
        message: t('messages:updateSuccess', 'Request sent')
      })
      return queryClient.invalidateQueries({
        queryKey: fiscalYearQueryKeys.list({ companyId })
      })
    }
  })
}
