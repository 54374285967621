import { DatePicker, Button, Space } from 'antd'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { BuildOutlined } from '@ant-design/icons'
import QuickFilters from './QuickFilters'
import { calculatedReportSelector, customReportDateSelector } from '../../../../redux/context/customReports/selectors'
import { setCalculatedReport, setCustomReportDate } from '../../../../redux/context/customReports/actions'
import { LayoutsContext, LayoutsContextType } from './layout/LayoutContext'
import { CustomReport } from '../../../../redux/context/customReports/types'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { BackendRequest } from '../../../../services/backend'
import { fetchReportData } from '../service'
import { firstReport } from './utils'
import { useCtxCustomReports } from '../../../../features/customReport/queries/report/useCtxCustomReports'
import { useCtxCustomReportStructure } from '../../../../features/customReport/queries/structure/useCtxCustomReportStructure'
import CtxCustomReportTreeSelect from '../../../../features/customReport/components/treeSelect/CtxCustomReportTreeSelect'

interface PageHeaderExtraProps {
  getReportDataRequest: BackendRequest
  itemsLoading?: number[]
}

const PageHeaderExtra: React.FC<PageHeaderExtraProps> = ({ itemsLoading, getReportDataRequest }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const calculatedReport = useSelector(calculatedReportSelector)
  const date = useSelector(customReportDateSelector)
  const navigate = useNavigate()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { setLayoutEdit } = useContext(LayoutsContext) as LayoutsContextType
  const { data: customReportTree, isPending: treeIsPending } = useCtxCustomReportStructure()
  const { data: customReports } = useCtxCustomReports()

  const [selectDisabled, setSelectDisabled] = useState(false)

  const setDate = (newDate: string) => {
    dispatch(setCustomReportDate(newDate))
  }

  const handleReportChange = (report?: CustomReport) => {
    if (report) {
      dispatch(setCalculatedReport(null))
      fetchReportData(dispatch, getReportDataRequest, companyId, report)
    }
  }

  const onReportChange = (id: number) => {
    setSelectDisabled(true)
    const report = customReports?.find(r => r.id === Number(id))
    handleReportChange(report)
    setTimeout(() => {
      setSelectDisabled(false)
    }, 3000)
  }

  useEffect(() => {
    if (!calculatedReport?.id) {
      setSelectDisabled(true)
      const fetchReport = firstReport(customReportTree)
      fetchReportData(dispatch, getReportDataRequest, companyId, fetchReport)
      setTimeout(() => {
        setSelectDisabled(false)
      }, 3000)
    }
  }, [customReportTree])

  return (
    <Space>
      <QuickFilters />
      <CtxCustomReportTreeSelect
        treeDefaultExpandAll
        value={calculatedReport?.id}
        disabled={treeIsPending || selectDisabled}
        loading={itemsLoading && itemsLoading?.length > 0}
        filterTreeNode={(inputval, node) => {
          return node && (node.title as string)?.toLowerCase().includes(inputval?.toLowerCase())
        }}
        onChange={onReportChange}
      />
      <Button icon={<BuildOutlined />} onClick={() => setLayoutEdit(true)} />
      <Button onClick={() => navigate('/settings/custom-report/order')}>{t('header:settings')}</Button>
      <DatePicker
        style={{ width: 100, height: '100%' }}
        key="datePicker"
        showWeek
        allowClear={false}
        defaultValue={dayjs(date)}
        onChange={newDate => newDate && setDate(newDate.toString())}
      />
    </Space>
  )
}

export default PageHeaderExtra
