import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import ColorPicker from '../../../../categorySelection/ColorPicker'

const Color = () => {
  const { t } = useTranslation()

  return (
    <Form.Item key="color" name={['style', 'color']} label={t('global:color')}>
      <ColorPicker />
    </Form.Item>
  )
}

export default Color
