import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { CustomReportSectionsType } from '../../../redux/context/customReports/typesSection'
import {
  customReportDateSelector,
  calculatedReportSelector,
  customReportQuickFiltersSelector
} from '../../../redux/context/customReports/selectors'
import { useBackend } from '../../../services/backend'
import { fetchCustomReportSection } from './service'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { AppDispatch } from '../../../redux/store'
import { updateSections } from '../../../redux/context/customReports/actions'
import { sleep } from '../../../utils/helpers'
import { sortSections } from './components/grid.utils'
import { appLanguageSelector } from '../../../redux/app/selectors'

export function useSectionLoading() {
  const dispatch: AppDispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const date = useSelector(customReportDateSelector)
  const calculatedReport = useSelector(calculatedReportSelector)!
  const quickFilters = useSelector(customReportQuickFiltersSelector)
  const [isFinished, setIsFinished] = useState<boolean>(false)
  const [itemsLoading, setItemsLoading] = useState<number[]>([])
  const language = useSelector(appLanguageSelector)

  const controller = new AbortController()
  const sectionRequest = useBackend(
    '/api/companies/{companyId}/reporting/custom/reports/{reportId}/sections/{sectionId}',
    undefined,
    undefined,
    controller.signal
  )

  const fetchSection = useCallback(
    async (sectionId: number) => {
      return fetchCustomReportSection(
        sectionRequest,
        companyId,
        calculatedReport.id,
        sectionId,
        date,
        quickFilters,
        language
      )
    },
    [companyId, date, quickFilters, calculatedReport?.id, language]
  )

  async function fetchInBatches<T>(
    ids: number[],
    requestFactory: (id: number) => Promise<T>,
    batchSize: number,
    callback: (results: T[]) => void
  ) {
    for (let i = 0; i < ids.length; i += batchSize) {
      const batchIds = ids.slice(i, i + batchSize)
      const batch = batchIds.map(id => requestFactory(id))
      try {
        setItemsLoading(batchIds)
        // eslint-disable-next-line no-await-in-loop
        const batchResults = await Promise.allSettled(batch)

        // Convert results to include null for rejected promises
        const results = batchResults.map(result => (result.status === 'fulfilled' ? result.value : null))
        // eslint-disable-next-line no-await-in-loop
        await sleep(200)
        callback(_.compact(results))
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    const handleFetch = async () => {
      const fetchFactory = (id: number) => fetchSection(id)
      const ids = sortSections(calculatedReport?.sections)?.map(({ id }) => id)

      const callback = (data: CustomReportSectionsType[]) => {
        dispatch(updateSections(data))
      }
      setIsFinished(false)
      await fetchInBatches(ids, fetchFactory, 5, callback)
      setIsFinished(true)
      setItemsLoading([])
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 1000)
    }

    handleFetch()

    return () => {
      controller.abort()
    }
  }, [calculatedReport?.id, date, quickFilters, language])

  return { isFinished, itemsLoading }
}
