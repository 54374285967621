import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'
import { fiscalYearLabel } from '../../../redux/context/fiscalYears/utils'
import { FormattedInputNumber } from '../../../components/Misc/FormattedInputNumber'
import { useCtxFiscalYears } from '../../../features/fiscalYear/queries/report/useCtxFiscalYears'

export const ValuationForm: React.FC = () => {
  const { t } = useTranslation()
  const { data: fiscalYears } = useCtxFiscalYears()

  return (
    <Col md={12} xs={24}>
      <Row gutter={24}>
        <Col span={6}>
          <h4>{t('valuationPage:fiscal-year')}</h4>
        </Col>
        <Col span={9}>
          <h4>{t('valuationPage:salary-correction')}</h4>
        </Col>
        <Col span={9}>
          <h4>{t('valuationPage:other-corrections')}</h4>
        </Col>
      </Row>
      {fiscalYears?.map(fiscalYear => {
        return (
          <Row gutter={24} key={fiscalYear.id}>
            <Col span={6} style={{ marginTop: '8px' }}>
              {fiscalYearLabel(fiscalYear)}
            </Col>
            <Col span={9}>
              <Form.Item name={['wages', `${fiscalYear.id}`]}>
                <FormattedInputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name={['other', `${fiscalYear.id}`]}>
                <FormattedInputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        )
      })}
    </Col>
  )
}

export default ValuationForm
