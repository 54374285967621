import { ContextFilters } from '../../../types/context/ContextFilters'

const customReportQueryKeys = {
  all: [{ scope: 'customReport' }] as const,
  lists: () => [{ ...customReportQueryKeys.all[0], entity: 'list' }] as const,
  list: (context: Pick<ContextFilters, 'companyId'>) => [{ ...customReportQueryKeys.lists()[0], ...context }] as const,
  details: () => [{ ...customReportQueryKeys.all[0], entity: 'detail' }] as const,
  detail: (context: Pick<ContextFilters, 'companyId'>, id: number) =>
    [{ ...customReportQueryKeys.details()[0], ...context, id }] as const,
  groups: (context: Pick<ContextFilters, 'companyId'>) =>
    [{ ...customReportQueryKeys.lists()[0], type: 'group', ...context }] as const
}

export default customReportQueryKeys
