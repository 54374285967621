import { queryOptions, useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { KeyFigureFact } from '../../../types/key-figures/KeyFigureFact'
import { personnelQueryKeys } from '..'
import { fetchPersonnel } from '../service/personnelService'

export const usePersonnelOptions = <TData = KeyFigureFact[]>(select?: (data: KeyFigureFact[]) => TData) => {
  const companyId = useSelector(contextCompanyIdSelector)!

  return queryOptions({
    queryKey: personnelQueryKeys.list({ companyId }),
    queryFn: fetchPersonnel,
    select
  })
}

export const usePersonnelQuery = <TData = KeyFigureFact[]>(select?: (data: KeyFigureFact[]) => TData) => {
  const options = usePersonnelOptions(select)
  return useQuery(options)
}

export const usePersonnel = () =>
  usePersonnelQuery(personnel =>
    personnel
      .map(
        p =>
          ({
            ...p,
            value: parseInt(p.value, 10).toString()
          } as KeyFigureFact)
      )
      .slice()
      .sort((a, b) => b.year - a.year)
  )

export default usePersonnel
