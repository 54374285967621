import { createSelector } from 'reselect'

import { pagesSelector } from '../../selectors'
import { Column } from '../../../context/periodGroups/hooks'
import { ReportTableRow } from '../../../../components/Table/types'

export const financialStatementsPageSelector = createSelector(pagesSelector, pages => pages.financialStatementsPage)
export const financialStatementsReallocationsSelector = createSelector(
  financialStatementsPageSelector,
  financialStatementsPage => financialStatementsPage.reallocations
)

export const financialStatementsOpenCommentSelector = createSelector(
  financialStatementsPageSelector,
  financialStatementsPage => financialStatementsPage.openComment
)

export const isCommentOpenSelector = (row: ReportTableRow, column: Column) =>
  createSelector(financialStatementsOpenCommentSelector, openComment => {
    return openComment?.column === column.label && openComment.row === row.key
  })
