/* eslint-disable react/no-this-in-sfc */
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useRef, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsData from 'highcharts/modules/data'
import { SizeMeProps, withSize } from 'react-sizeme'
import { GraphType } from './ChartTypeSelect'
import { getDataColumns, getMaxSectionSeriesValue, getSeries, labelFormatter } from './chartUtils'
import { getCustomChartPlotOptions } from '../utils'
import { ChartYAxis } from './types'
import { CustomReportChartSection } from '../../../../../redux/context/customReports/typesSection'

HighchartsExporting(Highcharts)
HighchartsData(Highcharts)

interface NewCustomChartProps {
  section: CustomReportChartSection
  size?: SizeMeProps['size']
}

const CustomChart: React.FC<NewCustomChartProps> = ({ section, size }) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null)
  const [graphType] = useState(section.style?.type ?? GraphType.column)

  const options = {
    chart: {
      type: graphType,
      width: size?.width,
      height: size?.height
    },
    title: {
      text: ''
    },
    data: {
      columns: getDataColumns(section),
      switchRowsAndColumns: !!section.style?.switchRowsAndColumns
    },
    series: getSeries(section),
    plotOptions: {
      ...getCustomChartPlotOptions(section?.style?.type ?? GraphType.column, section)[1]
    },
    yAxis: [
      {
        title: {
          text: null
        },
        max: section.style?.showDataLabels === 'true' ? getMaxSectionSeriesValue(section, ChartYAxis.left) : undefined
      },
      {
        // --- Secondary yAxis
        title: {
          text: null
        },
        opposite: true,
        max: section.style?.showDataLabels === 'true' ? getMaxSectionSeriesValue(section, ChartYAxis.right) : undefined
      }
    ],
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b><br/>'
    },
    credits: {
      enabled: false
    },
    legend: {
      labelFormatter() {
        return labelFormatter(this.options, this.name)
      }
    }
  } as Highcharts.Options

  useEffect(() => {
    chartRef.current?.chart?.update(options)
  }, [section])

  return (
    <div>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </div>
  )
}

export default withSize({ monitorHeight: true })(CustomChart)
