import { SettingOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Route } from '../../routes/Route'

interface SettingsMenuProps {
  routes: Route[]
}

const SettingsMenu = ({ routes = [] }: SettingsMenuProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const settingRoutes = routes
    ?.find((route: Route) => route.path === '/settings')
    ?.routes?.filter((route: Route) => route.path !== '/settings/user')

  const items = settingRoutes
    ?.filter(s => !s.hideInMenu)
    ?.map((route: Route) => {
      return {
        key: route.path,
        label: t(`header:${route.path}`),
        onClick: () => navigate(route.path)
      }
    })

  const menuProps = {
    items
  }

  return (
    <Dropdown placement="bottom" menu={menuProps}>
      <SettingOutlined />
    </Dropdown>
  )
}

export default SettingsMenu
