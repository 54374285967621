import i18next from 'i18next'
import dayjs from 'dayjs'
import React, { ReactNode } from 'react'
import DOMPurify from 'dompurify'
import { Tooltip } from 'antd'
import { CustomReportCategory } from './typesCategory'
import { CustomReportVariable } from './typesVariable'
import { SectionType } from './typesSection'
import { masterConfig } from '../../../pages/reporting/custom/components/variable/form/masterConfig'
import { transformTitle } from './transUtils'

export const toHtml = (val: string) => {
  return val?.replaceAll('\n', '<br>')
}

export const toLine = (val: string) => {
  return val?.replaceAll('\n', ' ')
}

export const generateStyledElement = (transformedTitle: string) => {
  return React.createElement('div', {
    dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(toHtml(transformedTitle)) }
  })
}

export const getCustomReportTableCategoryTitle = (
  c?: CustomReportCategory,
  categories?: CustomReportCategory[]
): string | ReactNode => {
  if (!c) return ''
  return generateStyledElement(transformTitle(c, categories))
}

export const scenarioTitle = (columnCategory: CustomReportCategory) => {
  const tooltipText = [
    <div key="scenario-name">{`${columnCategory.budgetingScenario?.name || i18next.t('global:default')} \n`}</div>
  ]

  if (columnCategory.budgetingScenario?.forecastCursor) {
    tooltipText.push(
      <div key="forecast-cursor">
        {i18next.t('global:actuals_until', {
          date: dayjs(columnCategory.budgetingScenario?.forecastCursor).format('MM/YYYY')
        })}
      </div>
    )
  }

  return (
    <Tooltip title={tooltipText}>
      <span style={{ color: '#0225a1' }}>{getCustomReportTableCategoryTitle(columnCategory)}</span>
    </Tooltip>
  )
}

export const isFieldDisabled = (record: CustomReportVariable, sectionType: SectionType, field: string): boolean => {
  const sectionConfig = masterConfig[sectionType]

  if (!sectionConfig) return true

  const groupConfig = sectionConfig[record.rowType]

  if (!groupConfig) return true
  const fields = Object.values(groupConfig).flat()

  return !fields.includes(field)
}
