import { QueryFunctionContext } from '@tanstack/react-query'
import { GET, PUT } from '../../../redux/middleware/types'
import backendRequest from '../../../services/api/backendRequest'
import companyGroupsSettingEndpoints from '../api/companyGroupSettingEndpoints'
import companyGroupSettingQueryKeys from '../queries/companyGroupSetting/companyGroupSettingQueryKeys'
import { GroupSettingData } from '../types/CompanyGroupSettings'

export const fetchCompanyGroupSettings = async ({
  queryKey: [{ companyId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof companyGroupSettingQueryKeys)['setting']>>) => {
  return backendRequest<GroupSettingData>({
    method: GET,
    url: companyGroupsSettingEndpoints.settings(companyId),
    signal
  })
}

export const updateCompanyGroupSetting = async (data: GroupSettingData, companyId: string) => {
  return backendRequest<GroupSettingData>({
    method: PUT,
    url: companyGroupsSettingEndpoints.settings(companyId),
    data
  })
}
