import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { App } from 'antd'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { unLockForecast } from '../../service/forecastService'
import forecastQueryKeys from './forecastQueryKeys'
import { BudgetingScenario } from '../../types/BudgetingScenario'

export const useUnLockForecast = () => {
  const companyId = useSelector(contextCompanyIdSelector)
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { notification } = App.useApp()

  if (!companyId) {
    throw new Error('Company ID is required for locking budgeting scenarios.')
  }

  return useMutation({
    mutationKey: forecastQueryKeys.list(companyId),
    mutationFn: (scenarioId: BudgetingScenario['id']) => {
      if (scenarioId === null) {
        throw new Error('Cannot unLock the default budgeting scenario.')
      }
      return unLockForecast(companyId, scenarioId)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: forecastQueryKeys.list(companyId) })
      notification.success({ message: t('message:UNLOCK_SCENARIO_SUCCESS') })
    },
    onError(error) {
      notification.error({ message: error.message })
    }
  })
}
