import { Form, Modal, Alert } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { requireContractProductExact } from '../../../components/Misc/RestrictedProduct'
import { ReportTableRow } from '../../../components/Table/types'
import { contextContractProductSelector } from '../../../redux/context/contract/selectors'
import { ContractProduct } from '../../../types/contract/Contract'
import { Investment } from '../../../types/investment/Investment'
import { InvestmentFunding } from '../../../types/investment/InvestmentFunding'
import InvestmentForm from './form/InvestmentForm'
import { getInitialValues, investmentSaveFormat } from './form/utils'
import { useAccounts } from '../../account/queries/useFetchAccounts'
import { useUpdateInvestment } from '../hooks/useUpdateInvestment'
import { useCreateInvestment } from '../hooks/useCreateInvestment'

interface InvestmentModalProps {
  visible: boolean
  isExisting: boolean
  investment?: Investment
  formType?: 'investment' | 'divestment'
  reportTableRow?: ReportTableRow
  sourceDisabled: boolean
  onlyDepreciable?: boolean
  onFinish: () => void
}

const InvestmentModal: React.FC<InvestmentModalProps> = ({
  visible,
  isExisting,
  investment,
  formType,
  reportTableRow,
  sourceDisabled,
  onlyDepreciable,
  onFinish
}) => {
  const { t } = useTranslation()
  const [form] = useForm<Investment>()
  const { data: accounts } = useAccounts()
  const contextContractProduct = useSelector(contextContractProductSelector)
  const [fundingError, setFundingError] = useState<null | string>(null)
  const { mutateAsync: updateInvestment, isPending: updateIsPending } = useUpdateInvestment(isExisting)
  const { mutateAsync: createInvestment, isPending: createIsPending } = useCreateInvestment(isExisting)

  useEffect(() => {
    form.setFieldsValue(getInitialValues(investment, reportTableRow))
  }, [investment, reportTableRow])

  const onValuesChange = (changedField: any, allFields: Investment) => {
    if (contextContractProduct && requireContractProductExact(contextContractProduct, ContractProduct.PRO)) {
      const { accountCode: changedAccountCode, date: changedDate } = changedField

      const { deprecationPlan: { bsAccountCode, startDate } = {} } = allFields

      const { statementRowId } = accounts?.find(a => a.code === changedAccountCode) || {}
      const { statementRowId: bsStatementRowId } = accounts?.find(a => a.code === bsAccountCode) || {}

      if (changedAccountCode && statementRowId !== bsStatementRowId) {
        form.setFieldsValue({ deprecationPlan: { bsAccountCode: changedAccountCode } })
      }

      if (changedDate && !startDate) {
        form.setFieldsValue({ deprecationPlan: { startDate: changedDate } })
      }
    }
  }

  const handleCancel = () => {
    onFinish()
    form.resetFields()
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      const balanceSheetRowId = values.balanceSheetRowId
        ? values.balanceSheetRowId
        : accounts?.find(a => a.code === values.accountCode)?.statementRowId
      if (!balanceSheetRowId) throw new Error('Failed')

      const data = { ...investmentSaveFormat(values), balanceSheetRowId, vat: 0, isExisting }

      if (!isExisting) {
        const { value: investmentValue } = data
        const fundingValuesSum = data?.fundings?.reduce((sum: number, f: InvestmentFunding) => sum + f.value, 0)
        if (!data?.fundings?.length) {
          setFundingError(t('investmentsPage:investmentHasToHaveFunding'))
          throw new Error()
        }
        if (investmentValue !== fundingValuesSum) {
          setFundingError(t('investmentsPage:valuesNotMatch'))
          throw new Error()
        }
      }

      setFundingError(null)

      if (data.id) {
        await updateInvestment(data)
      } else {
        await createInvestment(data)
      }

      form.resetFields()
      onFinish()
    } catch (error) {
      console.log('Validate Failed:', error)
    }
  }

  return (
    <Modal
      width={1000}
      title={
        formType === 'investment' || (investment && investment.value > 0)
          ? t('investmentsPage:investmentModalTitle', { context: investment ? 'update' : 'create' })
          : t('financialStatementsPage:divestment')
      }
      open={visible}
      onOk={handleOk}
      okText={t('global:save')}
      onCancel={handleCancel}
      cancelText={t('global:cancel')}
      maskClosable={false}
      confirmLoading={updateIsPending || createIsPending}
      destroyOnClose
      forceRender={false}
    >
      <Form form={form} name="investment" layout="vertical" autoComplete="off" onValuesChange={onValuesChange}>
        <InvestmentForm
          onlyDepreciable={onlyDepreciable}
          isExisting={isExisting}
          sourceDisabled={sourceDisabled}
          formType={formType === 'investment' || (investment && investment.value > 0) ? 'investment' : 'divestment'}
        />
      </Form>
      {fundingError && <Alert message={fundingError} type="error" />}
    </Modal>
  )
}

export default InvestmentModal
