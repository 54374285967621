import { ReportDataType } from '../../redux/context/reports/types'
import { BudgetingScenario } from '../../features/scenario/types/BudgetingScenario'
import { Company } from '../company/Company'
import { User } from '../user/User'
import { Delivery } from './Delivery'

export enum DeliverySubscriptionType {
  COMPANY_DASHBOARD = 'COMPANY_DASHBOARD',
  CUSTOM_REPORT = 'CUSTOM_REPORT',
  ACCOUNTOR_SCORECARD = 'ACCOUNTOR_SCORECARD',
  ALARM = 'ALARM'
}

export interface DeliverySubscription {
  id: number
  reportName?: string
  contractId?: number
  companyId: Company['id']
  company?: Company
  userId: User['id']
  user?: User
  recipients?: string[] | null
  type: DeliverySubscriptionType
  channel: DeliveryChannel
  displayNameOrEmails?: string
  initiator?: DeliverySubscriptionInitiator
  deliveries?: Delivery[]
  lastDeliveryAt?: string
  latestPeriodLock?: string
  params?: DeliverySubscriptionParams
  reportId?: number
  budgetingScenarioId?: BudgetingScenario['id']
}

export interface DeliverySubscriptionParams {
  dataTypes: ReportDataType[]
  incomeStatementRepresentation?: number
  financialStatementLevel?: 'statementRow' | 'account'
  balanceSheetLevel?: 'statementRow' | 'account'
  endDate?: Date
  budgetingScenarioId?: BudgetingScenario['id']
  dimensionId?: string
  dataSetting?: 'last12months' | 'startOfFiscalyear'
}

export type DeliverySubscriptionInitiator =
  | YearlyDelivery
  | MonthlyDelivery
  | WeeklyDelivery
  | DailyDelivery
  | PeriodLockDelivery
  | ManualDelivery

// month and day or boolean (end of year)
export interface YearlyDelivery {
  yearly: { month: number; day?: number } | boolean
}

// day of the month or boolean (end of month)
export interface MonthlyDelivery {
  monthly: { day: number } | boolean
}

export interface WeeklyDelivery {
  weekly: { dow: number } // 0-6 (su-la)
}

// every day when the job runs
export interface DailyDelivery {
  daily: boolean
}

export interface PeriodLockDelivery {
  periodLock: boolean
}

export interface ManualDelivery {
  manual?: boolean
}

export enum DeliveryChannel {
  EMAIL = 'EMAIL',
  FINADECK = 'FINADECK'
  // NOTIFICATION = 'NOTIFICATION'
}
