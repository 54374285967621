import { useMutation, useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { updateCustomReport } from '../../service/customReportService'
import { CustomReport } from '../../../../redux/context/customReports/types'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { updateCustomReportAction } from '../../../../redux/context/customReports/actions'
import customReportQueryKeys from '../customReportQueryKeys'

export const useCtxUpdateCustomReport = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { notification } = App.useApp()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: Partial<CustomReport>) => {
      if (data.id === undefined) {
        throw new Error('Report ID is required for update')
      }
      if (!companyId) {
        throw new Error('Company ID is required for update')
      }
      return updateCustomReport(data.id, data, companyId)
    },
    onSuccess: response => {
      dispatch(updateCustomReportAction(response))
      notification.success({
        message: t('messages:updateSuccess', 'Request sent')
      })
      return queryClient.invalidateQueries({
        queryKey: customReportQueryKeys.list({ companyId })
      })
    }
  })
}
