import { GET, POST } from '../../../redux/middleware/types'
import integrationRequest from '../../../services/api/integrationRequest'
import {
  JiraIssueComponent,
  JiraServicedeskRequest,
  JiraServicedeskRequestType,
  ServiceDeskResponse
} from '../../../types/jira/jira'
import jiraEndpoints from '../api/jiraEndpoints'

export const fetchJiraComponents = async () => {
  return integrationRequest<JiraIssueComponent[]>({
    method: GET,
    url: jiraEndpoints.components
  })
}

export const fetchJiraRequestTypes = async () => {
  return integrationRequest<ServiceDeskResponse<JiraServicedeskRequestType>>({
    method: GET,
    url: jiraEndpoints.requestTypes
  })
}

export const createJiraRequest = async (data: FormData) => {
  return integrationRequest<JiraServicedeskRequest>({
    method: POST,
    url: jiraEndpoints.request,
    data
  })
}
