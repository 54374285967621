import { Form, Input } from 'antd'
import React from 'react'

const Id = () => {
  return (
    <Form.Item key="id" name="id" hidden>
      <Input />
    </Form.Item>
  )
}

export default Id
