import React from 'react'
import dayjs from 'dayjs'
import { useWatch } from 'antd/lib/form/Form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Form, Table } from 'antd'
import { subsidiariesSelector } from '../../../../../../redux/context/subsidiaries/selectors'
import { Company } from '../../../../../../types/company/Company'
import { ReportDataType } from '../../../../../../redux/context/reports/types'
import ScenarioSelect from '../../../../../../features/scenario/components/select/ScenarioSelect'
import { useScenarios } from '../../../../../../features/scenario/queries/useScenarios'

interface ScenarioMapProps {
  selectedGroup?: Company
  dataType: ReportDataType.forecast | ReportDataType.budget
}

const ForecastCursorCell: React.FC<{ company: any }> = ({ company }) => {
  const { data } = useScenarios({ companyId: company.id })
  const form = Form.useFormInstance()
  const scenarioId = useWatch(['subsidiaries', company.id], form)
  const { forecastCursor } = data.find(scenario => scenario.id === scenarioId) || {}

  return <>{forecastCursor ? dayjs(forecastCursor).endOf('month').format('DD.MM.YYYY') : ''}</>
}

const ScenarioMapTable = ({ selectedGroup, dataType }: ScenarioMapProps) => {
  const { t } = useTranslation()
  const subsidiaries = useSelector(subsidiariesSelector)

  const scenarioTitle = dataType === ReportDataType.budget ? t('global:scenario') : t('global:forecast')

  const columns = [
    {
      title: t('global:company'),
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render: (text: string, record: any) => record?.name
    },
    {
      title: scenarioTitle,
      dataIndex: 'id',
      key: 'id',
      width: '45%',
      render: (companyId: string) => (
        <Form.Item
          noStyle
          name={['subsidiaries', companyId]}
          rules={[
            {
              required: dataType === ReportDataType.forecast,
              message: t('global:required-field')
            }
          ]}
        >
          <ScenarioSelect companyId={companyId} dataType={dataType} />
        </Form.Item>
      )
    }
  ]

  if (dataType === ReportDataType.forecast) {
    columns.push({
      title: t('global:actuals_until', { date: '' }),
      dataIndex: 'forecastCursor',
      key: 'forecastCursor',
      width: '15%',
      render: (value: string, record: any) => <ForecastCursorCell key={record.id} company={record} />
    })
  }
  if (!selectedGroup) return null

  return (
    <Table<Company>
      pagination={false}
      rowKey={val => val.id}
      dataSource={[selectedGroup, ...(subsidiaries || [])]}
      columns={columns}
    />
  )
}

export default ScenarioMapTable
