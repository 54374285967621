import { QueryFunctionContext } from '@tanstack/react-query'
import { Company } from '../../../types/company/Company'
import backendRequest from '../../../services/api/backendRequest'
import { Investment } from '../../../types/investment/Investment'
import investmentEndpoints from '../api/investmentEndpoints'
import investmentQueryKeys from '../queries/investmentQueryKeys'
import { DELETE, GET, POST, PUT } from '../../../redux/middleware/types'
import { InvestmentsPageReport } from '../../../redux/pages/investments/types'
import { DepreciationItem } from '../components/InvestmenDeprecationModal'
import { BudgetingScenario } from '../../scenario/types/BudgetingScenario'

export const deleteInvestment = async (companyId: Company['id'], investment: Investment) => {
  return backendRequest<Investment>({
    method: DELETE,
    url: investmentEndpoints.detail(companyId, investment.id)
  })
}

export const getInvestmentSummary = async ({
  queryKey: [{ companyId, date, isExisting, budgetingScenarioId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof investmentQueryKeys)['summary']>>) => {
  return backendRequest<InvestmentsPageReport>({
    method: GET,
    url: investmentEndpoints.summary(companyId),
    params: { date, isExisting, budgetingScenarioId },
    signal
  })
}

export const createInvestment = async (
  companyId: Company['id'],
  investment: Investment,
  budgetingScenarioId?: BudgetingScenario['id']
) => {
  return backendRequest<Investment>({
    method: POST,
    url: investmentEndpoints.list(companyId),
    data: investment,
    params: { budgetingScenarioId }
  })
}

export const updateInvestment = async (companyId: Company['id'], investment: Investment) => {
  return backendRequest<Investment>({
    method: PUT,
    url: investmentEndpoints.detail(companyId, investment.id),
    data: investment
  })
}

export const getInvestment = async (
  companyId: Company['id'],
  investmentId: number,
  budgetingScenarioId?: BudgetingScenario['id']
) => {
  return backendRequest<Investment>({
    method: GET,
    url: investmentEndpoints.detail(companyId, investmentId),
    params: { budgetingScenarioId }
  })
}

export const fetchInvestmentExport = async ({
  companyId,
  date,
  isExisting,
  budgetingScenarioId
}: QueryFunctionContext<ReturnType<(typeof investmentQueryKeys)['summary']>>['queryKey'][0]) => {
  return backendRequest<Blob>({
    method: GET,
    url: investmentEndpoints.export(companyId),
    params: { date, isExisting, budgetingScenarioId },
    responseType: 'blob'
  })
}

export const fetchInvestmentTemplate = async () => {
  return backendRequest<Blob>({
    method: GET,
    url: investmentEndpoints.template(),
    responseType: 'blob'
  })
}

export const createInvestmentImport = async ({
  data,
  companyId,
  budgetingScenarioId
}: {
  data: FormData
  companyId: string
  budgetingScenarioId?: BudgetingScenario['id']
}) => {
  return backendRequest<FormData>({
    headers: { 'Content-Type': 'multipart/form-data' },
    method: POST,
    url: investmentEndpoints.import(companyId),
    params: { budgetingScenarioId },
    data
  })
}

export const createInvestmentDepreciation = async (
  data: Partial<DepreciationItem>,
  companyId: string,
  depreciationId: number,
  budgetingScenarioId?: BudgetingScenario['id']
) => {
  return backendRequest<DepreciationItem>({
    method: POST,
    url: investmentEndpoints.depreciation(companyId, depreciationId),
    params: { budgetingScenarioId },
    data
  })
}

export const getInvestmentDepreciations = async ({
  queryKey: [{ companyId, investmentId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof investmentQueryKeys)['depreciations']>>) => {
  return backendRequest<DepreciationItem[]>({
    method: GET,
    url: investmentEndpoints.depreciations(companyId, investmentId),
    signal
  })
}

export const deleteInvestmentDepreciation = async (
  companyId: Company['id'],
  depreciationId: number,
  correctionId: number
) => {
  return backendRequest<DepreciationItem>({
    method: DELETE,
    url: investmentEndpoints.depreciationDetail(companyId, depreciationId, correctionId)
  })
}

export const updateInvestmentDepreciation = async (
  data: Partial<DepreciationItem>,
  companyId: Company['id'],
  depreciationId: number,
  correctionId: number
) => {
  return backendRequest<DepreciationItem>({
    method: PUT,
    url: investmentEndpoints.depreciationDetail(companyId, depreciationId, correctionId),
    data
  })
}
