import i18next from 'i18next'
import { RuleObject } from 'antd/lib/form'
import { Country } from '../../types/company/Company'

export const isValidVatId: RuleObject['validator'] = (__, id: string) => {
  const trimmedVatId = id.trim()

  const vatIdTests = {
    [Country.fi]: /([0-9]{7}-[0-9]{1})|([0-9]{8})|(FI[0-9]{8})/g,
    [Country.se]: /([0-9]{6}-[0-9]{4})|([0-9]{10})|(SE[0-9]{10})/g
  }

  const matches = Object.entries(vatIdTests).reduce((acc: { [key: string]: any[] | null }, [country, regExp]) => {
    const test = new RegExp(regExp)
    acc[country] = test.exec(trimmedVatId)
    return acc
  }, {})

  const valid = Object.values(matches).some(val => !!val)
  if (valid) {
    return Promise.resolve()
  }
  return Promise.reject(i18next.t('contractsPage:business-id-not-acceptable'))
}
