import { FormItemProps, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ThresholdValueSetting } from '../../../../../../../../redux/context/customReports/typesVariable'
import { FormItem } from '../../../../../../../../components/Form/FormItemContext'

const ThresholdValueSettingSelect = (props: FormItemProps) => {
  const { t } = useTranslation()

  return (
    <FormItem
      key="thresholdValueSetting"
      name={['style', 'thresholdValueSetting']}
      label={t('customReportPage:thresholdValueSetting')}
      {...props}
    >
      <Select
        options={Object.keys(ThresholdValueSetting).map(val => ({
          label: t(`customReportPage:${val}`),
          value: val
        }))}
      />
    </FormItem>
  )
}

export default ThresholdValueSettingSelect
