import { TreeSelect, TreeSelectProps } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { dimensionTreeSelector } from '../../redux/context/dimensions/selectors'
import { useBackend } from '../../services/backend'
import { Company } from '../../types/company/Company'
import { Dimension } from '../../types/dimension/Dimension'
import { getDimensionTree, treeToflatList } from './utils'

interface DimensionSelectProps {
  value?: string | string[]
  companyId?: Company['id']
  treeSelectProps?: TreeSelectProps
  width?: number
  disabled?: boolean
  onChange?: (value: string | string[]) => void
  onBlur?: (value?: string | string[]) => void
  companyDimension?: (dimension?: Dimension) => void
}

const DimensionSelect = React.forwardRef<HTMLSelectElement, DimensionSelectProps>(
  ({ value, companyId, width, onChange, onBlur, companyDimension, treeSelectProps, disabled }, ref: React.Ref<any>) => {
    const { t } = useTranslation()
    const selectedCompanyDimensionTree = useSelector(dimensionTreeSelector)
    const [dimensionTree, setDimensionTree] = useState(selectedCompanyDimensionTree)
    const [dimension, setDimension] = useState<string | string[]>()
    const getOtherCompanyDimensionTree = useBackend('/api/companies/{companyId}/accounting/dimensions')

    // companyDimension palauttaa takaisinpäin valitun dimension koko datan
    useEffect(() => {
      if (companyDimension) {
        const selectedDimension = treeToflatList((dimensionTree as any[]) || [])?.find(d => d.dimensionId === value)
        companyDimension?.(selectedDimension)
      }
    }, [value])

    // jos propsina tulee companyId niin hakee sen yrityksen dimensiot ja palauttaa ne companyDimension fuktiolla
    useEffect(() => {
      if (companyId) {
        getOtherCompanyDimensionTree
          .get({
            urlParams: { companyId }
          })
          .then((response: Dimension[]) => {
            setDimensionTree(getDimensionTree(response))
          })
          .catch((err: any) => {
            console.log(err)
          })
      }
    }, [companyId])

    const triggerChange = (val: string | string[]) => {
      setDimension(val)
      onChange?.(val)
    }

    const handleOnBlur = () => {
      onBlur?.(dimension)
    }

    // päivittää context companyn dimensiotreen kun asiointiroolin vaihtaa
    useEffect(() => setDimensionTree(selectedCompanyDimensionTree), [selectedCompanyDimensionTree])

    return (
      <TreeSelect
        allowClear
        showSearch
        disabled={disabled}
        value={value}
        ref={ref}
        style={{ width: width || '100%' }}
        dropdownStyle={{ minWidth: 350, maxHeight: 500, overflow: 'auto' }}
        fieldNames={{
          label: 'name',
          value: 'dimensionId',
          children: 'children'
        }}
        filterTreeNode={(inputval, node) => {
          return node && node.name?.toLowerCase().includes(inputval?.toLowerCase())
        }}
        treeData={dimensionTree}
        placeholder={t('contextDrawer:not-applied')}
        onChange={triggerChange}
        onBlur={handleOnBlur}
        {...treeSelectProps}
      />
    )
  }
)

export default DimensionSelect
