import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { REQUEST, PutRequestAction, PUT, GetRequestAction, GET } from '../../middleware/types'
import { Adjustment } from '../../../types/valuation/Valuation'
import { Company } from '../../../types/company/Company'
import {
  UPDATE_VALUATION_ADJUSTMENTS,
  UpdateValuationAdjustmentsAction,
  SetValuationAdjustmentsAction,
  SET_VALUATION_ADJUSTMENTS
} from './types'
import { BudgetingScenario } from '../../../features/scenario/types/BudgetingScenario'
import { Store } from '../../types'
import { contextRequest, ContextRequestParams } from '../actions'
import { getValuationRequest } from '../reports/actions'

export const updateAdjustments = (adjustments: Adjustment): UpdateValuationAdjustmentsAction => ({
  type: UPDATE_VALUATION_ADJUSTMENTS,
  payload: adjustments
})

export const setAdjustments = (adjustments: Adjustment): SetValuationAdjustmentsAction => ({
  type: SET_VALUATION_ADJUSTMENTS,
  payload: adjustments
})

export const updateValuationAdjustmentsAndGraph = (
  adjustments: Adjustment
): ThunkAction<void, Store, null, Action<string>> => {
  return dispatch => {
    updateAdjustments(adjustments)
    dispatch(contextRequest(getValuationRequest))
  }
}

export const getValuationAdjustmentRequest = ({ companyId, ...params }: ContextRequestParams): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params,
    url: `/api/companies/${companyId}/reporting/valuation/adjustments`,
    success: (payload: any) => setAdjustments(payload.adjustments)
  },
  meta: {
    type: 'VALUATION_ADJUSTMENT'
  }
})

export const updateValuationAdjustmentRequest = (
  companyId: Company['id'],
  payload: any,
  budgetingScenarioId?: BudgetingScenario['id']
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: payload,
    params: { budgetingScenarioId },
    url: `/api/companies/${companyId}/reporting/valuation/adjustments`,
    success: (response: any) => updateValuationAdjustmentsAndGraph(response.adjustments)
  },
  meta: {
    type: 'VALUATION_ADJUSTMENT'
  }
})
