import { FormItemProps } from 'antd'
import { t } from 'i18next'
import React, { useContext } from 'react'
import { CategoryContext, CategoryContextType } from '../../../../categorySelection/CategoryContext'
import { FormItem } from '../../../../../../../../components/Form/FormItemContext'
import ScenarioCascader from '../../../../../../../../features/scenario/components/cascader/ScenarioCascader'

const DataType = (props: FormItemProps) => {
  const { categoryTree } = useContext(CategoryContext) as CategoryContextType
  const companyId = categoryTree.companies[0]

  return (
    <FormItem key="dataType" name={['params', 'dataType']} label={t('global:dataType')} {...props}>
      <ScenarioCascader companyId={companyId} allowClear style={{ width: 200 }} />
    </FormItem>
  )
}

export default DataType
