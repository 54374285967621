import { Form, Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Name = () => {
  const { t } = useTranslation()
  return (
    <Form.Item
      key="title"
      name="name"
      label={t('customReportPage:title')}
      rules={[{ required: true, message: t('global:required-field') }]}
    >
      <Input />
    </Form.Item>
  )
}

export default Name
