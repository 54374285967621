import { Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Loading from '../shared/components/Loading'
import { fetchConfigs } from '../features/config/service/configService'
import { setAppConfigs } from '../redux/config/actions'
import { fetchEntities } from '../features/entities/service/entitiesService'
import { setEntities } from '../redux/entities/actions'
import RequestError from '../shared/components/RequestError'

type DataManagerProps = {
  children?: React.ReactNode
}

const { Text } = Typography

const DataManager: React.FC<DataManagerProps> = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const handleData = async () => {
    try {
      setIsLoading(true)
      setError(null) // Reset error state

      const configs = await fetchConfigs()
      if (!configs) {
        throw new Error('Failed to load configurations')
      }
      dispatch(setAppConfigs(configs))

      const entities = await fetchEntities()
      if (!entities) {
        throw new Error('Failed to load entities')
      }
      dispatch(setEntities(entities))
    } catch (e) {
      console.error('Data loading error:', e)
      setError(e instanceof Error ? e.message : t('global:dataLoadError', 'Failed to load required data'))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleData()
  }, [dispatch, t])

  if (isLoading) {
    return (
      <Loading>
        <Text type="secondary">{t('global:loadingData')}</Text>
      </Loading>
    )
  }

  if (error) {
    return (
      <RequestError
        error={error}
        refetch={() => {
          setIsLoading(true)
          setError(null)
          handleData()
        }}
      />
    )
  }

  return <div>{children}</div>
}

export default DataManager
