import React, { forwardRef } from 'react'
import { GetRef, Select, SelectProps } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { useTranslation } from 'react-i18next'
import { useScenarios } from '../../queries/useScenarios'
import { BudgetingScenario } from '../../types/BudgetingScenario'
import { defaultOptionsSelector } from '../../selectors/selectors'
import { ReportDataType } from '../../../../redux/context/reports/types'
import ScenarioPlaceholder from './ScenarioPlaceholder'

interface ScenarioSelectProps extends SelectProps {
  companyId?: string
  dataType?: ReportDataType.budget | ReportDataType.forecast
  optionSelector?: (data: BudgetingScenario[]) => DefaultOptionType[]
}

type SelectRefType = GetRef<typeof Select>

const ScenarioSelect = forwardRef<SelectRefType, ScenarioSelectProps>(
  ({ companyId, dataType, optionSelector, ...selectProps }, ref) => {
    const { t } = useTranslation()
    const { data: scenarioOptions, isLoading } = useScenarios({
      companyId,
      select: optionSelector || defaultOptionsSelector,
      dataType
    })

    return (
      <Select
        ref={ref}
        labelRender={({ label }) => (isLoading ? `${t('global:loading')}...` : label ?? t('global:deleted'))}
        loading={isLoading}
        showSearch
        placeholder={<ScenarioPlaceholder dataType={dataType} />}
        style={{ width: '100%', float: 'left' }}
        filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
        options={scenarioOptions}
        {...selectProps}
      />
    )
  }
)

export default ScenarioSelect
