import React from 'react'
import KeyfigureId from './fields/KeyfigureId'
import Id from './fields/Id'
import Name from './fields/Name'
import TranslateFields from '../../../../../../components/Form/TranslateFields'
import IntendLevel from './fields/style/IntendLevel'
import FontStyle from './fields/style/FontStyle'
import FontWeight from './fields/style/FontWeight'
import ThresholdValueSettingSelect from './fields/style/ThresholdValueSettingSelect'
import SeriesStyleFields from '../../chart/SeriesStyleFields'
import Color from './fields/style/Color'
import DataType from './fields/params/DataType'
import Month from './fields/params/Month'
import Offset from './fields/params/Offset'
import ShowEmptyRows from './fields/params/ShowEmptyRows'
import Dimension from './fields/params/Dimension'
import { ContractProduct } from '../../../../../../types/contract/Contract'
import Source from './fields/Source'
import FormulaId from './fields/FormulaId'
import Decimals from './fields/style/Decimals'
import { Authorizable } from '../../../../../../utils/Authorizable/types'
import { RowType } from '../../../../../../redux/context/customReports/typesVariable'
import { SectionType } from '../../../../../../redux/context/customReports/typesSection'

// Define a single field configuration
export interface FieldConfig extends Authorizable {
  key?: string
  element: React.ReactElement
}

export type SectionConfig = Partial<Record<RowType, GroupConfig>>

export interface GroupConfig {
  basic: string[]
  parameters: string[]
  styles: string[]
}

export type MasterConfig = Partial<Record<SectionType, SectionConfig>>

export const keyValueConfig: { [key: string]: FieldConfig } = {
  id: { element: <Id /> },
  keyFigureId: { element: <KeyfigureId /> },
  formulaId: { element: <FormulaId /> },
  source: { element: <Source /> },
  name: { element: <Name /> },
  translateFields: { element: <TranslateFields /> },
  intendLevel: { element: <IntendLevel /> },
  fontStyle: { element: <FontStyle /> },
  fontWeight: { element: <FontWeight /> },
  decimals: { element: <Decimals /> },
  thresholdValueSetting: { element: <ThresholdValueSettingSelect /> },
  seriesStyleFields: { element: <SeriesStyleFields /> },
  color: { element: <Color /> },
  dataType: { element: <DataType /> },
  month: { element: <Month /> },
  offset: { element: <Offset /> },
  showEmptyRows: { element: <ShowEmptyRows /> },
  dimension: {
    element: <Dimension />,
    authority: { product: ContractProduct.PRO }
  }
}

export const masterConfig: MasterConfig = {
  table: {
    formula: {
      basic: ['id', 'formulaId', 'name', 'translateFields'],
      parameters: ['dimension', 'month', 'dataType', 'offset', 'showEmptyRows', 'expandable'],
      styles: ['fontWeight', 'fontStyle', 'decimals', 'indentLevel', 'thresholdValueSetting']
    },
    keyFigure: {
      basic: ['id', 'keyFigureId', 'name', 'translateFields'],
      parameters: ['dimension', 'month', 'dataType', 'offset', 'showEmptyRows', 'expandable'],
      styles: ['fontStyle', 'fontWeight', 'decimals', 'indentLevel', 'thresholdValueSetting']
    },
    source: {
      basic: ['id', 'name', 'source'],
      parameters: ['dimension', 'month', 'dataType', 'offset', 'showEmptyRows', 'expandable'],
      styles: ['fontStyle', 'fontWeight', 'decimals', 'indentLevel', 'thresholdValueSetting']
    },
    empty: { basic: ['id'], parameters: ['showEmptyRows'], styles: [] },
    title: {
      basic: ['id', 'name', 'translateFields'],
      parameters: ['showEmptyRows'],
      styles: ['fontStyle', 'fontWeight', 'indentLevel']
    }
  },
  graph: {
    formula: {
      basic: ['id', 'formulaId', 'name', 'translateFields'],
      parameters: ['dimension', 'month', 'dataType', 'offset', 'showEmptyRows'],
      styles: ['fontStyle', 'fontWeight', 'decimals', 'indentLevel', 'seriesStyleFields']
    },
    keyFigure: {
      basic: ['id', 'keyFigureId', 'name', 'translateFields'],
      parameters: ['dimension', 'month', 'dataType', 'offset', 'showEmptyRows'],
      styles: ['fontStyle', 'fontWeight', 'decimals', 'indentLevel', 'seriesStyleFields']
    },
    source: {
      basic: ['id', 'source', 'name', 'translateFields'],
      parameters: ['dimension', 'month', 'dataType', 'offset', 'showEmptyRows'],
      styles: ['fontStyle', 'fontWeight', 'decimals', 'indentLevel', 'seriesStyleFields']
    }
  },
  pie: {
    formula: {
      basic: ['id', 'formulaId', 'name', 'translateFields'],
      parameters: ['dimension', 'month', 'dataType', 'offset', 'showEmptyRows'],
      styles: ['fontStyle', 'fontWeight', 'decimals', 'indentLevel', 'color']
    },
    keyFigure: {
      basic: ['id', 'keyFigureId', 'name', 'translateFields'],
      parameters: ['dimension', 'month', 'dataType', 'offset', 'showEmptyRows'],
      styles: ['fontStyle', 'fontWeight', 'decimals', 'indentLevel', 'color']
    },
    source: {
      basic: ['id', 'source', 'name'],
      parameters: ['dimension', 'month', 'dataType', 'offset', 'showEmptyRows'],
      styles: ['fontStyle', 'fontWeight', 'decimals', 'indentLevel', 'color']
    }
  }
}
