/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FiscalYear } from '../../types/fiscalYear/FiscalYear'
import { formatValueToNumberByType } from '../../utils/helpers'
import { ReportDataType } from '../../redux/context/reports/types'
import { ReportTableRow, VariableType } from '../../components/Table/types'

export const generateValuationChartCategories = (
  data: ReportTableRow | null,
  contextFiscalYears?: FiscalYear[]
): number[] => {
  const categories = []
  if (data && data.actuals) {
    for (const actual of data[ReportDataType.actuals]) {
      const foundData = contextFiscalYears?.find(
        (fiscalYear: FiscalYear) => fiscalYear.id === actual.fiscalYearId || fiscalYear.id === actual.groupId
      )
      categories.push(foundData ? foundData.year : 0)
    }
    if (data.budget) {
      for (const forecast of data[ReportDataType.budget]) {
        const foundData = contextFiscalYears?.find(
          (fiscalYear: FiscalYear) => fiscalYear.id === forecast.fiscalYearId || fiscalYear.id === forecast.groupId
        )
        categories.push(foundData ? foundData.year : 0)
      }
    }
  }
  return categories.sort((a, b) => (a > b ? 1 : -1))
}

export const generateValuationChartSeries = (data: ReportTableRow | null): (number | null)[] => {
  const series = []
  if (data && data.actuals) {
    for (const actual of data.actuals) {
      series.push(formatValueToNumberByType(actual.value, VariableType.absolute))
    }
    if (data.budget) {
      for (const forecast of data[ReportDataType.budget]) {
        series.push(formatValueToNumberByType(forecast.value, VariableType.absolute))
      }
    }
  }
  return series
}

export const transformInitial = (data: ReportTableRow) => {
  return {
    ...data,
    wages: data.wages?.reduce((acc: any, { fiscalYearId, value }: any) => {
      return { ...acc, [fiscalYearId]: value }
    }, {}),
    other: data.other?.reduce((acc: any, { fiscalYearId, value }: any) => {
      return { ...acc, [fiscalYearId]: value }
    }, {})
  }
}
