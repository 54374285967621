import { BudgetingScenario } from '../../../../features/scenario/types/BudgetingScenario'
import { Company } from '../../../../types/company/Company'
import { Dimension } from '../../../../types/dimension/Dimension'
import { Formula } from '../../../../types/formula/Formula'
import { Report, ReportRow } from '../../../context/reports/types'

export const ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS = 'ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS'
export const SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD = 'SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD'
export const ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS = 'ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS'
export const SET_MULTI_PURPOSE_DASHBOARD_FORMULAS = 'SET_MULTI_PURPOSE_DASHBOARD_FORMULAS'
export const RESET_MULTI_PURPOSE_DASHBOARD = 'RESET_MULTI_PURPOSE_DASHBOARD'
export const RESET_MULTI_PURPOSE_DASHBOARD_REPORTS = 'RESET_MULTI_PURPOSE_DASHBOARD_REPORTS'
export const SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS =
  'SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS'
export const ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS =
  'ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS'
export const SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT =
  'SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT'
export const SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT = 'SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT'
export const SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT =
  'SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT'
// Page data
export interface MultiPurposeDashboardPage {
  activeDashboard?: number
  keyfigureStatements: Record<string, ReportRow[]>
  internalKeyfigureStatements: Record<string, ReportRow[]>
  dimensionKeyfigureStatements: Record<string, ReportRow[]>
  formulas: Record<string, Formula[]>
  dimensions: Record<string, Dimension[]>
}

// Actions
export interface AddMultiPurposeDashboardFormulasAction {
  type: typeof ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS
  payload: { companyId: Company['id']; formulas: Formula[] }
}

export interface AddMultiPurposeDashboardDimensionsAction {
  type: typeof ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS
  payload: { companyId: Company['id']; dimensions: Dimension[] }
}

export interface SetMultiPurposeDashboardFormulasAction {
  type: typeof SET_MULTI_PURPOSE_DASHBOARD_FORMULAS
  payload: Record<string, Formula[]>
}

export interface ResetMultiPurposeDashboardAction {
  type: typeof RESET_MULTI_PURPOSE_DASHBOARD
}

export interface ResetMultiPurposeDashboardReportsAction {
  type: typeof RESET_MULTI_PURPOSE_DASHBOARD_REPORTS
}

export interface SetMultiPurposeDashboardsActiveDashboardAction {
  type: typeof SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD
  payload: number | undefined
}

export interface SetAllMultiPurposeDashboardKeyfigureStatementAction {
  type: typeof SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT
  payload: { dimensionId?: string; budgetingScenarioId?: BudgetingScenario['id']; data: Report }[]
}

export interface SetMultiPurposeDashboardInternalKeyfigureStatementAction {
  type: typeof SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT
  payload: { id: string; data: Report }
}

export interface SetAllMultiPurposeDashboardInternalKeyfigureStatementAction {
  type: typeof SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT
  payload: { dimensionId: string; data: Report }[]
}

export interface SetMultiPurposeDashboardAllKeyfigureStatementsAction {
  type: typeof SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS
  payload: Record<string, ReportRow[]>
}

export interface AddMultiPurposeDashboardDimensionKeyfigureStatementsAction {
  type: typeof ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS
  payload: Record<string, ReportRow[]>
}

export type MultiPurposeDashboardActionTypes =
  | AddMultiPurposeDashboardDimensionKeyfigureStatementsAction
  | AddMultiPurposeDashboardDimensionsAction
  | AddMultiPurposeDashboardFormulasAction
  | ResetMultiPurposeDashboardAction
  | ResetMultiPurposeDashboardReportsAction
  | SetAllMultiPurposeDashboardInternalKeyfigureStatementAction
  | SetAllMultiPurposeDashboardKeyfigureStatementAction
  | SetMultiPurposeDashboardAllKeyfigureStatementsAction
  | SetMultiPurposeDashboardFormulasAction
  | SetMultiPurposeDashboardInternalKeyfigureStatementAction
  | SetMultiPurposeDashboardsActiveDashboardAction
