import { useMutation } from '@tanstack/react-query'
import { App } from 'antd'
import { useTranslation } from 'react-i18next'
import { createJiraRequest } from '../service/jiraService'

export const useCreateJiraRequest = () => {
  const { t } = useTranslation()
  const { notification } = App.useApp()

  return useMutation({
    mutationFn: (data: FormData) => createJiraRequest(data),
    onSuccess: () => {
      notification.success({
        message: t('jiraRequestModal:successMessage', 'Request sent')
      })
    }
  })
}
