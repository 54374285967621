import _ from 'lodash'
import dayjs from 'dayjs'
import { contextAccountMapRulesGroupedByStatementRowIdSelector } from '../../redux/context/accountMapRules/selectors'
import { Column } from '../../redux/context/periodGroups/hooks'
import { ReportDataType } from '../../redux/context/reports/types'
import { store } from '../../redux/store'
import { vouchersEnabled } from '../../routes/utils'
import { AccountMapRule } from '../../types/accountMapRule/AccountMapRule'
import { ReportTableRow } from '../Table/types'
import { VoucherQueryParams } from '../../pages/reporting/vouchers/types'
import { currentUserSelector } from '../../redux/session/currentUser/selectors'

export const getStatementRowAccounts = (r: ReportTableRow): Number[] => {
  const state = store.getState() as any
  const accountMapRules = contextAccountMapRulesGroupedByStatementRowIdSelector(state)

  if (typeof r?.code === 'number') {
    return [r?.code]
  }
  const rules = accountMapRules[r.id] as AccountMapRule[] | undefined

  const statementRowAccounts = rules?.reduce((accounts, { from, to }) => {
    const values = _.range(from, to)
    return [...accounts, ...values]
  }, [] as Number[])
  return _.uniq(statementRowAccounts)
}

export const showVoucherMenu = (row: ReportTableRow, column: Column) => {
  const state: any = store.getState()
  const { permissions, roles } = currentUserSelector(state)
  const accounts = getStatementRowAccounts(row)

  if (column.dataType !== ReportDataType.actuals) return false
  if (row.rowType === 'budgetMenu') return false
  if (!vouchersEnabled()) return false
  if (accounts.length <= 0) return false

  if (roles.map(r => r.label).includes('superuser')) return true
  if (!permissions?.map(p => p.label).includes('read-vouchers')) return false

  return true
}

export const getRequestParams = (p: Partial<VoucherQueryParams>) => {
  return {
    ...p,
    invoicePartnerId: (p?.invoicePartnerId && p?.invoicePartnerId.length > 0 && p.invoicePartnerId) || undefined,
    from: p?.from ? dayjs(p?.from).format('YYYY-MM-DD') : undefined,
    until: p?.until ? dayjs(p?.until).format('YYYY-MM-DD') : undefined,
    invoiceNumber: p.invoiceNumber || undefined,
    transactionDesc: p.transactionDesc || undefined,
    voucherDesc: p.voucherDesc?.[0] || undefined,
    guid: p.guid?.[0] || undefined,
    accounts: (p?.accounts || [])?.length > 0 ? p.accounts : undefined,
    voucherType: (p?.voucherType && p?.voucherType.length > 0 && p.voucherType) || undefined
  }
}
