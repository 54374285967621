import { Company, CompanyAcquisition } from '../../../types/company/Company'

export const flattenGroup = (company: Company): Company[] => {
  // Start with the company and its group if present
  const baseCompanies = [company, company.group].filter(Boolean) as Company[]
  const allSubsidiaries = baseCompanies.slice()

  // Process all subsidiaries
  company.subsidiaries?.forEach((subsidiary: Company) => {
    const latestAcquisition = subsidiary.acquisitions?.reduce<CompanyAcquisition | undefined>(
      (latest, acq) => (!latest || new Date(acq.date) > new Date(latest.date) ? acq : latest),
      undefined
    )

    // Skip subsidiaries with 0% ownership in latest acquisition
    if (latestAcquisition?.percentage === 0) {
      return
    }

    // Add the subsidiary and its children
    const flattenedSubsidiaries = flattenGroup(subsidiary)
    for (const sub of flattenedSubsidiaries) {
      allSubsidiaries.push(sub)
    }
  })

  // Filter out companies that belong to a group
  return allSubsidiaries.filter(({ group }) => !group)
}
