import { queryOptions, UseQueryOptions } from '@tanstack/react-query'
import { fetchBudgetingScenarios } from '../../service/budgetingScenarioService'
import budgetingScenarioQueryKeys from './budgetingScenarioQueryKeys'
import { sortById } from '../../../../utils/helpers'
import { BudgetingScenario } from '../../types/BudgetingScenario'
import { normalizeScenario } from '../../utils/scenarioUtils'

type UseBudgetingScenarioOptionsProps<TData> = {
  companyId: string
  select?: (data: BudgetingScenario[]) => TData
}

export const useBudgetingScenarioOptions = <TData = BudgetingScenario[]>({
  companyId,
  select
}: UseBudgetingScenarioOptionsProps<TData>) => {
  return queryOptions({
    queryKey: budgetingScenarioQueryKeys.list(companyId),
    queryFn: fetchBudgetingScenarios,
    gcTime: 15 * 60 * 1000,
    enabled: !!companyId,
    select: data => {
      const sortedData = sortById(data)
      const normalizedData = sortedData.map(normalizeScenario)

      return select ? select(normalizedData) : normalizedData
    }
  }) as UseQueryOptions<TData>
}
