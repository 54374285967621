import dayjs, { Dayjs } from 'dayjs'
import { FiscalYear, Period } from '../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../types/periodGroup/PeriodGroup'

export const getFiscalYearsToShow = (
  fiscalYears: FiscalYear[] = [],
  lastClosedFiscalYear?: FiscalYear | null,
  currenFiscalYear?: FiscalYear | null
): FiscalYear[] => {
  const f = lastClosedFiscalYear || currenFiscalYear
  if (!f) return []
  return fiscalYears?.filter(({ year }) => {
    return year >= f.year - 3 && year < f.year + 5
  })
}

// Filtteroi annetuista tilikausista kuukaudet halutuilla parametreillä
export const getPartialFiscalYears = (month: number, length: number, fiscalYears: FiscalYear[]): PeriodGroup[] => {
  return fiscalYears.map((f, i) => {
    return {
      id: Date.now() + i + 1,
      startDate: dayjs(f.startDate).format('YYYY-MM-DD'), // väärin
      endDate: dayjs(f.startDate)
        .add(length - 1, 'M')
        .endOf('M')
        .format('YYYY-MM-DD'),
      periods: f.periods.filter(p => {
        return p.month >= month && p.month < month + length
      })
    }
  })
}

// Antaa kuukausia päivämäärien väliltä
export const enumerateDaysBetweenDates = (
  startDate: string | Dayjs | null,
  endDate: string | Dayjs | null
): Dayjs[] => {
  const dates: Dayjs[] = []
  if (startDate === null || endDate === null) return dates
  const start = dayjs(startDate).startOf('month')
  const end = dayjs(endDate).startOf('month')

  let loop = dayjs(start)
  do {
    dates.push(loop.clone())
    loop = dayjs(loop).add(1, 'month')
  } while (loop.diff(end) <= 0)

  return dates
}

export const generatePeriodGroupPeriods = (periodGroup: PeriodGroup): Period[] => {
  if (!periodGroup?.startDate && !periodGroup?.endDate && !periodGroup) return []

  const periods = enumerateDaysBetweenDates(periodGroup.startDate, periodGroup.endDate)

  return periods.map((period, index) => {
    return {
      period: index + 1,
      year: period.year(),
      month: period.month() + 1
    }
  })
}

// Periodgroupin luonti alku ja loppupäivämäärällä
export const customPeriodGroup = (start: Dayjs, end: Dayjs): PeriodGroup => {
  const periodId = Date.now()
  const startDate = start.startOf('month').format('YYYY-MM-DD')
  const endDate = end.endOf('month').format('YYYY-MM-DD')
  const periodGroup = {
    id: periodId,
    startDate,
    endDate,
    periods: generatePeriodGroupPeriods({
      id: periodId,
      startDate,
      endDate
    } as PeriodGroup)
  }

  return periodGroup
}

// Neljännes periodGrouppien luonti vuosiluvulla
export const yearQuarters = (year: number): PeriodGroup[] => {
  const date = dayjs({ year }).startOf('year')
  const quarters = [0, 1, 2, 3].map(i => {
    const startDate = date.add(i, 'Q').startOf('quarter')
    const enDate = date.add(i, 'Q').endOf('quarter')
    const id = Date.now() + i
    return {
      ...customPeriodGroup(startDate, enDate),
      id
    }
  })
  return quarters
}

// Muuntaa tilikaudet halutulle alkupäivämäärälle, ja pituudelle
// Ei huomioi alkuperäisen tilikauden kuukausia
export const periodGroupsFromFiscalYears = (
  month: number,
  length: number,
  fiscalYears: FiscalYear[]
): PeriodGroup[] => {
  return fiscalYears.map((f, i) => {
    const id = Date.now() + i + 1
    const startDate = dayjs({
      year: dayjs(f.startDate).year(),
      month
    }).startOf('M')
    const endDate = startDate
      .clone()
      .add(length - 1, 'M')
      .endOf('M')
    return { ...customPeriodGroup(startDate, endDate), id, budgetable: f.budgetable }
  })
}

// Tekee periodGroupista vertailukauden
// Siirtää alku- ja loppupäivää vuodella taaksepäinj ja kääntää id:n miinusmerkkiseksi
export const comparativePeriodGroup = (periodGroup: PeriodGroup): PeriodGroup => {
  return {
    id: -periodGroup.id,
    startDate: dayjs(periodGroup.startDate).subtract(1, 'year').format('YYYY-MM-DD'),
    endDate: dayjs(periodGroup.endDate).subtract(1, 'year').format('YYYY-MM-DD'),
    periods: periodGroup.periods.map(({ year, ...rest }) => ({ ...rest, year: year - 1 }))
  }
}
