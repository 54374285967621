import { Button, Menu } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItemType } from 'antd/es/menu/interface'
import { DownloadOutlined } from '@ant-design/icons'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { periodGroupLabel } from '../../redux/context/fiscalYears/utils'
import { ReportDataType } from '../../redux/context/reports/types'
import { notificationAction } from '../../redux/middleware/actions'
import { useBackend } from '../../services/backend'
import { Period } from '../../types/fiscalYear/FiscalYear'
import { VoucherQueryParams, VoucherQueryResult } from '../../pages/reporting/vouchers/types'
import VoucherTable from './VoucherTable'

import './_VouchersModal.sass'
import { ReportTableRow } from '../Table/types'
import { Column } from '../../redux/context/periodGroups/hooks'
import { getRequestParams, getStatementRowAccounts } from './utils'
import { AppDispatch } from '../../redux/store'
import ToolBar from '../Misc/ToolBar'
import { useCtxReportablePeriodGroups } from '../../features/fiscalYear/hooks/useCtxReportablePeriodGroups'

export interface VouchersModalProps {
  row: ReportTableRow
  column: Column
}

const VouchersModalContent: React.FC<VouchersModalProps> = ({ row, column }) => {
  const dispatch: AppDispatch = useDispatch()

  const groups = useCtxReportablePeriodGroups()
  const companyId = useSelector(contextCompanyIdSelector)

  const [voucherQueryResult, setVoucherQueryResult] = useState<Partial<VoucherQueryResult>>()
  const [openKeys, setOpenKeys] = useState([`${groups?.[0]?.id}`])
  const [params, setParams] = useState<Partial<VoucherQueryParams>>()
  const [selectedPeriod, setPeriod] = useState<Period>()

  const xlsxRequest = useBackend(`/reporting/vouchers/xlsx`, process.env.REACT_APP_INTEGRATION_URL)
  const vouchersRequest = useBackend(`/reporting/vouchers`, process.env.REACT_APP_INTEGRATION_URL)
  const { loading } = vouchersRequest
  const rowAccounts = useMemo(() => getStatementRowAccounts(row), [row])

  const request = (p: Partial<VoucherQueryParams>) => {
    const requestParams = getRequestParams(p)
    vouchersRequest
      .get({
        body: {
          params: {
            params: Object.fromEntries(Object.entries(requestParams).filter(([, value]) => !!value)),
            companyId
          }
        }
      })
      .then(setVoucherQueryResult)
      .catch((e: Error) => {
        setVoucherQueryResult({})
        dispatch(
          notificationAction({
            type: 'error',
            message: 'ERROR',
            description: e.message
          })
        )
      })
  }

  const getSelectedKeys = () => {
    if (!selectedPeriod) return []
    return [`${selectedPeriod.month} / ${selectedPeriod.year}`]
  }

  const handlePropsUpdate = () => {
    if (column) {
      if (column.periodGroup !== undefined) {
        const periods = column.periodGroup?.periods

        if (periods.length) {
          setPeriod(periods[0])
          setOpenKeys([column.periodGroup.id.toString()])
        }
      } else {
        for (const g of groups) {
          const { periods } = g
          const period = periods.find((p: Period) => `${p.month} / ${p.year}` === column?.label)
          if (period) {
            setPeriod(period)
            setOpenKeys([g.id.toString()])
          }
        }
      }
    }
    return () => {
      setPeriod(undefined)
      setOpenKeys([])
    }
  }

  const updateParams = () => {
    if (selectedPeriod && rowAccounts) {
      setParams({
        ...params,
        page: 1,
        from: dayjs({ year: selectedPeriod?.year, month: selectedPeriod?.month - 1 })
          .startOf('month')
          .format('YYYY-MM-DD'),
        until: dayjs({ year: selectedPeriod?.year, month: selectedPeriod?.month - 1 })
          .endOf('month')
          .format('YYYY-MM-DD'),
        accounts: rowAccounts
      })
    }
  }

  useEffect(handlePropsUpdate, [column, row])
  useEffect(updateParams, [selectedPeriod, rowAccounts])
  useEffect(() => params && request(params), [params])

  const onOpenChange = (newOpenKeys: any[]) => {
    const latestOpenKey = newOpenKeys.find(key => openKeys.indexOf(key) === -1)
    const rootSubmenuKeys = (groups as any[]).map(g => g.id)
    if (rootSubmenuKeys.indexOf(latestOpenKey) !== -1) {
      setOpenKeys(openKeys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const getMenuItems = (): MenuItemType[] => {
    return (groups as any[]).map((group: any) => {
      const periods = group?.periods
      return {
        key: group.id,
        label: periodGroupLabel(group, ReportDataType.actuals),
        children: periods.map((period: any) => {
          return {
            key: `${period.month} / ${period.year}`,
            label: `${period.month} / ${period.year}`,
            onClick: () => {
              setPeriod(period)
            }
          }
        })
      }
    })
  }

  const generateMenu = () => (
    <Menu
      selectedKeys={getSelectedKeys()}
      subMenuCloseDelay={100}
      selectable
      openKeys={openKeys}
      mode="inline"
      onOpenChange={onOpenChange}
      style={{ width: 184 }}
      items={getMenuItems()}
    />
  )

  const xlsx = (p: Partial<VoucherQueryParams>) => {
    /**
     * AMISKIKKA
     * Joo tämmönen säätö piti tehä, muuten tulee korruptoitunu tiedosto
     * Eli bäkki kääntää bufferin base64 ja tää sit siitä takasi
     */
    const base64ToArrayBuffer = (base64: string) => {
      const binaryString = window.atob(base64)
      const len = binaryString.length
      const bytes = new Uint8Array(len)
      for (let i = 0; i < len; i += 1) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      return bytes.buffer
    }
    const requestParams = getRequestParams(p)
    xlsxRequest
      .get({ body: { params: { params: requestParams, companyId } } })
      .then((response: BlobPart) => {
        const url = window.URL.createObjectURL(new Blob([base64ToArrayBuffer(response as string)]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'transactions.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => {
        dispatch(notificationAction({ type: 'error', message: 'ERROR' }))
      })
  }

  return (
    <div className="voucher-modal-wrapper">
      <div className="voucher-modal-menu">{generateMenu()}</div>
      <div className="voucher-modal-content">
        <ToolBar justifyContent="right">
          <Button
            type="default"
            icon={<DownloadOutlined />}
            loading={xlsxRequest.loading}
            onClick={() => params && xlsx(params)}
          >
            Excel
          </Button>
        </ToolBar>
        <VoucherTable
          filtersEnabled
          loading={loading}
          dataSource={voucherQueryResult}
          params={params}
          setParams={setParams}
        />
      </div>
    </div>
  )
}

export default VouchersModalContent
