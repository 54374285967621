/* eslint-disable no-underscore-dangle */
import { CloseOutlined, InboxOutlined } from '@ant-design/icons'
import { Button, Flex, Form, Input, Modal, Result, Select, TreeSelect, Upload } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { RcFile, UploadFile } from 'antd/es/upload'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { contextCompanySelector } from '../../../redux/context/company/selectors'
import { userCompaniesSelector } from '../../../redux/session/currentUser/selectors'
import { useBackend } from '../../../services/backend'
import { BudgetingScenario } from '../../scenario/types/BudgetingScenario'
import { Company } from '../../../types/company/Company'
import { Dimension } from '../../../types/dimension/Dimension'
import { CreateServicedeskRequestBody, JiraServicedeskRequestFormFields } from '../../../types/jira/jira'
import { useAuthority } from '../../../utils/Authorizable/authorize'
import { getDimensionTree } from '../../../components/Dimension/utils'
import { buildComponentTree } from '../utils'
import { useUseJiraComponents } from '../queries/useJiraComponents'
import { useUseJiraRequestTypes } from '../queries/useJiraRequestTypes'
import { useCreateJiraRequest } from '../queries/useCreateJiraRequest'
import { useCompaniesSearch } from '../../company/queries/useCompaniesSearch'
import { useScenarios } from '../../scenario/queries/useScenarios'

interface JiraRequestModalProps {
  open: boolean
  onCreate: () => void
  onCancel: () => void
}

const { REACT_APP_JIRA_COMPANY_FIELD, REACT_APP_JIRA_DIMENSION_FIELD, REACT_APP_JIRA_SCEANRIO_AND_FORECAST_FIELD } =
  process.env

export const JiraRequestModal: React.FC<JiraRequestModalProps> = ({ open, onCreate, onCancel }) => {
  const [form] = useForm<JiraServicedeskRequestFormFields>()
  const companies = useSelector(userCompaniesSelector)
  const contextCompany = useSelector(contextCompanySelector)
  const { t } = useTranslation()
  const dimensionRequest = useBackend('/api/companies/{companyId}/accounting/dimensions')
  const [files, setFiles] = useState<UploadFile[]>([])
  const [selectedCompany, setSelectedCompany] = useState<Company>()
  const [dimensions, setDimensions] = useState<Dimension[]>([])
  const isSuperUser = useAuthority({
    role: 'superuser'
  })
  const [companyVatId, setCompanyVatId] = useState<string>()
  const companyValue = Form.useWatch(['requestFieldValues', REACT_APP_JIRA_COMPANY_FIELD], form)

  const { data: scenarios, isPending: scenariosPending } = useScenarios({ companyId: selectedCompany?.id })
  const { data: jiraComponents, isFetching: jiraComponentsFetching } = useUseJiraComponents()
  const {
    data: jiraRequestTypes,
    isFetching: jiraRequestTypesFetching,
    error: jiraRequestTypesError
  } = useUseJiraRequestTypes()
  const { mutateAsync: createJiraRequest, isPending: createJiraRequestPending } = useCreateJiraRequest()
  const { data: companySearchData, isLoading: companySearchLoading } = useCompaniesSearch({
    vatId: companyVatId?.replace('-', '').trim()
  })

  const customFieldTransform = (item?: Dimension | BudgetingScenario | Company): string | undefined => {
    if (!item) return undefined
    return `${item.name} ( ${item.id} )`
  }

  const handleCompanyChange = (company?: Company) => {
    setSelectedCompany(company)
    form.setFieldsValue({
      requestFieldValues: {
        [REACT_APP_JIRA_COMPANY_FIELD]: company ? customFieldTransform(company) : undefined,
        [REACT_APP_JIRA_DIMENSION_FIELD]: undefined,
        [REACT_APP_JIRA_SCEANRIO_AND_FORECAST_FIELD]: undefined
      }
    })
  }

  const selectOptionTransform = (item: Dimension | BudgetingScenario | Company) => ({
    key: item.id,
    value: customFieldTransform(item),
    label: item.name
  })

  const getDimensionData = async (id: string) => {
    try {
      const dimensionResponse = await dimensionRequest.get({
        urlParams: {
          companyId: id
        }
      })
      setDimensions(getDimensionTree(dimensionResponse))
    } catch (error) {
      setDimensions([])
    }
  }

  useEffect(() => {
    const [first] = companySearchData || []
    if (first) {
      handleCompanyChange(first)
    }
  }, [companySearchData])

  useEffect(() => {
    if (open) {
      contextCompany?.id && handleCompanyChange(contextCompany)
    }
  }, [open])

  useEffect(() => {
    if (selectedCompany?.id && open) {
      getDimensionData(selectedCompany?.id)
    }
  }, [selectedCompany])

  const transformFields = (values: JiraServicedeskRequestFormFields): CreateServicedeskRequestBody => {
    // FIXME: components tulee yksikkönä ja pitää lähteä taulukkona, tarvitseeeko tehdä muutos jira integraation
    const data: CreateServicedeskRequestBody = {
      ...values,
      requestFieldValues: {
        ...values.requestFieldValues,
        components: values?.requestFieldValues?.components ? [{ id: values.requestFieldValues.components }] : undefined
      }
    }
    return data
  }

  interface TreeSelectOptionType {
    value?: string | number
    title?: React.ReactNode
    label?: React.ReactNode
    key?: React.Key
    children?: TreeSelectOptionType[]
  }

  const dimensionMap = ({ children, ...o }: Dimension): TreeSelectOptionType => ({
    value: customFieldTransform(o),
    title: o.name,
    children: children?.map(dimensionMap) // recursive call
  })

  const options = {
    name: 'file',
    multiple: true,
    fileList: files,
    onRemove: (item: UploadFile) => {
      setFiles(files.filter(f => f.name !== item.name))
    },
    beforeUpload: (file: UploadFile, allFiles: UploadFile[]) => {
      setFiles([...files, ...allFiles])
      return false
    }
  }

  return (
    <Modal
      open={open}
      title={t('jiraRequestModal:modalTitle')}
      width={700}
      maskClosable={false}
      okText={t('global:send')}
      cancelText={t('global:cancel')}
      onCancel={() => {
        form.resetFields()
        setCompanyVatId(undefined)
        setSelectedCompany(undefined)
        setFiles([])
        onCancel()
      }}
      confirmLoading={createJiraRequestPending}
      onOk={async () => {
        try {
          const values = await form.validateFields()
          const transformedData = transformFields(values)
          const formData = new FormData()
          files.forEach((file: UploadFile) => {
            formData.append('file', file as RcFile)
          })
          formData.append('data', JSON.stringify(transformedData))

          await createJiraRequest(formData)

          form.resetFields()
          setCompanyVatId(undefined)
          setSelectedCompany(undefined)
          setFiles([])
          onCreate()
        } catch (error) {
          console.log('Validate Failed:', error)
        }
      }}
    >
      {jiraRequestTypesError ? (
        <Result
          status="warning"
          title={t('jiraRequestModal:warningTitle')}
          subTitle={t('jiraRequestModal:warningSubTitle')}
          extra={process.env.REACT_APP_SUPPORT_EMAIL}
        />
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
            required
            name="requestTypeId"
            label={t('jiraRequestModal:requestType')}
            labelAlign="left"
          >
            <Select loading={jiraRequestTypesFetching}>
              {jiraRequestTypes?.map(requestType => {
                return (
                  <Select.Option value={requestType.id} key={requestType.id}>
                    <Flex align="center" gap={8}>
                      <img
                        style={{
                          height: '1rem'
                        }}
                        src={requestType.icon._links.iconUrls['16x16']}
                        alt="icon"
                      />
                      <span>{t(`jiraRequestType:${requestType.id}`)}</span>
                    </Flex>
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
            required
            name={['requestFieldValues', 'summary']}
            label={t('jiraRequestModal:summary')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
            required
            name={['requestFieldValues', 'description']}
            label={t('global:description')}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('global:required-field')
              }
            ]}
            required
            name={['requestFieldValues', 'components']}
            label={t('jiraRequestModal:components')}
          >
            <TreeSelect
              loading={jiraComponentsFetching}
              treeDefaultExpandAll
              treeData={buildComponentTree(jiraComponents)}
              treeTitleRender={item => {
                return t(`menu:${item.title}`)
              }}
            />
          </Form.Item>
          {isSuperUser ? (
            <>
              <Form.Item label={t('contractsPage:vatId')}>
                <Input.Search
                  allowClear
                  enterButton
                  placeholder={t('contractsPage:vatId')}
                  onSearch={setCompanyVatId}
                  onClear={() => setCompanyVatId(undefined)}
                  loading={companySearchLoading}
                  style={{
                    width: 200
                  }}
                />
              </Form.Item>
              <Form.Item label={t('global:company')}>
                <span className="ant-form-text">{companyValue}</span>
                {companyValue && <Button type="text" icon={<CloseOutlined />} onClick={() => handleCompanyChange()} />}
              </Form.Item>
            </>
          ) : (
            <Form.Item label={t('global:company')}>
              <Select
                value={selectedCompany?.id}
                showSearch
                optionFilterProp="children"
                onChange={(id: string) => {
                  const newCompany = companies.find(c => c.id === id)
                  handleCompanyChange(newCompany)
                }}
                options={companies.map(c => ({
                  key: c.id,
                  value: c.id,
                  label: c.name
                }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          )}
          <Form.Item
            hidden
            shouldUpdate
            name={['requestFieldValues', REACT_APP_JIRA_COMPANY_FIELD]}
            label={t('global:dimension')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            shouldUpdate
            name={['requestFieldValues', REACT_APP_JIRA_DIMENSION_FIELD]}
            label={t('global:dimension')}
          >
            <TreeSelect
              allowClear
              showSearch
              loading={dimensionRequest.loading}
              treeData={dimensions.map(dimensionMap)}
            />
          </Form.Item>
          <Form.Item
            name={['requestFieldValues', REACT_APP_JIRA_SCEANRIO_AND_FORECAST_FIELD]}
            label={t('global:scenarioSlashForecast')}
          >
            <Select loading={scenariosPending} allowClear options={scenarios.map(selectOptionTransform)} />
          </Form.Item>

          <Upload.Dragger {...options}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('jiraRequestModal:upload')}</p>
            <p className="ant-upload-hint">{t('jiraRequestModal:uploadInfo')}</p>
          </Upload.Dragger>
        </Form>
      )}
    </Modal>
  )
}
