import React, { useEffect, useMemo } from 'react'
import { Radio, Typography, Form, Alert, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LoadingWrapper from '../../../../../components/Misc/LoadingWrapper'
import { normalizedConfigSelector } from '../../../../../redux/config/selectors'
import AccountSelect from '../../../../../features/account/components/AccountSelect'
import { useCtxCompanyGroupSettings } from '../../../../../features/companyGroup/queries/companyGroupSetting/useCtxCompanyGroupSettings'
import { useCtxUpdateCompanyGroupSettings } from '../../../../../features/companyGroup/queries/companyGroupSetting/useCtxUpdateCompanyGroupSettings'
import {
  GroupSettingData,
  GroupSettingDataSource
} from '../../../../../features/companyGroup/types/CompanyGroupSettings'

const Figures = () => {
  const [form] = Form.useForm<GroupSettingData>()
  const { t } = useTranslation()
  const budgetSource = Form.useWatch(['budget', 'source'], form)
  const actualsSource = Form.useWatch(['actuals', 'source'], form)
  const {
    financialStatement: { plAssociateProfitRowId, plMinoritySharesRowId, bsMinoritySharesRowId, bsAssociateSharesRowId }
  } = useSelector(normalizedConfigSelector)

  const { data: groupSettings, isLoading } = useCtxCompanyGroupSettings()
  const { mutate: updateGroupSettings } = useCtxUpdateCompanyGroupSettings()

  const accountsRequired = useMemo(
    () => [budgetSource, actualsSource].includes(GroupSettingDataSource.subsidiaries),
    [actualsSource, budgetSource]
  )

  const groupSettingsConfig = {
    plAssociateProfitAccountCode: plAssociateProfitRowId,
    plMinoritySharesAccountCode: plMinoritySharesRowId,
    bsMinoritySharesAccountCode: bsMinoritySharesRowId,
    bsAssociateSharesAccountCode: bsAssociateSharesRowId
  }

  const handleSubmit = (data: GroupSettingData) => {
    updateGroupSettings(data)
  }

  const getInitValues = () => {
    if (groupSettings) {
      form.setFieldsValue(groupSettings)
    }
  }

  useEffect(getInitValues, [groupSettings])

  return (
    <LoadingWrapper loading={isLoading}>
      <Form
        onFinish={handleSubmit}
        onValuesChange={(changed, allValues) => handleSubmit(allValues)}
        initialValues={groupSettings}
        form={form}
        style={{ maxWidth: 600 }}
        layout="vertical"
      >
        <Typography style={{ marginTop: 24 }}>
          <Form.Item label={t('subsidiaries:groupActuals')} name={['actuals', 'source']} rules={[{ required: true }]}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={GroupSettingDataSource.group}>{t('subsidiaries:integration')}</Radio>
                <Radio value={GroupSettingDataSource.subsidiaries}>{t('subsidiaries:addUp')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t('subsidiaries:groupBudget')} name={['budget', 'source']} rules={[{ required: true }]}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={GroupSettingDataSource.group}>{t('subsidiaries:enter')}</Radio>
                <Radio value={GroupSettingDataSource.subsidiaries}>{t('subsidiaries:addUp')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {budgetSource === GroupSettingDataSource.subsidiaries ? (
            <Alert
              style={{ marginBottom: 16 }}
              message={t('global:alertNoteTitle')}
              description={t('subsidiaries:budgetingInfoText')}
              type="info"
            />
          ) : null}
          {accountsRequired
            ? Object.entries(groupSettingsConfig).map(([key, value]) => (
                <Form.Item label={t(`subsidiaries:${key}`)} name={key}>
                  <AccountSelect allowClear balanceSheetRowIds={value} />
                </Form.Item>
              ))
            : null}
          <Typography.Title level={5}>{t('subsidiaries:groupBalanceSheet')}</Typography.Title>
          <Typography.Paragraph>{t('subsidiaries:balanceSheetIncomestatement')}</Typography.Paragraph>
        </Typography>
      </Form>
    </LoadingWrapper>
  )
}

export default Figures
