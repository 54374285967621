import { useMutation, useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createCustomReport } from '../../service/customReportService'
import { CustomReport } from '../../../../redux/context/customReports/types'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { setCalculatedReport } from '../../../../redux/context/customReports/actions'
import customReportQueryKeys from '../customReportQueryKeys'

export const useCtxCreateCustomReport = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { notification } = App.useApp()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: Partial<CustomReport>) => createCustomReport(data, companyId),
    onSuccess: response => {
      dispatch(setCalculatedReport(response))

      notification.success({
        message: t('messages:createSuccess', 'Request sent')
      })
      return queryClient.invalidateQueries({
        queryKey: customReportQueryKeys.list({ companyId })
      })
    }
  })
}
