import React from 'react'
import { Form, FormInstance, Select, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useWatch } from 'antd/es/form/Form'
import FormulaInput from '../../../../../components/inputs/FormulaInput'
import KeyFigureInput from '../../../../../components/inputs/KeyFigureInput'
import { RowType } from '../../../../../redux/context/customReports/typesVariable'

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
}

type RestrictedRowType = RowType.keyFigure | RowType.formula

interface CustomReportVariableFormMultipleProps {
  form: FormInstance
}

const CustomReportVariableFormMultiple = ({ form }: CustomReportVariableFormMultipleProps) => {
  const { t } = useTranslation()
  const rowType: RestrictedRowType = useWatch('rowType', form)

  const variableSourceInputMap = {
    keyFigure: <KeyFigureInput mode="multiple" />,
    formula: <FormulaInput mode="multiple" />
  }

  return (
    <Form style={{ width: 500 }} {...layout} form={form} name="customReportVariableFormMultiple" layout="vertical">
      <Space direction="vertical" style={{ width: '100%' }}>
        <Form.Item name="rowType" label={t('global:type')} shouldUpdate>
          <Select
            options={[RowType.keyFigure, RowType.formula].map(type => ({
              label: t(`variableRowType:${type}`),
              value: type
            }))}
          />
        </Form.Item>
        {rowType ? (
          <Form.Item
            name="ids"
            rules={[{ required: true, message: t('global:required-field') }]}
            label={t('global:row', 'Rivit')}
          >
            {variableSourceInputMap[rowType]}
          </Form.Item>
        ) : null}
      </Space>
    </Form>
  )
}

export default CustomReportVariableFormMultiple
