import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useFiscalYearOptions } from '../useFiscalYearOptions'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { currentFiscalYearSelector } from '../../selectors/selectors'

export const useCtxCurrentFiscalYear = () => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const options = useFiscalYearOptions(companyId, currentFiscalYearSelector)
  return useQuery(options)
}
