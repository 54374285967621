import dayjs, { Dayjs } from 'dayjs'

export const investmentDatesDisabled = (
  current: Dayjs | null,
  isExisting: boolean,
  forecastCursor?: Dayjs | null,
  currentAndFutureRange?: [Dayjs, Dayjs] | null
) => {
  if (!current) return true
  if (isExisting) {
    // Can not select days after today
    return current >= dayjs().endOf('day')
  }
  const cursorStart = forecastCursor?.clone().add(1, 'month').startOf('month')
  const [fiscalsStart = null, fiscalsEnd = null] = currentAndFutureRange || [null, null]

  const startDate = cursorStart ?? fiscalsStart
  const endDate = fiscalsEnd ?? dayjs('9999-12-31')

  if (!startDate) return true

  return !current.isBetween(startDate, endDate, 'day', '[]')
}
