import { ContextFilters } from '../../../types/context/ContextFilters'

const dashboardQueryKeys = {
  all: [{ scope: 'dashboards' }] as const,
  companies: () => [{ ...dashboardQueryKeys.all[0], entity: 'companies' }] as const,
  dimensions: (context: Pick<ContextFilters, 'companyId'>) =>
    [{ ...dashboardQueryKeys.all[0], entity: 'dimensions', ...context }] as const
}

export default dashboardQueryKeys
