import React, { ReactNode } from 'react'
import { CustomReportSection } from '../../../../../redux/context/customReports/typesSection'
import { StyledLayoutItem } from './StyledComponents'

interface LayoutItemProps {
  section: CustomReportSection
  content: ReactNode
  children?: ReactNode
}

type Ref = any

const LayoutItem = React.forwardRef<Ref, LayoutItemProps>(({ section, children, content, ...restProps }, ref) => {
  return (
    <StyledLayoutItem ref={ref} key={section.id} {...restProps}>
      {content}
      {children}
    </StyledLayoutItem>
  )
})

export default LayoutItem
