import i18next from 'i18next'
import { BudgetingScenario } from '../types/BudgetingScenario'

export const normalizeScenario = (scenario: BudgetingScenario): BudgetingScenario => ({
  ...scenario,
  // id: scenario.id ?? 0,
  name: !scenario.id ? i18next.t('global:default') : scenario.name,
  description: scenario.description ?? i18next.t('global:default')
})

export const denormalizeScenario = (scenario: BudgetingScenario): BudgetingScenario =>
  ({
    ...scenario,
    // id: scenario.id === 0 ? null : scenario.id,
    name: scenario.id === 0 ? null : scenario.name,
    description: scenario.id === 0 ? null : scenario.description
  } as BudgetingScenario)
