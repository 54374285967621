import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useCompanyGroupSettingsOptions } from './useCompanyGroupSettingsOptions'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { GroupSettingData, GroupSettingDataSource } from '../../types/CompanyGroupSettings'

export const useCtxCompanyGroupSettings = <TData = GroupSettingData>(select?: (data: GroupSettingData) => TData) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const options = useCompanyGroupSettingsOptions({ companyId, select })
  return useQuery(options)
}

export const useCtxCompanyGroupBudgetSource = () => {
  const select = (data: GroupSettingData) => data?.budget?.source
  return useCtxCompanyGroupSettings(select)
}

export const useCtxCompanyGroupActualSource = () => {
  const select = (data: GroupSettingData) => data?.actuals?.source
  return useCtxCompanyGroupSettings(select)
}

export const useCtxCompanyGroupSourceSubsidiaries = () => {
  const select = (data: GroupSettingData) =>
    data?.actuals?.source === GroupSettingDataSource.subsidiaries &&
    data?.budget?.source === GroupSettingDataSource.subsidiaries
  return useCtxCompanyGroupSettings(select)
}
