import React from 'react'
import ScenariosPage from '../../../pages/settings/company/budgeting/budgetingScenarios/ScenariosPage'
import { useForecasts } from '../queries/forecast/useForecasts'
import { useCreateForecast } from '../queries/forecast/useCreateForecast'
import { useUpdateForecast } from '../queries/forecast/useUpdateForecast'
import { useDeleteForecast } from '../queries/forecast/useDeleteForecast'
import { useLockForecast } from '../queries/forecast/useLockForecast'
import { useUnLockForecast } from '../queries/forecast/useUnLockForecast'

const Forecasts: React.FC = () => {
  const query = useForecasts()
  const create = useCreateForecast()
  const update = useUpdateForecast()
  const remove = useDeleteForecast()
  const lock = useLockForecast()
  const unLock = useUnLockForecast()

  return (
    <ScenariosPage
      page="forecasts"
      query={query}
      createScenario={create}
      updateScenario={update}
      deleteScenario={remove}
      lockScenario={lock}
      unLockScenario={unLock}
    />
  )
}

export default Forecasts
