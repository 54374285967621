import React, { forwardRef } from 'react'
import { Cascader, CascaderAutoProps, GetRef } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { useTranslation } from 'react-i18next'
import { useScenarios } from '../../queries/useScenarios'
import { defaultOptionsSelector } from '../../selectors/selectors'
import { BudgetingScenario } from '../../types/BudgetingScenario'
import { ReportDataType } from '../../../../redux/context/reports/types'

type ScenarioCascaderProps = CascaderAutoProps & {
  companyId?: string
  optionSelector?: (data: BudgetingScenario[]) => DefaultOptionType[]
}

type CascaderRefType = GetRef<typeof Cascader>

const ScenarioCascader = forwardRef<CascaderRefType, ScenarioCascaderProps>(
  ({ companyId, optionSelector, ...props }, ref) => {
    const { t } = useTranslation()
    const { data: budgetOptions, isPending: scenariosPending } = useScenarios({
      companyId,
      select: optionSelector || defaultOptionsSelector,
      dataType: ReportDataType.budget
    })
    const { data: forecastOptions, isPending: forecastsPending } = useScenarios({
      companyId,
      select: optionSelector || defaultOptionsSelector,
      dataType: ReportDataType.forecast
    })

    const options: DefaultOptionType[] = [
      {
        value: ReportDataType.actuals,
        label: t('global:actuals')
      },
      {
        value: ReportDataType.budget,
        label: t('global:budget'),
        disabled: budgetOptions.length < 1,
        children: budgetOptions
      },
      {
        value: ReportDataType.forecast,
        label: t('global:forecast'),
        disabled: forecastOptions.length < 1,
        children: forecastOptions
      }
    ]

    return (
      <Cascader
        ref={ref}
        options={options}
        loading={scenariosPending || forecastsPending}
        displayRender={(label, selectedOptions) => {
          if (selectedOptions?.[0].value === ReportDataType.actuals) {
            return label[0]
          }
          return label.join(' / ')
        }}
        {...props}
      />
    )
  }
)

export default ScenarioCascader
