import { Cascader } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CategoryContext, CategoryContextType } from './CategoryContext'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import { transform, defaultTitle } from '../../../../../redux/context/customReports/transUtils'

interface ReferenceCategoryProps {
  path: string
  category: CustomReportCategory
}

export const ReferenceCategory: React.FC<ReferenceCategoryProps> = ({ path, category }: ReferenceCategoryProps) => {
  const { editObject, categories } = useContext(CategoryContext) as CategoryContextType
  const { t } = useTranslation()
  const changeValue = (c: any) => {
    if (c.value) {
      editObject(path, { ...category, value: c.value[c.value.length - 1] })
    } else {
      editObject(path, { ...category, value: undefined })
    }
  }

  function mapCategoryIds(
    this: string,
    { children, ...o }: CustomReportCategory,
    index: number
  ): CustomReportCategory & { disabled?: boolean } {
    const pathh = this ? `${this}-${index}` : `${index}`
    return {
      ...o,
      id: pathh,
      disabled: !!(o?.id && category?.id && category.id.substring(0, category.id.length - 2) === o.id),
      title: transform(o.title ?? defaultTitle(o.type, o.value), o, categories, true),
      children: children?.map(mapCategoryIds, pathh)
    }
  }

  const getValue = () => {
    const splittedId = (category.value as string)?.split('-')
    return splittedId?.map((val, index) => splittedId.slice(0, index + 1).join('-'))
  }

  return (
    <>
      <p>{t('customReportPage:referenceColumn')}:</p>
      <Cascader<any>
        style={{ width: 400 }}
        options={categories.map(mapCategoryIds)}
        allowClear
        onChange={value =>
          changeValue({
            value
          })
        }
        status={!getValue() ? 'error' : ''}
        value={getValue() as any}
        fieldNames={{ label: 'title', value: 'id' }}
        placeholder={t('global:choose-here')}
      />
    </>
  )
}
