import { Company } from '../../../types/company/Company'
import { Currency } from '../../../types/currency/Currency'

export interface CustomExchangeRateSeriesWithCompany extends CustomExchangeRateSeries {
  company?: Company
}

export interface CustomExchangeRateSeries {
  id: number

  /** Ryhmän y-tunnus */
  groupId: string

  /** Yrityksen y-tunnus */
  companyId: string

  /** Kussauskäytäntö */
  type: CustomExchangeRateSeriesType

  /** Perusvaluutta, arvo suhteessa noteerattuun valuuttaan (currency) on aina 1 */
  baseCurrency: Currency

  /** Noteerattu valuutta */
  currency: Currency

  /** Valuuttakurssi */
  rates: CustomExchangeRate[]
}

export enum CustomExchangeRateSeriesType {
  automatic = 'automatic',
  manual = 'manual'
}

export interface CustomExchangeRate {
  id?: number

  /** Tuloksen vai taseen kurssi */
  statementType: StatementType

  /** Arvon ajanhetki */
  date: string

  month?: number
  year?: number

  /** Arvo suhteessa perusvaluuttaan */
  value: number | string
}

export enum StatementType {
  pl = 'pl',
  bs = 'bs'
}

export const reportNames = {
  bs: 'BALANCE_SHEET',
  pl: 'INCOME_STATEMENT'
}

type ReportColumn = {
  report: keyof typeof reportNames
}

type ValueColumns = {
  [key: string]: string | null
}

export type SheetRow = ReportColumn & ValueColumns

export type ExchangeRateSeriesRequestBody = Omit<CustomExchangeRateSeries, 'id' | 'rates'>
