import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useForecastOptions } from './useForecastOptions'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'

export const useForecasts = () => {
  const contextCompanyId = useSelector(contextCompanyIdSelector)
  if (!contextCompanyId) {
    throw new Error('Company ID is required for fetching forecasts')
  }
  const options = useForecastOptions({ companyId: contextCompanyId })
  return useQuery(options)
}
