import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, Modal, Space } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedInputNumber } from '../../../../../../components/Misc/FormattedInputNumber'
import { Company } from '../../../../../../types/company/Company'
import { transformFieldData } from '../../../../../../utils/form'

interface AcquisitionsModalProps {
  open: boolean
  groupId?: string
  company?: Company
  onUpdate: (value: Company) => void
  onCancel: () => void
}

const AcquisitionsModal: React.FC<AcquisitionsModalProps> = ({ open, groupId, company, onUpdate, onCancel }) => {
  const [form] = Form.useForm<Pick<Company, 'acquisitions'>>()
  const { t } = useTranslation()

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(
      transformFieldData(company, {
        date: (val: string) => dayjs(val)
      })
    )
  }, [company, open])

  return (
    <Modal
      destroyOnClose
      open={open}
      width={700}
      title={t('subsidiaries:editSubsidiary')}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      maskClosable={false}
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      onOk={async () => {
        try {
          if (!company) return
          const values = await form.validateFields()

          const formattedValues = transformFieldData(values, {
            date: (val: string) => dayjs(val).format('YYYY-MM-DD')
          })

          form.resetFields()
          const newSubsidiary = {
            ...company,
            ...formattedValues
          }
          onUpdate(newSubsidiary)
        } catch (info) {
          console.error('Validate Failed:', info)
        }
      }}
    >
      <Form form={form} name="company_acquisitions" style={{ maxWidth: 700 }} autoComplete="off">
        <Form.Item label={t('global:company')}>{company?.name}</Form.Item>
        <Form.List name="acquisitions">
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <Space key={field.key} align="baseline">
                  <Form.Item initialValue={groupId} hidden name={[field.name, 'groupId']}>
                    <Input />
                  </Form.Item>
                  <Form.Item initialValue={company?.id} hidden name={[field.name, 'subsidiaryId']}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={t('contractsPage:date')}
                    name={[field.name, 'date']}
                    rules={[{ required: true, message: t('global:required-field') }]}
                  >
                    <DatePicker format="DD.MM.YYYY" />
                  </Form.Item>

                  <Form.Item
                    label={t('global:share')}
                    name={[field.name, 'percentage']}
                    initialValue={0}
                    rules={[{ required: true, message: t('global:required-field') }]}
                  >
                    <FormattedInputNumber step={1} precision={2} max={100} percentage addonAfter="%" />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {`${t('global:add')} ${t('global:share').toLowerCase()}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}

export default AcquisitionsModal
