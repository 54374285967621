import { BudgetingScenario } from '../types/BudgetingScenario'

const forecastEndpoints = {
  list: (companyId: string) => `/companies/${companyId}/budgeting/forecasts` as const,
  detail: (companyId: string, scenarioId: BudgetingScenario['id']) =>
    `/companies/${companyId}/budgeting/forecasts/${scenarioId}` as const,
  lock: (companyId: string, scenarioId: BudgetingScenario['id']) =>
    `/companies/${companyId}/budgeting/forecasts/${scenarioId}/lock` as const,
  unLock: (companyId: string, scenarioId: BudgetingScenario['id']) =>
    `/companies/${companyId}/budgeting/forecasts/${scenarioId}/unlock` as const
}

export default forecastEndpoints
