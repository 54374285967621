import React from 'react'
import { SelectProps, Space } from 'antd'
import { LockFilled } from '@ant-design/icons'

export const disabledLockRender: SelectProps['optionRender'] = ({ label, data: { disabled } }) => {
  return (
    <Space>
      {disabled ? <LockFilled /> : null}
      {label}
    </Space>
  )
}
