import { useMutation, useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { deleteCustomReport } from '../../service/customReportService'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { setCalculatedReport } from '../../../../redux/context/customReports/actions'
import customReportQueryKeys from '../customReportQueryKeys'

export const useCtxDeleteCustomReport = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { notification } = App.useApp()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id: number) => {
      if (!companyId) {
        throw new Error('Company ID is required')
      }
      if (!id) {
        throw new Error('Report ID is required')
      }
      return deleteCustomReport(companyId, id)
    },
    onSuccess: () => {
      dispatch(setCalculatedReport(null))
      notification.success({
        message: t('messages:deleteSuccess', 'Request sent')
      })
      return queryClient.invalidateQueries({
        queryKey: customReportQueryKeys.list({ companyId })
      })
    }
  })
}
