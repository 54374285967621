import { Button } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReportTableRow } from '../../../../../components/Table/types'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { ContractProduct } from '../../../../../types/contract/Contract'
import { notLocked } from '../../../../settings/company/budgeting/budgetingScenarios/utils'
import { GroupSettingDataSource } from '../../../../../features/companyGroup/types/CompanyGroupSettings'
import InvestmentModal from '../../../../../features/investment/components/InvestmentModal'
import DeprecationForm from './DeprecationForm'
import Authorized from '../../../../../components/Authorized/Authorized'
import { contextRequest } from '../../../../../redux/context/actions'
import { getFinancialStatementRequest } from '../../../../../redux/context/reports/actions'
import { AppDispatch } from '../../../../../redux/store'
import { useCtxCompanyGroupBudgetSource } from '../../../../../features/companyGroup/queries/companyGroupSetting/useCtxCompanyGroupSettings'

interface InvestmentMenuProps {
  reportTableRow: ReportTableRow
}

const InvestmentMenu: React.FC<InvestmentMenuProps> = (props: InvestmentMenuProps) => {
  const { t } = useTranslation()
  const { reportTableRow } = props
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const dispatch: AppDispatch = useDispatch()
  const [formType, setFormType] = useState<'investment' | 'divestment'>('investment')
  const { data: groupBudgetSourceSetting } = useCtxCompanyGroupBudgetSource()
  const { budgetingScenario } = useSelector(filtersSelector)

  const toggleModal = () => {
    setModalVisible(!modalVisible)
    dispatch(contextRequest(getFinancialStatementRequest))
  }

  return (
    <>
      {modalVisible && (
        <InvestmentModal
          sourceDisabled
          visible={modalVisible}
          isExisting={false}
          formType={formType}
          reportTableRow={reportTableRow}
          onFinish={toggleModal}
        />
      )}

      <Button
        type="default"
        size="small"
        disabled={!notLocked(budgetingScenario) || groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
        onClick={() => {
          setFormType('investment')
          setModalVisible(true)
        }}
      >
        {t('financialStatementsPage:investment')}
      </Button>

      <Button
        type="default"
        size="small"
        disabled={!notLocked(budgetingScenario) || groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
        onClick={() => {
          setFormType('divestment')
          setModalVisible(true)
        }}
      >
        {t('financialStatementsPage:divestment')}
      </Button>

      <Authorized
        authority={{
          product: ContractProduct.BASIC,
          custom: () => !!(reportTableRow.depreciable && notLocked(budgetingScenario))
        }}
      >
        <DeprecationForm balanceSheetRowId={reportTableRow.id} reportTableRow={reportTableRow} />
      </Authorized>
    </>
  )
}

export default React.memo(InvestmentMenu)
