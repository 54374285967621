import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Dropdown, MenuProps, Space, Tooltip } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ExportOutlined, FileExcelOutlined, LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import InvestmentGroup from '../../../features/investment/components/InvestmentGroup'
import InvestmentModal from '../../../features/investment/components/InvestmentModal'
import { setInvestmentPageDateAction } from '../../../redux/pages/investments/actions'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import InvestmentSummary from '../../../features/investment/components/InvestmentSummary'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { GroupSettingDataSource } from '../../../features/companyGroup/types/CompanyGroupSettings'
import { notLocked } from '../../settings/company/budgeting/budgetingScenarios/utils'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import { AppDispatch } from '../../../redux/store'
import { useInvestmentSummary } from '../../../features/investment/hooks/useInvestment'
import { useInvestmentExport } from '../../../features/investment/hooks/useInvestmentExport'
import { useInvestmentTemplate } from '../../../features/investment/hooks/useInvestmentTemplate'
import InvestmentImport from '../../../features/investment/components/InvestmentImport'
import { useCtxCompanyGroupBudgetSource } from '../../../features/companyGroup/queries/companyGroupSetting/useCtxCompanyGroupSettings'

interface InvestmentPageProps {
  isExisting: boolean
}

const InvestmentsPage: React.FC<InvestmentPageProps> = ({ isExisting }) => {
  const dispatch: AppDispatch = useDispatch()

  const location = useLocation()
  const pagePath = location.pathname
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    data: investmentsData,
    isLoading: investmentsLoading,
    isFetching,
    isRefetching
  } = useInvestmentSummary(isExisting)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const { budgetingScenario } = useSelector(filtersSelector)
  const { data: groupBudgetSourceSetting } = useCtxCompanyGroupBudgetSource()
  const { download: downloadReport, isLoading: isDownLoadingReport } = useInvestmentExport(isExisting)
  const { download: downloadTemplate, isLoading: isDownLoadingTemplate } = useInvestmentTemplate()
  const { date } = useSelector(investmentsPageSelector)

  const tabs = [
    {
      key: '/investments/existing',
      label: t('menu:/investments/existing')
    },
    {
      key: '/investments/investment',
      label: t('menu:/investments/investment')
    }
  ]

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const menuButtonItemsInvestments: MenuProps['items'] = [
    {
      key: 'download-investments',
      label: isExisting ? t('investmentsPage:downloadFixedAssets') : t('investmentsPage:downloadInvestments'),
      icon: <ExportOutlined />
    }
  ]

  const menuButtonItemsExisting: MenuProps['items'] = [
    {
      key: 'import',
      label: <InvestmentImport />,
      icon: <UploadOutlined />
    },
    {
      key: 'download-csv',
      label: t('investmentsPage:csvDownload'),
      icon: <FileExcelOutlined />
    },
    ...menuButtonItemsInvestments
  ]

  const handleMenuClick = ({ key }: any) => {
    switch (key) {
      case 'download-csv':
        downloadTemplate()
        break
      case 'download-investments':
        downloadReport()
        break
      default:
        break
    }
  }

  return (
    <>
      <InvestmentModal
        onlyDepreciable
        sourceDisabled={false}
        formType="investment"
        isExisting={isExisting}
        visible={modalVisible}
        onFinish={toggleModal}
      />
      <PageHeaderWrapper
        title={t('investmentsPage:investment')}
        subTitle={
          <Space>
            {t(`menu:${pagePath}`)}
            <Tooltip placement="right" title="info">
              <DocumentationLink
                route={{ path: '/budgeting' }}
                id={{
                  fi: 'poistot-professional-versio',
                  en: 'depreciation-professional-version',
                  sv: 'avskrivning-professionell-version'
                }}
              />
            </Tooltip>
          </Space>
        }
        onTabChange={(key: string) => {
          navigate(key)
        }}
        extra={[
          <DatePicker
            value={date ? dayjs(date) : dayjs()}
            format="MM/YYYY"
            picker="month"
            key="month"
            onChange={val => dispatch(setInvestmentPageDateAction(val?.toString()))}
          />,
          <Dropdown.Button
            icon={isDownLoadingReport || isDownLoadingTemplate ? <LoadingOutlined /> : undefined}
            onClick={toggleModal}
            key="dropdown"
            disabled={!notLocked(budgetingScenario) || groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
            menu={{
              items: isExisting ? menuButtonItemsExisting : menuButtonItemsInvestments,
              onClick: handleMenuClick
            }}
          >
            <PlusOutlined />
            {t('global:add-new')}
          </Dropdown.Button>
        ]}
        content={<InvestmentSummary summary={investmentsData?.summary} />}
        tabActiveKey={pagePath}
        tabList={tabs}
        ghost={false}
      >
        <LoadingWrapper loading={date ? investmentsLoading || isFetching || isRefetching : false}>
          <Space style={{ width: '100%' }} direction="vertical">
            {investmentsData?.balanceSheetRows.map(balanceSheetRow => (
              <InvestmentGroup key={balanceSheetRow.balanceSheetRowId} {...balanceSheetRow} isExisting={isExisting} />
            ))}
          </Space>
        </LoadingWrapper>
      </PageHeaderWrapper>
    </>
  )
}

export default InvestmentsPage
