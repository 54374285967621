import React from 'react'
import { DatePicker, Modal, Switch, Input, Form } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { last } from 'lodash'
import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'
import { useAuthority } from '../../../utils/Authorizable/authorize'
import { budgetableSettingAuthority } from '../../../pages/settings/company/fiscalYears/FiscalYears'
import { getSuggestedNextPeriod } from '../utils'
import { useCtxUpdateFiscalYear } from '../queries/report/useCtxUpdateFiscalYear'
import { useCtxCreateFiscalYear } from '../queries/report/useCtxCreateFiscalYear'
import { useCtxFiscalYears } from '../queries/report/useCtxFiscalYears'

interface FiscalYearsFormProps {
  fiscalYear?: FiscalYear
  modalVisible: boolean
  handleCancel: () => void
}

const FiscalYearsForm = ({ fiscalYear, modalVisible, handleCancel }: FiscalYearsFormProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { mutateAsync: updateFiscalYear } = useCtxUpdateFiscalYear()
  const { mutateAsync: createFiscalYear } = useCtxCreateFiscalYear()
  const { data: latestFiscalYear } = useCtxFiscalYears(last)

  const showBudgetableSetting = useAuthority(budgetableSettingAuthority)

  const cancelForm = () => {
    form.resetFields()
    handleCancel()
  }

  const handleOk = async () => {
    try {
      const {
        period: [startDate, endDate],
        ...restValues
      } = await form.validateFields()

      const values = {
        ...restValues,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      }

      if (values.id) {
        updateFiscalYear(values)
      }
      if (!values.id) {
        createFiscalYear(values)
      }
    } catch (error) {
      console.log(error)
    } finally {
      cancelForm()
    }
  }

  return (
    <Modal
      title={fiscalYear ? t('fiscalYearsPage:edit-fiscal-year') : t('fiscalYearsPage:add-new')}
      open={modalVisible}
      onOk={handleOk}
      onCancel={cancelForm}
      cancelText={t('global:cancel')}
      forceRender={false}
      maskClosable={false}
      destroyOnClose
    >
      <Form
        name="fiscalyears-form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        initialValues={{
          ...fiscalYear,
          period: fiscalYear?.id
            ? [dayjs(fiscalYear?.startDate), dayjs(fiscalYear?.endDate)]
            : getSuggestedNextPeriod(latestFiscalYear),
          isOpen: !fiscalYear ? true : fiscalYear.isOpen
        }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="period" label={t('global:time-period')}>
          <DatePicker.RangePicker format="DD.MM.YYYY" />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues: any, curValues: any) => prevValues?.isOpen !== curValues?.isOpen}>
          {() => (
            <>
              <Form.Item
                name="isOpen"
                valuePropName="checked"
                label={`${t('fiscalYearsPage:isopen')} / ${t('fiscalYearsPage:isclosed')}`}
              >
                <Switch
                  checkedChildren={t('fiscalYearsPage:isopen')}
                  unCheckedChildren={t('fiscalYearsPage:isclosed')}
                />
              </Form.Item>
            </>
          )}
        </Form.Item>
        <Form.Item
          hidden={!showBudgetableSetting}
          name="budgetable"
          valuePropName="checked"
          label={`${t('global:budgetable')} `}
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default FiscalYearsForm
