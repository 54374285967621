import { DashStyleValue } from 'highcharts'
import { VariableType } from '../../../components/Table/types'
import { ChartYAxis } from '../../../pages/reporting/custom/components/chart/types'
import { Dimension } from '../../../types/dimension/Dimension'
import { Period } from '../../../types/fiscalYear/FiscalYear'
import { Balance, ReportDataType } from '../reports/types'
import { ReferenceValueRule } from '../../../pages/reporting/keyFigures/components/referenceValueTable/types'

export interface ExplainerVariable {
  id: string
  source: string
  translation: string
  explanation: boolean
  params: VariableParams
  style: CustomReportVariableStyle
  balances: Balance[]
  period: string // current ja jotain muuta
  children: ExplainerVariable[]
}
export interface VariableParams {
  dimension?: Partial<Dimension>
  dataType?: CustomReportDataType
  budgetingScenario?: { id: string }
  month?: number
  offset?: number
  expandable?: boolean
  showEmptyRows?: boolean
}
export interface CustomReportVariableExplainer {
  variables: CustomReportVariable[]
  commonSizeVariables: CustomReportVariable[]
  categories: ExplainerVariable[]
}

export enum ThresholdValueSetting {
  biggerBetter = 'biggerBetter',
  smallerBetter = 'smallerBetter',
  unset = 'unset'
}

export enum RowType {
  keyFigure = 'keyFigure',
  formula = 'formula',
  source = 'source',
  title = 'title',
  empty = 'empty'
}

interface BaseVariable {
  id?: number
  name?: string
  nameFi?: string
  nameEn?: string
  nameSv?: string
  style?: CustomReportVariableStyle
  params?: VariableParams
  // Nää seuraavat tiedot tulee bäkistä
  // Other shared properties go here
  variableType?: VariableType
  type?: VariableType
  sectionId?: number
  period?: Period
  order?: number
  translation?: string
  balances?: Balance[]
  children?: CustomReportVariable[]
  account?: boolean
}

export interface EmptyVariable extends BaseVariable {
  rowType: RowType.empty
}

export interface TitleVariable extends BaseVariable {
  rowType: RowType.title
  name: string // Required for TitleRow
}

export interface KeyFigureVariable extends BaseVariable {
  rowType: RowType.keyFigure
  keyFigureId: number // Only present in KeyFigure rows
}

export interface FormulaVariable extends BaseVariable {
  rowType: RowType.formula
  formulaId: number // Only present in Formula rows
}

export interface SourceVariable extends BaseVariable {
  rowType: RowType.source
  source: string // Only present in Source rows
}

export type CustomReportVariable = EmptyVariable | TitleVariable | KeyFigureVariable | FormulaVariable | SourceVariable
export type CustomReportDataVariable = KeyFigureVariable | FormulaVariable | SourceVariable

/**
 * VariableRow on muokattu variable, jossa values on taulukolle menevä data
 * Valuesin [key: string] on dataIndex, joka mappaantuu sarakkeisiin.
 */
export type VariableRow = Omit<CustomReportVariable, 'children'> & {
  id: number
  children?: VariableRow[]
  variableType?: VariableType
  explanation?: boolean
  source?: string
  values: {
    [key: string]: number | undefined
  }
}

export type VariableFormValues = EmptyVariable | TitleVariable | KeyFigureVariable | FormulaVariable | SourceVariable

export interface CustomReportVariableStyle {
  color?: string
  dashStyle?: DashStyleValue
  zIndex?: number
  decimals?: number
  fontWeight?: 'bold'
  fontStyle?: 'italic' | 'normal'
  yAxis?: ChartYAxis
  thresholdValueSetting: ThresholdValueSetting
  referenceValueRules?: ReferenceValueRule[]
  indentLevel?: number
}

export const CustomReportDataType = {
  actuals: ReportDataType.actuals,
  budget: ReportDataType.budget,
  forecast: ReportDataType.forecast
} as const

export type CustomReportDataType = keyof typeof CustomReportDataType

export interface VariableParams {
  dimension?: Partial<Dimension> // vain PRO
  dataType?: CustomReportDataType
  budgetingScenario?: { id: string }
  month?: number
  offset?: number
}
