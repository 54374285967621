import { Col, Form, Row, Select, Switch, Table, TableProps, Typography } from 'antd'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { FormItem, FormItemGroup } from '../../../../../../components/Form/FormItemContext'
import { normalizedConfigSelector } from '../../../../../../redux/config/selectors'
import { BudgetSimulationFormItem } from '../../../../../../redux/config/financialStatement/types'
import { tulosSimulationsConfigSlector } from '../../../../../../redux/config/financialStatement/selectors'
import AccountSelect from '../../../../../../features/account/components/AccountSelect'
import { useCtxFiscalYears } from '../../../../../../features/fiscalYear/queries/report/useCtxFiscalYears'

const { Title, Paragraph, Text } = Typography

const BudgetSimulationsPage = () => {
  const { t } = useTranslation()
  const { financialStatement: financialStatementConfig } = useSelector(normalizedConfigSelector)
  const { taseSimulations, tulosSimulations } = useSelector(tulosSimulationsConfigSlector)

  const { data: fiscalYears } = useCtxFiscalYears()

  const columns: ColumnProps<BudgetSimulationFormItem>[] = [
    {
      title: t('global:name'),
      dataIndex: 'name',
      width: 400,
      render: name => {
        return t(`budgetingInputSimulations:${name}`)
      }
    },
    {
      title: t(`budgetingInputPage:automaticCalculation`),
      render: (value, { name }) => {
        return (
          <FormItem noStyle name={[name, 'enabled']} valuePropName="checked">
            <Switch />
          </FormItem>
        )
      }
    },
    {
      title: t('global:account'),
      width: 400,
      render: (value, { name, configKey }) => {
        const ids = [financialStatementConfig[configKey]].flat().filter(Boolean)
        return (
          <FormItem name={[name, 'accountCode']} style={{ margin: 0 }}>
            <AccountSelect placeholder={t('global:choose-here')} balanceSheetRowIds={ids} allowClear />
          </FormItem>
        )
      }
    }
  ]

  // Niinsanottu pääkytkin
  const enabledColumns: ColumnProps<any>[] = [
    {
      title: t('global:name'),
      dataIndex: 'name',
      width: 400,
      render: value => t(`budgetingInputSimulations:${value}`)
    },
    {
      title: t(`budgetingInputPage:automaticCalculation`),
      dataIndex: 'enabled',
      render: () => {
        return (
          <FormItem noStyle name="enabled" valuePropName="checked">
            <Switch />
          </FormItem>
        )
      }
    }
  ]

  const tableProps: TableProps<BudgetSimulationFormItem> = {
    pagination: false,
    rowKey: 'name',
    size: 'small',
    expandable: {
      expandedRowRender: (record: any) => {
        const paragraphs = t(`budgetingInputSimulations:${record.name}`, {
          context: 'description',
          returnObjects: true
        }) as string[]

        return paragraphs?.map((text, i) => (
          <Paragraph key={`key-${i.toString()}`}>
            <Trans components={[<Text strong />]}>{text}</Trans>
          </Paragraph>
        ))
      }
    }
  }

  return (
    <div>
      <FormItemGroup prefix={['simulations']}>
        <Row gutter={[0, 48]} style={{ marginBlock: 24 }}>
          {taseSimulations.length > 0 && (
            <Col span={24}>
              <Title level={4}>{t(`reportTypes:BALANCE_SHEET`)}</Title>
              <Table {...tableProps} dataSource={taseSimulations} columns={columns} />
            </Col>
          )}
          {tulosSimulations.length > 0 && (
            <Col span={24}>
              <Title level={4}>{t(`reportTypes:INCOME_STATEMENT`)}</Title>
              <Table {...tableProps} dataSource={tulosSimulations} columns={columns} />
            </Col>
          )}
          <Col span={24}>
            <Title level={4}>{t(`budgetingInputPage:mainSwitch`)}</Title>
            <Table {...tableProps} dataSource={[{ name: 'enabled' }]} columns={enabledColumns} />
          </Col>
          <Col span={24}>
            <Title level={4}>{t(`budgetingInputSimulations:baseBalanceDateTitle`)}</Title>
            <p>{t(`budgetingInputSimulations:baseBalanceDateInfo1`)}</p>
            <p>{t(`budgetingInputSimulations:baseBalanceDateInfo2`)}</p>
            <p style={{ marginTop: 10 }}>{t(`budgetingInputSimulations:baseBalanceDateInfo3`)}</p>
            <Form.Item name={['simulations', 'baseBalanceDate']}>
              <Select
                allowClear
                style={{ width: 200 }}
                options={fiscalYears?.map(fiscalYear => ({
                  value: dayjs(fiscalYear.endDate).format('YYYY-MM-DD'),
                  label: dayjs(fiscalYear.endDate).format('YYYY-MM-DD')
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </FormItemGroup>
    </div>
  )
}

export default BudgetSimulationsPage
