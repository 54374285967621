import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { App } from 'antd'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import companyGroupSettingQueryKeys from './companyGroupSettingQueryKeys'
import { updateCompanyGroupSetting } from '../../service/settingService'
import { GroupSettingData } from '../../types/CompanyGroupSettings'
import { exchangeRateQueryKeys } from '../../../exchangeRate'

export const useCtxUpdateCompanyGroupSettings = () => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { notification } = App.useApp()

  return useMutation({
    mutationKey: companyGroupSettingQueryKeys.setting(companyId),
    mutationFn: (data: GroupSettingData) => {
      if (!companyId) {
        throw new Error('Group ID is required for fetching group settings.')
      }
      return updateCompanyGroupSetting(data, companyId)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: companyGroupSettingQueryKeys.setting(companyId) })
      queryClient.invalidateQueries({ queryKey: exchangeRateQueryKeys.list(companyId) })
      notification.success({ message: t('message:UPDATE_BUDGETING_SCENARIO_SUCCESS') })
    }
  })
}
