import { JiraIssueComponent } from '../../types/jira/jira'

interface TreeNode {
  value: string | null
  title: string
  selectable?: boolean
  children?: TreeNode[]
}
export function buildComponentTree(data?: JiraIssueComponent[]): TreeNode[] {
  const root: TreeNode = { value: null, title: '/', children: [] }

  data
    ?.slice()
    .sort((a, b) => (a.description || '').localeCompare(b.description || '', 'en'))
    .forEach(component => {
      const pathSegments = component.description?.split('/').filter(Boolean) // Split and remove empty segments
      let currentNode = root
      let fullPath = '' // To track the full path

      pathSegments?.forEach((segment, index) => {
        fullPath += `/${segment}` // Build the full path progressively

        if (!currentNode.children) {
          currentNode.children = []
        }

        // Check if the node already exists
        let newNode = currentNode.children.find(child => child.title === fullPath)
        if (!newNode) {
          newNode = {
            value: component.description === fullPath ? component.id : fullPath, // Only set value for leaf nodes
            title: fullPath,
            selectable: index === pathSegments.length - 1,
            ...(index === pathSegments.length - 1 ? {} : { children: [] }) // Add `children` only for non-leaf nodes
          }
          currentNode.children.push(newNode)
        }

        currentNode = newNode
      })
    })

  return root.children || []
}
