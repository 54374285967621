import React from 'react'
import { useScenario } from '../queries/useScenario'
import { BudgetingScenario } from '../types/BudgetingScenario'

interface ScenarioNameProps {
  companyId?: string
  scenarioId?: BudgetingScenario['id']
}

const ScenarioName = ({ companyId, scenarioId }: ScenarioNameProps) => {
  const scenario = useScenario({ companyId, scenarioId })
  return <span>{scenario?.name}</span>
}

export default ScenarioName
