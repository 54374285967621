import { Dispatch } from 'react'
import { BackendRequest } from '../../../../../services/backend'
import { Company } from '../../../../../types/company/Company'
import { Budget } from './types'
import { BudgetingScenario } from '../../../../../features/scenario/types/BudgetingScenario'
import { PeriodGroup } from '../../../../../types/periodGroup/PeriodGroup'
import { Account } from '../../../../../features/account/types/Account'
import { StatementRow } from '../../../../../types/statementRow/StatementRow'
import { ReportTableRow } from '../../../../../components/Table/types'
import { getRowDimensionDataReq } from '../../service'
import { Dimension, DimensionQueryOperator } from '../../../../../types/dimension/Dimension'

interface KeyAndDimensions {
  incomeStatementKey: string
  dimensionIds: string[]
}

const getParentDimensions = (dimensionList: Dimension[], rowKeyAndDimensionIds: KeyAndDimensions) => {
  const dimensionParentIds: string[] = []
  for (const dimensionId of rowKeyAndDimensionIds.dimensionIds) {
    const selectedDimension = dimensionList?.find(d => d.dimensionId === dimensionId)
    let parentDimension = dimensionList?.find(d => d.dimensionId === selectedDimension?.parentDimensionId)
    while (parentDimension) {
      if (!dimensionParentIds.includes(parentDimension?.dimensionId)) {
        dimensionParentIds.push(parentDimension?.dimensionId)
      }
      // eslint-disable-next-line no-loop-func
      parentDimension = dimensionList?.find(d => d.dimensionId === parentDimension?.parentDimensionId)
    }
  }

  return dimensionParentIds
}

const getKeysAndDimensions = (
  dimensionList?: Dimension[],
  budgetsInEdit?: {
    [code: string]: boolean
  }
) => {
  let keysAndDimensions: KeyAndDimensions[] = []
  if (budgetsInEdit && dimensionList) {
    for (const editedKeys of Object.keys(budgetsInEdit)) {
      const incomeStatementKey = editedKeys.split('-')[0]
      const dimensionId = [editedKeys.split('-')[1]]
      if (dimensionId?.length > 0 && dimensionId[0] !== undefined) {
        const savedData = keysAndDimensions.find((f: KeyAndDimensions) => f.incomeStatementKey === incomeStatementKey)
        if (savedData) {
          const filtered = keysAndDimensions.filter(
            (f: KeyAndDimensions) => f.incomeStatementKey !== incomeStatementKey
          )
          keysAndDimensions = [
            ...filtered,
            {
              ...savedData,
              dimensionIds: [...(savedData.dimensionIds || []), ...dimensionId]
            }
          ]
        } else {
          keysAndDimensions.push({
            incomeStatementKey,
            dimensionIds: [...dimensionId]
          })
        }
      }
    }
    keysAndDimensions = keysAndDimensions.map((kd: KeyAndDimensions) => {
      return {
        ...kd,
        dimensionIds: [...new Set([...getParentDimensions(dimensionList, kd), ...kd.dimensionIds])]
      }
    })

    return keysAndDimensions
  }
  return []
}

export const reloadDimensionsData = (
  dispatch: Dispatch<any>,
  rowDimensionDataRequest?: BackendRequest,
  budgetingScenario?: BudgetingScenario,
  periodGroups?: PeriodGroup[],
  allAccounts?: Account[],
  statementRows?: StatementRow[],
  companyId?: Company['id'],
  dimensionList?: Dimension[],
  budgetsInEdit?: {
    [code: string]: boolean
  },
  driversInEdit?: {
    [code: string]: boolean
  }
) => {
  if (budgetsInEdit || driversInEdit) {
    const keysAndDimensionsData = [
      ...getKeysAndDimensions(dimensionList, budgetsInEdit),
      ...getKeysAndDimensions(dimensionList, driversInEdit)
    ]
    for (const keysAndDimensionsRow of keysAndDimensionsData) {
      const selectedAccountRow = allAccounts?.find(
        a => String(a.code) === String(keysAndDimensionsRow.incomeStatementKey)
      )
      const selectedStatementRow = statementRows?.find(
        a => String(a.code) === String(keysAndDimensionsRow.incomeStatementKey)
      )

      if (keysAndDimensionsRow.dimensionIds && (selectedAccountRow?.code || selectedStatementRow?.id)) {
        rowDimensionDataRequest &&
          companyId &&
          getRowDimensionDataReq(
            keysAndDimensionsRow.dimensionIds,
            {
              accountCode: selectedAccountRow?.code,
              statementRowId: selectedStatementRow?.id
            } as unknown as ReportTableRow,
            companyId,
            rowDimensionDataRequest,
            dispatch,
            budgetingScenario,
            periodGroups
          )
      }
    }
  }
}

export const saveBudgets = async (
  data: Budget[][],
  budgetDriversRequest: BackendRequest,
  budgetRequest: BackendRequest,
  companyId: Company['id']
) => {
  const promises = data.map((budgetData: Budget[]) => {
    if (budgetData.length > 0) {
      const request = budgetData[0].formulaId ? budgetDriversRequest : budgetRequest
      return request.put({
        urlParams: {
          companyId,
          ...(budgetData[0].formulaId && {
            formulaId: budgetData[0].formulaId
          })
        },
        body: {
          data: budgetData,
          params: budgetData[0].dimensionId
            ? {
                dimensions: JSON.stringify(
                  [budgetData[0].dimensionId]?.map(id => ({
                    k: id,
                    o: DimensionQueryOperator.has,
                    v: id
                  }))
                )
              }
            : undefined
        }
      }) as Promise<Budget[]>
    }
    return null
  })

  return Promise.all(promises)
}
