import { CheckOutlined } from '@ant-design/icons'
import { Button, Form, Select, Space } from 'antd'
import React from 'react'
import { useCtxFiscalYears } from '../../../../../features/fiscalYear/queries/report/useCtxFiscalYears'

const { Option } = Select

const YearQuartersForm: React.FC = () => {
  const { data: fiscalYears } = useCtxFiscalYears()

  return (
    <Form name="yearQuarters" initialValues={{ quartersYear: fiscalYears?.[0]?.year }}>
      <Space.Compact block>
        <Form.Item name="quartersYear" noStyle rules={[{ required: true }]}>
          <Select>
            {fiscalYears?.map(fiscalYear => (
              <Option key={fiscalYear.id} value={fiscalYear.year}>
                {fiscalYear.year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle>
          <Button type="primary" icon={<CheckOutlined />} htmlType="submit" />
        </Form.Item>
      </Space.Compact>
    </Form>
  )
}

export default YearQuartersForm
