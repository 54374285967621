import { QueryFunctionContext } from '@tanstack/react-query'
import { DELETE, GET, POST, PUT } from '../../../redux/middleware/types'
import budgetingScenarioQueryKeys from '../queries/budgetingScenario/budgetingScenarioQueryKeys'
import backendRequest from '../../../services/api/backendRequest'
import { BudgetingScenario } from '../types/BudgetingScenario'
import budgetingScenarioEndpoints from '../api/budgetingScenarioEndpoints'

export const fetchBudgetingScenarios = async ({
  queryKey: [{ companyId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof budgetingScenarioQueryKeys)['list']>>) => {
  return backendRequest<BudgetingScenario[]>({
    method: GET,
    url: budgetingScenarioEndpoints.list(companyId),
    signal
  })
}

export const createBudgetingScenario = async (data: BudgetingScenario, companyId: string) => {
  return backendRequest<BudgetingScenario>({
    method: POST,
    url: budgetingScenarioEndpoints.list(companyId),
    data
  })
}

export const updateBudgetingScenario = async (
  data: BudgetingScenario,
  companyId: string,
  scenarioId: BudgetingScenario['id']
) => {
  return backendRequest<BudgetingScenario>({
    method: PUT,
    url: budgetingScenarioEndpoints.detail(companyId, scenarioId),
    data
  })
}

export const updateDefaultBudgetingScenario = async (data: BudgetingScenario, companyId: string) => {
  return backendRequest<BudgetingScenario>({
    method: PUT,
    url: budgetingScenarioEndpoints.list(companyId),
    data
  })
}

export const deleteBudgetingScenario = async (companyId: string, scenarioId: BudgetingScenario['id']) => {
  return backendRequest<BudgetingScenario>({
    method: DELETE,
    url: budgetingScenarioEndpoints.detail(companyId, scenarioId)
  })
}

export const lockBudgetingScenario = async (companyId: string, scenarioId: BudgetingScenario['id']) => {
  return backendRequest<BudgetingScenario>({
    method: PUT,
    url: budgetingScenarioEndpoints.lock(companyId, scenarioId)
  })
}

export const unLockBudgetingScenario = async (companyId: string, scenarioId: BudgetingScenario['id']) => {
  return backendRequest<BudgetingScenario>({
    method: PUT,
    url: budgetingScenarioEndpoints.unLock(companyId, scenarioId)
  })
}
