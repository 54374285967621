import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useBudgetingScenarioOptions } from './useBudgetingScenarioOptions'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'

export const useBudgetingScenarios = () => {
  const companyId = useSelector(contextCompanyIdSelector)

  if (!companyId) {
    throw new Error('Company ID is required for budgeting scenarios')
  }

  const options = useBudgetingScenarioOptions({ companyId })
  return useQuery(options)
}
