import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useCustomReportOptions } from './useCustomReportOptions'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'

export const useCtxCustomReports = () => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const options = useCustomReportOptions(companyId)
  return useQuery(options)
}
