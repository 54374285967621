import React, { useMemo } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons'
import { App, Button, Select, Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import { VariableType } from '../../../components/Table/types'
import { Loan } from '../types/Loan'
import { transformFieldData } from '../../../utils/form'
import { formatValueByType, propertyFilterProps, propertySorterProps } from '../../../utils/helpers'
import { dimensionByIdSelector, dimensionListSelector } from '../../../redux/context/dimensions/selectors'
import { Purpose } from '../../../types/purpose/Purpose'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import EditableRowTable from '../../../components/Table/EditableRowTable'
import DimensionSelect from '../../../components/Dimension/DimensionSelect'
import LoanPurposesSelect from './LoanPurposesSelect'
import { loanRepaymentMethodsSelector } from '../../../redux/entities/loanRepaymentMethods/selectors'
import { normalizedConfigSelector } from '../../../redux/config/selectors'
import { liabilityMappingSelector } from '../../../redux/config/liabilityMapping/selectors'
import AccountSelect from '../../account/components/AccountSelect'
import { useUpdateLoan } from '../queries/useUpdateLoan'
import { useDeleteLoan } from '../queries/useDeleteLoan'
import loanQueryKeys from '../queries/loanQueryKeys'
import { useColumnSearchProps } from '../../../components/Table/useColumSearchProps'
import { store } from '../../../redux/store'
import { useFiscalYearToDateLabel } from '../../investment/hooks/hooks'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import { Investment } from '../../../types/investment/Investment'
import LoanTableActions from './LoanTableActions'
import CustomHeaderCell from '../../../components/Table/CustomHeaderCell'
import { useColumnRangePicker } from '../../../components/Table/useColumnRangePicker'

interface LoanTableProps {
  loans: Loan[]
  toggleModal: (investment?: Investment) => void
  toggleRepaymentSchedule: (e: React.MouseEvent<HTMLElement, MouseEvent>, loan: Loan) => void
  toggleInterestRate: (e: React.MouseEvent<HTMLElement, MouseEvent>, loan: Loan) => void
  balanceSheetRowId: number
  isExisting: boolean
}

const transform = {
  nextInstalmentDate: (val: string) => dayjs(val),
  purposes: (val: Purpose[]) => val.map(p => p.name)
}

const infoTooltip = (text: string) => (
  <Tooltip placement="top" title={text}>
    <InfoCircleOutlined style={{ verticalAlign: 'middle', marginLeft: 8 }} />
  </Tooltip>
)

const LoanTable: React.FC<LoanTableProps> = ({
  loans,
  balanceSheetRowId,
  isExisting,
  toggleModal,
  toggleRepaymentSchedule,
  toggleInterestRate
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const loanRepaymentMethods = useSelector(loanRepaymentMethodsSelector)
  const {
    financialStatement: { financialExpenseRowId }
  } = useSelector(normalizedConfigSelector)
  const liabilityMapping = useSelector(liabilityMappingSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const fiscalYearToDateLabel = useFiscalYearToDateLabel()
  const { message } = App.useApp()
  const { date } = useSelector(investmentsPageSelector)
  const queryClient = useQueryClient()
  const updateLoan = useUpdateLoan()
  const deleteLoan = useDeleteLoan()

  const dateLabel = dayjs(date).format('MM/YYYY')

  const loanArray = useMemo(
    () =>
      loans
        .slice()
        .sort((a: Loan, b: Loan) => a.id - b.id)
        .map(l => {
          return {
            ...transformFieldData(l, transform),
            removable: l?.id && location.pathname.includes('existing') && !budgetingScenario?.isLocked
          }
        }),
    [loans]
  )
  const dimensions = useSelector(dimensionListSelector)

  const columns: any = [
    {
      title: t('loansTable:purposes'),
      dataIndex: 'purposes',
      editable: true,
      type: 'custom',
      width: 140,
      fixed: 'left' as const,
      ...useColumnSearchProps('purposes'),
      inputField: LoanPurposesSelect,
      render: (value: Purpose[]) => {
        return value?.join(', ')
      }
    },
    {
      title: t('loansTable:value'),
      dataIndex: 'value',
      key: 'value',
      align: 'right' as const,
      editable: true,
      type: 'number',
      width: 140,
      fixed: 'left' as const,
      ...useColumnSearchProps('value'),
      sorter: { compare: (a: Loan, b: Loan) => a.value - b.value },
      render: (value: number) => formatValueByType(value, VariableType.absolute)
    },
    {
      title: t('loansTable:nextInstalmentDate'),
      dataIndex: 'nextInstalmentDate',
      align: 'right' as const,
      width: 120,
      editable: true,
      type: 'date',
      sorter: {
        compare: (a: Loan, b: Loan) => dayjs(a.nextInstalmentDate).diff(b.nextInstalmentDate)
      },
      ...useColumnRangePicker(['nextInstalmentDate']),
      render: (value: Dayjs) => value.format('DD.MM.YYYY')
    },
    {
      title: t('loansTable:financialExpensesAccountCode'),
      dataIndex: 'financialExpensesAccountCode',
      key: 'financialExpensesAccountCode',
      width: 145,
      editable: true,
      align: 'right' as const,
      type: 'custom',
      ...propertyFilterProps(loans, ['financialExpensesAccountCode']),
      sorter: propertySorterProps('financialExpensesAccountCode'),
      inputField: AccountSelect,
      inputFieldProps: { balanceSheetRowIds: financialExpenseRowId }
    },
    {
      title: t('loansTable:longTermAccountCode'),
      dataIndex: 'longTermAccountCode',
      key: 'longTermAccountCode',
      width: 115,
      editable: true,
      align: 'right' as const,
      type: 'custom',
      inputField: AccountSelect,
      inputFieldProps: { balanceSheetRowIds: [balanceSheetRowId] },
      sorter: propertySorterProps('longTermAccountCode'),
      ...propertyFilterProps(loans, ['longTermAccountCode'])
    },
    {
      title: t('loansTable:shortTermAccountCode'),
      dataIndex: 'shortTermAccountCode',
      key: 'shortTermAccountCode',
      width: 115,
      editable: true,
      align: 'right' as const,
      type: 'custom',
      inputField: AccountSelect,
      inputFieldProps: {
        balanceSheetRowIds: [liabilityMapping?.[balanceSheetRowId]]
      },
      sorter: propertySorterProps('shortTermAccountCode'),
      ...propertyFilterProps(loans, ['shortTermAccountCode'])
    },
    {
      title: (
        <div>
          {t('loansTable:term')}
          {infoTooltip(t('loansTable:term/info'))}
        </div>
      ),
      align: 'center' as const,
      editable: true,
      children: [
        {
          title: t('loansTable:termYears', { count: 2 }),
          dataIndex: 'termYears',
          align: 'right' as const,
          width: 100,
          editable: true,
          type: 'number',
          sorter: propertySorterProps('termYears'),
          ...propertyFilterProps(loans, ['termYears'])
        },
        {
          title: t('loansTable:termMonths', { count: 2 }),
          dataIndex: 'termMonths',
          align: 'right' as const,
          width: 100,
          editable: true,
          type: 'number',
          sorter: propertySorterProps('termMonths'),
          ...propertyFilterProps(loans, ['termMonths'])
        }
      ]
    },
    {
      title: (
        <div>
          {t('loansTable:repaymentProgram')}
          {infoTooltip(t('loansTable:repaymentProgram/info'))}
        </div>
      ),
      align: 'center' as const,
      editable: true,
      children: [
        {
          title: t('loansTable:repaymentProgramYears', { count: 2 }),
          dataIndex: 'repaymentProgramYears',
          align: 'right' as const,
          width: 100,
          editable: true,
          type: 'number',
          sorter: propertySorterProps('repaymentProgramYears'),
          ...propertyFilterProps(loans, ['repaymentProgramYears'])
        },
        {
          title: t('loansTable:repaymentProgramMonths', { count: 2 }),
          dataIndex: 'repaymentProgramMonths',
          align: 'right' as const,
          width: 100,
          editable: true,
          type: 'number',
          sorter: propertySorterProps('repaymentProgramMonths'),
          ...propertyFilterProps(loans, ['repaymentProgramMonths'])
        }
      ]
    },
    {
      title: t('loansTable:yearlyInstalments'),
      dataIndex: 'yearlyInstalments',
      align: 'right' as const,
      width: 130,
      editable: true,
      type: 'number',
      sorter: propertySorterProps('yearlyInstalments'),
      ...propertyFilterProps(loans, ['yearlyInstalments'])
    },
    {
      title: (
        <div>
          {t('loansTable:instalmentFreePeriod')}
          {infoTooltip(t('loansTable:instalmentFreePeriod/info'))}
        </div>
      ),
      dataIndex: 'instalmentFreePeriod',
      align: 'right' as const,
      width: 100,
      editable: true,
      type: 'number',
      sorter: propertySorterProps('instalmentFreePeriod'),
      ...propertyFilterProps(loans, ['instalmentFreePeriod'])
    },
    {
      title: t('loansTable:loanRepaymentMethodId'),
      dataIndex: 'loanRepaymentMethodId',
      align: 'right' as const,
      width: 130,
      editable: true,
      type: 'custom',
      inputField: React.forwardRef((props: any, ref: React.Ref<any>) => (
        <Select ref={ref} {...props} showSearch optionFilterProp="children">
          {loanRepaymentMethods.map(loanRepaymentMethod => {
            return (
              <Select.Option key={loanRepaymentMethod.id} value={loanRepaymentMethod.id}>
                {t(`loanRepaymentMethod:${loanRepaymentMethod.id}`)}
              </Select.Option>
            )
          })}
        </Select>
      )),
      sorter: propertySorterProps('loanRepaymentMethods'),
      filters: loanRepaymentMethods.map(({ id, name }) => ({
        text: name,
        value: id
      })),
      onFilter: (value: number, record: Loan) => record.loanRepaymentMethodId === value,
      render: (val: number) => t(`loanRepaymentMethod:${val}`)
    },
    {
      title: t('loansTable:interestRate'),
      dataIndex: 'interestRate',
      align: 'right' as const,
      width: 100,
      editable: true,
      type: 'percent',
      sorter: propertySorterProps('interestRate'),
      ...propertyFilterProps(loans, ['interestRate']),
      render: (value: number) => formatValueByType(value, VariableType.percentage, { maximumFractionDigits: 4 })
    },
    {
      title: t('loansTable:dimensionId'),
      dataIndex: 'dimensionId',
      align: 'right' as const,
      width: 130,
      editable: true,
      type: 'custom',
      inputField: DimensionSelect,
      render: (val: string) => {
        const di = dimensions?.find(d => d.dimensionId === val)
        return di?.name
      },
      // dimensionByIdSelector
      sorter: propertySorterProps('dimensionId'),
      filters: [...new Set(loans.map(c => c.dimensionId))].map(value => ({
        text: dimensionByIdSelector(value as string)(store.getState() as any)?.name,
        value
      })),
      onFilter: (value: string, record: Loan) => record.dimensionId === value
    },
    {
      title: `${t('loansTable:summary/interestValue')} ${dateLabel}`,
      dataIndex: ['summary', 'interestValue'],
      align: 'right' as const,
      width: 80,
      sorter: propertySorterProps(['summary', 'interestValue']),
      render: (value: number) => formatValueByType(value, VariableType.absolute)
    },
    {
      title: `${t('loansTable:summary/instalmentValue')} ${dateLabel}`,
      dataIndex: ['summary', 'instalmentValue'],
      align: 'right' as const,
      width: 80,
      sorter: propertySorterProps(['summary', 'instalmentValue']),
      render: (value: number) => formatValueByType(value, VariableType.absolute)
    },
    {
      title: `${t('loansTable:summary/fiscalYearInterestValue')} ${fiscalYearToDateLabel}`,
      dataIndex: ['summary', 'fiscalYearInterestValue'],
      align: 'right' as const,
      width: 80,
      sorter: propertySorterProps(['summary', 'fiscalYearInterestValue']),
      render: (value: number) => formatValueByType(value, VariableType.absolute)
    },
    {
      title: `${t('loansTable:summary/fiscalYearInstalmentValue')} ${fiscalYearToDateLabel}`,
      dataIndex: ['summary', 'fiscalYearInstalmentValue'],
      align: 'right' as const,
      width: 80,
      sorter: propertySorterProps(['summary', 'fiscalYearInstalmentValue']),
      render: (value: number) => formatValueByType(value, VariableType.absolute)
    },
    {
      title: t('loansTable:summary/budgetBalance', 'Loppusaldo'),
      dataIndex: ['summary', 'budgetBalance'],
      align: 'right' as const,
      width: 80,
      fixed: 'right' as const,
      sorter: propertySorterProps(['summary', 'budgetBalance']),
      render: (value: number) => formatValueByType(value, VariableType.absolute)
    },
    {
      title: t('loansTable:repaymentSchedule'),
      dataIndex: 'repaymentSchedule',
      key: 'repaymentSchedule',
      width: 65,
      align: 'center' as const,
      fixed: 'right' as const,
      render: (value: any, record: Loan) => (
        <Tooltip placement="top" title={t('loansTable:repaymentSchedule')}>
          <Button
            block
            size="small"
            style={{ marginRight: '8px' }}
            icon={<OrderedListOutlined />}
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              return toggleRepaymentSchedule(e, record)
            }}
          />
        </Tooltip>
      )
    },
    {
      title: t('loansTable:interestRates'),
      dataIndex: 'interestRates',
      key: 'interestRates',
      width: 65,
      align: 'center' as const,
      fixed: 'right' as const,
      render: (value: any, record: Loan) => (
        <Tooltip placement="top" title={t('loansTable:changing-interest')}>
          <Button
            block
            size="small"
            style={{ marginRight: '8px' }}
            icon={<OrderedListOutlined />}
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              return toggleInterestRate(e, record)
            }}
          />
        </Tooltip>
      )
    },
    {
      title: t('global:actions'),
      dataIndex: 'actions',
      width: 80,
      align: 'center' as const,
      fixed: 'right' as const,
      render: (_: any, loan: Loan) => {
        return <LoanTableActions isExisting={isExisting} toggleModal={toggleModal} loan={loan} />
      }
    }
  ]

  const handleSubmit = (loanData: Loan) => {
    const values = {
      ...loanData,
      nextInstalmentDate: dayjs(loanData.nextInstalmentDate).format('YYYY-MM-DD'),
      balanceSheetRowId: loanData.balanceSheetRowId,
      value: loanData.value,
      interestRate: loanData.interestRate,
      purposes: (loanData.purposes as unknown as string[])?.map((p: string) => {
        return { name: p }
      }) as Purpose[]
    }

    updateLoan.mutate(values, {
      onSuccess() {
        message.success(t('messages:updateSuccess', 'Success'))
        queryClient.invalidateQueries({
          queryKey: loanQueryKeys.summaries()
        })
      }
    })
  }

  const handleRowDelete = async (loan: Loan) => {
    await deleteLoan.mutateAsync(loan.id, {
      onSuccess: () => {
        message.success(t('messages:deleteSuccess', 'Success'))
        queryClient.invalidateQueries({
          queryKey: loanQueryKeys.summaries()
        })
      }
    })
  }

  return isExisting ? (
    <EditableRowTable<Loan>
      scroll={{ x: 1900 + 150 }}
      size="small"
      rowKey="id"
      bordered
      pagination={false}
      components={{
        header: {
          cell: CustomHeaderCell
        }
      }}
      dataSource={loanArray}
      columns={columns.filter((c: any) => {
        return c.dataIndex !== 'actions'
      })}
      handleSave={handleSubmit}
      handleRowDelete={handleRowDelete}
    />
  ) : (
    <Table<Loan>
      scroll={{ x: 1900 + 150 }}
      size="small"
      rowKey="id"
      bordered
      components={{
        header: {
          cell: CustomHeaderCell
        }
      }}
      pagination={false}
      dataSource={loanArray}
      columns={columns.filter((c: any) => {
        return c.dataIndex !== 'purposes'
      })}
    />
  )
}

export default LoanTable
