import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Input, Select, Modal, DatePicker, Form, Radio, Space, Alert, Table, TableProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { FormInstance, useWatch } from 'antd/es/form/Form'
import { BudgetingScenarioTransfer, ForecastType } from '../../../../../../features/scenario/types/BudgetingScenario'
import ScenarioMapTable from './ScenarioMapTable'
import { Company } from '../../../../../../types/company/Company'
import { availableEntitiesSelector } from '../../../../../../redux/context/contract/selectors'
import { ReportDataType } from '../../../../../../redux/context/reports/types'
import ScenarioSelect from '../../../../../../features/scenario/components/select/ScenarioSelect'
import { getEntityTransfers } from '../utils'
import { useScenario } from '../../../../../../features/scenario/queries/useScenario'
import {
  useCtxCompanyGroupBudgetSource,
  useCtxCompanyGroupSourceSubsidiaries
} from '../../../../../../features/companyGroup/queries/companyGroupSetting/useCtxCompanyGroupSettings'
import { GroupSettingDataSource } from '../../../../../../features/companyGroup/types/CompanyGroupSettings'

interface BudgetingScenarioFormProps {
  modalVisible: boolean
  handleOk: (e: BudgetingScenarioTransfer[]) => void
  handleCancel: (e: React.MouseEvent<HTMLElement>) => void
  form: FormInstance
  page: 'budgetingScenarios' | 'forecasts'
  selectedGroup?: Company
  loading?: boolean
}

type TransferEntity = {
  name: string
  key: string
}

const BudgetingScenarioForm = ({
  modalVisible,
  handleOk,
  handleCancel,
  form,
  page,
  selectedGroup,
  loading
}: BudgetingScenarioFormProps) => {
  const { t } = useTranslation()
  const { data: groupBudgetSourceSetting } = useCtxCompanyGroupBudgetSource()
  const { data: isGroupSourceSubsidiaries } = useCtxCompanyGroupSourceSubsidiaries()

  const budgetingScenarioName = form.getFieldValue('name')
  const budgetingScenarioForecastType = useWatch('forecastType', form)
  const availableEntities = useSelector(availableEntitiesSelector)
  const [selectedTableRows, setSelectedTableRows] = useState<React.Key[]>([])
  const scenarioId = useWatch('id', form) as number
  const scenario = useScenario({ scenarioId })

  useEffect(() => {
    setSelectedTableRows([])
  }, [modalVisible])

  useEffect(() => {
    form.setFieldsValue({
      transfers: getEntityTransfers(availableEntities, scenario?.transferLog)
    })
  }, [scenarioId])

  const columns: TableProps<TransferEntity>['columns'] = [
    {
      title: t('global:name'),
      dataIndex: 'name',
      render: (text: string) => {
        return t(`budgetingScenarioEntity:${text}`, text)
      }
    },
    {
      title: t('global:scenarioSlashForecast'),
      dataIndex: 'key',
      width: '50%',
      render: (entity, record, index) => {
        const entityEnabled = selectedTableRows?.includes(entity)

        const validateSourceId = (_: any, value: number | null) => {
          if (value === undefined && entityEnabled) {
            // Reject undefined when row is selected
            return Promise.reject(new Error(t('global:required-field')))
          }
          // Allow null or number (valid BudgetingScenario['id'])
          return Promise.resolve()
        }

        return (
          <>
            <Form.Item hidden name={[index, 'entity']} initialValue={entity} />
            <Form.Item
              noStyle
              name={[index, 'sourceId']}
              rules={[
                {
                  validator: validateSourceId
                }
              ]}
            >
              <ScenarioSelect
                optionSelector={data =>
                  data.map(s => ({
                    label: s.name,
                    value: s.id,
                    disabled: s.id === scenarioId
                  }))
                }
                disabled={!entityEnabled}
              />
            </Form.Item>
          </>
        )
      }
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedTableRows(selectedRowKeys)
    },
    getCheckboxProps: (record: { name: string }) => ({
      name: record.name
    }),
    selectedRowKeys: selectedTableRows
  }

  const handleOkPrep = () => {
    form
      .validateFields()
      .then(() => {
        const transfers = form.getFieldValue('transfers') || []
        const filteredArray = transfers?.filter((transfer: BudgetingScenarioTransfer) =>
          selectedTableRows.some(filterItem => filterItem === transfer.entity)
        )
        handleOk(filteredArray)
      })
      .catch(error => console.error(error))
  }

  const modalTitle = () => {
    if (scenarioId || scenarioId === null) {
      return `${t('global:edit')} ${budgetingScenarioName}`
    }

    return `${t('global:add-new')} ${page === 'budgetingScenarios' ? t('global:scenario') : t('global:forecast')}`
  }

  return (
    <Modal
      width={800}
      title={modalTitle()}
      maskClosable={false}
      open={modalVisible}
      onOk={() => handleOkPrep()}
      onCancel={handleCancel}
      okText={form.getFieldValue('id') ? t('global:update') : t('global:save')}
      cancelText={t('global:cancel')}
      confirmLoading={loading}
      destroyOnClose
    >
      <Form layout="vertical" form={form}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          rules={[{ required: true, message: t('global:required-field') }]}
          label={t('global:name')}
        >
          {/* Disabled for default scenario */}
          <Input placeholder={t('global:name')} disabled={scenarioId === null} />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[{ required: true, message: t('global:required-field') }]}
          label={t('global:description')}
        >
          {/* Disabled for default scenario */}
          <Input placeholder={t('global:description')} disabled={scenarioId === null} />
        </Form.Item>

        {!isGroupSourceSubsidiaries && availableEntities?.length > 0 && (
          <>
            <Alert message={t('budgetingScenarioPage:entitiesWarnig')} type="warning" showIcon />
            <Space direction="vertical" style={{ width: '100%', marginBottom: 10 }}>
              <Form.List name="transfers">
                {() => (
                  <Table<TransferEntity>
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection
                    }}
                    columns={columns}
                    pagination={false}
                    dataSource={availableEntities.map(a => ({ name: a, key: a }))}
                  />
                )}
              </Form.List>
            </Space>
          </>
        )}

        {page === 'forecasts' && !isGroupSourceSubsidiaries && (
          <>
            <Form.Item
              initialValue={budgetingScenarioForecastType === 'MANUAL' ? 'manual' : 'rolling'}
              name="selectedForecastType"
              label={t('budgetingScenarioPage:selectForecastType')}
            >
              <Select>
                <Select.Option key="rolling" value="rolling">
                  {t('budgetingScenarioPage:rolling')}
                </Select.Option>
                <Select.Option key="manual" value="manual">
                  {t('budgetingScenarioPage:manual')}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues?.selectedForecastType !== currentValues?.selectedForecastType
              }}
            >
              {({ getFieldValue }) =>
                getFieldValue(['selectedForecastType']) === 'manual' ? (
                  <Form.Item
                    rules={[{ required: true, message: t('global:required-field') }]}
                    name="forecastCursor"
                    label={t('budgetingScenarioPage:select-cursor-filter')}
                  >
                    <DatePicker.MonthPicker format="YYYY-MM" placeholder={t('global:select-month')} />
                  </Form.Item>
                ) : (
                  <Form.Item initialValue={ForecastType.CURRENT} name="forecastType">
                    <Radio.Group>
                      <Radio key={ForecastType.CURRENT} value={ForecastType.CURRENT}>
                        {t('budgetingScenarioPage:CURRENT')}
                      </Radio>
                      <Radio key={ForecastType.PREVIOUS} value={ForecastType.PREVIOUS}>
                        {t('budgetingScenarioPage:PREVIOUS')}
                      </Radio>
                      {groupBudgetSourceSetting !== GroupSettingDataSource.subsidiaries && (
                        <Radio key={ForecastType.PERIOD_LOCK} value={ForecastType.PERIOD_LOCK}>
                          {t('budgetingScenarioPage:PERIOD_LOCK')}
                        </Radio>
                      )}
                    </Radio.Group>
                  </Form.Item>
                )
              }
            </Form.Item>
          </>
        )}
        {groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries && (
          <ScenarioMapTable
            selectedGroup={selectedGroup}
            dataType={page === 'forecasts' ? ReportDataType.forecast : ReportDataType.budget}
          />
        )}
      </Form>
    </Modal>
  )
}

export default BudgetingScenarioForm
