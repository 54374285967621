import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { getSubsidiariesRequest } from '../../../../../redux/context/subsidiaries/actions'

const ExchangeRatePage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const groupId = useSelector(contextCompanyIdSelector)!

  useEffect(() => {
    dispatch(getSubsidiariesRequest(groupId))
    if (location.pathname.endsWith('edit')) {
      navigate(-1)
    }
  }, [groupId])

  return (
    <div>
      <Outlet />
    </div>
  )
}

export default ExchangeRatePage
