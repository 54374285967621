import { DownSquareOutlined, UpSquareOutlined } from '@ant-design/icons'
import { Button, Col, Form, Row, Space, Switch, Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/es/form/Form'
import _, { Dictionary } from 'lodash'
import { GroupSettingData, GroupSettingDataSource } from '../../../features/companyGroup/types/CompanyGroupSettings'
import { contextCompanyIdSelector, contextCompanySelector } from '../../../redux/context/company/selectors'
import { useBackend } from '../../../services/backend'
import { Company } from '../../../types/company/Company'
import { StatementRow } from '../../../types/statementRow/StatementRow'
import AccountMapEditor from './AccountMapEditor'
import { contextAccountMapRulesGroupedByStatementRowIdSelector } from '../../../redux/context/accountMapRules/selectors'
import { AccountMapRule } from '../../../types/accountMapRule/AccountMapRule'
import { objectMap } from '../../../utils/helpers'
import { updateContextAccountMapRulesRequest } from '../../../redux/context/accountMapRules/actions'
import ToolBar from '../../Misc/ToolBar'
import SubmitButton from '../../Misc/SubmitButton'
import AccountMapExpandedTable from './AccountMapExpandedTable'
import { AppDispatch } from '../../../redux/store'
import { flattenGroup } from './utils'

interface AccounMapTableProps {
  translation: string
  statementRows: StatementRow[]
  loading: boolean
}

const AccountMapTable: React.FC<AccounMapTableProps> = ({ translation, statementRows, loading }) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const companyId = useSelector(contextCompanyIdSelector)
  const company = useSelector(contextCompanySelector)!
  const groupRequest = useBackend(`api/companies/${companyId}/group`)
  const [accountMapCompanies, setAccountMapCompanies] = useState<Company[]>([])
  const [form] = useForm<Dictionary<AccountMapRule[]>>()
  const rules = useSelector(contextAccountMapRulesGroupedByStatementRowIdSelector)
  const groupSettingsRequest = useBackend(`/api/companies/${companyId}/group/settings`)
  const [editTable, setEditTable] = useState(false)

  const getCompanies = async () => {
    const groupSettings: GroupSettingData = await groupSettingsRequest.get()

    if (groupSettings.actuals.source === GroupSettingDataSource.group) {
      setAccountMapCompanies([company])
    }
    if (groupSettings.actuals.source === GroupSettingDataSource.subsidiaries) {
      const groupCompany = await groupRequest.get()
      setAccountMapCompanies(flattenGroup(groupCompany))
    }
  }
  useEffect(() => {
    getCompanies()

    return () => {
      setAccountMapCompanies([])
    }
  }, [companyId])

  const toggleAllSelected = () => {
    const allIds = statementRows.map((row: StatementRow) => row.id.toString())
    allIds.length === selectedKeys.length ? setSelectedKeys([]) : setSelectedKeys(allIds)
  }

  const getIcon = (isOpen: boolean) => {
    return isOpen ? <UpSquareOutlined /> : <DownSquareOutlined />
  }

  const columns = [
    {
      title: () => (
        <span>
          <Button
            style={{
              marginRight: '8px',
              marginLeft: '-30px',
              cursor: 'pointer'
            }}
            icon={getIcon((statementRows || []).length === selectedKeys.length)}
            type="text"
          />
          {t('accountMapPage:name')}
        </span>
      ),
      dataIndex: 'title',
      rowSpan: 2,
      key: 'id',
      onHeaderCell: () => {
        return {
          onClick: () => {
            toggleAllSelected()
          }
        }
      },
      render: (text: string, statementRow: StatementRow) => {
        return statementRow.isParent ? (
          <b>{t(`${translation}:${statementRow.id}`)}</b>
        ) : (
          t(`${translation}:${statementRow.id}`)
        )
      }
    }
  ]

  const toggleSelectedKeys = (id: string) => {
    const index = selectedKeys.indexOf(id)
    index < 0 ? setSelectedKeys([...selectedKeys, id]) : setSelectedKeys(selectedKeys.filter((k, i) => index !== i))
  }

  const expandIconRender = ({ record }: any) => {
    const id = record.id.toString()
    return record.isParent ? null : (
      <Button
        type="text"
        onClick={() => toggleSelectedKeys(id)}
        icon={getIcon(selectedKeys.includes(record.id.toString()))}
      />
    )
  }

  const transformData = () => {
    const grouped = objectMap(rules, v => _.groupBy(v, 'companyId'))
    return grouped
  }

  useEffect(() => {
    form.setFieldsValue(transformData())
    const reset = () => {
      if (rules) {
        form.resetFields()
      }
    }

    return reset
  }, [rules])

  const onFinnish = (values: Dictionary<AccountMapRule[]>) => {
    const updated = Object.values(
      Object.entries(values).reduce((acc, [key, value]) => {
        acc[key] = Object.values(value)
          .filter(Boolean)
          .flatMap(arr => arr)
        return acc
      }, rules)
    ).flatMap(arr => arr)
    companyId && dispatch(updateContextAccountMapRulesRequest(companyId, updated))
    setEditTable(false)
  }

  return (
    <div style={{ marginBlock: 8 }}>
      <Form onFinish={onFinnish} form={form}>
        <Space direction="vertical" size="middle">
          <ToolBar offsetTop={70}>
            <Row align="middle" justify="space-between" style={{ width: '100%' }}>
              <Col>
                <Space>
                  <Switch
                    checked={editTable}
                    onChange={val => {
                      setEditTable(val)
                    }}
                  />
                  <Typography.Text>{t('global:edit')}</Typography.Text>
                </Space>
              </Col>
              <Col>{editTable && <SubmitButton />}</Col>
            </Row>
          </ToolBar>
          <Table
            className="account-map-table"
            size="small"
            tableLayout="fixed"
            columns={columns}
            rowKey={statementRow => statementRow.id.toString()}
            loading={loading}
            expandable={{
              expandIcon: expandIconRender,
              defaultExpandAllRows: true,
              expandedRowKeys: selectedKeys,
              expandedRowRender: (statementRow: StatementRow) => {
                if (statementRow.isParent) return null
                return editTable ? (
                  <AccountMapEditor companies={accountMapCompanies} statementRow={statementRow} />
                ) : (
                  <AccountMapExpandedTable companies={accountMapCompanies} statementRow={statementRow} />
                )
              }
            }}
            dataSource={statementRows}
            pagination={false}
          />
        </Space>
      </Form>
    </div>
  )
}

export default AccountMapTable
