const entitiesEndpoints = {
  entities: '/entities',
  contractProducts: '/contract-products',
  industries: '/industries',
  investmentFundingSources: '/investment-funding-sources',
  keyFigures: '/key-figures',
  loanRepaymentMethods: '/loan-repayment-methods',
  permissions: '/permissions',
  statementRows: '/statement-rows'
}

export default entitiesEndpoints
