import { useQuery } from '@tanstack/react-query'
import companyQueryKeys from './companyQueryKeys'
import { fetchcompanies } from '../service/companyService'
import { CompanySearchFilters } from '../types/CompanySearchFilters'

export const useCompaniesSearch = (filters: CompanySearchFilters = {}) => {
  return useQuery({
    queryKey: companyQueryKeys.list(filters),
    queryFn: fetchcompanies,
    enabled: Object.values(filters).some(Boolean)
  })
}
