import { createSelector } from 'reselect'
import { Store } from '../../types'
import { CustomReport } from './types'
import { QuickFilterForm } from '../../../pages/reporting/custom/components/QuickFilters'

export const calculatedReportSelector = (state: Store): CustomReport | null => state.context.customReports.calculated

export const customReportDateSelector = (state: Store): string | undefined => {
  return state.context.customReports.date
}

export const customReportQuickFiltersSelector = (state: Store): QuickFilterForm | undefined => {
  return state.context.customReports.quickFilters
}

export const customReportSortSelector = (state: Store) => {
  return state.context.customReports.sort
}

export const activeReportKeySelector = createSelector(calculatedReportSelector, report => {
  return report?.id ? report?.id.toString() : ''
})
