import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useFiscalYearOptions } from '../useFiscalYearOptions'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { FiscalYear } from '../../../../types/fiscalYear/FiscalYear'

export const useCtxFiscalYears = <TData = FiscalYear[]>(select?: (data: FiscalYear[]) => TData) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const options = useFiscalYearOptions(companyId, select)
  return useQuery(options)
}
