import React, { useState } from 'react'
import { BookOutlined, CalendarOutlined, NotificationOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useDocsURL } from '../../utils/docs'
import { JiraRequestModal } from '../../features/jira/components/JiraRequestModal'

const HelpMenu = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const [modalOpen, setModalOpen] = useState(false)

  const items = [
    {
      key: 'docs',
      label: t('header:manual'),
      icon: <BookOutlined />,
      onClick: () => window.open(docsURL, 'noopener noreferrer')
    },
    {
      key: 'serviceRequest',
      label: t('header:contact'),
      icon: <NotificationOutlined />,
      onClick: () => setModalOpen(true)
    },
    {
      key: 'demo',
      label: t('header:demo'),
      icon: <CalendarOutlined />,
      onClick: () =>
        window.open(
          `https://calendly.com/finadeck/finadeck-demo?month=${dayjs().format('YYYY-MM')}`,
          '_blank',
          'noopener noreferrer'
        )
    }
  ]

  const menuProps = {
    items
  }

  return (
    <>
      <JiraRequestModal open={modalOpen} onCreate={() => setModalOpen(false)} onCancel={() => setModalOpen(false)} />
      <Dropdown placement="bottom" menu={menuProps}>
        <QuestionCircleOutlined />
      </Dropdown>
    </>
  )
}

export default HelpMenu
