import _, { isArray } from 'lodash'
import { ReportTableRow } from '../../../../../components/Table/types'
import { getGroupPeriods } from '../../../../../components/Table/utils'
import { ReportDataType, Balance } from '../../../../../redux/context/reports/types'
import { FiscalYear, Period } from '../../../../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../../../../types/periodGroup/PeriodGroup'
import { Budget, BudgetingMethod } from './types'

enum DistributionMode {
  Evenly,
  Weights,
  ToAllFiscalYearMonths
}

export const parseArrayFromString = (inputString = '') => {
  // Use a regular expression to extract the part within the parentheses
  if (isArray(inputString)) return inputString
  const match = inputString?.match(/\[([^)]+)\]/)

  if (match) {
    const arrayString = match[1] // Extract the content within the parentheses
    try {
      const array = JSON.parse(`[${arrayString}]`)
      return array
    } catch (error) {
      return null
    }
  }

  return null // Return null if no array is found
}

export const hasSamePeriods = (a: FiscalYear | PeriodGroup, b?: FiscalYear | PeriodGroup): boolean => {
  if (!b || !a) return false

  const aPeriods = a.periods
  const bPeriods = b.periods

  return aPeriods[0].month === bPeriods[0].month && aPeriods.length === bPeriods.length
}

export const getDistributionWeightsEnabled = (
  ids: React.Key[],
  rowBudgets: (Budget & { reportTableRow: ReportTableRow })[] | undefined,
  lastClosedFiscalYear?: FiscalYear | null
): boolean => {
  if (!rowBudgets || !lastClosedFiscalYear) return false
  for (const rowBudget of rowBudgets.filter(b =>
    ids.includes(`${b.periodGroup?.id}-${b.code}-${b.dimensionId}-${b.dataType}` || -1)
  )) {
    const periodGroups = rowBudget.periodGroup
    const samePeriods = hasSamePeriods(lastClosedFiscalYear, periodGroups)
    const lastClosedFiscalYearActualValue = rowBudget?.reportTableRow?.actuals?.find(
      (a: any) => a.groupId === lastClosedFiscalYear?.id
    )?.value
    if (
      !lastClosedFiscalYearActualValue ||
      !samePeriods ||
      rowBudget?.reportTableRow?.budgetingMethod?.method !== BudgetingMethod.absolute
    ) {
      return false
    }
  }
  return true
}

// Laske painoarvot
export const calculatePeriodWeights = (
  actuals: ReportTableRow[ReportDataType.actuals],
  lastClosedFiscalYear?: FiscalYear | null
): number[] => {
  if (!lastClosedFiscalYear) return []
  const lastClosedBalance = actuals?.find((balance: Balance) => balance.groupId === lastClosedFiscalYear.id)
  const weights = lastClosedFiscalYear.periods.map(period => {
    const periodBalance = actuals?.find(
      (balance: Balance) => balance.year === period.year && balance.month === period.month
    )
    if (lastClosedBalance && periodBalance) {
      return (periodBalance.value ?? 0) / (lastClosedBalance.value ?? 0)
    }
    return 0
  })
  return weights
}

export const calculateDistributionValue = (
  distributionMode: DistributionMode,
  value: number,
  periods: Period[],
  index: number,
  periodWeights: number[]
) => {
  if (distributionMode === DistributionMode.ToAllFiscalYearMonths) {
    return value
  }
  if (distributionMode === DistributionMode.Evenly) {
    return value / periods.length
  }
  if (distributionMode === DistributionMode.Weights) {
    return value * periodWeights[index]
  }
  return value
}

export const distributePeriodGroupBudgets = (
  rowBudget: Budget[],
  selectedPeriodGroupsIds: React.Key[],
  distributionMode: DistributionMode,
  lastClosedFiscalYear?: FiscalYear | null,
  reportTableRow?: ReportTableRow
): Budget[] => {
  return _.cloneDeep(rowBudget)
    .filter(rb =>
      selectedPeriodGroupsIds.includes(`${rb.periodGroup?.id}-${rb.code}-${rb.dimensionId}-${rb.dataType}` || -1)
    )
    .reduce((acc: Budget[], budget: Budget) => {
      const {
        periodGroup: budgetPeriodGroup,
        value,
        incomeStatementRowId,
        accountCode,
        dimensionId,
        dataType,
        formulaId,
        code
      } = budget
      const periods = budgetPeriodGroup ? getGroupPeriods(budgetPeriodGroup) : []
      const periodWeights = calculatePeriodWeights(reportTableRow?.actuals, lastClosedFiscalYear)
      const distributedBudgets = periods.map((period, index) => {
        const { year, month } = period
        return {
          incomeStatementRowId,
          accountCode,
          dimensionId,
          year,
          month,
          dataType,
          formulaId,
          value: calculateDistributionValue(distributionMode, value, periods, index, periodWeights),
          code
        }
      })
      return [...acc, ...distributedBudgets]
    }, [])
}
