import React, { useEffect } from 'react'
import { Card, Typography, List, Divider } from 'antd'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ProCard } from '@ant-design/pro-components'
import { comparableKeyFiguresSelector } from '../../redux/entities/keyFigures/selectors'
import IndustryForm from './components/IndustryForm'
import { contextCompanySelector } from '../../redux/context/company/selectors'
import KeyFigureSelection from '../../components/KeyFigure/KeyFigureSelection'
import { companyDataSelector, industryDataSelector } from '../../redux/context/industryComparison/selectors'
import { getIndustryComparisonData } from '../../redux/context/industryComparison/actions'
import CompetitorChart from './components/Chart'
import { generateCompareChartCategories, generateIndustryChartSeries } from './utils'
import { setIndustryCompetitorKeyfigureSelection } from '../../redux/pages/industryAndCompetitorComparison/actions'
import { industryAndCompetitorComparisonPageSelector } from '../../redux/pages/industryAndCompetitorComparison/selectors'
import { KeyFigure } from '../../types/keyFigure/KeyFigure'
import { AppDispatch } from '../../redux/store'
import { CompanySearchFilters } from '../../features/company/types/CompanySearchFilters'

const IndustryAndCompetitorComparison: React.FC = () => {
  const { t } = useTranslation()
  const contextCompany = useSelector(contextCompanySelector)
  const keyFigures = useSelector(comparableKeyFiguresSelector)
  const companyData = useSelector(companyDataSelector)
  const industryData = useSelector(industryDataSelector)
  const { selectedKeyfigures } = useSelector(industryAndCompetitorComparisonPageSelector)

  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    contextCompany && dispatch(getIndustryComparisonData({ industryId: contextCompany.industryId }, contextCompany.id))
  }, [contextCompany, dispatch])

  const onChange = (keyFigureSelections: number[]) => {
    dispatch(setIndustryCompetitorKeyfigureSelection(keyFigureSelections as number[]))
  }

  const onSave = (values: Partial<CompanySearchFilters>) => {
    contextCompany && dispatch(getIndustryComparisonData(values, contextCompany.id))
  }

  return (
    <ProCard split="vertical">
      <ProCard colSpan="300px">
        <Typography.Title level={4}>{t('comparisonPage:comparison-definition')}</Typography.Title>
        <IndustryForm
          submitText={t('global:update')}
          industryInitialValue={contextCompany && contextCompany.industryId}
          handleSubmit={onSave}
        />
        <Divider />
        <Typography.Title level={4}>{t('comparisonPage:comparison-targets')}</Typography.Title>
        <KeyFigureSelection
          keyFigureTranslation="keyFigureStatement"
          selectableKeyFigures={keyFigures}
          selectedKeyfigures={selectedKeyfigures}
          onChange={onChange}
        />
      </ProCard>
      <ProCard>
        <List
          locale={{ emptyText: t('comparisonPage:no-selected-comparison-targets') }}
          grid={{
            gutter: 16,
            lg: 1,
            xl: 2,
            xxl: 3
          }}
          dataSource={selectedKeyfigures.map((id: number) => _.keyBy(keyFigures, 'id')[id])}
          renderItem={(item: KeyFigure) => (
            <List.Item>
              <Card className="ant-card-hoverable" title={t(`keyFigureStatement:${item.id}`)}>
                <CompetitorChart
                  categories={generateCompareChartCategories()}
                  series={generateIndustryChartSeries(item.id, companyData, industryData)}
                />
              </Card>
            </List.Item>
          )}
        />
      </ProCard>
    </ProCard>
  )
}

export default IndustryAndCompetitorComparison
