import {
  InputNumber,
  Select,
  Checkbox,
  Row,
  Col,
  Radio,
  DatePicker,
  TreeSelect,
  Divider,
  Alert,
  Form,
  FormInstance
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RadioChangeEvent } from 'antd/lib/radio'
import dayjs from 'dayjs'
import { useWatch } from 'antd/es/form/Form'
import {
  currentUserSortedCompaniesSelector,
  currentUserSelector
} from '../../../../../../redux/session/currentUser/selectors'
import { useBackend } from '../../../../../../services/backend'
import { Company } from '../../../../../../types/company/Company'
import {
  DailyDelivery,
  DeliverySubscription,
  DeliverySubscriptionType,
  ManualDelivery,
  MonthlyDelivery,
  PeriodLockDelivery,
  WeeklyDelivery
} from '../../../../../../types/deliverySubscription/DeliverySubscription'
import { User } from '../../../../../../types/user/User'
import { ReportDataType } from '../../../../../../redux/context/reports/types'
import { CompanyDashboard } from '../../../../../../types/dashboard/company/types'
import { Dimension } from '../../../../../../types/dimension/Dimension'
import { mapObjectToFieldData } from '../../../../../../utils/form'
import config from '../config'
import { DeliverySubscriptionFormData, IniatorType } from './DeliverySubscriptionModal'
import { getDimensionTree } from '../../../../../../components/Dimension/utils'
import ScenarioSelect from '../../../../../../features/scenario/components/select/ScenarioSelect'
import CustomReportTreeSelect from '../../../../../../features/customReport/components/treeSelect/CustomReportTreeSelect'

const { Option } = Select
export interface DeliverySubscriptionFieldOptions {
  recipients?: boolean
}

interface DeliverySubscriptionFormProps {
  form: FormInstance<DeliverySubscriptionFormData>
  deliverySubscription?: DeliverySubscription
  initiatorOptions: string[]
  paramsOptions?: string[]
  fieldOptions?: DeliverySubscriptionFieldOptions
  subscriptionType: DeliverySubscriptionType
  initiatorOption?: IniatorType
  setInitiatorOption: (type: IniatorType) => void
}
const DeliverySubscriptionForm: React.FC<DeliverySubscriptionFormProps> = ({
  form,
  deliverySubscription,
  initiatorOptions,
  paramsOptions,
  fieldOptions,
  subscriptionType,
  initiatorOption,
  setInitiatorOption
}: DeliverySubscriptionFormProps) => {
  const { t } = useTranslation()

  const selectableCompanies = useSelector(currentUserSortedCompaniesSelector)
  const currentUser = useSelector(currentUserSelector)

  const [companyDashBoards, setCompanyDashboards] = useState<CompanyDashboard[]>([])
  const [selectableUsers, setSelectableUsers] = useState<User[]>([])
  const [dimensionList, setDimensionList] = useState<Dimension[]>()
  const [forecastDisabled, setForecastDisabled] = useState(false)
  const usersRequest = useBackend('/api/companies/{companyId}/users')
  const companyDasboardRequest = useBackend('/api/companies/{companyId}/dashboards')
  const dimensionRequest = useBackend(`/api/companies/{companyId}/accounting/dimensions`)

  const companyId = useWatch('companyId', form)

  if (!selectableUsers.find((user: User) => user.id === currentUser.id)) {
    selectableUsers.push(currentUser)
  }

  const handleInitiatorOptionChange = (initiator: IniatorType) => {
    setInitiatorOption(initiator)
  }

  useEffect(() => {
    form.resetFields()
    if (deliverySubscription) {
      if (((deliverySubscription.initiator as MonthlyDelivery).monthly as { day: number })?.day) {
        setInitiatorOption('monthly')
      }
      if (((deliverySubscription.initiator as WeeklyDelivery).weekly as { dow: number })?.dow) {
        setInitiatorOption('weekly')
      }
      if ((deliverySubscription.initiator as DailyDelivery).daily) {
        setInitiatorOption('daily')
      }
      if ((deliverySubscription.initiator as PeriodLockDelivery).periodLock) {
        setInitiatorOption('periodLock')
      }
      if ((deliverySubscription.initiator as ManualDelivery).manual) {
        setInitiatorOption('manual')
      }

      const cfg = {
        endDate: (value: any) => {
          return value && dayjs(value).endOf('month')
        },
        recipients: (value: any) => {
          return value || undefined
        }
      }

      const fields = mapObjectToFieldData(deliverySubscription, cfg)
      form.setFields(fields)
    }
  }, [deliverySubscription])

  useEffect(() => {
    const fetch = (d?: DeliverySubscription) => {
      const requestBody = {
        urlParams: {
          companyId
        }
      }

      if (companyId) {
        switch (subscriptionType) {
          case DeliverySubscriptionType.COMPANY_DASHBOARD:
            companyDasboardRequest
              .get(requestBody)
              .then((response: CompanyDashboard[]) => {
                setCompanyDashboards(response)
              })
              .catch(() => {
                setCompanyDashboards([])
              })
            break

          default:
            break
        }

        dimensionRequest.get({ urlParams: { companyId } }).then((res: Dimension[]) => {
          setDimensionList(getDimensionTree(res))
          if (d) {
            form.setFieldsValue({
              params: {
                dimensionId: d?.params?.dimensionId
              }
            })
          }
        })

        const company = selectableCompanies.find(c => c.id === companyId)

        usersRequest
          .get({ urlParams: { companyId: company?.id } })
          .then((users: User[]) => {
            const subUser = d?.user

            if (subUser && users.findIndex(u => u.id === subUser.id) < 0) {
              setSelectableUsers([...users, subUser])
            } else {
              setSelectableUsers(users)
            }
          })
          .catch(() => {
            setSelectableUsers([])
          })
      }
    }

    fetch(deliverySubscription)

    return () => {
      setCompanyDashboards([])
      setDimensionList([])
    }
  }, [companyId])

  const renderTreeSelectNodes = (dimensions: Dimension[]) => {
    return dimensions.map(dimension => {
      return (
        <TreeSelect.TreeNode value={dimension.dimensionId} title={dimension.name} key={dimension.dimensionId}>
          {dimension.children && renderTreeSelectNodes(dimension.children)}
        </TreeSelect.TreeNode>
      )
    })
  }

  const isForecastDisabled = (dataType: ReportDataType) => {
    if (dataType === ReportDataType.forecast && forecastDisabled) {
      return true
    }
    return false
  }

  const dataSettingChange = (e: any) => {
    if (e.target.value === 'last12months') {
      const datatypes = form.getFieldValue('params')?.dataTypes || []
      form.setFieldsValue({
        params: {
          dataTypes: datatypes.filter((f: ReportDataType) => f !== ReportDataType.forecast)
        }
      })
      setForecastDisabled(true)
    } else {
      setForecastDisabled(false)
    }
  }

  const showRecipients = () => {
    return (fieldOptions?.recipients && form.getFieldValue('recipients')?.length) || !form.getFieldValue('userId')
  }

  return (
    <Form
      onValuesChange={changedValue => {
        if (changedValue.companyId) {
          form.resetFields()
          form.setFieldValue('companyId', changedValue.companyId)
        }
      }}
      form={form}
      layout="vertical"
      className="delivery-subsricption-form"
    >
      <Form.Item
        name="companyId"
        label={t('subscriptionsPage:company')}
        rules={[{ required: true, message: t('global:required-field') }]}
      >
        <Select placeholder={t('subscriptionsPage:select-company')} showSearch optionFilterProp="children">
          {selectableCompanies.map((company: Company) => (
            <Option key={company.id} value={company.id}>
              {company.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {subscriptionType === DeliverySubscriptionType.CUSTOM_REPORT && (
        <Form.Item
          label={t('global:report')}
          name="reportId"
          rules={[{ required: true, message: t('global:required-field') }]}
        >
          <CustomReportTreeSelect companyId={companyId} style={{ width: '100%' }} />
        </Form.Item>
      )}
      {subscriptionType === DeliverySubscriptionType.COMPANY_DASHBOARD && (
        <>
          <Form.Item
            label="Dashboard"
            name="reportId"
            rules={[{ required: true, message: t('global:required-field') }]}
          >
            <Select placeholder={t('subscriptionsPage:choose-dashboard')} showSearch optionFilterProp="children">
              {companyDashBoards.map(dashboard => (
                <Option key={dashboard.id} value={dashboard.id}>
                  {dashboard.name || t('global:default')}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={`${t('contextDrawer:budgeting-scenario')}`} name={['params', 'budgetingScenarioId']}>
            <ScenarioSelect companyId={companyId} />
          </Form.Item>
          <Form.Item label={t('contextDrawer:dimension')} name={['params', 'dimensionId']}>
            <TreeSelect
              allowClear
              showSearch
              filterTreeNode={(inputval, node) => {
                return node && node.props && node.props.title.toLowerCase().includes(inputval.toLowerCase())
              }}
            >
              {dimensionList && renderTreeSelectNodes(dimensionList)}
            </TreeSelect>
          </Form.Item>
        </>
      )}
      <Form.Item shouldUpdate noStyle>
        {() => {
          return (
            <>
              {!form.getFieldValue('recipients')?.length && (
                <Form.Item
                  label={t('subscriptionsPage:user')}
                  name="userId"
                  shouldUpdate
                  rules={[{ required: true, message: t('global:required-field') }]}
                >
                  <Select
                    placeholder={t('subscriptionsPage:select-user')}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    onChange={(value: number) => {
                      if (value) {
                        form.setFieldsValue({
                          recipients: undefined
                        })
                      }
                    }}
                  >
                    {selectableUsers.map((user: User) => (
                      <Option key={user.id} value={user.id}>
                        {user.displayName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              {fieldOptions?.recipients &&
                !form.getFieldValue('recipients')?.length &&
                !form.getFieldValue('userId') && <Divider>{t('global:or')}</Divider>}
              {showRecipients() && (
                <Form.Item
                  shouldUpdate
                  label={t('subscriptionsPage:recipients')}
                  name="recipients"
                  rules={[{ required: true, message: t('global:required-field') }]}
                >
                  <Select
                    mode="tags"
                    optionFilterProp="children"
                    onChange={(values: string[]) => {
                      if (values.length && values.length > 0) {
                        form.setFieldsValue({
                          userId: undefined
                        })
                      }
                    }}
                  />
                </Form.Item>
              )}
            </>
          )
        }}
      </Form.Item>
      <Form.Item label={t('subscriptionsPage:initiator')}>
        <Radio.Group
          value={initiatorOption}
          onChange={(e: RadioChangeEvent) => handleInitiatorOptionChange(e.target.value)}
        >
          {initiatorOptions.map(option => (
            <Radio style={{ margin: 4 }} key={option} value={option}>
              {t(`subscriptionsPage:${option}`)}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      {initiatorOption === 'monthly' && (
        <Form.Item
          label={t('subscriptionsPage:iMonthly', { day: 'X' })}
          name={['initiator', 'monthly', 'day']}
          rules={[{ required: true, message: t('global:required-field') }]}
        >
          <InputNumber placeholder="15" min={1} max={31} precision={0} />
        </Form.Item>
      )}
      {initiatorOption === 'weekly' && (
        <Form.Item
          name={['initiator', 'weekly', 'dow']}
          rules={[{ required: true, message: t('global:required-field') }]}
          label={t('subscriptionsPage:selectDayOfWeek')}
        >
          <Select style={{ width: 200 }}>
            {[1, 2, 3, 4, 5, 6, 0].map(val => (
              <Option className="type" key={val} value={val}>
                {dayjs().day(val).format('dddd')}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {initiatorOption === 'daily' && (
        <Form.Item initialValue="true" style={{ display: 'none' }} name={['initiator', 'daily']}>
          <Checkbox checked />
        </Form.Item>
      )}
      {initiatorOption === 'periodLock' && (
        <>
          <Form.Item initialValue="true" style={{ display: 'none' }} name={['initiator', 'periodLock']}>
            <Checkbox checked />
          </Form.Item>
          <Form.Item>
            <Alert message={t('global:attention')} description={t('subscriptionsPage:period-lock-info')} type="info" />
          </Form.Item>
        </>
      )}
      {initiatorOption === 'manual' && (
        <>
          <Form.Item initialValue="true" style={{ display: 'none' }} name={['initiator', 'manual']}>
            <Checkbox checked />
          </Form.Item>
          <Form.Item
            name={['params', 'endDate']}
            rules={[{ required: true, message: t('global:required-field') }]}
            label={t('subscriptionsPage:choose-which-months-scorecard-you-want-to-send')}
          >
            <DatePicker.MonthPicker placeholder="Select month" />
          </Form.Item>
        </>
      )}

      {subscriptionType === DeliverySubscriptionType.COMPANY_DASHBOARD && (
        <Form.Item
          name={['params', 'dataSetting']}
          rules={[{ required: true, message: t('global:required-field') }]}
          label={t(`subscriptionsPage:dataSettingTitle`)}
        >
          <Radio.Group onChange={(e: any) => dataSettingChange(e)}>
            <Radio key="last12months" value="last12months">
              {t(`subscriptionsPage:last12months`)}
            </Radio>
            <Radio key="startOfFiscalyear" value="startOfFiscalyear">
              {t(`subscriptionsPage:startOfFiscalyear`)}
            </Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {paramsOptions?.includes('dataincomeStatementRepresentationTypes') &&
        companyDashBoards
          ?.find((dashb: CompanyDashboard) => dashb.id === form.getFieldValue('reportId'))
          ?.items.some((item: any) => item.type === 'INCOME_STATEMENT') && (
          <>
            <Form.Item
              name={['params', 'incomeStatementRepresentation']}
              label={t('subscriptionsPage:income-statement-presentation')}
              rules={[{ required: true, message: t('global:required-field') }]}
            >
              <Select>
                {config.map(val => (
                  <Option className="type" key={val.id} value={val.id}>
                    {t(`subscriptionsPage:${val.title}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={['params', 'financialStatementLevel']}
              label={t(`subscriptionsPage:incomeStatementAccuracy`)}
              rules={[{ required: true, message: t('global:required-field') }]}
            >
              <Select>
                <Option key="statementRow" value="statementRow">
                  {t(`subscriptionsPage:byAccountGroup`)}
                </Option>
                <Option key="account" value="account">
                  {t(`subscriptionsPage:byAccount`)}
                </Option>
              </Select>
            </Form.Item>
          </>
        )}
      {paramsOptions?.includes('dataBalanceSheetRepresentationTypes') &&
        companyDashBoards
          ?.find((dashb: CompanyDashboard) => dashb.id === form.getFieldValue('reportId'))
          ?.items.some((item: any) => item.type === 'BALANCE_SHEET') && (
          <>
            <Form.Item
              name={['params', 'balanceSheetLevel']}
              label={t(`subscriptionsPage:balanceSheetAccuracy`)}
              rules={[{ required: true, message: t('global:required-field') }]}
            >
              <Select>
                <Option key="statementRow" value="statementRow">
                  {t(`subscriptionsPage:byAccountGroup`)}
                </Option>
                <Option key="account" value="account">
                  {t(`subscriptionsPage:byAccount`)}
                </Option>
              </Select>
            </Form.Item>
          </>
        )}
      {paramsOptions?.includes('dataTypes') && (
        <Form.Item
          name={['params', 'dataTypes']}
          initialValue={deliverySubscription?.params?.dataTypes}
          rules={[{ required: true, message: t('global:required-field') }]}
        >
          <Checkbox.Group style={{ width: '100%' }}>
            <Row>
              {Object.entries(ReportDataType).map(([key, dataType]: any) => {
                return (
                  <Col key={key} span={8}>
                    <Checkbox key={dataType} value={dataType} disabled={isForecastDisabled(dataType)}>
                      {t(`contextDrawer:${dataType}`)}
                    </Checkbox>
                  </Col>
                )
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      )}
    </Form>
  )
}

export default DeliverySubscriptionForm
