import { BudgetingScenario } from '../../../../features/scenario/types/BudgetingScenario'
import { Report } from '../../../context/reports/types'
import {
  MultiPurposeDashboardActionTypes,
  MultiPurposeDashboardPage,
  ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS,
  ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS,
  ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS,
  RESET_MULTI_PURPOSE_DASHBOARD,
  RESET_MULTI_PURPOSE_DASHBOARD_REPORTS,
  SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD,
  SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS,
  SET_MULTI_PURPOSE_DASHBOARD_FORMULAS,
  SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT,
  SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT,
  SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT
} from './types'

const initialState: MultiPurposeDashboardPage = {
  activeDashboard: undefined,
  keyfigureStatements: {},
  internalKeyfigureStatements: {},
  dimensionKeyfigureStatements: {},
  dimensions: {},
  formulas: {}
}

const parseAllData = (
  data: { dimensionKey?: string; budgetingScenarioId?: BudgetingScenario['id']; data: Report }[]
) => {
  const parsedData = {}
  for (const p of data.filter(Boolean)) {
    if (p.dimensionKey || p.budgetingScenarioId) {
      Object.assign(parsedData, {
        [p?.dimensionKey || p?.budgetingScenarioId || 0]: p.data
      })
    }
  }
  return parsedData
}

const multiPurposeDashboardReducer = (state = initialState, action: MultiPurposeDashboardActionTypes) => {
  switch (action.type) {
    case SET_MULTI_PURPOSE_DASHBOARD_ALL_KEYFIGURE_STATEMENTS:
      return {
        ...state,
        keyfigureStatements: {
          ...state.keyfigureStatements,
          ...action.payload
        }
      }
    case ADD_MULTI_PURPOSE_DASHBOARD_DIMENSION_KEYFIGURE_STATEMENTS:
      return {
        ...state,
        dimensionKeyfigureStatements: {
          ...state.dimensionKeyfigureStatements,
          ...action.payload
        }
      }
    case SET_MULTI_PURPOSE_DASHBOARD_ACTIVE_DASHBOARD:
      return { ...state, activeDashboard: action.payload }
    case ADD_MULTI_PURPOSE_DASHBOARD_FORMULAS:
      return {
        ...state,
        formulas: {
          ...state.formulas,
          [action.payload.companyId]: action.payload.formulas
        }
      }
    case ADD_MULTI_PURPOSE_DASHBOARD_DIMENSIONS:
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          [action.payload.companyId]: action.payload.dimensions
        }
      }
    case SET_MULTI_PURPOSE_DASHBOARD_FORMULAS:
      return { ...state, formulas: { ...state.formulas, ...action.payload } }
    case SET_ALL_MULTI_PURPOSE_DASHBOARD_KEYFIGURE_STATEMENT:
      return {
        ...state,
        keyfigureStatements: {
          ...state.keyfigureStatements,
          ...parseAllData(action.payload)
        }
      }
    case SET_ALL_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT:
      return {
        ...state,
        internalKeyfigureStatements: {
          ...state.internalKeyfigureStatements,
          ...parseAllData(action.payload)
        }
      }
    case SET_MULTI_PURPOSE_DASHBOARD_INTERNAL_KEYFIGURE_STATEMENT:
      return {
        ...state,
        internalKeyfigureStatements: {
          ...state.internalKeyfigureStatements,
          [action.payload.id]: action.payload.data
        }
      }
    case RESET_MULTI_PURPOSE_DASHBOARD:
      return { ...initialState, activeDashboard: state?.activeDashboard }
    case RESET_MULTI_PURPOSE_DASHBOARD_REPORTS:
      return { ...state, keyfigureStatements: {}, dimensionKeyfigureStatements: {} }
    default:
      return state
  }
}

export default multiPurposeDashboardReducer
