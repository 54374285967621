import React, { useEffect, useState } from 'react'
import { Form, Modal, Tabs } from 'antd'
import { NamePath } from 'antd/es/form/interface'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useResetFormOnCloseModal } from '../../../../../components/Form/hooks'
import CustomReportVariableFormMultiple from './CustomReportVariableFormMultiple'
import VariableForm from './form/VariableForm'
import { Tab } from '../../../../../types/tab/tab'

interface ModalFormProps {
  variableNamePath?: NamePath
  open: boolean
  onCancel: () => void
}

const StyledContainer = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  margin: 0px -14px;
  margin-top: 8px;
  padding: 0px 14px;
  max-height: calc(100vh - 200px);
`

const SectionFormVariableModal: React.FC<ModalFormProps> = ({ variableNamePath, open, onCancel }) => {
  const { t } = useTranslation()
  const [singleForm] = Form.useForm()
  const [multiForm] = Form.useForm()
  const [activeTabKey, setActiveTabKey] = useState('singleRow')

  const parentForm = Form.useFormInstance()
  const variable = variableNamePath ? parentForm?.getFieldValue(variableNamePath) : undefined

  useResetFormOnCloseModal({
    form: singleForm,
    open
  })
  useResetFormOnCloseModal({
    form: multiForm,
    open
  })

  useEffect(() => {
    open && singleForm.setFieldsValue(variable)
  }, [variableNamePath])

  const onOk = () => {
    switch (activeTabKey) {
      case 'singleRow':
        singleForm.submit()
        break
      case 'multiRow':
        multiForm.submit()
        break

      default:
        break
    }
  }

  const items: Tab[] = [
    {
      label: t('global:singleRow'),
      key: 'singleRow',
      children: <VariableForm form={singleForm} />
    },
    {
      label: t('global:multipleRows'),
      key: 'multiRow',
      children: <CustomReportVariableFormMultiple form={multiForm} />
    }
  ]

  const onChange = (key: string) => {
    setActiveTabKey(key)
  }

  return (
    <Modal
      destroyOnClose
      title={variable ? t('global:edit') : t('global:add')}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={false}
      width="fit-content"
      style={{ top: 20 }}
    >
      <StyledContainer>
        {variable ? (
          <VariableForm form={singleForm} />
        ) : (
          <Tabs onChange={onChange} activeKey={activeTabKey} destroyInactiveTabPane items={items} />
        )}
      </StyledContainer>
    </Modal>
  )
}

export default SectionFormVariableModal
