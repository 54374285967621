import React from 'react'
import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { isValidVatId } from '../../../features/company/utils'
import { CompanySearchFilters } from '../../../features/company/types/CompanySearchFilters'

interface VatIdFormProps {
  submitText?: string
  handleSubmit: (values: CompanySearchFilters) => void
}

const VatIdForm = ({ handleSubmit, submitText }: VatIdFormProps) => {
  const { t } = useTranslation()

  return (
    <Form onFinish={handleSubmit} layout="horizontal">
      <Form.Item
        label={t('comparisonPage:vatid')}
        name="vatId"
        rules={[
          {
            validator: isValidVatId
          }
        ]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item>
        <Button block type="primary" htmlType="submit">
          {submitText}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default VatIdForm
