import React from 'react'
import { Select, Table } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  CustomExchangeRateSeriesType,
  CustomExchangeRateSeriesWithCompany,
  ExchangeRateSeriesRequestBody
} from '../types/exchangeRate'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { useExchangeRates } from '..'
import { Loading } from '../../../components/Misc/Loading'
import useUpdateExchangeRate from '../queries/useUpdateExchangeRate'
import useCreateExchangeRate from '../queries/useCreateExchangeRate'

const ExchangeRateTable = () => {
  const { t } = useTranslation()
  const groupId = useSelector(contextCompanyIdSelector)!

  const { data: exchangeRates, isPending: exchangeRatesPending, isLoading: exchangeRatesLoading } = useExchangeRates()
  const { mutate: updateExchangeRate, isPending: exchangeRateUpdatePending } = useUpdateExchangeRate()
  const { mutate: createExchangeRate, isPending: exchangeRateCreatePending } = useCreateExchangeRate()

  const handleSave = async (
    type: CustomExchangeRateSeriesType,
    { companyId, baseCurrency, currency, id }: CustomExchangeRateSeriesWithCompany
  ) => {
    const data: ExchangeRateSeriesRequestBody = {
      groupId,
      companyId,
      baseCurrency,
      currency,
      type
    }

    if (id) {
      updateExchangeRate({ data, id })
    } else {
      createExchangeRate(data)
    }
  }

  const columns: ColumnProps<CustomExchangeRateSeriesWithCompany>[] = [
    {
      dataIndex: ['company', 'name'],
      title: t('global:company'),
      render(value) {
        if (!value) {
          return t('errorMessage:COMPANY_UNAUTHORIZED')
        }
        return value
      }
    },
    {
      dataIndex: 'currency',
      title: t('global:currency'),
      render(currency, { baseCurrency }) {
        return `${currency}/${baseCurrency}`
      }
    },
    {
      dataIndex: 'type',
      title: t('exchangeRatesPage:exchangeRateType'),
      render(value, record) {
        return (
          <Select
            size="small"
            value={value}
            disabled={!record.company?.name}
            style={{ width: 120 }}
            onChange={newType => handleSave(newType, record)}
            options={Object.values(CustomExchangeRateSeriesType).map(val => ({
              value: val,
              label: t(`global:${val}`)
            }))}
          />
        )
      }
    },
    {
      dataIndex: 'edit',
      title: t('global:actions'),
      render: (value, record) => {
        if (!record.company?.name) return null
        return (
          <Link to="/settings/company/group/exchangeRate/edit" state={record}>
            {record.type === CustomExchangeRateSeriesType.manual ? t('global:edit') : 'Tarkastele'}
          </Link>
        )
      }
    }
  ]

  if (exchangeRatesPending) return <Loading />

  return (
    <Table
      loading={exchangeRatesLoading || exchangeRateUpdatePending || exchangeRateCreatePending}
      size="small"
      rowKey="id"
      columns={columns}
      dataSource={exchangeRates}
    />
  )
}

export default ExchangeRateTable
