import dayjs from 'dayjs'
import i18next from 'i18next'
import { arrayDownload, downloadMenuItems } from '../../utils/tableExport'
import {
  CustomExchangeRateSeries,
  StatementType,
  CustomExchangeRate,
  CustomExchangeRateSeriesWithCompany,
  SheetRow
} from './types/exchangeRate'

/**
 * Parses exchange rate data from a sheet into a structured `CustomExchangeRateSeries`.
 * Combines new rates with existing company data and applies optional form updates.
 *
 * @param sheet - Array of rows from a sheet, where each row contains a `report` field
 *                (e.g., 'bs' or 'pl') and exchange rate values keyed by "month/year".
 * @param companyData - Existing exchange rate series data for a company. Required to
 *                      produce a result; if omitted, returns `null`.
 * @param formValues - Optional partial updates to the exchange rate series (e.g., `type` or `currency`).
 * @returns A `CustomExchangeRateSeries` object with updated rates, or `null` if `companyData` is not provided.
 *
 * @example
 * ```typescript
 * const sheet = [{ report: "pl", "3/2025": "1.23" }];
 * const companyData = { id: 1, groupId: "123", companyId: "456", type: "manual",
 *                       baseCurrency: "USD", currency: "EUR", rates: [] };
 * const result = parseRates(sheet, companyData);
 * // Returns updated series with a rate for March 2025
 * ```
 */
export const parseRates = (
  sheet: SheetRow[],
  companyData?: CustomExchangeRateSeries,
  formValues?: Partial<CustomExchangeRateSeries>
): CustomExchangeRateSeries | null => {
  if (!companyData) return null

  const series = sheet.flatMap(row => {
    const { report, ...values } = row
    return Object.entries(values)
      .map(([key, value]) => {
        const [month, year] = key.split('/').map(Number)
        const date = dayjs({ month: month - 1, year }).format('YYYY-MM-DD')
        return {
          statementType: report as StatementType,
          date,
          month,
          year,
          value: value ? parseFloat(value.replace(',', '.')) : null
        }
      })
      .filter(s => s.value !== null) as CustomExchangeRate[]
  })

  const rates: CustomExchangeRate[] = series.map(serie => {
    const found = companyData?.rates.find(
      ex => dayjs(ex.date).utc().isSame(serie.date, 'month') && serie.statementType === ex.statementType
    )
    return {
      ...serie,
      id: found?.id
    }
  })

  return { ...companyData, ...formValues, rates }
}

/**
 * Transforms exchange rate data into a 2D array grouped by statement type.
 * Each row starts with the statement type ('bs' or 'pl') followed by its associated rate values.
 *
 * @param data - Optional exchange rate series data including company details and rates.
 *               If not provided, returns an empty array.
 * @returns A 2D array where each sub-array represents a statement type and its rate values.
 *
 * @example
 * ```typescript
 * const data = {
 *   id: 1,
 *   companyId: "123",
 *   rates: [
 *     { statementType: "pl", value: 1.23, date: "2025-03-01" },
 *     { statementType: "bs", value: 1.25, date: "2025-03-01" },
 *     { statementType: "pl", value: 1.30, date: "2025-04-01" }
 *   ]
 * };
 * const result = parseReportRows(data);
 * // Returns [["pl", 1.23, 1.3], ["bs", 1.25]]
 * ```
 */
export const parseReportRows = (data?: CustomExchangeRateSeriesWithCompany): (string | number)[][] => {
  if (!data) return []
  return Object.values(
    data.rates.reduce((acc, { statementType, value }) => {
      if (!acc[statementType]) {
        acc[statementType] = [statementType] // Start with the statement type
      }
      acc[statementType].push(parseFloat(value.toString())) // Add the value
      return acc
    }, {} as Record<string, (string | number)[]>)
  )
}

/**
 * Creates a title row array for exchange rate data, starting with the currency conversion pair
 * followed by unique dates from the rates.
 *
 * @param data - Optional exchange rate series data including company details, currencies, and rates.
 *               If not provided, returns an empty array.
 * @returns An array starting with the currency pair (e.g., "EUR/USD") followed by unique dates
 *          from the rates in chronological order.
 *
 * @example
 * ```typescript
 * const data = {
 *   id: 1,
 *   companyId: "123",
 *   baseCurrency: "USD",
 *   currency: "EUR",
 *   rates: [
 *     { statementType: "pl", value: 1.23, date: "2025-03-01" },
 *     { statementType: "bs", value: 1.25, date: "2025-03-01" },
 *     { statementType: "pl", value: 1.30, date: "2025-04-01" }
 *   ]
 * };
 * const result = parseTitleRow(data);
 * // Returns ["EUR/USD", "2025-03-01", "2025-04-01"]
 * ```
 */
export const parseTitleRow = (data?: CustomExchangeRateSeriesWithCompany) => {
  if (!data) return []
  const conversion = `${data.currency}/${data.baseCurrency}`
  const uniqueDates = [...new Set(data.rates.map(item => item.date))]
  return [conversion, ...uniqueDates]
}

export const downloadMenu = (data: SheetRow[]) => {
  const handleMenuClick = ({ key }: any) => {
    const aoa = data.map(row => Object.values(row))
    switch (key) {
      case 'csv':
      case 'xlsx':
        arrayDownload(aoa, i18next.t('menu:/settings/company/group/exchangeRate'), key)
        break
      default:
        break
    }
  }

  const menuProps = {
    items: downloadMenuItems,
    onClick: handleMenuClick
  }

  return menuProps
}
