/* eslint-disable no-nested-ternary */
import { useDispatch, useSelector } from 'react-redux'
import { Popover, Row, Typography } from 'antd'
import React, { ReactNode, useState, useMemo, useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { LoadingOutlined } from '@ant-design/icons'
import {
  financialStatementLoadingSelector,
  internalKeyFigureStatementSelector
} from '../../../../../redux/context/reports/selectors'
import { Report, ReportRow } from '../../../../../redux/context/reports/types'
import {
  CompanyDashboardItem,
  defaultCompanyDashboardChartRepresentationOptions
} from '../../../../../types/dashboard/company/types'
import { ReportTableRow, VariableType } from '../../../../../components/Table/types'
import { errorSelector } from '../../../../../redux/error/selectors'
import ReportTable from '../../../../../components/Table/ReportTable'
import KeyFigureChart from '../../../../reporting/keyFigures/components/keyFigureChart/KeyFigureChart'
import { useTableColumns } from '../../../../../redux/context/periodGroups/hooks'
import { useChartSeries } from '../../../../../components/Chart/hooks'
import { dimensionQuerySelector, filtersSelector } from '../../../../../redux/context/filters/selectors'
import { getPieSeriesData } from '../utils'
import { PieChart } from '../../../../../components/Chart/PieChart'
import InfoStatistics from '../InfoStatistics'
import LoadingWrapper from '../../../../../components/Misc/LoadingWrapper'
import { loadingSelector } from '../../../../../redux/loading/selectors'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { getInternalKeyFigureVariableExplainerRequest } from '../../../../../redux/context/reports/actions'
import { FiscalYear } from '../../../../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../../../../types/periodGroup/PeriodGroup'
import PeriodGroupTreeSelect from '../../../../../components/PeriodGroup/PeriodGroupTreeSelect'
import { AppDispatch } from '../../../../../redux/store'
import { appLanguageSelector } from '../../../../../redux/app/selectors'
import { ChartBasicType } from '../../../../../components/Chart/types'
import { renderCell } from './utils'
import { useCtxCurrentFiscalYear } from '../../../../../features/fiscalYear/queries/report/useCtxCurrentFiscalYear'
import { useCtxReportablePeriodGroups } from '../../../../../features/fiscalYear/hooks/useCtxReportablePeriodGroups'

interface InternalKeyFiguresProps {
  item: CompanyDashboardItem
  width: number
  height: number
}

export const InternalKeyFigures: React.FC<InternalKeyFiguresProps> = ({
  item,
  height,
  width
}: InternalKeyFiguresProps) => {
  const loading = useSelector(financialStatementLoadingSelector)
  const internalKeyFigureStatement = useSelector(internalKeyFigureStatementSelector)
  const errors = useSelector(errorSelector)

  const companyId = useSelector(contextCompanyIdSelector)
  const { periodGroup, periodGroups } = useSelector(filtersSelector)
  const dimensionQuery = useSelector(dimensionQuerySelector)
  const sortedColumnGroup = useCtxReportablePeriodGroups()
  const { data: currentFiscalYear } = useCtxCurrentFiscalYear()
  const loadingExp = useSelector(loadingSelector)
  const language = useSelector(appLanguageSelector)
  const { internalKeyFigureStatement: internalKeyFigureStatementLoading, formulas: formulasLoading } =
    useSelector(loadingSelector)
  let columns = useTableColumns()
  const dispatch: AppDispatch = useDispatch()

  const [selectedPeriodGroup, setSelectedPeriodGroup] = useState<FiscalYear | PeriodGroup | undefined>(
    (currentFiscalYear &&
      periodGroups?.find(
        p => new Date(p.endDate).getFullYear() === new Date(currentFiscalYear.endDate).getFullYear()
      )) ||
      periodGroups?.[0] ||
      currentFiscalYear
  )

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(selectedPeriodGroup?.endDate))
  const [showPeriods, setShowPeriods] = useState<boolean>(!!periodGroup)

  const { params } = item

  const report: Report = useMemo(
    () =>
      params.keyFigureIds &&
      params.keyFigureIds
        .map((kid: number) => {
          return internalKeyFigureStatement.find(({ id }) => kid === id)
        })
        .sort((a: any, b: any) => a.order - b.order)
        .filter(Boolean),
    [params, internalKeyFigureStatement]
  )

  const reportForDefaultPie: Report = useMemo(
    () =>
      params.keyFigureDefaultPieIds
        ? internalKeyFigureStatement.filter(row => params.keyFigureDefaultPieIds.includes(row.id))
        : internalKeyFigureStatement,
    [params, internalKeyFigureStatement]
  )

  const series: any[] = useChartSeries(report, { cumulative: false, chartType: ChartBasicType.column })

  if (params.representation.type === 'info') {
    columns = columns.filter(f => f.dataType === 'actuals')
  }

  const renderTitleInternalKeyFigures = (reportTableRow: ReportTableRow): ReactNode => {
    return (
      <div style={{ float: 'left' }}>
        <Popover content={<Typography.Text code>{reportTableRow.source}</Typography.Text>}>
          {reportTableRow.name || reportTableRow.title}
        </Popover>
      </div>
    )
  }

  const getInternalKeyFigureRows = (internalKeyFigureRows: Report, type: string) => {
    return internalKeyFigureRows.filter(d => d.type === type)
  }

  const expandable = {
    expandIcon: ({ expanded, onExpand, record }: any) => {
      return !record.children ? undefined : loadingExp[`internalKeyfigureVariableExplainer${record.id}`] ? (
        <LoadingOutlined style={{ marginRight: 12, float: 'left' }} />
      ) : expanded ? (
        <button
          className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
          aria-label="Collapse row"
          type="button"
          onClick={e => onExpand(record, e)}
        />
      ) : (
        <button
          className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
          aria-label="Collapse row"
          type="button"
          onClick={e => onExpand(record, e)}
        />
      )
    },
    onExpand: (exp: any, record: any) => {
      companyId &&
        exp &&
        !record.explanation &&
        record.children.length <= 0 &&
        dispatch(
          getInternalKeyFigureVariableExplainerRequest(
            companyId,
            record as unknown as ReportRow,
            language,
            dimensionQuery,
            periodGroups
          )
        )
    },
    rowExpandable: () => true
  }

  useEffect(() => {
    const filteredPeriodGroups =
      (periodGroups && periodGroups?.length > 0 && periodGroups?.filter((f: PeriodGroup) => f.id > 0)) || null
    setSelectedPeriodGroup(
      (currentFiscalYear &&
        filteredPeriodGroups?.find(
          p => new Date(p.endDate).getFullYear() === new Date(currentFiscalYear.endDate).getFullYear()
        )) ||
        filteredPeriodGroups?.[0] ||
        currentFiscalYear
    )
    setSelectedDate(
      dayjs(
        (
          currentFiscalYear &&
          filteredPeriodGroups?.find(
            p => new Date(p.endDate).getFullYear() === new Date(currentFiscalYear.endDate).getFullYear()
          )
        )?.endDate ||
          filteredPeriodGroups?.[0].endDate ||
          currentFiscalYear?.endDate
      )
    )
    setShowPeriods(false)
  }, [periodGroups, internalKeyFigureStatement])

  const selectedPeriodGroupOrPeriod = (e: string | number) => {
    if (Number(e)) {
      setShowPeriods(false)
      setSelectedPeriodGroup(Object(sortedColumnGroup).find((f: FiscalYear | PeriodGroup) => f.id === e))
      setSelectedDate(dayjs(Object(sortedColumnGroup).find((f: FiscalYear | PeriodGroup) => f.id === e).endDate))
    } else {
      setShowPeriods(true)
      const monthSelected = e as string
      const date = monthSelected.split(' / ')
      setSelectedPeriodGroup(undefined)
      setSelectedDate(dayjs(`${date[1]}-${date[0]}-01`))
    }
  }

  const getComponent = () => {
    switch (params.representation.type) {
      case 'chart':
        return (
          <KeyFigureChart
            chartType={(params.representation.options || defaultCompanyDashboardChartRepresentationOptions).type}
            report={getInternalKeyFigureRows(report, params.selectedType)}
            height={height}
            width={width}
            error={errors?.internalKeyFigureStatement}
          />
        )
      case 'pie':
        return (
          <>
            <Row style={{ padding: 8 }} justify="center" align="middle">
              <PeriodGroupTreeSelect
                showPeriods={showPeriods}
                selectedDate={selectedDate}
                selectedPeriodGroup={selectedPeriodGroup}
                selectedPeriodGroupOrPeriod={selectedPeriodGroupOrPeriod}
                sortedColumnGroup={sortedColumnGroup}
              />
            </Row>

            {report && (
              <PieChart
                titleText=""
                drilldown
                report={report || []}
                currentPeriodGroup={selectedPeriodGroup}
                percentage={report.every(row => row.type === VariableType.percentage)}
                seriesData={getPieSeriesData(
                  reportForDefaultPie,
                  report,
                  selectedPeriodGroup,
                  item.params.type,
                  selectedDate && new Date(selectedDate.format('YYYY-MM-DD'))
                )}
                seriesName={item.name}
                date={selectedDate}
                height={height}
                width={width}
              />
            )}
          </>
        )
      case 'info':
        return (
          <>
            <InfoStatistics
              periodGroup={periodGroup}
              series={series}
              report={report}
              columns={columns}
              params={params}
              periodGroups={periodGroups}
              currentFiscalYear={currentFiscalYear}
            />
            <KeyFigureChart
              chartType={(params.representation.options || defaultCompanyDashboardChartRepresentationOptions).type}
              report={report}
              height={height - 60}
              width={width}
              error={errors?.keyFigureStatement}
            />
          </>
        )
      case 'table':
        return (
          <ReportTable
            toolbar={{ csvExport: false }}
            report={report?.map(row => ({ ...row, children: [...(row.children || [])] }))}
            renderCell={renderCell}
            renderTitle={renderTitleInternalKeyFigures}
            yScroll={height}
            error={errors?.internalKeyFigureStatement}
            expandable={expandable}
          />
        )
      default:
        return null
    }
  }

  return (
    <LoadingWrapper loading={loading || formulasLoading || internalKeyFigureStatementLoading || false}>
      {getComponent()}
    </LoadingWrapper>
  )
}
