import { Button, Col, Collapse, CollapseProps, DatePicker, Form, Input, Popconfirm, Row } from 'antd'
import dayjs from 'dayjs'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'antd/es/form/Form'
import { DeleteOutlined } from '@ant-design/icons'
import _ from 'lodash'
import CurrencyInput from '../../../pages/dashboard/multiPurposeDashboard/form/CurrencyInput'
import GlobalKeyFigureInput from '../../../pages/dashboard/multiPurposeDashboard/form/GlobalKeyFigureInput'
import CompanyInput from '../../../pages/dashboard/multiPurposeDashboard/form/CompanyInput'
import MultiPurposeFormulaInput from '../../../pages/dashboard/multiPurposeDashboard/form/MultiPurposeFormulaInput'
import {
  MultiPurposeDashboardContext,
  MultiPurposeDashboardContextType
} from '../../../pages/dashboard/multiPurposeDashboard/MultiPurposeDashboardProvider'
import DimensionSelect from '../../../components/Dimension/DimensionSelect'
import { MultiDashboard } from '..'
import { DashboardPageType } from '../../../pages/dashboard/types'

export type MultiDashboardFormFields = Omit<MultiDashboard, 'startDate' | 'endDate'> & {
  period: [dayjs.Dayjs, dayjs.Dayjs]
}
const transformParams = (values: MultiDashboardFormFields): MultiDashboard => {
  const { period, ...rest } = values
  const [start, end] = period || []
  return {
    startDate: start?.startOf('month').format('YYYY-MM-DD'),
    endDate: end?.endOf('month').format('YYYY-MM-DD'),
    ...rest
  }
}

interface Props {
  setLoading: (loading: boolean) => void
}

const MultiDashboardForm: React.FC<Props> = () => {
  const { t } = useTranslation()
  const [form] = useForm<MultiDashboardFormFields>()
  const {
    formSaveLoading,
    dashboard,
    dashboardRef,
    multiPurposeDashboardPage,
    updateDashboard,
    fetchCompanyFormulas,
    deleteDashboard
  } = useContext(MultiPurposeDashboardContext) as MultiPurposeDashboardContextType
  const { ...restParams } = dashboard || {}

  useEffect(() => {
    if (dashboard) {
      form.setFieldsValue({ period: [dayjs(dashboard?.startDate), dayjs(dashboard?.endDate)], ...restParams })
    }
  }, [dashboard?.id])

  const onValuesChange = ({ companies }: Partial<MultiDashboardFormFields>) => {
    if (companies) {
      fetchCompanyFormulas(companies)
    }
  }

  const colProps = {
    xs: 24,
    sm: 12,
    lg: 8,
    xxl: 4
  }

  const buttonColProps = {
    xs: 24,
    sm: 6,
    lg: 4,
    xxl: 2
  }

  const formItemStyle = {
    marginBottom: 8
  }

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: dashboard?.name,
      children: (
        <Form<MultiDashboardFormFields>
          form={form}
          name="multiCompanyDashboardParams"
          onValuesChange={onValuesChange}
          initialValues={
            dashboard && {
              period: [dayjs(dashboard.startDate), dayjs(dashboard.endDate)],
              ...restParams
            }
          }
          style={{ width: '100%' }}
          layout="vertical"
          onFinish={values => {
            if (values.id) {
              updateDashboard(values)
            }
          }}
        >
          <Row gutter={24} align="bottom">
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
            <Form.Item hidden name="userId">
              <Input />
            </Form.Item>
            <Col {...colProps}>
              <Form.Item style={formItemStyle} name="name" label={t('global:name')}>
                <Input />
              </Form.Item>
            </Col>

            {multiPurposeDashboardPage === DashboardPageType.companies && (
              <Col {...colProps}>
                <Form.Item style={formItemStyle} label={t('global:period')} name="period">
                  <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    placeholder={[t('contextDrawer:start'), t('contextDrawer:end')]}
                    picker="month"
                  />
                </Form.Item>
              </Col>
            )}

            {multiPurposeDashboardPage === DashboardPageType.companies && (
              <Col {...colProps}>
                <Form.Item style={formItemStyle} label={t('global:companies')} name="companies">
                  <CompanyInput mode="multiple" />
                </Form.Item>
              </Col>
            )}

            {multiPurposeDashboardPage === DashboardPageType.default && (
              <Col {...colProps}>
                <Form.Item style={formItemStyle} label={t('global:dimensions')} name="dimensions">
                  <DimensionSelect treeSelectProps={{ multiple: true }} />
                </Form.Item>
              </Col>
            )}

            <Col {...colProps}>
              <Form.Item style={formItemStyle} label={t('keyFigurePage:KEY_FIGURES')} name="keyFigures">
                <GlobalKeyFigureInput mode="multiple" />
              </Form.Item>
            </Col>

            <Col {...colProps}>
              <Form.Item style={formItemStyle} label={t('keyFigurePage:INTERNAL_KEY_FIGURES')} name="formulas">
                <MultiPurposeFormulaInput mode="multiple" />
              </Form.Item>
            </Col>

            {multiPurposeDashboardPage === DashboardPageType.companies && (
              <Col {...buttonColProps}>
                <Form.Item style={formItemStyle} label={t('global:currency')} name="currency">
                  <CurrencyInput />
                </Form.Item>
              </Col>
            )}

            <Col {...buttonColProps} style={{ textAlign: 'right' }}>
              <Form.Item style={formItemStyle} shouldUpdate>
                {({ getFieldsValue }) => (
                  <Button
                    loading={formSaveLoading}
                    block
                    htmlType="submit"
                    type={_.isEqual(dashboardRef.current, transformParams(getFieldsValue())) ? 'default' : 'primary'}
                  >
                    {t('global:save')}
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
      extra: (
        <Popconfirm
          placement="bottomRight"
          title={t('global:delete-confirm')}
          onConfirm={deleteDashboard}
          okText={t('global:yes')}
          cancelText={t('global:no')}
        >
          <Button size="small" icon={<DeleteOutlined />} danger type="text">
            {t('global:delete')}
          </Button>
        </Popconfirm>
      )
    }
  ]

  return <Collapse bordered={false} collapsible="header" defaultActiveKey={['1']} items={items} />
}

export default MultiDashboardForm
