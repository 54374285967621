import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Result } from 'antd'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import FileUpload from '../../../../../components/FileUpload/FileUpload'
import DataUpdate from '../dataUpdate'
import { useBackend } from '../../../../../services/backend'

interface ApiResponse {
  httpCode: number
}

const VismaL7: React.FC = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const getCredentials = useBackend(`/l7/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)

  const [authorized, setAuthorized] = useState<boolean>(false)

  const getCredentialsHandler = () => {
    getCredentials
      .get({
        urlParams: { companyId }
      })
      .then((response: ApiResponse) => {
        if (response?.httpCode === 200) {
          setAuthorized(true)
        } else {
          setAuthorized(false)
        }
      })
      .catch(() => {
        setAuthorized(false)
      })
  }

  useEffect(getCredentialsHandler, [companyId])

  const authButton = () => {
    return (
      <>
        <Button type="primary" onClick={() => setAuthorized(false)}>
          {t('integrationsPage:authenticate')}
        </Button>
        <DataUpdate page="l7" />
      </>
    )
  }

  return authorized ? (
    <>
      <Result status="success" title={t('integrationsPage:authenticated')} extra={authButton()} />
    </>
  ) : (
    <FileUpload
      actionUrl={`${process.env.REACT_APP_INTEGRATION_URL}/l7/uploads/${companyId}`}
      taskRequestUrl="/l7/tasks/{companyId}"
      uploadText={t('integrationsPage:excel-info')}
      success={() => setAuthorized(true)}
    />
  )
}

export default VismaL7
