import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { App } from 'antd'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { createBudgetingScenario } from '../../service/budgetingScenarioService'
import { BudgetingScenario } from '../../types/BudgetingScenario'
import budgetingScenarioQueryKeys from './budgetingScenarioQueryKeys'

export const useCreateBudgetingScenario = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const queryClient = useQueryClient()
  const { notification } = App.useApp()

  if (!companyId) {
    throw new Error('Company ID is required for fetching budgeting scenarios.')
  }

  return useMutation({
    mutationKey: budgetingScenarioQueryKeys.list(companyId),
    mutationFn: (data: BudgetingScenario) => createBudgetingScenario(data, companyId),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: budgetingScenarioQueryKeys.list(companyId) })
      notification.success({ message: t('message:CREATE_BUDGETING_SCENARIO_SUCCESS') })
    }
  })
}
