import { QueryFunctionContext } from '@tanstack/react-query'
import { DELETE, GET, POST, PUT } from '../../../redux/middleware/types'
import backendRequest from '../../../services/api/backendRequest'
import fiscalYearEndpoints from '../api/fiscalYearEndpoints'
import FiscalYearQueryKeys from '../queries/fiscalYearQueryKeys'
import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'

export const fetchFiscalYears = ({
  queryKey: [{ companyId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof FiscalYearQueryKeys)['list']>>) => {
  return backendRequest<FiscalYear[]>({
    method: GET,
    url: fiscalYearEndpoints.list(companyId),
    signal
  })
}

export const fetchFiscalYear = (fiscalYearId: number, companyId: string) => {
  return backendRequest<FiscalYear>({
    method: GET,
    url: fiscalYearEndpoints.detail(companyId, fiscalYearId)
  })
}

export const createFiscalYear = (data: Partial<FiscalYear>, companyId: string) => {
  return backendRequest<FiscalYear>({
    method: POST,
    url: fiscalYearEndpoints.list(companyId),
    data
  })
}

export const updateFiscalYear = (id: number, data: Partial<FiscalYear>, companyId: string) => {
  return backendRequest<FiscalYear>({
    method: PUT,
    url: fiscalYearEndpoints.detail(companyId, id),
    data
  })
}

export const deleteFiscalYear = (companyId: string, id: number) => {
  return backendRequest<FiscalYear>({
    method: DELETE,
    url: fiscalYearEndpoints.detail(companyId, id)
  })
}
