import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { updateCustomReportAction } from '../../../../../redux/context/customReports/actions'
import { AppDispatch } from '../../../../../redux/store'
import { makeInitialLayouts, BREAKPOINTS, removeItem, Breakpoint, ResponsiveLayouts } from '../grid.utils'
import { calculatedReportSelector } from '../../../../../redux/context/customReports/selectors'
import { CustomReportSectionsType } from '../../../../../redux/context/customReports/typesSection'
import { useCtxUpdateCustomReport } from '../../../../../features/customReport/queries/report/useCtxUpdateCustomReport'

export type LayoutsContextType = {
  layouts?: ResponsiveLayouts
  currentBreakPoint: Breakpoint
  editedLayouts?: ResponsiveLayouts
  layoutEdit: boolean
  onSectionRemove: (section: CustomReportSectionsType) => void
  setLayoutEdit: (edit: boolean) => void
  setCurrentBreakPoint: (breakpoint: Breakpoint) => void
  handleLayoutSubmit: (newLayouts?: ResponsiveLayouts) => void
  setEditedLayouts: (layouts?: ResponsiveLayouts) => void
}

export const LayoutsContext = React.createContext<LayoutsContextType | null>(null)

interface LayoutsProviderProps {
  children?: ReactNode
}

const LayoutsProvider: React.FC<LayoutsProviderProps> = ({ children }) => {
  const calculatedReport = useSelector(calculatedReportSelector)! // TODO: calculated pitää olla että voi editaoida layout
  const dispatch: AppDispatch = useDispatch()
  const [layoutEdit, setLayoutEdit] = useState<boolean>(false)
  const [layouts, setLayouts] = useState(calculatedReport?.layouts)
  const [editedLayouts, setEditedLayouts] = useState<ResponsiveLayouts>()
  const [currentBreakPoint, setCurrentBreakPoint] = useState<Breakpoint>('lg')

  const { mutateAsync: updateReport } = useCtxUpdateCustomReport()

  useEffect(() => {
    const initial = makeInitialLayouts(Object.keys(BREAKPOINTS) as Breakpoint[], calculatedReport?.sections)
    const newLayouts = calculatedReport?.sections?.length ? _.merge({}, initial, calculatedReport?.layouts) : undefined
    setLayouts(newLayouts)

    // Taulukoiden headerin korkeus ei tule oikein muuten, voisi olla parempikin keino tähän
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 500)
    return () => {
      setLayouts(undefined)
    }
  }, [calculatedReport?.id])

  const handleLayoutSubmit = async () => {
    await updateReport({ id: calculatedReport?.id, layouts: editedLayouts })
    dispatch(updateCustomReportAction({ layouts: editedLayouts }))
    setEditedLayouts(undefined)

    setLayouts(editedLayouts)
    setLayoutEdit(false)
  }

  const onSectionRemove = (section: CustomReportSectionsType) => {
    setLayouts(removeItem(section.id.toString(), layouts))
  }

  return (
    <LayoutsContext.Provider
      value={{
        layouts,
        layoutEdit,
        editedLayouts,
        currentBreakPoint,
        setCurrentBreakPoint,
        setLayoutEdit,
        onSectionRemove,
        setEditedLayouts,
        handleLayoutSubmit
      }}
    >
      {children}
    </LayoutsContext.Provider>
  )
}

export default LayoutsProvider
