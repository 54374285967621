import { Form, Input, Select, Space, theme } from 'antd'
import { t } from 'i18next'
import React from 'react'
import FormulaHelp from '../../../../../../settings/company/keyFigures/formulas/components/FormulaHelp'
import { internalKeyFigureTypes } from '../../../../../keyFigures/config'
import { RowType } from '../../../../../../../redux/context/customReports/typesVariable'

const Source = () => {
  const {
    token: { colorBgContainer }
  } = theme.useToken()

  return (
    <Space.Compact block key={RowType.source}>
      <Form.Item
        style={{ width: '70%', margin: 0 }}
        name="source"
        label={t('global:formula')}
        tooltip={{ title: <FormulaHelp />, color: colorBgContainer }}
        rules={[{ required: true, message: t('global:required-field') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        style={{ width: '30%' }}
        name="type"
        label={t('global:type')}
        rules={[{ required: true, message: t('global:required-field') }]}
      >
        <Select showSearch optionFilterProp="children">
          {internalKeyFigureTypes.map(keyFigureType => (
            <Select.Option key={keyFigureType} value={keyFigureType}>
              {t(`keyFigurePage:${keyFigureType}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Space.Compact>
  )
}

export default Source
