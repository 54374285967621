import dayjs, { Dayjs } from 'dayjs'
import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'
import { Contract, ContractProduct } from '../../../types/contract/Contract'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { Filters } from '../../../redux/context/filters/types'
import { ReportDataType } from '../../../redux/context/reports/types'

export const currentAndFutureRangeSelect = (fiscalYears: FiscalYear[]): [Dayjs, Dayjs] => {
  const now = new Date()
  const currentAndFuture = fiscalYears.filter(fiscalYear => {
    const endDate = new Date(fiscalYear.endDate)
    return +endDate >= +now // Vertailu Unix-aikaleimoina
  })

  // If no current/future fiscal years, return today as both start and end
  if (currentAndFuture.length === 0) {
    const today = dayjs(now)
    return [today, today]
  }

  const firstFiscal = currentAndFuture[0]
  const lastFiscal = currentAndFuture[currentAndFuture.length - 1]

  return [dayjs(firstFiscal.startDate), dayjs(lastFiscal.endDate)]
}

export const lastClosedFiscalYearsSelector = (fiscalYears: FiscalYear[]): FiscalYear | null => {
  // Filter for closed fiscal years (where isOpen is false)
  const closedFiscalYears = fiscalYears.filter(fy => !fy.isOpen)

  if (closedFiscalYears.length === 0) {
    return null
  }

  // Sort by endDate descending and return the first one (most recent)
  return closedFiscalYears.slice().sort((a, b) => {
    return new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
  })[0]
}

export const currentFiscalYearSelector = (fiscalYears: FiscalYear[]) => {
  const today = new Date()
  return fiscalYears?.find(fy => {
    const start = new Date(fy.startDate)
    const end = new Date(fy.endDate)
    return today >= start && today <= end
  })
}

export const reportableFiscalYearsSelector = (contract?: Contract) => (fiscalYears: FiscalYear[]) => {
  if (!contract) return []
  const current = currentFiscalYearSelector(fiscalYears) || fiscalYears[fiscalYears.length - 1]

  let history
  let future

  switch (contract.product) {
    case ContractProduct.LIGHT:
      history = 4
      future = 1
      break
    case ContractProduct.PRO:
    case ContractProduct.BASIC:
      history = 3
      future = undefined
      break
    default:
      history = 4
      future = 1
      break
  }

  const currentIndex = fiscalYears.indexOf(current)
  const startIndex = currentIndex - history >= 0 ? currentIndex - history : 0
  const endIndex = future && currentIndex + future
  return fiscalYears.slice(startIndex, endIndex)
}

export const sortedPeriodGroupSelector = (
  periodGroups: PeriodGroup[],
  fiscalYears: FiscalYear[] = [],
  filters: Filters
) => {
  const { dataTypes } = filters
  const groups = periodGroups && periodGroups.length > 0 ? periodGroups : fiscalYears
  const sortedPeriodGroupsArray: PeriodGroup[] | FiscalYear[] = groups
    .slice()
    .sort((n1: PeriodGroup | FiscalYear, n2: PeriodGroup | FiscalYear) => +new Date(n1.endDate) - +new Date(n2.endDate))
  if (dataTypes.includes(ReportDataType.comparativePeriod)) {
    sortedPeriodGroupsArray.sort((a, b) => +new Date(b.endDate) - +new Date(a.endDate))
    sortedPeriodGroupsArray.sort((a, b) => Math.abs(a.id) - Math.abs(b.id))
  }
  return sortedPeriodGroupsArray || []
}
