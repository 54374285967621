import { Descriptions } from 'antd'
import React, { CSSProperties } from 'react'
import { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { SheetRow, reportNames } from '../types/exchangeRate'

interface ExchangeAverageRatesProps {
  data: SheetRow[]
  dates: Dayjs[]
}

const ExchangeAverageRates: React.FC<ExchangeAverageRatesProps> = ({ data, dates }) => {
  const {
    t,
    i18n: { language }
  } = useTranslation()

  const calculateAverageRates = (rows: SheetRow[]) => {
    const calculated = rows.map(row => {
      const { report, ...periods } = row
      const values = dates.map(date => Number(periods[date.format('MM/YYYY')])).filter(Number)
      return {
        title: report,
        value: values.reduce((sum, val) => sum + val, 0),
        count: values.length
      }
    })

    const items = calculated.map((row, index) => {
      const avg = row.value / row.count
      const formatted = avg.toLocaleString(language, {
        maximumFractionDigits: 4
      })
      return {
        span: 24,
        key: index,
        contentStyle: { textAlign: 'right' } as CSSProperties,
        label: t(`reportTypes:${reportNames[row.title as keyof typeof reportNames]}`),
        children: formatted
      }
    })

    return items
  }

  return (
    <Descriptions
      style={{ marginTop: 24, width: 400 }}
      title={t('exchangeRatesPage:averageRate')}
      bordered
      items={calculateAverageRates(data)}
    />
  )
}

export default ExchangeAverageRates
