import { Layout } from 'react-grid-layout'
import { CustomReportSectionsType } from '../../../../redux/context/customReports/typesSection'
import { objectMap } from '../../../../utils/helpers'

export const ROW_HEIGHT = 16
export const ITEM_MARGIN = [10, 10]

export const BREAKPOINTS = {
  lg: 768,
  xxs: 0
}

export const COLS = { lg: 12, xxs: 1 }

export type Breakpoint = keyof typeof BREAKPOINTS

// Type for layouts object using your breakpoints
export type ResponsiveLayouts = {
  [P in Breakpoint]: Layout[]
}

const getHeight = (section?: CustomReportSectionsType) => {
  const headerHeight = 102 // pixels
  const maxHeight = window.innerHeight - headerHeight
  const maxRows = Math.floor(maxHeight / (ROW_HEIGHT + ITEM_MARGIN[0])) - 1

  if (!section) return 0
  switch (section.type) {
    case 'table':
      // eslint-disable-next-line no-nested-ternary
      return section.variables.length < 4
        ? 8
        : section.variables.length + 5 > maxRows
        ? maxRows
        : section.variables.length + 6

    default:
      return 13
  }
}

const makeInitialLayout = (sections?: CustomReportSectionsType[]): Layout[] => {
  return (
    sections
      ?.slice()
      .sort((a, b) => a.id - b.id)
      .reduce((layout, section, i) => {
        const height = getHeight(section)
        const y = i === 0 ? 0 : layout[i - 1].y + layout[i - 1].h
        layout.push({
          w: 12,
          h: height,
          x: 0,
          y,
          i: section?.id.toString()
        })
        return layout
      }, [] as Layout[]) || []
  )
}

export const makeInitialLayouts = (bp: Breakpoint[], sections?: CustomReportSectionsType[]): ResponsiveLayouts => {
  const layout = makeInitialLayout(sections)

  return bp.reduce((layouts, breakpoint) => {
    return {
      ...layouts,
      [breakpoint]: layout
    }
  }, {} as ResponsiveLayouts)
}

export const removeItem = (id: string, layouts?: ResponsiveLayouts) => {
  if (!layouts) return layouts
  const filteredLayouts = objectMap(layouts, breakpoint =>
    breakpoint.filter(item => item.i !== id)
  ) as ResponsiveLayouts
  if (Object.values(filteredLayouts).every(items => items.length === 0)) return undefined
  return filteredLayouts
}

export const getDataGrid = (
  section: CustomReportSectionsType,
  currentBreakPoint: Breakpoint,
  layouts?: ResponsiveLayouts
) => {
  let dataGrid: Layout = {
    w: 12,
    h: getHeight(section),
    x: 0,
    y: Infinity,
    i: section.id.toString()
  }
  if (layouts) {
    const dg = layouts[currentBreakPoint]?.find(item => item.i === section.id.toString())
    if (dg) dataGrid = dg
  }
  return dataGrid
}

export const sortSections = (sections?: CustomReportSectionsType[], layouts?: ResponsiveLayouts) => {
  if (!sections) {
    return []
  }

  if (layouts && layouts.lg) {
    const layoutData = layouts.lg

    // Sort layout data by y first, then x
    layoutData.sort((a, b) => {
      if (a.y === b.y) {
        return a.x - b.x
      }
      return a.y - b.y
    })

    // Create a lookup map for sections by id
    const sectionMap = new Map(sections.map(section => [section.id, section]))

    const sortedItems: CustomReportSectionsType[] = []

    layoutData.forEach(layout => {
      const sectionId = Number(layout.i)
      const section = sectionMap.get(sectionId)
      if (section) {
        sortedItems.push(section)
        sectionMap.delete(sectionId) // Remove from the map once added to sortedItems
      }
    })

    // Add the remaining sections that were not in the layout
    const remainingSections = Array.from(sectionMap.values())

    return [...sortedItems, ...remainingSections]
  }

  return sections
}
