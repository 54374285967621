import backendRequest from '../../../services/api/backendRequest'
import loginEndpoints from '../api/loginEndpoints'
import { Credentials, LoginResponse } from '../types/Login'

export const loginUser = async (credentials: Credentials) => {
  return backendRequest<LoginResponse>({
    method: 'POST',
    url: loginEndpoints.login,
    data: credentials
  })
}
