import { Form, Modal } from 'antd'
import { FormProviderProps } from 'antd/es/form/context'
import { Dayjs } from 'dayjs'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setPeriodGroupFilter, setPeriodGroups } from '../../../../redux/context/filters/actions'
import {
  customPeriodGroup,
  yearQuarters,
  comparativePeriodGroup,
  getFiscalYearsToShow,
  periodGroupsFromFiscalYears
} from '../../../../utils/periodGroups/utils'
import CustomPeriodGroup from './components/CustomPeriodGroupForm'
import PartialFiscalYearForm from './components/PartialFiscalYearForm'
import YearQuartersForm from './components/YearQuartersForm'
import { AppDispatch } from '../../../../redux/store'
import { DashboardPageType } from '../../../../pages/dashboard/types'
import { useCtxFiscalYears } from '../../../../features/fiscalYear/queries/report/useCtxFiscalYears'
import { lastClosedFiscalYearsSelector } from '../../../../features/fiscalYear/selectors/selectors'
import { useCtxCurrentFiscalYear } from '../../../../features/fiscalYear/queries/report/useCtxCurrentFiscalYear'

interface PeriodGroupsModalProps {
  visible: boolean
  onCancel: () => void
}

const PeriodGroupsModal: React.FC<PeriodGroupsModalProps> = ({ visible, onCancel }) => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const location = useLocation()
  const currentPage = location.pathname.split('/').pop()
  const { data: currentFiscalYear } = useCtxCurrentFiscalYear()
  const { data: lastClosedFiscalYear } = useCtxFiscalYears(lastClosedFiscalYearsSelector)

  const { data: fiscalYears } = useCtxFiscalYears()

  const customPeriodGroupHandler = ([start, end]: [Dayjs, Dayjs]) => {
    const periodGroup = customPeriodGroup(start, end)
    dispatch(setPeriodGroups([periodGroup]))
  }

  const quartersForSelectedYearHandler = (year: number) => {
    const yQuarters = yearQuarters(year)
    const comparativeQuarters = yQuarters.map(comparativePeriodGroup)
    const quarters = [...comparativeQuarters, ...yQuarters]
    dispatch(setPeriodGroups(quarters))
  }

  const periodGroupsFromFiscalYearsHandler = (periodStart: number, periodLength: number) => {
    const fiscalYearstoShow = getFiscalYearsToShow(fiscalYears, lastClosedFiscalYear, currentFiscalYear)
    const partialFiscalYears = periodGroupsFromFiscalYears(periodStart, periodLength, fiscalYearstoShow)
    dispatch(setPeriodGroups(partialFiscalYears))
  }

  const handleFormSubmit: FormProviderProps['onFormFinish'] = (name, { values }) => {
    dispatch(setPeriodGroupFilter(undefined))
    switch (name) {
      case 'yearQuarters':
        quartersForSelectedYearHandler(values.quartersYear)
        break
      case 'customPeriodGroup':
        customPeriodGroupHandler(values.periodGroupRange)
        break
      case 'partialFiscalYear':
        periodGroupsFromFiscalYearsHandler(values.periodStart, values.periodLength)
        break

      default:
        break
    }
    onCancel()
  }

  const periodGroupForms = [
    <Form.Item key="0" wrapperCol={{ span: 24 }} label={t('contextDrawer:period-limitations-within-financial-years')}>
      <PartialFiscalYearForm />
    </Form.Item>,
    <Form.Item key="1" wrapperCol={{ span: 24 }} label={t('contextDrawer:free-period-group-cropping')}>
      <CustomPeriodGroup />
    </Form.Item>,
    <Form.Item key="2" wrapperCol={{ span: 24 }} label={t('contextDrawer:quarterly-search')}>
      <YearQuartersForm />
    </Form.Item>
  ]

  const pageForms = useMemo(() => {
    if (currentPage === DashboardPageType.compact) {
      return periodGroupForms[1]
    }
    return periodGroupForms
  }, [currentPage])

  return (
    <Modal
      title={t('contextDrawer:period-group-limits')}
      open={visible}
      destroyOnClose
      width={400}
      maskClosable={false}
      onCancel={() => onCancel()}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Form.Provider onFormFinish={handleFormSubmit}>{pageForms}</Form.Provider>
    </Modal>
  )
}

export default PeriodGroupsModal
