import { useQueries } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useBudgetingScenarioOptions } from './budgetingScenario/useBudgetingScenarioOptions'
import { useForecastOptions } from './forecast/useForecastOptions'
import { BudgetingScenario, UseScenariosProps } from '../types/BudgetingScenario'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { ReportDataType } from '../../../redux/context/reports/types'

export const useScenarios = <TData = BudgetingScenario[]>({
  companyId,
  select,
  dataType = 'all' // Default to fetching both if not specified
}: UseScenariosProps<TData> = {}) => {
  const contextCompanyId = useSelector(contextCompanyIdSelector)!

  const optionProps = { companyId: companyId || contextCompanyId, select }

  const budgetingScenarioOptions = useBudgetingScenarioOptions(optionProps)
  const forecastOptions = useForecastOptions(optionProps)

  // Determine which queries to run based on the 'type' parameter
  const queries = []
  if (dataType === ReportDataType.budget || dataType === 'all') {
    queries.push(budgetingScenarioOptions)
  }
  if (dataType === ReportDataType.forecast || dataType === 'all') {
    queries.push(forecastOptions)
  }

  return useQueries({
    queries,
    combine: results => ({
      data: results.flatMap(result => result.data).filter(Boolean) as TData,
      isPending: results.some(result => result.isPending),
      isLoading: results.some(result => result.isLoading),
      error: results.map(result => result.error)[0],
      refetch: () => {
        results.filter(result => result.error)?.forEach(result => result.refetch())
      }
    })
  })
}
