import { Account } from '../types/Account'
import { Company } from '../../../types/company/Company'
import backendRequest from '../../../services/api/backendRequest'

export const deleteAccount = async (companyId: Company['id'], code: number) => {
  return backendRequest<Account>({
    method: 'DELETE',
    url: `/companies/${companyId}/accounting/accounts/${code}`
  })
}

export const getAccounts = async (companyId: Company['id']) => {
  return backendRequest<Account[]>({
    method: 'GET',
    url: `/companies/${companyId}/accounting/accounts`
  })
}

export const createAccount = async (companyId: Company['id'], data: Account) => {
  return backendRequest<Account>({
    method: 'POST',
    url: `/companies/${companyId}/accounting/accounts`,
    data
  })
}

export const updateAccount = async (companyId: Company['id'], data: Account) => {
  return backendRequest<Account>({
    method: 'PUT',
    url: `/companies/${companyId}/accounting/accounts/${data.code}`,
    data
  })
}
