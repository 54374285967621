import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../redux/context/company/selectors'
import { filtersSelector } from '../redux/context/filters/selectors'
import { setBudgetingScenarioFilter } from '../redux/context/filters/actions'
import Loading from '../shared/components/Loading'
import RequestError from '../shared/components/RequestError'
import { useScenarios } from '../features/scenario/queries/useScenarios'
import { useCtxFiscalYears } from '../features/fiscalYear/queries/report/useCtxFiscalYears'

const { Text } = Typography

function CompanyContextManager() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)
  const { budgetingScenario, fiscalYear } = useSelector(filtersSelector)

  // Fetch data using custom hooks (assuming these exist or you'll create them)
  const {
    data: scenarios,
    isLoading: scenariosLoading,
    error: scenariosError,
    refetch: refetchScenarios
  } = useScenarios()
  const {
    data: fiscalYears,
    isLoading: fiscalLoading,
    error: fiscalError,
    refetch: refetchFiscal
  } = useCtxFiscalYears()

  useEffect(() => {
    // Wait for all data to load and check for errors
    if (scenariosLoading || fiscalLoading || scenariosError || fiscalError || !scenarios || !fiscalYears) {
      return
    }

    // Validate and set default budgeting scenario
    const isValidScenario = scenarios.some(scenario => scenario.id === budgetingScenario?.id)
    if (!isValidScenario && scenarios.length > 0) {
      dispatch(setBudgetingScenarioFilter(scenarios.find(s => s.id === null)))
    }
  }, [
    companyId,
    scenarios,
    budgetingScenario?.id,
    scenariosLoading,
    scenariosError,
    fiscalYears,
    fiscalYear?.id,
    fiscalLoading,
    fiscalError,
    dispatch
  ])

  // Handle loading and error states
  if (scenariosLoading || fiscalLoading) {
    return (
      <Loading>
        <Text type="secondary">{t('global:loadingCompanyData')}</Text>
      </Loading>
    )
  }

  if (scenariosError) return <RequestError error={scenariosError} refetch={refetchScenarios} />
  if (fiscalError) return <RequestError error={fiscalError} refetch={refetchFiscal} />

  return <Outlet />
}

export default CompanyContextManager
