import React, { useContext, useState, useEffect, useRef, FunctionComponent, ComponentClass, ReactNode } from 'react'
import { Form, TableProps } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { ColumnType } from 'antd/lib/table/interface'
import EditableStyledTable from './EditableStyledTable'

const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface EditableRowProps {
  index: number
}

export type EditableCellTableDataIndex = string | number | (string | number)[]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

interface EditableCellProps<T> {
  title: React.ReactNode
  editable: boolean
  inputField: FunctionComponent<any> | ComponentClass<any>
  children: React.ReactNode
  dataIndex: Extract<keyof T, string>
  record: T
  handleSave: (record: T, previousRecord: T, dataIndex: EditableCellTableDataIndex) => void
}

function EditableCell<T extends {}>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  title,
  editable,
  children,
  dataIndex,
  inputField,
  record,
  handleSave,
  ...restProps
}: EditableCellProps<T>) {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<any>(null)
  const form = useContext(EditableContext)!

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }

  const save = () => {
    form.validateFields().then(values => {
      toggleEdit()
      handleSave({ ...record, ...values }, record, dataIndex)
    })
  }

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
    }
  }, [editing])

  // save on enter press
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      save()
    }
  }

  let childNode = children

  const classNode: any = children
  const cellClassName = `editable-cell editable-cell-value-wrap${classNode?.[1] !== undefined ? ' value' : ' no-value'}`

  if (editable) {
    childNode = editing ? (
      <Form.Item noStyle name={dataIndex as string}>
        {React.createElement(inputField, {
          onKeyDown: handleKeyDown,
          onBlur: save,
          ref: inputRef,
          defaultValue: classNode && classNode?.[1] !== undefined && classNode?.[1]
        })}
      </Form.Item>
    ) : (
      <div className={cellClassName} role="button" tabIndex={0} onClick={toggleEdit} onKeyDown={toggleEdit}>
        {children}
        &#160;
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

export interface EditableColumnProps<T> extends ColumnType<T> {
  editable?: boolean
  inputField?: ReactNode | any
  children?: EditableColumnProps<T>[]
}

interface EditableCellTableProps<T> extends TableProps<T> {
  columns: EditableColumnProps<T>[]
  onUpdate: (record: T, previousRecord: T, dataIndex: EditableCellTableDataIndex) => void
}

// EditableCellTableProps oli tyyppinä alemmassa
function EditableCellTable<T extends {}>({ columns, onUpdate, ...restProps }: EditableCellTableProps<T>) {
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  const handleSave = (row: T, record: T, dataIndex: EditableCellTableDataIndex) => {
    onUpdate(row, record, dataIndex)
  }

  const tableColumns = columns?.map((col: any) => {
    let { children }: any = col
    if (children) {
      children = children.map((c: any) => {
        if (c.editable) {
          return {
            ...c,
            onCell: (record: T) => ({
              record,
              inputField: c.inputField,
              editable: c.editable,
              dataIndex: c.dataIndex,
              title: c.title,
              handleSave
            })
          }
        }
        return c
      })
    }
    if (!col.editable) {
      return { ...col, children }
    }
    return {
      ...col,
      children,
      onCell: (record: T) => ({
        record,
        inputField: col.inputField,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    }
  })

  return (
    <EditableStyledTable
      components={components}
      rowClassName={() => 'editable-row'}
      bordered
      columns={tableColumns as ColumnType<T>[]}
      {...restProps}
    />
  )
}

export default EditableCellTable
