import React, { ReactNode, useRef } from 'react'
import { Skeleton, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { styled } from 'styled-components'
import classNames from 'classnames'
import CustomReportTable from '../customReportTable/CustomReportTable'
import CustomChart from '../chart/CustomChart'
import { calculatedReportSelector } from '../../../../../redux/context/customReports/selectors'
import NewPieChart from '../chart/NewPieChart'
import CustomReportTableTwo from '../customReportTable/CustomReportTableTwo'
import Editor from '../editor/Editor'
import CustomReportSectionActions from './CustomReportSectionActions'
import { CustomReportSectionsType } from '../../../../../redux/context/customReports/typesSection'
import Loading from '../../../../../shared/components/Loading'
import { loadingSelector } from '../../../../../redux/loading/selectors'

interface ReportSectionProps {
  // index: number
  section: CustomReportSectionsType
  editModal: (section: CustomReportSectionsType) => void
}

const ContainerDiv = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  padding: 2px;
`

const HeaderDiv = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  padding: 2px;
`
const ContentDiv = styled.div`
  /* border: 1px solid green; */
  height: calc(100% - 32px);
  padding: 2px;
  overflow: hidden;

  // Teksti osiolle vain y-scroll
  &.text {
    overflow-y: auto;
  }
`

const ReportSection: React.FC<ReportSectionProps> = ({ section, editModal }) => {
  const { id: reportId } = useSelector(calculatedReportSelector) || {}
  const sectionLoading = useSelector(loadingSelector)
  const sectionElementRef = useRef(null)

  if (!section) return null

  const renderItem = (sec: CustomReportSectionsType): ReactNode | null => {
    if (!reportId) return null
    switch (sec.type) {
      case 'table': {
        if (sec?.style?.switchRowsAndColumns) {
          return <CustomReportTableTwo section={sec} />
        }
        return <CustomReportTable section={sec} reportId={reportId} />
      }
      case 'graph': {
        return <CustomChart section={sec} />
      }
      case 'pie': {
        return <NewPieChart section={sec} />
      }
      case 'text': {
        return <Editor editable={false} section={sec} />
      }
      default:
        return null
    }
  }

  if (!section) return <Loading />
  if (sectionLoading[`customReportSection${section.id}`]) return <Skeleton active style={{ padding: 16 }} />

  return (
    <ContainerDiv>
      <HeaderDiv>
        <Typography.Title level={4}>{section.title}</Typography.Title>
        <CustomReportSectionActions section={section} sectionElementRef={sectionElementRef} editModal={editModal} />
      </HeaderDiv>
      <ContentDiv className={classNames('section-content', `${section.type}`)} ref={sectionElementRef}>
        {renderItem(section)}
      </ContentDiv>
    </ContainerDiv>
  )
}

export default ReportSection
