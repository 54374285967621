import Icon, { UserOutlined, GlobalOutlined, LogoutOutlined } from '@ant-design/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Avatar, Dropdown, MenuProps } from 'antd'
import { currentUserSelector } from '../../redux/session/currentUser/selectors'
import { useLogout } from '../../services/api/hooks/useLogout'

const AvatarMenu = () => {
  const navigate = useNavigate()
  const logout = useLogout()
  const { t, i18n } = useTranslation()
  const { displayName } = useSelector(currentUserSelector)

  const handleLocaleClick = ({ key }: { key: string }) => {
    i18n.changeLanguage(key)
  }

  const items: MenuProps['items'] = [
    {
      key: 'displayName',
      label: displayName
    },
    {
      type: 'divider'
    },
    {
      key: 'profile',
      label: t('header:profile'),
      icon: <UserOutlined />,
      onClick: () => navigate('/settings/user/profile')
    },
    {
      key: 'locale',
      label: t('header:locale'),
      icon: <GlobalOutlined />,
      children: [
        {
          key: 'fi',
          label: t('header:finnish'),
          icon: <Icon component={() => <span>🇫🇮</span>} />,
          onClick: handleLocaleClick
        },
        {
          key: 'en',
          label: t('header:english'),
          icon: <Icon component={() => <span>🇬🇧</span>} />,
          onClick: handleLocaleClick
        },
        {
          key: 'sv',
          label: t('header:swedish'),
          icon: <Icon component={() => <span>🇸🇪</span>} />,
          onClick: handleLocaleClick
        }
      ]
    },
    {
      key: 'logout',
      label: t('header:logout'),
      icon: <LogoutOutlined />,
      onClick: logout
    }
  ]

  const menuProps = {
    items
  }

  return (
    <Dropdown placement="bottomLeft" menu={menuProps}>
      <Avatar className="avatar" icon={<UserOutlined />} size="default" style={{ marginInline: 8 }} />
    </Dropdown>
  )
}

export default AvatarMenu
