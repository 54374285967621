import { useQuery } from '@tanstack/react-query'
import { fetchJiraComponents } from '../service/jiraService'
import jiraQueryKeys from './jiraQueryKeys'

export const useUseJiraComponents = () => {
  return useQuery({
    queryKey: jiraQueryKeys.components,
    queryFn: fetchJiraComponents
  })
}
