import { CustomReportSortOrder } from '../../../pages/reporting/custom/components/SortButton'
import { QuickFilterForm } from '../../../pages/reporting/custom/components/QuickFilters'
import { CustomReportSectionsType, CustomReportTableSection, CustomReportChartSection } from './typesSection'
import { CustomReportVariable, VariableRow, ExplainerVariable } from './typesVariable'
import { CustomReportCategory } from './typesCategory'
import { SetCompanyAction } from '../company/types'
import { ResponsiveLayouts } from '../../../pages/reporting/custom/components/grid.utils'

export const SET_CUSTOM_REPORTS = 'SET_CUSTOM_REPORTS'
export const SET_CUSTOM_REPORT = 'SET_CUSTOM_REPORT'
export const UPDATE_CUSTOM_REPORT = 'UPDATE_CUSTOM_REPORT'
export const SET_CALCULATED_REPORT = 'SET_CALCULATED_REPORT'
export const SET_SECTION_DATA = 'SET_SECTION_DATA'
export const SET_VARIABLE_ORDER = 'SET_VARIABLE_ORDER'
export const ADD_SECTION_VARIABLE = 'ADD_SECTION_VARIABLE'
export const ADD_SECTION_VARIABLE_EXPLAINER = 'ADD_SECTION_VARIABLE_EXPLAINER'
export const UPDATE_SECTIONS = 'UPDATE_SECTIONS'
export const ADD_CUSTOM_REPORT_SECTION = 'ADD_CUSTOM_REPORT_SECTION'
export const EDIT_CUSTOM_REPORT_SECTION = 'EDIT_CUSTOM_REPORT_SECTION'
export const DELETE_CUSTOM_REPORT_SECTION = 'DELETE_CUSTOM_REPORT_SECTION'
export const ORDER_COLUMN_ASCENDING = 'ORDER_COLUMN_ASCENDING'
export const ORDER_COLUMN_DESCENDING = 'ORDER_COLUMN_DESCENDING'
export const ORDER_COLUMN_DEFAULT = 'ORDER_COLUMN_DEFAULT'
export const RESET_CUSTOM_REPORT_ORDER = 'RESET_CUSTOM_REPORT_ORDER'
export const SET_DATE = 'SET_DATE'
export const EDIT_CUSTOM_REPORT_SECTION_STYLE = 'EDIT_CUSTOM_REPORT_SECTION_STYLE'
export const SET_QUICK_FILTERS = 'SET_QUICK_FILTERS'
export const EMPTY_QUICK_FILTERS = 'EMPTY_QUICK_FILTERS'
export const SET_ALL_SECTION_DATA = 'SET_ALL_SECTION_DATA'

interface CustomReportStyle {
  orientation: 'landscape' | 'portrait'
}

export interface CustomReport {
  id: number
  title: string
  sections: CustomReportSectionsType[]
  style: CustomReportStyle
  layouts?: ResponsiveLayouts
  name?: string
  companyId?: string
  groupId?: number | null
  order: number
  type?: string
  group?: boolean
}

export interface CustomReportGroup {
  id: number
  groupId?: number | null
  name: string
  order: number
  group: boolean
}

export enum TimePeriodOptions {
  FiscalyearToDate = 'fiscalyeartodate',
  FiscalyearFirstMonth = 'fiscalyearfirstmonth',
  PreviousFiscalyear = 'previousfiscalyear',
  Currentfiscalyear = 'currentfiscalyear',
  CurrentMonth = 'currentmonth',
  CurrentYear = 'currentyear',
  PreviousFiscalyearToDate = 'previousfiscalyeartodate',
  YearToDate = 'yeartodate',
  PreviousYear = 'previousyear',
  Previousyeartodate = 'previousyeartodate',
  Past12months = 'past12months',
  Quarter = 'quarter',
  FromToDate = 'fromtodate',
  CustomPeriod = 'customperiod',
  CustomYear = 'customyear',
  CustomMonth = 'custommonth',
  CumulativeFiscalyear = 'cumulativefiscalyear',
  CurrentWeek = 'currentweek',
  CurrentDay = 'currentday'
}

export interface UpdateSectionsAction {
  type: typeof UPDATE_SECTIONS
  payload: CustomReportSectionsType[]
}

export interface SetCustomReportsAction {
  type: typeof SET_CUSTOM_REPORTS
  payload: CustomReport[]
}

export interface DeleteCustomReportSectionAction {
  type: typeof DELETE_CUSTOM_REPORT_SECTION
  payload: {
    sectionId: CustomReportSectionsType['id']
    reportId: CustomReport['id']
  }
}

export interface AddCustomReportSectionAction {
  type: typeof ADD_CUSTOM_REPORT_SECTION
  payload: CustomReportSectionsType
}

export interface EditCustomReportSectionAction {
  type: typeof EDIT_CUSTOM_REPORT_SECTION
  payload: CustomReportSectionsType
}

export interface SetCustomReportAction {
  type: typeof SET_CUSTOM_REPORT
  payload: CustomReport
}

export interface EditCustomReportSectionStyleAction {
  type: typeof EDIT_CUSTOM_REPORT_SECTION_STYLE
  payload: CustomReportTableSection | CustomReportChartSection
}

export interface UpdateCustomReportAction {
  type: typeof UPDATE_CUSTOM_REPORT
  payload: Partial<CustomReport>
}

export interface SetCalculatedReportAction {
  type: typeof SET_CALCULATED_REPORT
  payload: CustomReport | null
}

export interface SetSectionDataAction {
  type: typeof SET_SECTION_DATA
  payload: CustomReportSectionsType
}

export interface SetAllSectionDataAction {
  type: typeof SET_ALL_SECTION_DATA
  payload: CustomReportSectionsType[]
}

export interface SetVariableOrderAction {
  type: typeof SET_VARIABLE_ORDER
  payload: { reportId: number; sectionId: number; order: Partial<CustomReportVariable>[] }
}

export interface AddSectionVariableAction {
  type: typeof ADD_SECTION_VARIABLE
  payload: { reportId: number; variable: CustomReportVariable[] }
}
export interface AddSectionVariableExplainerAction {
  type: typeof ADD_SECTION_VARIABLE_EXPLAINER
  payload: { explainer: ExplainerVariable; variable: VariableRow }
}

export interface ResetCustomReportOrderAction {
  type: typeof RESET_CUSTOM_REPORT_ORDER
}

export interface OrderColumnDefaultAction {
  type: typeof ORDER_COLUMN_DEFAULT
  payload: {
    row: VariableRow
    column: CustomReportCategory
    sectionId: number
  }
}

export interface OrderColumnAscendingAction {
  type: typeof ORDER_COLUMN_ASCENDING
  payload: {
    row: VariableRow
    column: CustomReportCategory
    sectionId: number
  }
}

export interface OrderColumnDescendingAction {
  type: typeof ORDER_COLUMN_DESCENDING
  payload: {
    row: VariableRow
    column: CustomReportCategory
    sectionId: number
  }
}

export interface SetCustomReportDateAction {
  type: typeof SET_DATE
  payload: string
}

export interface SetCustomReportQuickFiltersAction {
  type: typeof SET_QUICK_FILTERS
  payload: QuickFilterForm
}

export interface EmptyCustomReportQuickFiltersAction {
  type: typeof EMPTY_QUICK_FILTERS
}

export type CustomReportsActionTypes =
  | SetCompanyAction
  | SetCustomReportsAction
  | DeleteCustomReportSectionAction
  | AddCustomReportSectionAction
  | EditCustomReportSectionAction
  | SetCustomReportAction
  | SetCalculatedReportAction
  | SetSectionDataAction
  | SetVariableOrderAction
  | AddSectionVariableAction
  | SetCustomReportDateAction
  | AddSectionVariableExplainerAction
  | EditCustomReportSectionStyleAction
  | OrderColumnAscendingAction
  | OrderColumnDescendingAction
  | OrderColumnDefaultAction
  | ResetCustomReportOrderAction
  | SetCustomReportQuickFiltersAction
  | EmptyCustomReportQuickFiltersAction
  | SetCustomReportsAction
  | UpdateSectionsAction
  | UpdateCustomReportAction

export interface CustomReportStore {
  date?: string
  calculated: CustomReport | null
  sort: {
    [key: string]: {
      [key: string]: {
        columnId: string
        sortOrder: CustomReportSortOrder
      }
    }
  }
  quickFilters?: QuickFilterForm
}
