import { useMutation, useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { CustomReportOrderData } from '../../../../pages/reporting/custom/components/settings/CustomReportOrder'
import { updateStructure } from '../../service/customReportStructureService'
import customReportQueryKeys from '../customReportQueryKeys'

export const useUpdateStructure = () => {
  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { notification } = App.useApp()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: CustomReportOrderData[]) => updateStructure(data, companyId),
    onSuccess: () => {
      notification.success({
        message: t('message:UPDATE_CUSTOM_REPORT_STRUCTURE_SUCCESS', 'Request sent')
      })
      return queryClient.invalidateQueries({
        queryKey: customReportQueryKeys.list({ companyId })
      })
    }
  })
}
