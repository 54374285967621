import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { deleteForecast } from '../../service/forecastService'
import forecastQueryKeys from './forecastQueryKeys'
import useDeleteQueryItem from '../../../../services/api/hooks/useDeleteQueryItem'
import { useForecastOptions } from './useForecastOptions'
import { BudgetingScenario } from '../../types/BudgetingScenario'

export const useDeleteForecast = () => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const options = useForecastOptions({ companyId })
  const deleteQueryItem = useDeleteQueryItem()

  if (!companyId) {
    throw new Error('Company ID is required for deleting budgeting scenarios.')
  }

  return useMutation({
    mutationKey: forecastQueryKeys.list(companyId),
    mutationFn: (scenarioId: BudgetingScenario['id']) => deleteForecast(companyId, scenarioId),
    onSuccess(item) {
      deleteQueryItem(item, options)
    }
  })
}
