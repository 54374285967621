import { DeleteOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Collapse, DatePicker, Divider, Form, Popconfirm, Select, Space, theme } from 'antd'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'antd/es/form/Form'
import { TimePeriodOptions } from '../../../../../redux/context/customReports/types'
import { CustomReportCategory, OffsetUnit } from '../../../../../redux/context/customReports/typesCategory'
import { ReportDataType } from '../../../../../redux/context/reports/types'
import { CategoryContext, CategoryContextType } from './CategoryContext'
import ColorPicker from './ColorPicker'
import { FormattedInputNumber } from '../../../../../components/Misc/FormattedInputNumber'
import SeriesStyleFields from '../chart/SeriesStyleFields'
import { FormItemGroup } from '../../../../../components/Form/FormItemContext'
import RowSettingsModal from '../RowSettingsModal'
import { getEndDate, getStartDate } from './utils'
import ScenarioCascader from '../../../../../features/scenario/components/cascader/ScenarioCascader'
import { useCtxFiscalYears } from '../../../../../features/fiscalYear/queries/report/useCtxFiscalYears'
import { useCtxCurrentFiscalYear } from '../../../../../features/fiscalYear/queries/report/useCtxCurrentFiscalYear'

const { MonthPicker, RangePicker, YearPicker } = DatePicker

interface PeriodGroupCategoryProps {
  path: string
  category: CustomReportCategory
  hideActions?: boolean
}

export const PeriodGroupCategory: React.FC<PeriodGroupCategoryProps> = ({
  path,
  category,
  hideActions
}: PeriodGroupCategoryProps) => {
  const [color, setColor] = useState<string | undefined>(category.color || 'rgb(255,255,255,1)')
  const [styleForm] = useForm()
  const { t } = useTranslation()
  const { token } = theme.useToken()
  const { editObject, deleteObject, getPathContext, sectionForm } = useContext(CategoryContext) as CategoryContextType
  const categoryContext = getPathContext(category?.id)
  const switchRowsAndColumns = useWatch(['style', 'switchRowsAndColumns'], sectionForm)
  const sectionType = useWatch('type', sectionForm)
  const { data: currentFiscalYear } = useCtxCurrentFiscalYear()
  const { data: fiscalYears } = useCtxFiscalYears()

  const categoryStylesEnabled = useMemo(
    () => switchRowsAndColumns && sectionType === 'graph',
    [switchRowsAndColumns, sectionType]
  )

  const onChange = (c: Partial<CustomReportCategory>) => {
    if (c) {
      const newCategory = {
        ...category,
        ...c
      }
      editObject(path, newCategory)
    }
  }

  useEffect(() => {
    styleForm.setFieldsValue(category)
  }, [category])

  const renderOffset = () => {
    return (
      [
        TimePeriodOptions.CurrentMonth,
        TimePeriodOptions.Past12months,
        TimePeriodOptions.Quarter,
        TimePeriodOptions.YearToDate,
        TimePeriodOptions.Previousyeartodate,
        TimePeriodOptions.FiscalyearToDate,
        TimePeriodOptions.PreviousFiscalyearToDate,
        TimePeriodOptions.Currentfiscalyear,
        TimePeriodOptions.PreviousFiscalyear,
        TimePeriodOptions.CurrentYear,
        TimePeriodOptions.FiscalyearFirstMonth,
        TimePeriodOptions.CumulativeFiscalyear,
        TimePeriodOptions.CurrentWeek,
        TimePeriodOptions.CurrentDay
      ].includes(category.value as TimePeriodOptions) && (
        <>
          <FormattedInputNumber
            placeholder="offset"
            value={category.offset}
            onChange={offset => {
              onChange({ offset: offset as number })
              if (!category.offsetUnit) {
                onChange({ offsetUnit: OffsetUnit.month })
              }
            }}
          />
          <Select
            value={category.offsetUnit || OffsetUnit.month}
            onChange={offsetUnit => onChange({ offsetUnit })}
            style={{ width: 100 }}
            placeholder="offset unit"
          >
            {Object.keys(OffsetUnit).map((val: string) => (
              <Select.Option value={val} key={val}>
                {t(`global:${val}`)}
              </Select.Option>
            ))}
          </Select>
        </>
      )
    )
  }

  const renderCustomPeriod = () => {
    return (
      category.value === TimePeriodOptions.CustomPeriod && (
        <RangePicker
          format="YYYY-MM"
          value={[dayjs(category.startDate) as any, dayjs(category.endDate) as any]}
          onPanelChange={dates => {
            dates &&
              onChange({
                startDate: dates[0]?.startOf('month').format('YYYY-MM-DD'),
                endDate: dates[1]?.endOf('month')?.format('YYYY-MM-DD')
              })
          }}
          mode={['month', 'month']}
        />
      )
    )
  }

  const renderCustomMonth = () => {
    return (
      category.value === TimePeriodOptions.CustomMonth && (
        <MonthPicker
          value={category.startDate ? (dayjs(category.startDate) as any) : null}
          onChange={date => {
            onChange({
              startDate: date?.utc().startOf('month').format('YYYY-MM-DD'),
              endDate: date?.utc().endOf('month').format('YYYY-MM-DD')
            })
          }}
        />
      )
    )
  }

  const renderCustomYear = () => {
    return (
      category.value === TimePeriodOptions.CustomYear && (
        <YearPicker
          value={category.startDate ? (dayjs(category.startDate) as any) : null}
          onChange={date => {
            onChange({
              startDate: date?.utc().startOf('year').format('YYYY-MM-DD'),
              endDate: date?.utc().endOf('year').format('YYYY-MM-DD')
            })
          }}
        />
      )
    )
  }

  const renderFromToDate = () => {
    return (
      category.value === TimePeriodOptions.FromToDate && (
        <MonthPicker
          value={dayjs(category.startDate) as any}
          onChange={date => {
            onChange({
              startDate: date?.startOf('month').format('YYYY-MM-DD')
            })
          }}
        />
      )
    )
  }

  const handleColorChange = (newColor?: string) => {
    setColor(newColor)
    onChange({ color: newColor })
  }

  return (
    <div>
      <Space size={0} split={<Divider type="vertical" />}>
        <Select
          onChange={(value: string | number) =>
            onChange({
              value,
              startDate: getStartDate(value as string, currentFiscalYear, fiscalYears),
              endDate: getEndDate(value as string, currentFiscalYear, fiscalYears)
            })
          }
          showSearch
          optionFilterProp="children"
          style={{ width: 200 }}
          dropdownStyle={{ width: 300 }}
          value={category.value as string | number}
        >
          {Object.values(TimePeriodOptions)
            .sort((a, b) => t(`customReportPage:${a}`).localeCompare(t(`customReportPage:${b}`)))
            .map(timePeriodOption => {
              return (
                <Select.Option key={timePeriodOption} value={timePeriodOption}>
                  {t(`customReportPage:${timePeriodOption}`)}
                </Select.Option>
              )
            })}
        </Select>
        <ScenarioCascader
          companyId={categoryContext.companyId}
          value={
            category.dataType
              ? [category.dataType as string, category.budgetingScenarioId || null]
              : [ReportDataType.actuals as string]
          }
          onChange={([dataType, budgetingScenarioId]: (string | number | null)[]) => {
            return onChange({
              dataType: dataType as CustomReportCategory['dataType'],
              budgetingScenarioId: budgetingScenarioId as number | null
            })
          }}
          allowClear={false}
          style={{ width: 200 }}
        />
        {renderOffset()}
        {renderCustomPeriod()}
        {renderCustomMonth()}
        {renderFromToDate()}
        {renderCustomYear()}
        {!hideActions && (
          <>
            {!categoryStylesEnabled && categoryContext?.function !== 'sum' && (
              <ColorPicker value={color} onChange={handleColorChange} />
            )}

            <Popconfirm
              title={t('global:delete-confirm')}
              onConfirm={() => deleteObject(path)}
              okText={t('global:yes')}
              cancelText={t('global:no')}
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )}
        <RowSettingsModal onChange={onChange} path={path} category={category} showKeyValue={!hideActions} />
      </Space>
      {categoryStylesEnabled && !categoryContext?.function ? (
        <Collapse
          bordered={false}
          size="small"
          expandIcon={({ isActive }) => <SettingOutlined rotate={isActive ? 90 : 0} />}
          style={{
            background: token.colorBgContainer,
            margin: 0,
            marginTop: 4
          }}
          items={[
            {
              key: '1',
              label: t('global:style'),
              children: (
                <Space>
                  <Form
                    key={path}
                    layout="inline"
                    form={styleForm}
                    onValuesChange={(val, allVals) => onChange(allVals)}
                  >
                    <FormItemGroup prefix={['style']}>
                      <SeriesStyleFields />
                    </FormItemGroup>
                  </Form>
                </Space>
              )
            }
          ]}
        />
      ) : null}
    </div>
  )
}
