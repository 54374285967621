import { Action, ThunkAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { Dispatch } from 'react'
import { Company } from '../../../types/company/Company'
import {
  DELETE,
  DeleteRequestAction,
  GET,
  GetRequestAction,
  POST,
  PostRequestAction,
  PUT,
  PutRequestAction,
  REQUEST
} from '../../middleware/types'
import { Store } from '../../types'
import {
  AddCustomReportSectionAction,
  ADD_CUSTOM_REPORT_SECTION,
  CustomReport,
  DeleteCustomReportSectionAction,
  DELETE_CUSTOM_REPORT_SECTION,
  EditCustomReportSectionAction,
  EDIT_CUSTOM_REPORT_SECTION,
  SetCalculatedReportAction,
  SET_CALCULATED_REPORT,
  SetCustomReportDateAction,
  SET_DATE,
  SET_SECTION_DATA,
  SetSectionDataAction,
  AddSectionVariableExplainerAction,
  ADD_SECTION_VARIABLE_EXPLAINER,
  UPDATE_CUSTOM_REPORT,
  UpdateCustomReportAction,
  EDIT_CUSTOM_REPORT_SECTION_STYLE,
  EditCustomReportSectionStyleAction,
  OrderColumnAscendingAction,
  ORDER_COLUMN_ASCENDING,
  OrderColumnDescendingAction,
  ORDER_COLUMN_DESCENDING,
  OrderColumnDefaultAction,
  ORDER_COLUMN_DEFAULT,
  SetCustomReportQuickFiltersAction,
  SET_QUICK_FILTERS,
  EmptyCustomReportQuickFiltersAction,
  EMPTY_QUICK_FILTERS,
  UPDATE_SECTIONS,
  UpdateSectionsAction
} from './types'
import { QuickFilterForm } from '../../../pages/reporting/custom/components/QuickFilters'
import { CustomReportCategory } from './typesCategory'
import {
  CustomReportSectionsType,
  CustomReportTableSection,
  CustomReportChartSection,
  CustomReportTextSection
} from './typesSection'
import { VariableRow, ExplainerVariable } from './typesVariable'
import { Language } from '../../../types/user/User'

export const addCustomReportSection = (payload: CustomReportSectionsType): AddCustomReportSectionAction => ({
  type: ADD_CUSTOM_REPORT_SECTION,
  payload
})

export const editCustomReportSection = (payload: CustomReportSectionsType): EditCustomReportSectionAction => ({
  type: EDIT_CUSTOM_REPORT_SECTION,
  payload
})

export const editCustomReportSectionStyle = (
  payload: CustomReportTableSection | CustomReportChartSection
): EditCustomReportSectionStyleAction => ({
  type: EDIT_CUSTOM_REPORT_SECTION_STYLE,
  payload
})

export const deleteCustomReportSection = (
  sectionId: CustomReportSectionsType['id'],
  reportId: CustomReport['id']
): DeleteCustomReportSectionAction => ({
  type: DELETE_CUSTOM_REPORT_SECTION,
  payload: { sectionId, reportId }
})

// Report actions
export const updateSections = (sections: CustomReportSectionsType[]): UpdateSectionsAction => ({
  type: UPDATE_SECTIONS,
  payload: sections
})

export const updateCustomReportAction = (report: Partial<CustomReport>): UpdateCustomReportAction => ({
  type: UPDATE_CUSTOM_REPORT,
  payload: report
})

export const setQuickFiltersData = (filters: QuickFilterForm): SetCustomReportQuickFiltersAction => ({
  type: SET_QUICK_FILTERS,
  payload: filters
})

export const emptyQuickFiltersData = (): EmptyCustomReportQuickFiltersAction => ({
  type: EMPTY_QUICK_FILTERS
})

export const setCalculatedReport = (report: CustomReport | null): SetCalculatedReportAction => ({
  type: SET_CALCULATED_REPORT,
  payload: report
})

export const setSectionData = (section: CustomReportSectionsType): SetSectionDataAction => ({
  type: SET_SECTION_DATA,
  payload: section
})

export const oderColumnDefault = (
  sectionId: number,
  row: VariableRow,
  column: CustomReportCategory
): OrderColumnDefaultAction => {
  return {
    type: ORDER_COLUMN_DEFAULT,
    payload: {
      row,
      column,
      sectionId
    }
  }
}

export const oderColumnAscending = (
  sectionId: number,
  row: VariableRow,
  column: CustomReportCategory
): OrderColumnAscendingAction => {
  return {
    type: ORDER_COLUMN_ASCENDING,
    payload: {
      row,
      column,
      sectionId
    }
  }
}

export const oderColumnDescending = (
  sectionId: number,
  row: VariableRow,
  column: CustomReportCategory
): OrderColumnDescendingAction => {
  return {
    type: ORDER_COLUMN_DESCENDING,
    payload: {
      row,
      column,
      sectionId
    }
  }
}

export const addSectionVariableExplainer = (
  explainer: ExplainerVariable,
  variable: VariableRow
): AddSectionVariableExplainerAction => ({
  type: ADD_SECTION_VARIABLE_EXPLAINER,
  payload: { explainer, variable }
})

export const setCustomReportDate = (payload: string): SetCustomReportDateAction => ({
  type: SET_DATE,
  payload
})

// ****************
// Report requests
// ****************

export const updateCustomReportRequest = (
  companyId: Company['id'],
  id: CustomReport['id'],
  data: Partial<CustomReport>
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    data,
    method: PUT,
    url: `/api/companies/${companyId}/reporting/custom/reports/${id}`,
    success: updateCustomReportAction
  },
  meta: {
    type: 'CUSTOM_REPORTS'
  }
})

// ****************
// Section requests
// ****************

export const getCustomReportSectionRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: CustomReportSectionsType['id'],
  date?: string,
  quickFilter?: QuickFilterForm
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: {
      date: date && dayjs(date).endOf('month').format('YYYY-MM-DD'),
      quickFilter
    },
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${sectionId}`,
    success: setSectionData
  },
  meta: {
    type: `CUSTOM_REPORT_SECTION_${sectionId}`
  }
})

export const deleteCustomReportSectionRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: CustomReportSectionsType['id']
): DeleteRequestAction => ({
  type: REQUEST,
  payload: {
    method: DELETE,
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${sectionId}`,
    success: () => deleteCustomReportSection(sectionId, reportId)
  },
  meta: {
    type: 'CUSTOM_REPORT_SECTION'
  }
})

const editCustomReportSectionAndFetchData = (
  payload: CustomReportSectionsType,
  styleUpdate: boolean | undefined,
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: CustomReportSectionsType['id'],
  date?: string,
  quickFilters?: QuickFilterForm
) => {
  return (dispatch: any) => {
    if (styleUpdate) {
      dispatch(editCustomReportSectionStyle(payload as CustomReportTableSection | CustomReportChartSection))
    }
    if (!styleUpdate) {
      dispatch(editCustomReportSection(payload))
      dispatch(getCustomReportSectionRequest(companyId, reportId, sectionId, date, quickFilters))
    }
  }
}

export const updateCustomReportSectionRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: CustomReportSectionsType['id'],
  data: CustomReportSectionsType,
  styleUpdate?: boolean,
  date?: string,
  quickFilters?: QuickFilterForm
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data,
    params: {
      date: date && (data as CustomReportTextSection)?.text && dayjs(date).endOf('month').format('YYYY-MM-DD')
    },
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${sectionId}`,
    success: (payload: CustomReportSectionsType) =>
      editCustomReportSectionAndFetchData(payload, styleUpdate, companyId, reportId, sectionId, date, quickFilters)
  },
  meta: {
    type: 'CUSTOM_REPORT_SECTION'
  }
})

const addCustomReportSectionAndFetchData = (
  payload: CustomReportSectionsType,
  companyId: Company['id'],
  reportId: CustomReport['id'],
  date?: string,
  quickFilters?: QuickFilterForm
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(addCustomReportSection(payload))
    dispatch(getCustomReportSectionRequest(companyId, reportId, payload.id, date, quickFilters))
  }
}

export const addCustomReportSectionRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  section: CustomReportSectionsType,
  date?: string,
  quickFilters?: QuickFilterForm
): PostRequestAction => ({
  type: REQUEST,
  payload: {
    method: POST,
    data: section,
    params: {
      date: (section as CustomReportTextSection).text && date && dayjs(date).endOf('month').format('YYYY-MM-DD')
    },
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections`,
    success: (payload: CustomReportSectionsType) =>
      addCustomReportSectionAndFetchData(payload, companyId, reportId, date, quickFilters)
  },
  meta: {
    type: 'CUSTOM_REPORT_SECTION'
  }
})

export const updateCustomReportSectionOrdersRequest = (
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sections: Partial<CustomReportSectionsType>[]
): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    data: sections,
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/order`
  },
  meta: {
    type: 'UPDATE_CUSTOM_REPORTS_SECTION_ORDERS'
  }
})

// ****************
// Variable requests
// ****************

export const deleteVariablesAction = (actions: any[]): ThunkAction<void, Store, null, Action<string>> => {
  return dispatch => {
    actions.forEach((action: any) => {
      dispatch(action)
    })
  }
}

export const getVariableExplainerRequest = (
  companyId: Company['id'],
  reportId: number,
  variable: VariableRow,
  date: string,
  lng: Language,
  quickFilter?: QuickFilterForm
): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    params: { sectionId: variable.sectionId, variableId: variable.id, date, lng, quickFilter },
    url: `/api/companies/${companyId}/reporting/custom/reports/${reportId}/sections/${variable.sectionId}/explain`,
    success: (payload: any) => addSectionVariableExplainer(payload.variables, variable)
  },
  meta: {
    type: `CUSTOM_REPORT_VARIABLE_EXPLAINER_${variable.id}`
  }
})
