import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'
import { ReportDataType } from '../reports/types'
import { Dimension } from '../../../types/dimension/Dimension'
import { BudgetingScenario } from '../../../features/scenario/types/BudgetingScenario'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { Column } from '../periodGroups/hooks'

export interface Filters {
  fiscalYear: FiscalYear | null
  periodGroup: FiscalYear | PeriodGroup | undefined
  dataTypes: ReportDataType[]
  displayEmptyRows: boolean
  dimensions?: string[]
  budgetingScenario?: BudgetingScenario
  cumulativeFilter?: Column
  showIncomeStatemetPercentageChange: boolean
  periodGroups?: PeriodGroup[]
}

export const SET_FISCAL_YEAR_FILTER = 'SET_FISCAL_YEAR_FILTER'
export const SET_DATATYPE_FILTER = 'SET_DATATYPE_FILTER'
export const TOGGLE_DATATYPE_FILTER = 'TOGGLE_DATATYPE_FILTER'
export const TOGGLE_DISPLAY_EMPTY_ROWS = 'TOGGLE_DISPLAY_EMPTY_ROWS'
export const SET_DIMENSION_FILTER = 'SET_DIMENSION_FILTER'
export const SET_DIMENSIONS_FILTER = 'SET_DIMENSIONS_FILTER'
export const SET_BUDGETING_SCENARIO_FILTER = 'SET_BUDGETING_SCENARIO_FILTER'
export const SET_CONTEXT_FILTER = 'SET_CONTEXT_FILTER'
export const SET_CUMULATIVE_FILTER = 'SET_CUMULATIVE_FILTER'
export const TOGGLE_SHOW_INCOME_STATEMENT_PERCENTAGE_CHANGE = 'TOGGLE_SHOW_INCOME_STATEMENT_PERCENTAGE_CHANGE'
export const ADD_PERIOD_GROUP = 'ADD_PERIOD_GROUP'
export const SET_PERIOD_GROUPS = 'SET_PERIOD_GROUPS'
export const REMOVE_PERIOD_GROUP = 'REMOVE_PERIOD_GROUP'
export const SET_PERIOD_GROUP_FILTER = 'SET_PERIOD_GROUP_FILTER'
export const TOGGLE_CUMULATIVE_FILTER = 'TOGGLE_CUMULATIVE_FILTER'
export const RESET_CONTEXT_FILTER = 'RESET_CONTEXT_FILTER'

export interface ToggleCumulativeFilterAction {
  type: typeof TOGGLE_CUMULATIVE_FILTER
  payload: Column
}

export interface SetCumulativeFilterAction {
  type: typeof SET_CUMULATIVE_FILTER
  payload: any
}

export interface SetFiscalYearFilterAction {
  type: typeof SET_FISCAL_YEAR_FILTER
  payload: FiscalYear | null
}

export interface SetDataTypeFilterAction {
  type: typeof SET_DATATYPE_FILTER
  payload: ReportDataType[]
}

export interface ToggleDataTypeFilterAction {
  type: typeof TOGGLE_DATATYPE_FILTER
  payload: ReportDataType
}

export interface ToggleDisplayEmptyRowsAction {
  type: typeof TOGGLE_DISPLAY_EMPTY_ROWS
}

export interface ToggleShowIncomeStatementPercentageChangeAction {
  type: typeof TOGGLE_SHOW_INCOME_STATEMENT_PERCENTAGE_CHANGE
}

export interface SetContextFilterAction {
  type: typeof SET_CONTEXT_FILTER
  payload: {
    dimension?: Dimension
    budgetingScenario?: BudgetingScenario
    periodGroups?: PeriodGroup[]
  }
}

export interface SetDimensionsFilterAction {
  type: typeof SET_DIMENSIONS_FILTER
  payload?: string[]
}

export interface SetBudgetingScenarioFilterAction {
  type: typeof SET_BUDGETING_SCENARIO_FILTER
  payload?: BudgetingScenario
}

export interface SetPeriodGroupsAction {
  type: typeof SET_PERIOD_GROUPS
  payload?: PeriodGroup[]
}

export interface AddPeriodGroupAction {
  type: typeof ADD_PERIOD_GROUP
  payload: PeriodGroup
}

export interface RemovePeriodGroupAction {
  type: typeof REMOVE_PERIOD_GROUP
  payload: PeriodGroup
}

export interface SetPeriodGroupFilterAction {
  type: typeof SET_PERIOD_GROUP_FILTER
  payload?: FiscalYear | PeriodGroup
}

export interface ResetContextFilterAction {
  type: typeof RESET_CONTEXT_FILTER
}

export type FilterActionTypes =
  | SetContextFilterAction
  | SetFiscalYearFilterAction
  | ToggleDisplayEmptyRowsAction
  | SetDataTypeFilterAction
  | ToggleDataTypeFilterAction
  | SetBudgetingScenarioFilterAction
  | SetCumulativeFilterAction
  | ToggleShowIncomeStatementPercentageChangeAction
  | SetPeriodGroupsAction
  | AddPeriodGroupAction
  | RemovePeriodGroupAction
  | SetPeriodGroupFilterAction
  | ToggleCumulativeFilterAction
  | SetDimensionsFilterAction
  | ResetContextFilterAction
