import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import uniqBy from 'lodash/uniqBy'
import { CustomExchangeRateSeries, CustomExchangeRateSeriesWithCompany } from '../types/exchangeRate'
import { currentUserSortedCompaniesSelector } from '../../../redux/session/currentUser/selectors'
import { useExchangeRatesOptions } from './useExchangeRateOptios'

export const useExchangeRatesQuery = <TData = CustomExchangeRateSeries[]>(
  select?: (data: CustomExchangeRateSeries[]) => TData
) => {
  const options = useExchangeRatesOptions(select)
  return useQuery(options)
}

export const useExchangeRates = () => {
  const companies = useSelector(currentUserSortedCompaniesSelector)
  return useExchangeRatesQuery(rates => {
    return uniqBy(
      rates.map(er => {
        const company = companies.find(s => s?.id === er.companyId)
        return {
          ...er,
          company,
          rates: er.rates.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        }
      }),
      'id'
    ) as CustomExchangeRateSeriesWithCompany[]
  })
}
export default useExchangeRates
